<template>
  <div>
    <embed style="width: 100%; height: 100vh" :src="pdfUrl" />
    <Head>
      <title>Titre livre - Doonun</title>
      <meta name="robots" content="Noindex, nofollow" />
    </Head>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { Head } from "@vueuse/head";

export default {
  name: "LivreSlug",
  components: {
    Head,
  },
  data() {
    return {
      pdfUrl: null,
    };
  },
  props: {},
  async mounted() {
    this.isLoading = true;
    const currentSlug = this.$route.params.slug;
    try {
      const response = await axios.get("/livres", {
        params: {
          "filters[url][$eq]": currentSlug,
          populate: ["pdf"],
        },
        headers: { Authorization: `Bearer ${this.getToken}` },
      });

      if (response.data.data.length > 0) {
        this.pdfUrl = `https://cp.doonun.com${response.data.data[0].attributes.pdf.data.attributes.url}`;
        this.error = false;

        // Ajouter la directive "frame-ancestors" au CSP
        const metaTag = document.querySelector(
          'meta[http-equiv="Content-Security-Policy"]'
        );
        if (metaTag) {
          const existingCSP = metaTag.getAttribute("content");
          metaTag.setAttribute(
            "content",
            `${existingCSP} frame-ancestors 'self' https://* http://* https://cp.doonun.com/`
          );
        }
      } else {
        console.error("Aucun livre correspondant au slug trouvé.");
        this.error = true;
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du livre:", error);
      this.error = true;
    } finally {
      this.isLoading = false;
    }
  },
  computed: {
    ...mapGetters("user", ["getToken"]),
  },
  methods: {},
};
</script>

<style lang="css" scoped>
#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* Page content */
.content {
  padding: 16px;
}
</style>
