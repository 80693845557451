<template>
  <div class="">
    <div class="c-psd">
      <div class="box-newpsd">
        <h1>Réinitialiser le mot de passe</h1>
        <Head>
          <title>Réinitialiser - Doonun</title>
          <meta name="robots" content="Noindex, nofollow" />
          <link
            rel="icon"
            href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
          />
        </Head>
        <form @submit.prevent="newPassword">
          <label for="password">Nouveau mot de passe</label>
          <div class="p">
            <input
              :type="showPassword ? 'text' : 'password'"
              name="password"
              v-model="password"
              placeholder="Nouveau mot de passe"
              @input="clearMessages"
            />
            <span class="toggle-password" @click="togglePasswordVisibility">
              <openEye v-if="showPassword" />
              <CloseEye v-if="!showPassword" />
            </span>
          </div>
          <label for="cPassword">Confirmez le mot de passe</label>
          <div class="cp">
            <input
              :type="showCPassword ? 'text' : 'password'"
              name="cPassword"
              v-model="confirmPassword"
              placeholder="Confirmez le mot de passe"
              @input="clearMessages"
            />
            <span class="toggle-password" @click="toggleCPasswordVisibility">
              <openEye v-if="showCPassword" />
              <CloseEye v-if="!showCPassword" />
            </span>
          </div>
          <p v-if="passwordMismatch" class="error-message">
            Les mots de passe ne correspondent pas.
          </p>
          <p v-if="success" class="success-message">
            Votre mot de passe a été réinitialisé avec succès.
          </p>
          <p v-if="errorMessage" class="error-message">
            {{ errorMessage }}
          </p>

          <button type="submit" class="">Soumettre</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import openEye from "../components/icons/openEye.vue";
import CloseEye from "../components/icons/closeEye.vue";
import { Head } from "@vueuse/head";

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      passwordMismatch: false,
      success: false,
      errorMessage: "",
      resetCode: "",
      showPassword: false,
      showCPassword: false,
    };
  },
  components: {
    openEye,
    CloseEye,
    Head,
  },
  created() {
    this.resetCode = this.$route.query.code;
  },
  methods: {
    clearMessages() {
      this.success = false;
      this.errorMessage = "";
      this.passwordMismatch = false;
    },
    validatePasswords() {
      this.passwordMismatch = false;
      this.errorMessage = "";

      // Vérifie si les mots de passe correspondent
      if (this.password !== this.confirmPassword) {
        this.passwordMismatch = true;
        this.errorMessage = "Les mots de passe ne correspondent pas.";
        return false;
      }

      // Vérifie la longueur du mot de passe
      if (this.password.length < 8) {
        this.errorMessage =
          "Le mot de passe doit contenir au moins 8 caractères.";
        return false;
      }

      // Vérifie si le mot de passe contient des lettres et des chiffres
      if (!(/[a-zA-Z]/.test(this.password) && /\d/.test(this.password))) {
        this.errorMessage =
          "Le mot de passe doit contenir des lettres et des chiffres.";
        return false;
      }

      return true;
    },
    async newPassword() {
      if (!this.validatePasswords()) {
        return;
      }

      await axios
        .post("/auth/reset-password", {
          code: this.resetCode,
          password: this.password,
          passwordConfirmation: this.confirmPassword,
        })
        .then(() => {
          this.success = true;
          console.log("Votre mot de passe a été réinitialisé avec succès.");
        })
        .catch((error) => {
          this.success = false;
          this.errorMessage = error.response
            ? error.response.data.message
            : "Une erreur est survenue.";
          console.log("Une erreur est survenue :", this.errorMessage);
        });
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleCPasswordVisibility() {
      this.showCPassword = !this.showCPassword;
    },
  },
};
</script>

<style scoped>
.c-psd {
  margin-top: -40px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: var(--card);
}

.box-newpsd {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  width: 600px;
  border-radius: 10px;
  padding: var(--padding) 40px;
}

.box-newpsd form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.box-newpsd form input {
  width: 100%;
  padding: 15px;
  color: var(--text);
  font-weight: 500;
  font-size: 15px;
}

.box-newpsd form button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  background: #614efa;
  font-size: 15px;
  cursor: pointer;
}

.p,
.cp {
  display: flex;
  gap: 5px;
  align-items: center;
  border: 1px solid var(--textMD);
  border-radius: 6px;
  padding-right: 10px;
  padding-left: 3px;
}

.p span,
.cp span {
  cursor: pointer;
}

.is-invalid {
  border-color: #ff3860;
}

.success-message {
  color: #23d160;
}

.error-message {
  color: #ff3860;
}

@media (max-width: 480px) {
  .box-newpsd {
    padding: var(--padding);
  }
}
</style>
