<template>
  <div class="">
    <div class="c-oublie">
      <div class="box-oublie">
        <h1>Mot de passe oublié ?</h1>
        <Head>
          <title>Mot de passe oublié ?</title>
          <meta name="robots" content="Noindex, nofollow" />
          <link
            rel="icon"
            href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
          />
        </Head>
        <p>
          Entrez l'adresse email associée à votre compte pour réinitialiser
          votre mot de passe. (N'accepte pas les comptes crées avec Google.)
        </p>
        <form @submit.prevent="forgotPassword">
          <input
            type="email"
            v-model.trim="email"
            placeholder="Saisissez votre email"
            :class="{ 'is-invalid': emailError }"
          />
          <p v-if="done" class="success-message">
            Un lien de réinitialisation a été envoyé à cet email.
          </p>
          <p v-if="emailError" class="error-message">
            Veuillez entrer une adresse email.
          </p>
          <p v-if="emailError || error" class="error-message">
            {{ errorMessage }}
          </p>
          <button type="submit" class="">Soumettre</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Head } from "@vueuse/head";

export default {
  name: "ForgotPassword",
  components: {
    Head,
  },
  data() {
    return {
      email: "",
      done: false,
      error: false,
      errorMessage: "", // Assurez-vous d'avoir un champ pour le message d'erreur
      emailError: false,
    };
  },
  methods: {
    clearMessages() {
      this.done = false;
      this.error = false;
      this.errorMessage = "";
      this.emailError = false;
    },
    validateEmail() {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      this.emailError = !re.test(this.email);
      if (this.emailError) {
        this.errorMessage = "Le format de l'email est invalide.";
      }
    },
    async checkEmailExists() {
      if (!this.email) {
        this.errorMessage = "Veuillez entrer une adresse email.";
        return false;
      }

      try {
        const response = await axios.get(
          `/users?filters[$and][0][email][$eq]=${this.email}`
        );
        if (response.data && response.data.length > 0) {
          return true;
        } else {
          this.errorMessage = "Aucun compte n'est lié à cet email.";
          return false;
        }
      } catch (error) {
        console.error("Erreur lors de la vérification de l'email:", error);
        this.errorMessage =
          "Impossible de vérifier l'email. Veuillez réessayer plus tard.";
        return false;
      }
    },
    async forgotPassword() {
      this.clearMessages();
      this.validateEmail();
      if (this.emailError) {
        this.error = true;
        return;
      }

      const emailExists = await this.checkEmailExists();
      if (!emailExists) {
        this.error = true;
        return;
      }

      try {
        await axios.post("/auth/forgot-password", {
          email: this.email,
        });
        this.done = true;
        this.errorMessage =
          "Un lien de réinitialisation a été envoyé à cet email.";
      } catch (errorResponse) {
        this.error = true;
        if (
          errorResponse &&
          errorResponse.response &&
          errorResponse.response.data
        ) {
          this.errorMessage =
            errorResponse.response.data.message ||
            "Une erreur est survenue lors de la réinitialisation du mot de passe.";
        } else {
          this.errorMessage = "Une erreur de réseau s'est produite.";
        }
      }
    },
  },
};
</script>

<style scoped>
.c-oublie {
  margin-top: -40px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: var(--card);
}

.box-oublie {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
  width: 600px;
  border-radius: 10px;
  padding: var(--padding) 40px;
}

.box-oublie form {
  width: 100%;
}

.box-oublie form input {
  width: 100%;
  padding: 15px;
  border: 1px solid var(--textMD);
  border-radius: 6px;
  color: var(--text);
  font-weight: 500;
  font-size: 15px;
}

.box-oublie form button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-weight: 600;
  background: #614efa;
  font-size: 15px;
  cursor: pointer;
}

.is-invalid {
  border-color: #ff3860;
}

.success-message {
  margin-top: 10px;
  color: #23d160;
}

.error-message {
  margin-top: 10px;
  color: #ff3860;
}

@media (max-width: 480px) {
  .box-oublie {
    padding: var(--padding);
  }
}
</style>
