import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import MarkdownIt from "markdown-it";
import { html5Media } from "markdown-it-html5-media";
import markdownItVideo from "markdown-it-video";
import markdownItMultimdTable from "markdown-it-multimd-table";
import { createHead } from "@vueuse/head";
const head = createHead();
require("./assets/css/markdown.css");
import "./assets/css/style.css";
import "./assets/css/theme.css";
import "./assets/css/tajweed.css";
import "./registerServiceWorker";

// Configuration globale d'Axios, si nécessaire
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const app = createApp(App);

const md = new MarkdownIt();

md.use(markdownItMultimdTable, {
  multiline: false,
  rowspan: false,
  headerless: false,
  multibody: true,
  autolabel: true,
});

md.use(html5Media, {
  audioAttrs: 'class="my-audio-css" controls',
});

md.use(markdownItVideo, {
  youtube: {
    width: "100%",
    height: "397",
    nocookie: false,
    modestbranding: 1,
    related: 0,
    showinfo: 0,
    parameters: {},
  },
  vimeo: { width: "100%", height: 281 },
  vine: { width: "100%", height: 600, embed: "simple" },
  prezi: { width: "100%", height: 400 },
});

const savedThemeColors = localStorage.getItem("themeColors");
if (savedThemeColors) {
  const themeColors = JSON.parse(savedThemeColors);
  applyTheme(themeColors);
}

// Méthode pour appliquer le thème
function applyTheme(themeColors) {
  document.documentElement.style.setProperty("--card", themeColors.card);
  document.documentElement.style.setProperty("--button", themeColors.button);
  document.documentElement.style.setProperty("--body", themeColors.body);
  document.documentElement.style.setProperty("--headProfil", themeColors.head);
  document.documentElement.style.setProperty("--bglogo", themeColors.bglogo);
  document.documentElement.style.setProperty(
    "--options-v-background-color",
    themeColors.option
  );
  document.documentElement.style.setProperty(
    "--truncated",
    themeColors.truncated
  );
}

app.config.globalProperties.$md = md;

// Attacher axios à l'instance de Vue pour l'utiliser partout
app.config.globalProperties.$axios = axios;

// app.use(StripePlugin);
app.use(router);
app.use(store);
app.use(head);
// app.use(createMetaManager())
router.afterEach(() => {
  window.scrollTo({ top: 0 });
});

router.onError((error) => {
  if (error.response && error.response.status === 500) {
    router.push({ name: "ServerError" });
  } else {
    console.error(error);
  }
});

(async () => {
  await store.dispatch("user/initializeAuthState");
  app.mount("#app");
})();
