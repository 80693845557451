// src/router.js

import { createRouter, createWebHistory } from "vue-router";
import store from "./store";

// Importez vos composants Vue ici
import Accueil from "./views/Accueil.vue";
import Coran from "./views/Coran.vue";
import Cours from "./views/Cours.vue";
import CoursId from "./views/CoursId.vue";
import Chapitre from "./views/Chapitre.vue";
import Fin from "./views/Fin.vue";
// import Hadith from "./views/Hadith.vue";
import Livres from "./views/Livres.vue";
import LivreSlug from "./views/LivreSlug.vue";
import Connexion from "./views/Connexion.vue";
import Oublie from "./views/Oublie.vue";
import Reset from "./views/Reset.vue";
import Inscription from "./views/Inscription.vue";
import Sourate from "./views/Sourate.vue";
import Verset from "./views/Verset.vue";
import Profil from "./views/Profil.vue";
import Echec from "./views/paiement/Echec.vue";
import Success from "./views/paiement/Success.vue";
import Parametres from "./components/Profil/Parametres.vue";
// import Kathir from "./views/Kathir.vue";
import DuaaSlug from "./views/DuaaSlug.vue";
import DuaaTitre from "./views/DuaaTitre.vue";
import Invocations from "./views/Invocations.vue";
import Redirect from "./views/Redirect.vue";
import Classement from "./views/Classement.vue";
import Avis from "./views/Avis.vue";
import PageNotFound from "./views/PageNotFound.vue";
import ServerError from "./views/ServerError.vue";
import Contact from "./views/Contact.vue";
import Utilisations from "./views/mentions/Utilisations.vue";
import CGV from "./views/mentions/CGV.vue";
import Cookies from "./views/mentions/Cookies.vue";
import Confirm from "./views/Confirm.vue";
import Priere from "./views/Priere.vue";
import Tarifs from "./views/Tarifs.vue";
import Actus from "./views/Actus.vue";
import Notification from "./views/Notification.vue";

const routes = [
  {
    path: "/connect/google/redirect",
    name: "redirect",
    component: Redirect,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/erreur-serveur",
    name: "ServerError",
    component: ServerError,
  },
  {
    path: "/",
    name: "accueil",
    component: Accueil,
  },
  {
    path: "/actus",
    name: "actus",
    component: Actus,
  },
  {
    path: "/mentions-legales",
    name: "mentions-legal",
    component: Utilisations,
  },
  {
    path: "/cgv",
    name: "cgv",
    component: CGV,
  },
  {
    path: "/cookies",
    name: "cookies",
    component: Cookies,
  },
  {
    path: "/priere",
    name: "priere",
    component: Priere,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/avis",
    name: "avis",
    component: Avis,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/classement",
    name: "classement",
    component: Classement,
  },
  {
    path: "/coran",
    name: "coran",
    component: Coran,
  },

  // {
  //     path: '/ibn-kathir/',
  //     name: 'ibn-kathir',
  //     component: Kathir
  // },
  // {
  //   path: "/ibn-kathir/:id/:subId",
  //   name: "tafsir",
  //   component: Kathir,
  // },
  {
    path: "/sourate/:id",
    name: "sourate",
    component: Sourate,
  },
  {
    path: "/versets",
    name: "versets",
    component: Verset,
  },
  {
    path: "/duaas",
    name: "duaas",
    component: Invocations,
  },
  {
    path: "/duaas/:slug",
    name: "duaa-slug",
    component: DuaaSlug,
  },
  {
    path: "/duaas/:slug/:subSlug",
    name: "duaa-slug-titre",
    component: DuaaTitre,
  },
  {
    path: "/cours",
    name: "cours",
    component: Cours,
  },
  {
    path: "/cours/:slug",
    name: "cours-slug",
    component: CoursId,
  },
  {
    path: "/fin/:slug",
    name: "fin",
    component: Fin,
  },
  {
    path: "/cours/:slug/:id",
    name: "chapitre",
    component: Chapitre,
  },
  // {
  //   path: "/hadith",
  //   name: "hadith",
  //   component: Hadith,
  // },
  {
    path: "/livres",
    name: "livres",
    component: Livres,
  },
  {
    path: "/livres/:slug",
    name: "livre-slug",
    component: LivreSlug,
  },
  {
    path: "/inscription",
    name: "inscription",
    component: Inscription,
  },
  {
    path: "/payment-success",
    name: "PaymentSuccess",
    component: Success,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/notif/:slug",
    name: "notif",
    component: Notification,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment-cancel",
    name: "PaymentCancel",
    component: Echec,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tarifs",
    name: "tarifs",
    component: Tarifs,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/connexion",
    name: "connexion",
    component: Connexion,
  },
  {
    path: "/oublie",
    name: "oublie",
    component: Oublie,
  },
  {
    path: "/reset",
    name: "reset",
    component: Reset,
  },
  {
    path: "/confirm",
    name: "confirm",
    component: Confirm,
  },
  {
    path: "/profil",
    name: "profil",
    component: Profil,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/parametres",
    name: "parametres",
    component: Parametres,
    meta: {
      requiresAuth: true,
    },
  },
];

const redirectUrlsForAuthenticatedUser = [
  "/connexion",
  "/inscription",
  "/oublie",
  "/reset",
  "/confirm",
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.getters["user/isLoggedIn"]; // utilisez store.getters["user/isLoggedIn"]

  if (requiresAuth && !isAuthenticated) {
    next("/connexion");
  } else if (
    redirectUrlsForAuthenticatedUser.includes(to.path) &&
    isAuthenticated
  ) {
    next("/profil");
  } else {
    next();
  }
});

export default router;
