<template>
    <div class="loop-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_repeate-music" data-name="vuesax/linear/repeate-music" transform="translate(-684 -514)">
                <g id="repeate-music">
                    <path id="Vector" d="M12.01,0l2.44,2.34L6.5,2.32A6.512,6.512,0,0,0,1.91,13.44"
                        transform="translate(685.99 517)" fill="none" stroke="#292d32" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector" d="M2.44,13.44,0,11.1l7.95.02A6.512,6.512,0,0,0,12.54,0"
                        transform="translate(691.56 521.56)" fill="none" stroke="#292d32" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M0,0H6" transform="translate(693 526)" fill="none"
                        stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(684 514)" fill="none"
                        opacity="0" />
                </g>
            </g>
        </svg>

    </div>
</template>
<script>
export default {
    name: 'loop-d'
}

</script>

<style scoped>
.loop-icon {
    display: flex;
    align-items: center;
}

.loop-icon svg {
    width: 20px;
    height: 20px;
}

.loop-icon svg path {
    stroke: var(--text) !important;
}
</style>