<template>
  <div class="container c">
    <Head>
      <title>Chapitre</title>
      <meta name="robots" content="index, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
    <div class="container-details-c">
      <div class="box-c">
        <div class="head-c">
          <div class="back-course">
            <router-link :to="`/cours/${this.slug}`">
              <Back />
            </router-link>
            <router-link :to="`/cours/${this.slug}`">
              <p>Page du cours</p>
            </router-link>
          </div>

          <div class="pr">
            <progressCircle :taux="percentage" />
          </div>
        </div>
        <div
          v-if="
            showContent &&
            chapitres &&
            chapitres.contenus &&
            chapitres.contenus.data.length > 0
          "
        >
          <h1>{{ chapitres.contenus.data[cCIndex].attributes.titre }}</h1>
          <span
            ><i class="fa-regular fa-lightbulb"></i> Chapitre
            {{ chapitres.numero }} : {{ chapitres.titre }}</span
          >
          <hr />
          <Head>
            <title>{{ chapitres.titre }}</title>
            <meta name="robots" content="index, nofollow" />
          </Head>
          <CourseAudio
            v-if="
              chapitres.contenus.data[cCIndex] &&
              chapitres.contenus.data[cCIndex].attributes.audio &&
              chapitres.contenus.data[cCIndex].attributes.audio.data &&
              chapitres.contenus.data[cCIndex].attributes.audio.data
                .attributes &&
              chapitres.contenus.data[cCIndex].attributes.audio.data.attributes
                .url
            "
            :urlAudio="
              chapitres.contenus.data[cCIndex].attributes.audio.data.attributes
                .url
            "
          />
          <hr
            v-if="
              chapitres.contenus.data[cCIndex] &&
              chapitres.contenus.data[cCIndex].attributes.audio &&
              chapitres.contenus.data[cCIndex].attributes.audio.data &&
              chapitres.contenus.data[cCIndex].attributes.audio.data
                .attributes &&
              chapitres.contenus.data[cCIndex].attributes.audio.data.attributes
                .url
            "
          />
          <div
            id="markdown"
            class="markdown-body"
            v-html="
              $md.render(chapitres.contenus.data[cCIndex].attributes.cours)
            "
          ></div>
          <div v-if="this.cCIndex > 0" class="back" @click="goBack()">
            <i class="fa-solid fa-chevron-left"></i>
            <p>Revenir en arrière</p>
          </div>
        </div>
        <div
          v-if="
            showQuiz &&
            chapitres &&
            chapitres.contenus.data[cCIndex] &&
            chapitres.contenus.data[cCIndex].attributes.quizzes.data[quizIndex]
          "
        >
          <h1>
            {{
              chapitres.contenus.data[cCIndex].attributes.quizzes.data[
                quizIndex
              ].attributes.Question
            }}
          </h1>
          <p>
            {{
              chapitres.contenus.data[cCIndex].attributes.quizzes.data[
                quizIndex
              ].attributes.Instruction
            }}
          </p>
          <form @submit.prevent="submitQuiz">
            <div class="quiz-option">
              <div
                v-for="option in quizOptions"
                :key="option.letter"
                class="option"
                :class="`option-${option.letter} ${optionClass(option.letter)}`"
                @click="selectOption(option.letter)"
              >
                <label :for="`option-${option.letter}`">{{
                  option.text
                }}</label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="controls-c">
        <div class="c-control">
          <div class="nxt" @click="showQuiz ? null : goForward()">
            <Suivant />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import Back from "../components/icons/Back.vue";
import progressCircle from "../components/icons/progressCircle.vue";
import Suivant from "../components/icons/Suivant.vue";
import correctSoundPath from "../assets/audios/quiz/correct.wav";
import incorrectSoundPath from "../assets/audios/quiz/error.mp3";
import { Head } from "@vueuse/head";
import CourseAudio from "../components/CourseAudio.vue";

export default {
  name: "chapitre-d",
  components: {
    Back,
    progressCircle,
    Suivant,
    Head,
    CourseAudio,
  },
  data() {
    return {
      chapitres: null,
      slug: this.$route.params.slug,
      idCours: null,
      idSuivi: null,
      id: null,
      totalChapters: null,
      cCIndex: 0,
      quizIndex: 0,
      errorCount: 0,
      showContent: true,
      showQuiz: false,
      selectedAnswer: null,
      user: null,
      percentage: 0,
      totalElementsCourse: 0,
      clickCount: 0,
      prevShowState: true,
      termine: null,
      lastErrorQuizIndex: -1,
      token: localStorage.getItem("jwtToken"),
      currentAudioElement: null,
    };
  },

  watch: {
    "$route.params.id"(newId, oldId) {
      console.log(
        `Route change detected. isNavigatingBack: ${this.isNavigatingBack}`
      );
      if (newId !== oldId) {
        this.fetchChaptersDetails();
        this.resetIndices();
        this.updateProgress(newId);
      }
    },
  },

  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),

    quizOptions() {
      const quiz =
        this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data[
          this.quizIndex
        ].attributes;
      return ["A", "B", "C", "D"].map((letter) => ({
        letter: letter,
        text: quiz[letter],
      }));
    },
  },

  methods: {
    ...mapActions("user", ["fetchUserInfo"]),

    async loadUserProgress() {
      const token = this.getToken;
      try {
        if (token && this.getUserInfo) {
          const progressResponse = await axios.get("/suivis", {
            params: {
              "filters[user_id][$eq]": [this.getUserInfo.id],
              // 'filters[id_cours][$eq]': [this.idCours],
              "filters[slug][$eq]": [this.$route.params.slug],
            },
            headers: { Authorization: `Bearer ${token}` },
          });

          this.idSuivi = progressResponse.data.data[0].attributes.id;
          if (progressResponse.data.data.length > 0) {
            let progressData = progressResponse.data.data[0].attributes;
            this.cCIndex = parseInt(progressData.id_contenu);
            this.quizIndex = parseInt(progressData.id_quiz) || 0;
            this.lastErrorQuizIndex = parseInt(progressData.indexError) || -1;
            this.percentage = parseInt(progressData.pourcentage);
            this.clickCount = parseInt(progressData.clicks);
            this.idCours = parseInt(progressData.id_cours);
            this.id = parseInt(progressData.id_chapitre);
            this.termine = progressData.termine;

            this.showContent = Boolean(progressData.content_active);
            this.showQuiz = Boolean(progressData.quiz_active);
            const targetUrl = `/cours/${this.$route.params.slug}/${progressData.id_chapitre}`;
            this.$router.push(targetUrl).then(() => {
              this.fetchChaptersDetails();
            });
          } else {
            // Aucune progression trouvée : charger le contenu du début
            this.fetchChaptersDetails();
          }
        }
      } catch (error) {
        // console.error("Error loading user progress:", error);
        this.fetchChaptersDetails();
      }
    },

    async updateProgress(newChapterId) {
      const token = this.getToken;
      try {
        if (token) {
          // Vérifier d'abord si une entrée existe pour cet utilisateur et ce cours
          const existingProgressResponse = await axios.get("/suivis", {
            params: {
              "filters[user_id][$eq]": [this.getUserInfo.id],
              "filters[slug][$eq]": [this.$route.params.slug],
            },
            headers: { Authorization: `Bearer ${token}` },
          });

          if (existingProgressResponse.data.data.length > 0) {
            // Mettre à jour les données de progression
            const suiviId = existingProgressResponse.data.data[0].id;
            await axios.put(
              `/suivis/${suiviId}`,
              {
                data: {
                  user_id: String(this.getUserInfo.id),
                  id_cours: String(this.idCours),
                  id_chapitre: newChapterId || this.$route.params.id,
                  id_quiz: String(this.quizIndex >= 0 ? this.quizIndex : 0),
                  indexError: String(
                    this.lastErrorQuizIndex >= 0
                      ? this.lastErrorQuizIndex
                      : "-1"
                  ),
                  id_contenu: String(this.cCIndex),
                  pourcentage: String(this.percentage),
                  clicks: String(this.clickCount),
                  elements: String(this.totalElementsCourse),
                  slug: String(this.$route.params.slug),
                  quiz_active: this.showQuiz,
                  content_active: this.showContent,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
            // console.log("Progression mise à jour:", response);
          } else {
            // Créer une nouvelle entrée de suivi
            await axios.post(
              `/suivis`,
              {
                data: {
                  user_id: String(this.getUserInfo.id),
                  id_cours: String(this.idCours),
                  id_chapitre: newChapterId || this.$route.params.id,
                  id_quiz: String(this.quizIndex >= 0 ? this.quizIndex : 0),
                  indexError: String(
                    this.lastErrorQuizIndex >= 0
                      ? this.lastErrorQuizIndex
                      : "-1"
                  ),
                  id_contenu: String(this.cCIndex),
                  pourcentage: String(this.percentage),
                  clicks: String(this.clickCount),
                  elements: String(this.totalElementsCourse),
                  slug: String(this.$route.params.slug),
                  quiz_active: this.showQuiz,
                  content_active: this.showContent,
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
            // console.log("Nouvelle progression créée:", newProgressResponse);
          }
        }
      } catch (error) {
        // console.error("Erreur lors de la mise à jour de la progression:", error);
      }
    },

    async fetchChaptersDetails() {
      const slug = this.$route.params.slug;
      await this.$store.dispatch("cours/fetchChaptersDetails", slug);

      // Accédez aux données du cours depuis l'état Vuex
      const courseDetails = this.$store.state.cours.currentCoursDetails;
      // console.log("cours détails", courseDetails);

      if (courseDetails) {
        this.idCours = courseDetails.id; // Assurez-vous que cela est correctement défini
        this.totalChapters = courseDetails.attributes.chapitres.data.length;

        // Trouver le chapitre correspondant à l'ID du chapitre actuel
        const currentChapter = courseDetails.attributes.chapitres.data.find(
          (chapitre) =>
            chapitre.attributes.numero === parseInt(this.$route.params.id)
        );
        if (currentChapter) {
          this.chapitres = currentChapter.attributes;
          // console.log("ce chapitre : ", this.chapitres);
        }

        // Calculer le total des éléments du cours
        let totalElementsCourse = 0;

        courseDetails.attributes.chapitres.data.forEach((chapitre) => {
          // Initialiser le total des éléments pour le chapitre courant
          let totalElementsForChapter = 0;

          chapitre.attributes.contenus.data.forEach((contenu) => {
            totalElementsForChapter += 1; // Compter chaque contenu comme un élément
            totalElementsForChapter += contenu.attributes.quizzes.data.length; // Ajouter le nombre de quiz comme éléments supplémentaires
          });

          // Ajouter le total des éléments du chapitre au total global des éléments du cours
          totalElementsCourse += totalElementsForChapter;
        });

        this.totalElementsCourse = totalElementsCourse;
        // console.log("total élement", this.totalElementsCourse)
      } else {
        // console.error("Aucun détail de cours trouvé dans l'état Vuex.");
      }
    },

    resetIndices() {
      this.cCIndex = 0;
      this.quizIndex = 0;
      this.showContent = true;
      this.showQuiz = false;
      this.selectedAnswer = null;
    },

    calculateProgress() {
      let nombreElementsParcourus = this.clickCount;
      if (nombreElementsParcourus > this.totalElementsCourse) {
        this.percentage = 100;
      } else {
        this.percentage = Math.round(
          (nombreElementsParcourus / this.totalElementsCourse) * 100
        );
      }
    },

    incrementClickCount() {
      if (this.clickCount < this.totalElementsCourse) {
        this.clickCount++;
        this.calculateProgress();
      }
    },

    decrementClickCount() {
      if (this.clickCount > 0) {
        this.clickCount--;
        this.calculateProgress();
      }
    },

    goBack() {
      if (this.showContent !== this.prevShowState) {
        if (this.quizIndex > 0) {
          this.quizIndex--;
          this.decrementClickCount();
          this.updateProgress();
        } else {
          this.showQuiz = false;
          this.showContent = true;
          this.decrementClickCount();
          this.updateProgress();
        }
      } else if (this.showContent && this.cCIndex > 0) {
        this.cCIndex--;
        this.quizIndex =
          this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data
            .length - 1;
        this.decrementClickCount();
        this.updateProgress();
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data
            .length > 0
        ) {
          this.showQuiz = true;
          this.showContent = false;
          this.decrementClickCount();
          this.updateProgress();
        }
      }
      window.scrollTo(0, 0);
    },

    goForward() {
      if (this.showContent) {
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data
            .length > 0
        ) {
          this.showContent = false;
          this.showQuiz = true;
          this.reprendreQuiz();
          this.incrementClickCount();
          this.updateProgress();
        } else {
          this.moveToNextContentOrChapter();
          this.incrementClickCount();
        }
      } else {
        if (
          this.quizIndex <
          this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data
            .length -
            1
        ) {
          this.quizIndex++;
          this.incrementClickCount();
          this.updateProgress();
          // console.log('Quiz index', this.quizIndex);
        } else {
          this.moveToNextContentOrChapter();
          this.showContent = true;
          this.showQuiz = false;
          this.incrementClickCount();
          this.updateProgress();
        }
      }
      window.scrollTo(0, 0);
    },

    moveToNextContentOrChapter() {
      this.quizIndex = 0;
      this.showQuiz = false;
      if (this.cCIndex < this.chapitres.contenus.data.length - 1) {
        // Passer au contenu suivant
        this.cCIndex++;
        if (
          this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data
            .length > 0
        ) {
          this.showQuiz = !this.showContent;
          // this.showContent = false;
        } else {
          this.showContent = !this.showQuiz;
        }
        this.updateProgress();
      } else {
        // Passer au chapitre suivant
        if (this.$route.params.id < this.totalChapters) {
          let nextChapterId = parseInt(this.$route.params.id) + 1;
          // Rediriger vers la prochaine page de chapitre
          this.$router.push(`/cours/${this.slug}/${nextChapterId}`);
          this.quizIndex = 0;
          this.showQuiz = false;
        } else {
          // Rediriger vers la page "fin" lorsque vous n'avez plus de chapitres
          this.updateProgress();
          this.$router.push(`/fin/${this.slug}`);
        }
      }

      this.selectedAnswer = null;
      // console.log("moveToNextContentOrChapter");
      window.scrollTo(0, 0);
    },

    shuffleQuizOptions() {
      for (let i = this.quizOptions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.quizOptions[i], this.quizOptions[j]] = [
          this.quizOptions[j],
          this.quizOptions[i],
        ];
      }
    },

    selectOption(option) {
      this.selectedAnswer = option;
      this.validateQuiz();
    },

    validateQuiz() {
      let correctAnswer =
        this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data[
          this.quizIndex
        ].attributes.reponse;
      if (this.selectedAnswer === correctAnswer) {
        // Réinitialiser le compteur d'erreurs en cas de réponse correcte
        this.errorCount = 0;
        this.lastErrorQuizIndex = -1;
        // Son pour une réponse correcte
        const correctSound = new Audio(correctSoundPath);
        correctSound.play();
        this.updateProgress();
        setTimeout(() => {
          this.selectedAnswer = null;
          this.goForward();
        }, 1000);
      } else {
        // Son pour une réponse incorrecte
        const incorrectSound = new Audio(incorrectSoundPath);
        incorrectSound.play();

        // Incrémenter le compteur d'erreurs
        this.errorCount++;

        // Mélanger les options de quiz
        this.shuffleQuizOptions();

        if (this.errorCount >= 2) {
          this.lastErrorQuizIndex = this.quizIndex;
          this.decrementClickCount();
          // console.log("index erreur : ", this.lastErrorQuizIndex);
          setTimeout(() => {
            this.showContent = true;
            this.showQuiz = false;
            this.errorCount = 0;
          }, 1000);
        }

        setTimeout(() => {
          this.selectedAnswer = null;
        }, 1000);
      }
      // console.log("validateQuiz");
    },

    reprendreQuiz() {
      if (this.lastErrorQuizIndex !== -1) {
        // Reprendre le quiz à partir de l'indice de la dernière erreur
        this.quizIndex = this.lastErrorQuizIndex;
        this.showQuiz = true;
        this.showContent = false;
      } else {
        this.quizIndex = 0;
      }
    },

    optionClass(option) {
      let correctAnswer =
        this.chapitres.contenus.data[this.cCIndex].attributes.quizzes.data[
          this.quizIndex
        ].attributes.reponse;

      // Si une réponse a été sélectionnée
      if (this.selectedAnswer) {
        // Si la réponse sélectionnée est correcte
        if (this.selectedAnswer === correctAnswer) {
          return option === this.selectedAnswer ? "green" : "red";
        } else {
          // Si la réponse sélectionnée est incorrecte, appliquez 'red' uniquement à cette option
          return option === this.selectedAnswer ? "red" : "";
        }
      }
      return "";
    },
    renderAudioDescription() {
      const markdownElement = document.getElementById("markdown");
      if (!markdownElement) {
        console.error("markdownElement non trouvé");
        return;
      }
      const audioElements = markdownElement.querySelectorAll(".my-audio-css");
      if (!audioElements.length) {
        console.error("Aucun élément audio trouvé");
        return;
      }
      let currentAudio = null;

      audioElements.forEach((audioElement) => {
        const parentParagraph = audioElement.closest("p");
        const descriptionText = audioElement.textContent
          .trim()
          .match(/Here is a description of the content: (.*)/)[1];

        parentParagraph.textContent = descriptionText;
        parentParagraph.setAttribute("data-src", audioElement.src);

        // Ajouter la classe "word" à la balise <p>
        parentParagraph.classList.add("word");

        parentParagraph.addEventListener("click", () => {
          if (currentAudio) {
            // Si un audio est en cours de lecture, l'arrêter
            currentAudio.pause();
          }

          const audioSrc = parentParagraph.getAttribute("data-src");
          const newAudio = new Audio(audioSrc);

          // Mettre à jour l'audio en cours de lecture
          currentAudio = newAudio;

          // Démarrer le nouvel audio
          newAudio.play();
        });

        audioElement.style.display = "none";
      });
    },

    renderAudioDescriptionTable() {
      const markdownElement = document.getElementById("markdown");
      if (!markdownElement) {
        console.error("markdownElement non trouvé");
        return;
      }
      const tableCells = markdownElement.querySelectorAll('td a[href$=".mp3"]');
      if (!tableCells.length) {
        console.error("Aucun lien audio trouvé dans les cellules de tableau");
        return;
      }
      let currentAudio = null;

      tableCells.forEach((audioLink) => {
        const parentTableCell = audioLink.closest("td");

        // Créer un nouveau paragraphe <p>
        const newParagraph = document.createElement("p");

        // Récupérer le texte du lien pour la description
        const descriptionText = audioLink.textContent.trim();

        // Définir le contenu du paragraphe avec la description
        newParagraph.textContent = descriptionText;

        // Ajouter la classe "word" au paragraphe
        newParagraph.classList.add("word");

        // Définir l'attribut data-src avec l'URL du lien audio
        newParagraph.setAttribute("data-src", audioLink.href);

        // Remplacer le contenu de la cellule de tableau par le nouveau paragraphe
        parentTableCell.innerHTML = ""; // Supprimer le contenu existant
        parentTableCell.appendChild(newParagraph); // Ajouter le nouveau paragraphe

        // Ajouter un écouteur d'événements au paragraphe pour la lecture audio
        newParagraph.addEventListener("click", () => {
          if (currentAudio) {
            // Si un audio est en cours de lecture, l'arrêter
            currentAudio.pause();
          }

          const audioSrc = newParagraph.getAttribute("data-src");
          const newAudio = new Audio(audioSrc);

          // Mettre à jour l'audio en cours de lecture
          currentAudio = newAudio;

          // Démarrer le nouvel audio
          newAudio.play();
        });
      });
    },
  },

  async mounted() {
    await this.fetchUserInfo();
    await this.fetchChaptersDetails();
    await this.loadUserProgress(this.idCours);
    if (!this.getUserInfo) {
      this.$router.push("/connexion");
    } else {
      if (this.idSuivi === null) {
        this.$router.push("/cours");
      }
    }
    this.renderAudioDescription();
    this.renderAudioDescriptionTable();
  },
};
</script>

<style scoped>
.head-c {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 30px;
  margin-top: 20px;
}

.back-course {
  display: flex;
  align-items: center;
  gap: 5px;
}

@keyframes growProgressBar {
  0%,
  33% {
    --pgPercentage: 0;
  }

  100% {
    --pgPercentage: var(--value);
  }
}

@property --pgPercentage {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 60px;
  --fg: #369;
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: radial-gradient(
      closest-side,
      var(--body) 80%,
      transparent 0 99.9%,
      var(--body) 0
    ),
    conic-gradient(var(--button) calc(var(--pgPercentage) * 1%), var(--card) 0);
  font-family: Inter, Arial, sans-serif;
  font-weight: 700;
  font-size: calc(var(--size) / 5);
  color: var(--button);
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) "%";
}

.container-details-c {
  background: var(--card);
  border-radius: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  width: 100%;
  min-height: 100vh;
  margin-top: 40px;
  margin-bottom: 200px;
  padding: 50px 20px;
}

.box-c {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding-bottom: 200px;
}

.container-details-c span {
  color: var(--text);
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  /* font-style: italic; */
  font-weight: 600;
  margin-bottom: 10px;
}

hr {
  border: 1px solid var(--body);
}

.markdown-body {
  width: 100%;
  margin-top: 20px;
  background: transparent !important;
}

.markdown-body #player {
  width: 100% !important;
}

.back {
  margin-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

.back p {
  font-size: 18px;
}

h1 {
  font-size: 25px;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.markdown-body h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.markdown-body p {
  font-size: 18px !important;
  margin-bottom: 10px;
  padding: 0;
}

.markdown-body a {
  color: #0066cc;
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

.controls-c {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
  width: 100%;
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  border-radius: 5px 5px 0 0;
  padding-top: 10px;
  padding-bottom: 5vh;
}

.c-control {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-control div.nxt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  background: var(--options-v-background-color);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
}

.quiz {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* margin: auto; */
}

.quiz h1 {
  margin-top: 10px;
}

.quiz-option {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  margin-bottom: 20px;
  gap: 10px;
}

.quiz-option .option {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.option-A,
.option-B,
.option-C,
.option-D {
  box-shadow: rgba(0, 0, 0, 0.2) 0px -6px 0px 0px inset;
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  border-left: 2px solid rgba(0, 0, 0, 0.2);
  border-right: 2px solid rgba(0, 0, 0, 0.2);
  background: var(--bglogo);
}

.quiz-option .input-quiz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quiz-option .green {
  background: #7cf448;
  color: #000;
}

.quiz-option .red {
  background: #ff4e4e;
  color: #000;
}

.quiz-option div label {
  font-size: 18px;
  cursor: pointer;
}

.quiz-option label {
  display: block;
  color: #000 !important;
}

.quiz-option input {
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: red;
}

.pr {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.pr svg {
  width: 60px !important;
  height: 60px !important;
}

@media (max-width: 830px) {
  .c {
    margin-top: -40px;
    padding-top: 20px;
    background: var(--card) !important;
  }

  .container-details-c {
    padding: 0 0 200px 0;
  }
}
</style>
