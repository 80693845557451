<template>
    <div class="play">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_play" data-name="vuesax/linear/play" transform="translate(-492 -318)">
                <g id="play">
                    <path id="Vector"
                        d="M0,8.523V4.963C0,.543,3.13-1.267,6.96.943l3.09,1.78L13.14,4.5c3.83,2.21,3.83,5.83,0,8.04l-3.09,1.78L6.96,16.1C3.13,18.313,0,16.5,0,12.083Z"
                        transform="translate(496 321.477)" fill="none" stroke="#292d32" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(492 318)" fill="none"
                        opacity="0" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Play-d',
}
</script>

<style scoped>
.play {
    display: flex;
    align-items: center;
    justify-content: center;
}

.play svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.play svg path {
    stroke: var(--text);
}
</style>