<template>
    <div class="box-prg">
        <div class="container-progr">
            <!-- <p>Chargement des versets</p> -->
            <div class="progress-loader">
                <div class="progress"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loading-d'
}
</script>

<style scoped>
.container-progr {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1400px;
    width: 100%;
    height: 100vh;
}

.progress-loader {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 250px;
    background: var(--card);
    height: 10px;
    border-radius: 7px;
}

/* .progress-loader p {
    top: 40%;
    left: 50%;
    margin-bottom: 100px;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
} */

.progress {
    width: 1px;
    height: 10px;
    border-radius: 7px;
    background: var(--button);
    transition: 0.5s;
    animation: loading_44 5s cubic-bezier(.4, 1.01, 1, 1) infinite;
}

@keyframes loading_44 {
    0% {
        width: 0%;
    }

    50% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}
</style>
