<template>
    <div class="pause-icon">
        <svg width="35" height="35" fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M20.25 4.5v15a1.5 1.5 0 0 1-1.5 1.5h-3.375a1.5 1.5 0 0 1-1.5-1.5v-15a1.5 1.5 0 0 1 1.5-1.5h3.375a1.5 1.5 0 0 1 1.5 1.5ZM8.625 3H5.25a1.5 1.5 0 0 0-1.5 1.5v15a1.5 1.5 0 0 0 1.5 1.5h3.375a1.5 1.5 0 0 0 1.5-1.5v-15a1.5 1.5 0 0 0-1.5-1.5Z">
            </path>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'pause-d'
}

</script>

<style scoped>
.pause-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgb(86, 61, 241, 0.70) 0px 22px 70px 4px;
    border-radius: 50%;
    padding: 15px;
    background: var(--button);
    cursor: pointer;
}

.pause-icon svg {
    width: 25px;
    height: 25px;
    cursor: pointer;

}

.pause-icon svg path {
    fill: #fff;
}
</style>