<template>
  <div class="navbar">
    <nav>
      <div class="logo">
        <router-link to="/">
          <div class="img-logo">
            <Logo />
          </div>
        </router-link>
        <p class="name">Doonun</p>
      </div>
      <div class="liens">
        <ul>
          <li>
            <router-link to="/">Accueil</router-link>
          </li>
          <li>
            <router-link to="/coran">Coran</router-link>
          </li>
          <li>
            <router-link to="/cours">Cours</router-link>
          </li>
          <li>
            <router-link to="/duaas">Duaas</router-link>
          </li>
          <li>
            <router-link to="/priere">Horaires</router-link>
          </li>
          <!-- <li>
            <a href="https://fr.tipeee.com/doonun/" target="_blank">
              Soutenir
            </a>
          </li> -->
          <!-- <li>
                        <router-link to="/hadith">Hadith</router-link>
                    </li> -->
          <!-- <li>
                        <router-link to="/livres">Livres</router-link>
                    </li> -->
          <!-- <li>
                        <a href="#">Tarifs</a>
                    </li> -->
        </ul>
      </div>
      <div class="login">
        <ul>
          <router-link to="/versets" class="search-btn mr">
            <Search />
          </router-link>
          <div class="dark-mode mr" @click="toggleMode()">
            <Sun v-if="!dark" />
            <Moon v-else />
          </div>
          <template v-if="isUserLoggedIn">
            <li class="mr">
              <router-link to="">
                <Bell />
              </router-link>
            </li>
            <li class="hss mr">
              <span>{{ formattedUserPoints }}</span>
              <Blitz />
            </li>
            <li class="profil">
              <router-link to="/profil">
                <img :src="`https://cp.doonun.com${avatar}`" alt="Avatar" />
              </router-link>
            </li>
          </template>
          <template v-else>
            <li class="connexion mr">
              <router-link to="/connexion">
                <Connect />
              </router-link>
            </li>
            <li class="mr">
              <router-link to="/Inscription"
                ><span class="text-inscription inscription">Rejoindre</span
                ><span class="logo-inscr">
                  <!-- <Inscription /> -->
                </span></router-link
              >
            </li>
          </template>
          <li class="menu">
            <Hamburger />
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <!-- <div class="box-app">
    <div class="apps">
      <ul>
        <li>
          <img
            src="https://cp.doonun.com/uploads/tipeee_logo_deaf2da1dc.svg"
            alt=""
          />
        </li>
      </ul>
      <p>
        Faites un don à Doonun, pour qu'il reste toujours en ligne et sans pub.
      </p>
      <a href="https://fr.tipeee.com/doonun/" target="_blank">
        <button>Soutenir Doonun</button>
      </a>
    </div>
  </div> -->
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import Hamburger from "./icons/Hamburger.vue";
import Moon from "./icons/Moon.vue";
import Search from "./icons/Search.vue";
import Sun from "./icons/Sun.vue";
import Blitz from "./icons/Blitz.vue";
import Bell from "./icons/Bell.vue";
import Logo from "./icons/Logo.vue";
import Connect from "./icons/Connect.vue";
//import Inscription from './icons/Inscription.vue'

export default {
  name: "navbar-d",
  components: {
    Blitz,
    Bell,
    Hamburger,
    Logo,
    Sun,
    Moon,
    Search,
    Connect, //Inscription
  },
  data() {
    return {
      dark: false,
      isUserLoggedIn: false,
      idAvatar: null,
      avatar: "",
      token: localStorage.getItem("jwtToken"),
      deferredPrompt: null,
      showInstallButton: false,
    };
  },
  watch: {
    avatarId(newId) {
      if (newId) {
        this.fetchAvatarById(newId);
      }
    },
  },
  computed: {
    ...mapState(["avatarId"]),
    ...mapGetters("user", ["getUserInfo", "getUserPoints", "getToken"]),
    formattedUserPoints() {
      return this.formatPoints(this.getUserPoints);
    },
  },
  async mounted() {
    this.isUserLoggedIn = !!this.token;
    this.dark = localStorage.getItem("navbarDarkMode") === "true";
    this.fetchUser();
    if (this.isUserLoggedIn) {
      await this.fetchUserInfo();
      this.fetchAvatars();
    }
    window.addEventListener("beforeinstallprompt", (event) => {
      // Empêcher l'affichage de la fenêtre d'installation automatique
      event.preventDefault();
      // Stocker l'événement pour l'utiliser plus tard
      this.deferredPrompt = event;
      // Afficher le bouton d'installation
      this.showInstallButton = true;
    });
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),

    logout() {
      // Supprimez le token JWT du localStorage
      localStorage.removeItem("jwtToken");

      // Réinitialisez les en-têtes par défaut d'Axios
      this.$axios.defaults.headers.common["Authorization"] = "";

      // Rechargez entièrement la page et redirigez vers la page de connexion
      window.location.href = "/connexion";
    },

    toggleMode() {
      this.dark = !this.dark;
      localStorage.setItem("navbarDarkMode", this.dark.toString());
      this.$emit("toggle-dark-mode", this.dark);
    },

    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return Number(points);
      }
    },

    async fetchUser() {
      try {
        if (this.token) {
          const { data } = await axios.get("users/me?populate=*", {
            headers: { Authorization: `Bearer ${this.token}` },
          });
          this.user = data;
          this.username = this.user.username;
          this.email = this.user.email;
          this.idAvatar = this.user.avatar.id || 23;
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
      }
    },

    async fetchAvatars() {
      // Utilisez une valeur par défaut pour idAvatar si elle est nulle
      const id = this.idAvatar || 23;

      try {
        const response = await axios.get(`/avatars/${id}?populate=*`);
        this.avatar =
          response.data.data.attributes.Avatar.data.attributes.formats.small.url;
      } catch (error) {
        // console.error("Erreur lors de la récupération des avatars:", error);
        // En cas d'erreur, vous pouvez définir une URL d'avatar par défaut ici
      }
    },

    async fetchAvatarById(avatarId) {
      // Utilisez une valeur par défaut pour avatarId si elle est nulle
      const id = avatarId || 23;

      try {
        const response = await axios.get(`/avatars/${id}?populate=*`, {
          headers: { Authorization: `Bearer ${this.token}` },
        });
        this.avatar =
          response.data.data.attributes.Avatar.data.attributes.formats.small.url;
      } catch (error) {
        // console.error("Erreur lors de la récupération de l'avatar:", error);
        // En cas d'erreur, vous pouvez définir une URL d'avatar par défaut ici
      }
    },

    installApp() {
      // Afficher l'invite d'installation lorsque l'utilisateur clique sur le bouton
      if (this.deferredPrompt) {
        // Installer automatiquement sans confirmation de l'utilisateur
        this.deferredPrompt.prompt();
        // Réinitialiser deferredPrompt après l'installation
        this.deferredPrompt = null;
        // Masquer le bouton d'installation après l'installation
        this.showInstallButton = false;
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  width: 100%;
  min-height: 60px;
  max-height: 100px;
  background: var(--card);
  margin-bottom: 40px;
  z-index: 1000;
}

.box-app {
  display: flex;
  justify-content: center;
  margin-top: -40px;
  margin-bottom: 40px;
  background: var(--options-v-background-color);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
  padding: 10px 20px;
  color: var(--text);
}

.box-app button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 700;
  background: #d64758;
  color: #fff;
  cursor: pointer;
}

.apps {
  max-width: 1400px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.apps ul {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 15px;
}

.apps ul li {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.apps ul img {
  display: inline-block;
  object-fit: contain;
  object-position: center center;
  width: auto;
  height: 30px;
}

.navbar nav {
  width: 1400px;
  display: grid;
  grid-template-columns: 250px 1fr 250px;
  align-items: center;
}

.navbar nav .logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.navbar nav .logo p {
  font-weight: 500;
}

.navbar nav .logo .img-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--br10);
  background: var(--bglogo);
}

.navbar nav .liens ul,
.navbar nav .login ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar nav .liens ul {
  justify-content: center;
}

.navbar nav .login ul {
  justify-content: end;
}

.navbar nav .login ul li a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar nav .liens ul a {
  color: var(--text);
  font-size: 16px;
}

.navbar nav .login .profil a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar nav .login .profil img {
  width: 40px;
  height: 40px;
  border-radius: var(--br10);
  object-fit: cover;
  object-position: center;
  background: var(--body);
}

.navbar nav .login li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.navbar nav .login .inscription {
  background: #dbc07d;
  padding: 8px 20px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  transition: 0.2s;
}

.inscription:hover {
  background: #000 !important;
  color: #fff;
}

.navbar nav .login .inscription a {
  color: #fff;
}

/* Masquez le bouton hamburger par défaut */
.navbar nav .login .menu {
  display: none;
}

.dark-mode {
  cursor: pointer;
}

/* .logo-inscr {
    display: none;
} */

@media screen and (max-width: 1400px) {
  .navbar nav {
    padding: 0 20px;
  }
}

/* @media screen and (max-width: 1280px) {
  .navbar nav {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;
  }

} */

@media screen and (max-width: 1000px) {
  .navbar nav .liens {
    display: none;
  }

  .navbar nav .login .menu {
    display: block;
  }

  .navbar nav {
    grid-template-columns: auto 1fr;
  }

  .navbar nav .login ul {
    gap: 10px;
  }

  .navbar nav .liens ul {
    gap: 20px;
  }

  /* Masquez les liens sur les petits écrans */

  .mr {
    margin: 0 8px;
  }

  /* Affichez le bouton hamburger sur les petits écrans */
}

@media screen and (max-width: 480px) {
  .text-inscription {
    font-size: 13px;
    margin-bottom: -2px;
    /* display: none; */
  }

  .logo-inscr {
    display: block;
  }

  .inscription {
    padding: 8px 15px !important;
    border-radius: 5px !important;
  }

  .navbar nav .logo .img-logo {
    width: 40px !important;
    height: 40px !important;
  }

  .navbar nav .login .profil img {
    width: 40px;
    height: 40px;
  }

  .name {
    display: none;
  }

  .apps p {
    display: none;
  }

  .apps ul {
    gap: 10px;
  }
}

@media (max-width: 360px) {
  .navbar nav .login ul {
    gap: 5px;
  }

  .mr {
    margin: 0 8px;
  }

  .navbar nav .logo .img-logo {
    width: 35px !important;
    height: 35px !important;
  }

  .navbar nav .login .profil img {
    width: 35px;
    height: 35px;
  }

  .hss .mr span {
    font-size: 14px;
  }
}
</style>
