<template>
    <div class="volumeoff-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_volume-cross" data-name="vuesax/linear/volume-cross" transform="translate(-366 -188)">
                <g id="volume-cross">
                    <path id="Vector"
                        d="M0,5.842v4a2.652,2.652,0,0,0,3,3H4.43a2.1,2.1,0,0,1,1.06.3l2.92,1.83c2.52,1.58,4.59.43,4.59-2.54V3.252c0-2.98-2.07-4.12-4.59-2.54L5.49,2.542a2.1,2.1,0,0,1-1.06.3H3A2.652,2.652,0,0,0,0,5.842Z"
                        transform="translate(368 192.318)" fill="none" stroke="#292d32" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector" d="M3.96,3.96,0,0" transform="translate(384.04 198.159)"
                        fill="none" stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M3.96,0,0,3.96" transform="translate(384 198.199)" fill="none"
                        stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(366 188)" fill="none"
                        opacity="0" />
                </g>
            </g>
        </svg>



    </div>
</template>
<script>
export default {
    name: 'volumeoff-d'
}

</script>

<style scoped>
.volumeoff-icon {
    display: flex;
    align-items: center;
}

.volumeoff-icon svg {
    width: 20px;
    height: 20px;
}

.volumeoff-icon svg path {
    stroke: var(--text) !important;
}
</style>