<template>
  <button class="cta">
    <span><span class="c">Co</span>mmencer l'aventure</span>
    <svg width="15px" height="10px" viewBox="0 0 13 10">
      <path d="M1,5 L11,5"></path>
      <polyline points="8 1 12 5 8 9"></polyline>
    </svg>
  </button>
</template>

<script>
export default {
  name: "go-btn",
};
</script>

<style scoped>
.cta {
  position: relative;
  margin: auto;
  padding: 12px 18px;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: #efebe8;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #000;
}

.cta .c {
  color: #000;
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #000;
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover:before {
  width: 100%;
  background: #efebe8;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:hover span,
.cta:hover .c {
  color: #000;
}

.cta:hover svg {
  stroke: #000;
}

.cta:active {
  transform: scale(0.95);
}
</style>
