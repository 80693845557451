<template>
  <div class="box-tarif">
    <h2>Choisissez l'offre<br />qui vous convient</h2>
    <p></p>
    <div class="c-y-m">
      <p>Mensuelle</p>
      <div class="toggle-switch">
        <input
          class="toggle-input"
          id="toggle"
          type="checkbox"
          @change="toggleAnnual"
        />
        <label class="toggle-label" for="toggle"></label>
      </div>
      <p class="annuel">
        Annuelle <span :class="{ 'actif-f': isAnnual }">-36%</span>
      </p>
    </div>
    <div class="tarifs">
      <Carousel
        v-bind="settings"
        :breakpoints="breakpoints"
        :wrap-around="true"
      >
        <Slide v-for="tarif in tarifs" :key="tarif.id">
          <div :class="tarif.class">
            <div class="info-tarifs">
              <div>
                <h3>{{ tarif.title }}</h3>
                <p>{{ tarif.description }}</p>
                <p class="prix">{{ tarif.price }}</p>
              </div>
              <div class="options-t">
                <p><span></span>{{ tarif.option1 }}</p>
                <p><span></span>{{ tarif.option2 }}</p>
                <p><span></span>{{ tarif.option3 }}</p>
                <p v-if="tarif.option4"><span></span>{{ tarif.option4 }}</p>
              </div>
              <div class="btn-t">
                <router-link to="/inscription">
                  <button type="button" class="SS_ProductCheckout">
                    <span>Commencer</span>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Tarif-d",
  components: {
    Carousel,
    Slide,
    Pagination,
    // Navigation,
  },
  data() {
    return {
      isAnnual: false,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        700: {
          itemsToShow: 1.5,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
      tarifs: [
        {
          id: 1,
          class: "basic",
          title: "Basic",
          description: "Profitez des options basiques de Doonun gratuitement",
          price: "Gratuit",
          option1: "Fonctionnalités de Base.",
          option2: "Débloquer des cours.",
          option3: "Suivre sa progression du Coran",
        },
        {
          id: 2,
          class: "premium",
          title: "Etudiant",
          description:
            "L'offre étudiant vous donne un accès complet à la plateforme.",
          price: "4.99€/mois",
          option1: "Fonctionnalités Avancées.",
          option2: "Accès illimité au cours",
          option3: "Changement de thème",
          option4: "Ajout réseaux sociaux",
        },
        {
          id: 3,
          class: "life",
          title: "Pour la vie",
          description: "Payez en une seule fois, et obtenez Doonun à vie.",
          price: "147€",
          option1: "Fonctionnalités Premium.",
          option2: "Contenus Exclusifs.",
          option3: "Groupe privé des étudiants.",
        },
      ],
    };
  },
  async mounted() {},
  methods: {
    toggleAnnual() {
      this.isAnnual = !this.isAnnual;
      this.tarifs = this.tarifs.map((tarif) => {
        if (tarif.id === 2) {
          return {
            ...tarif,
            price: this.isAnnual ? "40.98€/année" : "4.99€/mois",
          };
        }
        return tarif;
      });
    },
  },
};
</script>

<style scoped>
.box-tarif {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  max-width: 1000px;
  width: 100%;
}

.box-tarif h2 {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  line-height: 2.3rem;
  margin-bottom: 15px;
}

.tarifs {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px !important;
  gap: 20px;
}

.tarifs h3 {
  font-size: 35px;
  font-weight: 700;
}

.tarifs .info-tarifs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tarifs .basic {
  max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--header);
  background: var(--card);
  border-radius: var(--br15);
}

.tarifs .premium {
  max-width: 340px;
  background: var(--card);
  border: 1px solid var(--button);
  border-radius: var(--br15);
}

.tarifs .life {
  max-width: 340px;
  background: var(--card);
  border: 1px solid var(--header);
  border-radius: var(--br15);
}

.tarifs .basic,
.tarifs .premium,
.tarifs .life {
  display: grid;
  align-items: center;
  padding: 30px 30px 20px 30px;
  text-align: center;
  height: auto;
}

.get {
  display: inline-block;
  font-weight: 600;
  padding: 15px 50px;
  background: var(--button);
  border-radius: 60px;
  color: var(--white);
}

.prem {
  background: var(--white);
  color: var(--text);
}

.tarifs .prix {
  font-size: 25px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
}

.btn-t {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-t span {
  font-weight: 600;
}

.tarifs .btn-t button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  outline: 1px solid var(--black);
  border: none;
  background: transparent;
  border-radius: 15px !important;
  color: var(--text);
  cursor: pointer;
  padding: 15px 40px;
  font-size: 16px;
  font-weight: 600;
  margin-top: 30px;
  transition: 0.2s;
  min-width: 160px;
  font-family: "Inter", Helvetica, sans-serif;
}

.tarifs .btn-t button:hover {
  background: var(--black);
  color: var(--color);
}

.premium .btn-t button {
  outline: none;
  border: none;
  background: var(--button);
  color: #fff;
}

.options-t {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.options-t p {
  display: flex;
  gap: 10px;
  text-align: left;
  align-items: center;
}

.options-t p span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #57d798;
  border-radius: 50px;
}

.annuel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.annuel span {
  padding: 3px 10px;
  color: var(--text);
  background: var(--header);

  border-radius: 5px;
  font-weight: 600;
}

.annuel .actif-f {
  background: #ff3358;
  color: #fff;
}

.c-y-m {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 34px;
  border-radius: 17px;
  background-color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease;
}

.toggle-input:checked + .toggle-label {
  background-color: #57d798;
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(26px);
}
</style>
