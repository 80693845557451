<template>
  <h2>Questions fréquemment posées</h2>
  <div id="accordion">
    <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
      <div class="title-ac">
        <h3 @click="toggle(index)">
          {{ faq.attributes.titre }}
        </h3>
        <span class="icon" v-if="openIndex === index" @click="toggle(index)">
          <i class="fa-solid fa-xmark"></i>
        </span>
        <span class="icon" v-else @click="toggle(index)">
          <i class="fa-solid fa-plus"></i>
        </span>
      </div>
      <div
        :class="{ 'faq-content-active': openIndex === index }"
        class="faq-content"
      >
        <transition name="fade">
          <p v-if="openIndex === index">{{ faq.attributes.reponse }}</p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "faq-d",
  components: {},
  data() {
    return {
      openIndex: null,
      faqs: [],
    };
  },
  methods: {
    toggle(index) {
      this.openIndex = this.openIndex === index ? null : index;
    },
    async fetchFaqs() {
      try {
        const response = await axios.get("/faqs");
        this.faqs = response.data.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des FAQ :", error);
      }
    },
  },
  mounted() {
    this.fetchFaqs();
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 600;
}

#accordion {
  max-width: 1000px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.title-ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.title-ac span {
  cursor: pointer;
}

.icon {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.faq-item {
  background: var(--card);
  padding: 20px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.faq-item h3 {
  margin: 0;
  cursor: pointer;
  user-select: none;
  font-weight: 500;
  font-size: 18px;
}

.faq-content {
  margin-top: 8px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  font-size: 400;
}

.faq-content-active {
  max-height: 500px; /* définir une hauteur maximale suffisamment grande pour le contenu le plus long */
}
</style>
