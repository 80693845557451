<template>
    <nav aria-label="Pagination">
        <ul class="pagination">
            <li v-if="showFirstPage" class="page-item">
                <a href="#" class="page-link" @click.prevent="emitPageChange(1)">
                    1
                </a>
            </li>
            <li v-if="showFirstEllipsis" class="ellipsis">...</li>
            <li v-for="page in visiblePages" :key="page" class="page-item">
                <a href="#" class="page-link" :class="{ active: page === currentPage }"
                    @click.prevent="emitPageChange(page)">
                    {{ page }}
                </a>
            </li>
            <li v-if="showLastEllipsis" class="ellipsis">...</li>
            <li v-if="showLastPage" class="page-item">
                <a href="#" class="page-link" @click.prevent="emitPageChange(totalPages)">
                    {{ totalPages }}
                </a>
            </li>
        </ul>
    </nav>
    <div class="pg"></div>
</template>

<script>
export default {
    name: 'Pagination-d',
    props: {
        totalItems: {
            type: Number,
            required: true
        },
        itemsPerPage: {
            type: Number,
            required: true
        },
        currentPage: {
            type: Number,
            required: true
        }
    },
    computed: {
        totalPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },
        visiblePages() {

            const range = 2;
            let start = Math.max(this.currentPage - range, 1);
            let end = Math.min(start + range * 2, this.totalPages);

            if (this.totalPages - this.currentPage < range) {
                start = Math.max(this.totalPages - range * 2, 1);
            }

            const pages = [];
            for (let i = start; i <= end; i++) {
                pages.push(i);
            }
            return pages;
        },
        showFirstPage() {
            return this.currentPage > 4;
        },
        showFirstEllipsis() {
            return this.currentPage > 4;
        },
        showLastPage() {
            return this.currentPage < this.totalPages - 3;
        },
        showLastEllipsis() {
            return this.currentPage < this.totalPages - 3;
        },
    },
    methods: {
        emitPageChange(page) {
            this.$emit('page-changed', page);
            window.scrollTo(0, 0);
        }
    }
};
</script>

  
<style scoped>
.Pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--padding);

}

.Pagination div {
    display: inline-block;
    width: 20px;
    height: 20px;
}

.pagination {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 30px;
}

.pagination li {
    list-style: none !important;
}

.pagination li a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding: var(--padding);
    background: var(--card);
    border-radius: 10px;
    font-weight: 500;
    transition: .2s;
}

.pagination li a:hover {
    background: var(--button);
    color: #fff;
}

.pagination li a.active {
    background: var(--button);
    /* Couleur de fond pour la page active */
    color: #fff;
    /* Couleur du texte pour la page active */
    font-weight: bold;
    /* Rendre le texte de la page active plus gras */
}

.pg {
    margin-top: 100px;
}
</style>
  