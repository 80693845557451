<template>
  <div class="search-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_linear_search-status"
        data-name="vuesax/linear/search-status"
        transform="translate(-684 -188)"
      >
        <g id="search-status">
          <path
            id="Vector"
            d="M0,0H6"
            transform="translate(698 193)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H3"
            transform="translate(698 196)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M19,9.5A9.5,9.5,0,1,1,9.5,0"
            transform="translate(686 190)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M2,2,0,0"
            transform="translate(704 208)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(684 188)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "search-d",
};
</script>

<style>
.search-icon {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-icon svg {
  width: 20px;
  height: 20px;
}

.search-icon svg path {
  stroke: var(--text) !important;
}

@media (max-width: 360px) {
  .search-icon svg {
    width: 15px;
    height: 15px;
  }
}
</style>
