import axios from "axios";

export default {
  state: () => ({
    duaas: [],
    selectedDuaa: null,
    categories: [],
    currentCategory: null,
  }),
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
    SET_CURRENT_CATEGORY(state, category) {
      state.currentCategory = category;
    },
    SET_SELECTED_DUAA(state, titreduaa) {
      state.selectedDuaa = titreduaa;
    },
  },
  actions: {
    async fetchCategories({ commit, rootState }) {
      try {
        const response = await axios.get("/cat-duaas?populate=*", {
          headers: { Authorization: `Bearer ${rootState.user.token}` },
        });

        // Ici, nous mappons les catégories pour ne garder que le 'name' et l'URL complète de l'icône
        const categories = response.data.data.map((cat) => ({
          name: cat.attributes.name,
          slug: cat.attributes.slug,
          iconUrl: `https://cp.doonun.com${cat.attributes.icon.data.attributes.url}`, // Concaténation de l'URL de base avec l'URL de l'icône
        }));

        commit("SET_CATEGORIES", categories);
        localStorage.setItem("categories", JSON.stringify(categories));
      } catch (error) {
        // console.error("Erreur lors de la récupération des catégories:", error);
        const storedCategories = localStorage.getItem("categories");
        if (storedCategories) {
          commit("SET_CATEGORIES", JSON.parse(storedCategories));
        } else {
          commit("SET_CATEGORIES", []);
        }
      }
    },
    initCategories({ commit }) {
      const storedCategories = localStorage.getItem("categories");
      if (storedCategories) {
        commit("SET_CATEGORIES", JSON.parse(storedCategories));
      }
    },
    async fetchCategoryBySlug({ commit, rootState }, slug) {
      try {
        const response = await axios.get("/cat-duaas", {
          headers: { Authorization: `Bearer ${rootState.user.token}` },
          params: {
            "filters[slug][$eq]": slug,
            populate: ["titreduaas", "icon"], // Ici, vous pouvez spécifier quelles relations peupler, si vous ne voulez pas toutes les peupler avec '*'
          },
        });

        // Si la réponse contient des données, nous prenons la première catégorie, car le slug doit être unique
        const categoryData = response.data.data[0];

        if (categoryData) {
          const category = {
            name: categoryData.attributes.name,
            slug: categoryData.attributes.slug,
            iconUrl: `https://cp.doonun.com/${categoryData.attributes.icon.data.attributes.url}`,
            titreduaas: categoryData.attributes.titreduaas.data.map(
              (titreduaa) => ({
                id: titreduaa.id,
                name: titreduaa.attributes.name,
                slug: titreduaa.attributes.slug,
              })
            ),
          };

          // Commit de la catégorie trouvée dans le state
          commit("SET_CURRENT_CATEGORY", category);
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération de la catégorie par slug:", error);
        commit("SET_CURRENT_CATEGORY", null);
      }
    },
    async fetchTitreDuaaBySlug({ commit, rootState }, subSlug) {
      try {
        const response = await axios.get(`/titreduaas`, {
          headers: { Authorization: `Bearer ${rootState.user.token}` },
          params: {
            "filters[slug][$eq]": subSlug,
            populate: ["douas", "cat_duaas"], // Peupler les relations duaas et cat_duaas
          },
        });

        console.log("log duaas", response);

        // Vérifiez si la réponse contient des données avant de continuer
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const titreduaaData = response.data.data[0];

          const titreduaa = {
            id: titreduaaData.id,
            name: titreduaaData.attributes.name,
            slug: titreduaaData.attributes.slug,
            duaas: titreduaaData.attributes.douas.data.map((duaa) => ({
              id: duaa.id,
              arab: duaa.attributes.arab,
              transcript: duaa.attributes.transcript,
              source: duaa.attributes.source,
              translate: duaa.attributes.translite,
              urlMp3: duaa.attributes.url_mp3,
            })),
          };
          commit("SET_SELECTED_DUAA", titreduaa);
        } else {
          // Si aucune donnée n'est retournée, commit une valeur null pour indiquer qu'aucun titreduaa n'a été trouvé
          commit("SET_SELECTED_DUAA", null);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du titreduaa par slug:",
          error
        );
        commit("SET_SELECTED_DUAA", null);
      }
    },
  },
  getters: {
    duaasList: (state) => state.duaas,
    duaaDetails: (state) => state.selectedDuaa,
    categoriesList: (state) => state.categories,
    currentCategory: (state) => state.currentCategory,
  },
};
