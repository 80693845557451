<template>
  <div class="links-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_linear_global"
        data-name="vuesax/linear/global"
        transform="translate(-172 -252)"
      >
        <g id="global">
          <path
            id="Vector"
            d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10Z"
            transform="translate(174 254)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M.463,0h1a28.424,28.424,0,0,0,0,18h-1"
            transform="translate(179.538 255)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0A28.424,28.424,0,0,1,0,18"
            transform="translate(187 255)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,1V0A28.424,28.424,0,0,0,18,0V1"
            transform="translate(175 267)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,1.463a28.424,28.424,0,0,1,18,0"
            transform="translate(175 259.538)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(196 276) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "links-d",
};
</script>

<style>
.links-icon {
  display: flex;
  align-items: center;
  width: 100%;
}

.links-icon svg {
  width: 20px;
  height: 20px;
}

.links-icon svg path {
  stroke: var(--text) !important;
}
</style>
