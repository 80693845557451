<template>
  <div class="box-slug-duaa" v-if="currentCategory">
    <div class="box-c-duaa">
      <div class="container">
        <div class="back">
          <router-link to="/duaas">
            <Back />
          </router-link>
          <router-link to="/duaas">
            <p>Revenir</p>
          </router-link>
        </div>
      </div>
      <div class="current-cat">
        <span>
          <img :src="currentCategory.iconUrl" :alt="currentCategory.name" />
          <h1>{{ currentCategory.name }}</h1>
        </span>
      </div>
      <div
        class="img-bg-dua"
        :style="{ backgroundImage: `url('${currentCategory.iconUrl}')` }"
      ></div>
    </div>
    <div class="list-duas" v-if="currentCategory.titreduaas">
      <router-link
        v-for="titreduaa in currentCategory.titreduaas"
        :key="titreduaa.id"
        :to="`/duaas/${this.$route.params.slug}/${titreduaa.slug}`"
      >
        <div class="dua">
          <p>{{ titreduaa.name }}</p>
        </div>
      </router-link>
    </div>
  </div>
  <div class="nothing" v-else>
    <p>La catégorie n'a pas été trouvée ou est en cours de chargement.</p>
  </div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <Tabs />
</template>

<script>
import { mapGetters } from "vuex";
import { computed } from "vue";
import Back from "../components/icons/Back.vue";
import { useHead, Head } from "@vueuse/head";
import store from "../store";
import Tabs from "../components/Tabs.vue";
export default {
  name: "duaa-id",
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("fetchCategoryBySlug", to.params.slug)
      .then(() => {
        next();
      })
      .catch(() => {
        next("/duaas");
      });
  },
  setup() {
    const currentCategory = computed(() => store.getters["currentCategory"]);

    useHead({
      title: currentCategory.value ? currentCategory.value.name : "",
      meta: [
        {
          name: `description`,
          content: `${currentCategory.value.name} : Liste de toutes les invocations, Duaas importantes à connaître.`,
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: currentCategory.value ? currentCategory.value.name : "",
        },
        {
          property: "og:description",
          content: `${currentCategory.value.name} : Liste de toutes les invocations, Duaas importantes à connaître.`,
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/duaas_73117ce2be.png",
        },
        {
          property: "og:url",
          content: `https://www.doonun.com/duaas/${
            currentCategory.value ? currentCategory.value.slug : ""
          }`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: currentCategory.value ? currentCategory.value.name : "",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content: `${currentCategory.value.name} : Liste de toutes les invocations, Duaas importantes à connaître.`,
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/duaas_73117ce2be.png",
        },
      ],
    });

    return {
      currentCategory,
    };
  },
  components: {
    Back,
    Head,
    Tabs,
  },
  computed: {
    ...mapGetters({
      currentCategory: "currentCategory",
    }),
  },
  created() {
    this.$store.dispatch("fetchCategoryBySlug", this.$route.params.slug);
  },
};
</script>

<style scoped>
.box-slug-duaa {
  min-height: 100vh;
}

.img-bg-dua {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1 !important;
}

.box-c-duaa {
  position: relative;
  z-index: 1;
  margin-top: -40px;
  padding-top: 40px;
  padding-bottom: 100px;
}

.current-cat {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
}

.current-cat span {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
}

.current-cat span img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.list-duas {
  position: relative;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 800px;
  padding: 0 20px;
  z-index: 10 !important;
  margin-bottom: 200px;
}

.dua {
  padding: 10px 20px;
  background: var(--card);
  border-radius: var(--br10);
  margin-bottom: 10px;
}

.dua p {
  font-size: 18px;
  font-weight: 600;
}

.back {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.back p {
  display: inline-block;
}

.nothing {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.nothing p {
  text-align: center;
}
</style>
