<template>
  <div class="coran-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_linear_book"
        data-name="vuesax/linear/book"
        transform="translate(-556 -188)"
      >
        <g id="book">
          <path
            id="Vector"
            d="M20,14.078V2.008A1.97,1.97,0,0,0,17.83.018h-.06a18.851,18.851,0,0,0-7.07,2.37l-.17.11a1.108,1.108,0,0,1-1.06,0l-.25-.15A18.757,18.757,0,0,0,2.16.008,1.967,1.967,0,0,0,0,2v12.08a2.055,2.055,0,0,0,1.74,1.98l.29.04a25.693,25.693,0,0,1,7.44,2.44l.04.02a1.08,1.08,0,0,0,.96,0,25.461,25.461,0,0,1,7.46-2.46l.33-.04A2.055,2.055,0,0,0,20,14.078Z"
            transform="translate(558 190.662)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0V15"
            transform="translate(568 193.49)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M2.25,0H0"
            transform="translate(561.5 196.49)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M3,0H0"
            transform="translate(561.5 199.49)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(580 212) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "coran-d",
};
</script>

<style>
.coran-icon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.coran-icon svg {
  width: 20px;
  height: 20px;
}

.coran-icon svg path {
  stroke: var(--text) !important;
}
</style>
