<template>
    <div>
        <p>Status de la transaction: {{ paymentStatus }}</p>
        <!-- Détails de la transaction ici -->
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'echec-d',
    data() {
        return {
            paymentStatus: '',
            // ... autres données
        };
    },
    async mounted() {
        const params = new URLSearchParams(document.location.search);
        const checkoutSessionId = params.get("sessionId");
        try {
            const response = await axios.get(`/strapi-stripe/retrieveCheckoutSession/${checkoutSessionId}`);
            this.paymentStatus = response.data.payment_status;
            // Traiter d'autres détails de la réponse ici
        } catch (error) {
            console.error("Erreur lors de la récupération des détails de la session de paiement:", error);
            this.paymentStatus = 'Erreur';
        }
    }
}
</script>

<style></style>