<template>
    <div class="pause">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_pause" data-name="vuesax/linear/pause" transform="translate(-620 -384)">
                <g id="pause">
                    <path id="Vector"
                        d="M7.65,16.11V1.89C7.65.54,7.08,0,5.64,0H2.01C.57,0,0,.54,0,1.89V16.11C0,17.46.57,18,2.01,18H5.64C7.08,18,7.65,17.46,7.65,16.11Z"
                        transform="translate(623 387)" fill="none" stroke="#292d32" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector"
                        d="M7.65,16.11V1.89C7.65.54,7.08,0,5.64,0H2.01C.58,0,0,.54,0,1.89V16.11C0,17.46.57,18,2.01,18H5.64C7.08,18,7.65,17.46,7.65,16.11Z"
                        transform="translate(633.35 387)" fill="none" stroke="#292d32" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 384)" fill="none"
                        opacity="0" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Pause-d',
}
</script>

<style scoped>
.pause {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pause svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.pause svg path {
    stroke: var(--text);
}
</style>