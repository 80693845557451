<template>
  <div class="coran-icon-tab">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bold_book"
        data-name="vuesax/bold/book"
        transform="translate(-556 -188)"
      >
        <path
          id="Vector"
          d="M10.261,2.407V15.6a2.28,2.28,0,0,1-1.952,2.2l-.344.044A28.4,28.4,0,0,0,1.52,19.7,1.107,1.107,0,0,1,0,18.669V3.239a1.132,1.132,0,0,1,.6-.987A21.285,21.285,0,0,1,7.787,0h.067A2.4,2.4,0,0,1,10.261,2.407Z"
          transform="translate(568.837 190.912)"
          fill="#292d32"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M9.673,2.252A21.285,21.285,0,0,0,2.485,0H2.407A2.4,2.4,0,0,0,0,2.407V15.6a2.28,2.28,0,0,0,1.952,2.2l.344.044A28.4,28.4,0,0,1,8.741,19.7a1.107,1.107,0,0,0,1.52-1.032V3.239A1.108,1.108,0,0,0,9.673,2.252ZM3.339,5.613h2.5a.832.832,0,1,1,0,1.664h-2.5a.832.832,0,1,1,0-1.664ZM6.667,10.6H3.339a.832.832,0,1,1,0-1.664H6.667a.832.832,0,1,1,0,1.664Z"
          transform="translate(556.902 190.912)"
          fill="#292d32"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(580 212) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "coran-d",
};
</script>

<style>
.coran-icon-tab {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.coran-icon-tab svg {
  width: 23px;
  height: 23px;
}

.router-link-exact-active .coran-icon-tab svg path {
  fill: var(--button) !important;
}

.coran-icon-tab svg path {
  fill: var(--placeholder) !important;
}
</style>
