<template>
  <div class="bg-pre" :class="progressColor">
    <svg
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <circle :class="progressColor" r="45" cx="50" cy="50" />
      <path
        class="meter"
        :class="progressColor"
        d="M5,50a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
        stroke-linecap="round"
        stroke-linejoin="round"
        :stroke-dasharray="circumference"
        :stroke-dashoffset="circumference - (taux / 100) * circumference"
      />
      <text
        x="50"
        y="50"
        text-anchor="middle"
        dominant-baseline="central"
        font-size="23"
      >
        {{ taux }}%
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: "progress-c",
  props: {
    taux: Number,
  },
  computed: {
    circumference() {
      return 2 * Math.PI * 45; // Circonférence du cercle
    },
    progressColor() {
      if (this.taux >= 100) {
        return "progress-green";
      } else if (this.taux >= 80) {
        return "progress-orange";
      } else if (this.taux >= 50) {
        return "progress-red";
      } else {
        return "progress-blue";
      }
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: bottom;
  width: 55px;
  height: 55px;
}

circle {
  stroke-width: 5px;
  stroke-dasharray: 0;
  fill: none;
}

.meter {
  stroke-width: 10px;
  fill: none;
  transition: stroke-dashoffset 1s cubic-bezier(0.43, 0.41, 0.22, 0.91);
  transform-origin: center center;
  transform: rotate(-90deg) scaleX(-1);
}

.progress-blue path {
  stroke: #75aaff;
}

.bg-pre.progress-blue {
  background: #d3e3fd;
  border-radius: 50%;
}

circle.progress-blue {
  stroke: #c4d9fc;
}

.progress-red path {
  stroke: #fbbb7f;
}

.bg-pre.progress-red {
  background: #f9eedc;
  border-radius: 50%;
}

circle.progress-red {
  stroke: #ffe0c1;
}

.progress-orange path {
  stroke: #ffd071;
}

.bg-pre.progress-orange {
  background: #fff0d1;
  border-radius: 50%;
}

circle.progress-orange {
  stroke: #ffe8bb;
}

.progress-green path {
  stroke: #c4eed0;
}

.bg-pre.progress-green {
  background: #c4eed0;
  border-radius: 50%;
}

circle.progress-green {
  stroke: #c4eed0;
}

text {
  fill: #000;
  font-weight: bold;
}
</style>
