<template>
  <div class="popup-container" v-if="alert">
    <div class="popup">
      <img :src="url" alt="" />
      <p>{{ alert }} {{ message }}</p>
      <div class="buttons">
        <button class="dacc" @click="confirm">D'accord</button>
        <router-link class="sub" to="/tarifs">M'abonner</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "popup-sub",
  props: {
    url: String,
    alert: String,
    message: String,
  },
  methods: {
    confirm() {
      this.$emit("close-popup");
    },
    subAndClose() {
      this.$emit("close-popup");
      localStorage.setItem("rappel", true);
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  padding: 20px;
  gap: 20px;
}

.popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 500px;
  background-color: var(--card);
  padding: 30px;
  border-radius: 8px;
  text-align: center;
}

.popup img {
  width: 80px;
  height: 80px;
}

.buttons {
  margin-top: 20px;
}

.sub {
  background: rgb(253, 196, 72);
  background: linear-gradient(
    45deg,
    rgba(253, 196, 72, 1) 0%,
    rgba(255, 212, 117, 1) 100%
  );
  color: #000;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 4px;
}
.dacc {
  background: rgb(219, 219, 219);
  background: linear-gradient(
    45deg,
    rgba(219, 219, 219, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );
  color: #000;
  font-weight: 600;
  padding: 10px 20px;
}

button {
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}
</style>
