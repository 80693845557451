<template>
    <div class="container">
        <div class="box-cookies">
            <h1>Politique de cookies</h1>
            <Head>
                <title>Politique de cookies - Doonun</title>
                <meta name="robots" content="Noindex, nofollow">
            </Head>
            <p>Cette politique de cookies explique ce que sont les cookies et comment nous les utilisons sur notre site Web,
                Doonun.</p>

            <h2>Qu'est-ce qu'un cookie ?</h2>

            <p>Un cookie est un petit fichier texte envoyé à votre navigateur par un site Web que vous visitez. Il stocke
                des informations sur votre visite, telles que votre langue préférée et d'autres paramètres. Cela peut
                faciliter votre prochaine visite et rendre le site plus utile pour vous. Les cookies jouent un rôle
                important. Sans eux, utiliser le Web serait une expérience beaucoup plus frustrante.</p>

            <h2>Comment utilisons-nous les cookies ?</h2>

            <p>Lorsque vous utilisez et accédez au site Web, nous pouvons placer un certain nombre de cookies dans votre
                navigateur. Nous utilisons des cookies pour les suivantes raisons :</p>

            <ul>
                <li>Pour activer certaines fonctions du site</li>
                <li>Pour fournir des analyses</li>
                <li>Pour stocker vos préférences</li>
                <li>Pour authentifier les utilisateurs</li>
            </ul>

            <h2>Quels types de cookies utilisons-nous ?</h2>

            <p>Nous utilisons à la fois des cookies de session et des cookies persistants sur le site Web Doonun.</p>

            <ul>
                <li>Les cookies de session sont des cookies temporaires qui sont utilisés pour mémoriser vos préférences
                    pendant que vous naviguez sur le site. Ils expirent une fois que vous quittez le site.</li>
                <li>Les cookies persistants sont stockés sur votre appareil pendant une période spécifiée dans le cookie.
                    Ils restent sur votre appareil même après avoir fermé le navigateur ou redémarré l'appareil.</li>
            </ul>

            <h2>Comment contrôler les cookies ?</h2>

            <p>Vous avez le droit de décider d'accepter ou de refuser les cookies. La plupart des navigateurs Web acceptent
                automatiquement les cookies, mais vous pouvez modifier les paramètres de votre navigateur pour refuser les
                cookies si vous le préférez. Cependant, cela peut vous empêcher de tirer pleinement parti du site.</p>

            <p>Pour plus d'informations sur la gestion des cookies dans votre navigateur, veuillez consulter les
                instructions fournies par le fournisseur de votre navigateur.</p>

            <h2>Modifications de la politique de cookies</h2>

            <p>Nous pouvons mettre à jour notre politique de cookies de temps à autre. Nous vous recommandons de consulter
                régulièrement cette page pour être informé de tout changement. Les modifications entrent en vigueur dès leur
                publication sur cette page.</p>

            <h2>Nous contacter</h2>

            <p>Si vous avez des questions sur notre utilisation des cookies, veuillez nous contacter :</p>

            <ul>
                <li>Par email : <a href="mailto:contact.doonun@gmail.com">contact.doonun@gmail.com</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Head } from '@vueuse/head';
export default {
    name: 'cookies-d',
    components : {
        Head
    },
}
</script>

<style scoped>
.box-cookies {
    display: block;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    padding: 50px 40px;
    background: var(--card);
    border-radius: 10px;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}

h2,
h3 {
    margin-bottom: 20px;
}

p {
    text-align: justify;
    margin-bottom: 30px;
}

ul {
    margin-bottom: 20px
}

@media (max-width: 480px) {
    .container {
        margin-top: -40px;
        background: var(--card);
    }

    .box-cookies {
        padding: 40px 0;
    }
}

</style>