<template>
  <div class="container-sub">
    <div class="block-login">
      <div class="card-login">
        <h1>Doonun</h1>
        <div class="log-r">
          <div class="google" @click="authenticateWithGoogle">
            <img
              src="https://cp.doonun.com/uploads/google_efae55871e.svg"
              alt="google"
            />
          </div>
        </div>
        <div class="a-input">
          <span></span>
          <p>Ou inscrivez-vous avec</p>
          <span></span>
        </div>
        <div class="form-log">
          <form action="" id="signup" @submit.prevent="register">
            <div class="label-input">
              <div class="form-field">
                <input
                  name="name"
                  type="text"
                  placeholder="Nom d'utilisateur"
                  v-model="username"
                  autocomplete="off"
                  @input="clearError('username')"
                />
              </div>
              <small>{{ errors.username }}</small>
              <div class="form-field">
                <input
                  name="email"
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  autocomplete="off"
                  @input="clearError('email')"
                />
              </div>
              <small>{{ errors.email }}</small>
              <div class="form-field p">
                <input
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Mot de passe"
                  v-model="password"
                  autocomplete="off"
                  @input="clearError('password')"
                />
                <span class="toggle-password" @click="togglePasswordVisibility">
                  <openEye v-if="showPassword" />
                  <CloseEye v-if="!showPassword" />
                </span>
              </div>
              <div class="form-field p">
                <input
                  name="c-password"
                  :type="showCPassword ? 'text' : 'password'"
                  placeholder="Confirmez le mot de passe"
                  v-model="confirmPassword"
                  autocomplete="off"
                  @input="clearError('password')"
                />
                <span
                  class="toggle-password"
                  @click="toggleCPasswordVisibility"
                >
                  <openEye v-if="showCPassword" />
                  <CloseEye v-if="!showCPassword" />
                </span>
              </div>
              <small>{{ errors.password }}</small>
              <small>{{ errors.confirmPassword }}</small>
            </div>
            <small class="success">{{ success }}</small>
            <button type="submit">S'inscrire</button>
          </form>
          <router-link class="sub-c" to="/connexion">
            <span>Se connecter</span></router-link
          >
        </div>
      </div>
      <Head>
        <title>Inscription - Doonun</title>
        <meta name="robots" content="Noindex, nofollow" />
        <link
          rel="icon"
          href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
        />
      </Head>
      <div class="card-img-log"></div>
    </div>
  </div>
</template>

<script>
import openEye from "../components/icons/openEye.vue";
import CloseEye from "../components/icons/closeEye.vue";
import { Head } from "@vueuse/head";

export default {
  name: "inscription-d",
  components: {
    openEye,
    CloseEye,
    Head,
  },
  data() {
    return {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      errors: {},
      success: "",
      showPassword: false,
      showCPassword: false,
      token: process.env.VUE_APP_T,
    };
  },
  methods: {
    validateEmail(email) {
      // Expression régulière pour la validation de l'email
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(email);
    },

    async checkEmailExists() {
      if (!this.email) {
        // Si aucun email n'est fourni, pas besoin de vérifier
        return false;
      }

      try {
        const response = await this.$axios.get(
          `/users?filters[$and][0][email][$eq]=${this.email}`,
          {
            headers: {
              Authorization: `Bearer ${String(this.token)}`,
            },
          }
        );
        if (response.data && response.data.length > 0) {
          // L'email existe déjà
          this.errors.email = "Cet email est déjà utilisé.";
          return true; // Retourne true pour indiquer que l'email existe
        }
        return false; // Retourne false, l'email n'existe pas
      } catch (error) {
        // Gérer les erreurs potentielles de la requête
        console.error("Erreur lors de la vérification de l'email:", error);
        this.errors.email =
          "Impossible de vérifier l'email. Veuillez réessayer plus tard.";
        return false;
      }
    },

    async register() {
      this.errors = {}; // Réinitialise les messages d'erreur

      // Vérification du nom d'utilisateur
      if (!this.username) {
        this.errors.username = "Ce champ doit être rempli.";
      } else if (this.username.length < 3) {
        this.errors.username =
          "Le nom d'utilisateur doit contenir au moins 3 caractères.";
      } else if (this.username.length > 20) {
        this.errors.username =
          "Le nom d'utilisateur ne doit pas dépasser 20 caractères.";
      }

      // Vérification de l'email
      if (!this.email) {
        this.errors.email = "Ce champ doit être rempli.";
      } else if (!this.validateEmail(this.email)) {
        this.errors.email = "Le format de l'email est invalide.";
      }

      // Vérifie si l'email existe déjà
      const emailExists = await this.checkEmailExists();
      if (emailExists) {
        return;
      }

      // Vérification des mots de passe
      if (!this.password || !this.confirmPassword) {
        this.errors.password =
          "Les champs de mot de passe ne doivent pas être vides.";
      } else if (this.password.length < 8) {
        this.errors.password =
          "Le mot de passe doit faire minimum 8 caractères.";
      } else if (this.password !== this.confirmPassword) {
        this.errors.password = "Les mots de passe ne correspondent pas.";
      }

      // Si il y a des erreurs, arrête l'exécution de la fonction
      if (this.errors.email || this.errors.password) {
        return;
      }

      // Si tout est bon, continue avec l'inscription
      try {
        await this.$axios.post(
          "auth/local/register",
          {
            username: this.username,
            email: this.email,
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${String(this.token)}`,
            },
          }
        );

        // Si l'inscription est réussie, affiche un message de succès
        this.success = "Inscription réussie. Redirection en cours...";

        // Redirection vers la page de connexion après 3 secondes
        setTimeout(() => {
          this.$router.push("/connexion");
        }, 3000);
      } catch (error) {
        // Gestion des erreurs retournées par l'API
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errors = error.response.data.message[0].messages.reduce(
            (acc, message) => {
              const key = message.id.split(".")[3];
              acc[key] = message.message;
              return acc;
            },
            {}
          );
        } else {
          // Gestion des erreurs non liées à la réponse de l'API
          console.error(
            "Une erreur s'est produite lors de l'inscription:",
            error
          );
          this.errors.generic =
            "Une erreur s'est produite lors de l'inscription. Veuillez réessayer plus tard.";
        }
      }
    },

    authenticateWithGoogle() {
      const googleAuthUrl = "https://cp.doonun.com/api/connect/google";
      window.location.href = googleAuthUrl;
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    toggleCPasswordVisibility() {
      this.showCPassword = !this.showCPassword;
    },

    clearError(fieldName) {
      if (fieldName === "email") {
        this.errors.email = "";
      } else if (fieldName === "password") {
        this.errors.password = "";
        this.errors.confirmPassword = ""; // Assurez-vous aussi de réinitialiser les erreurs de confirmation de mot de passe
      } else if (fieldName === "username") {
        this.errors.username = "";
      }
    },
  },
};
</script>

<style scoped>
.container-sub {
  margin-top: -40px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.block-login {
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100%;
}

.card-img-log {
  width: 480px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 10px;
  min-height: 570px;
  height: auto;
  background-image: url("https://cp.doonun.com/uploads/sub_flower_89d9ce8e37.png");
}

.card-login {
  width: 100%;
  max-width: 400px;
  height: auto;
  /* background-color: var(--card); */
  padding: 30px;
  border-radius: 10px;
  /* border: 1px solid var(--textMD); */
  border-right: 0;
}

.card-login h1 {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 28px;
}

.log-r {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.log-r .google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 5px;
  border: 1px solid var(--textMD);
  border-radius: 6px;
  font-size: 13px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.log-r img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.a-input {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.a-input p {
  width: auto;
  font-weight: 300;
  text-align: center;
}

.a-input span {
  height: 0.5px;
  background: var(--textMD);
  width: 100%;
  max-width: 25%;
}

.label-input {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.label-input input {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--textMD);
  color: var(--text);
  border-radius: 6px;
  margin: 10px 0 0 0;
  font-weight: 500;
  font-size: 15px;
}

.form-log form button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  color: var(--color);
  font-weight: 600;
  background: var(--text);
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.form-log form button:hover {
  background: var(--button);
  color: #fff;
}

.sub-c {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.form-field.error input {
  border-color: var(--error-color);
}

.form-field.success input {
  border-color: var(--success-color);
}

.p input {
  border: none;
  margin: 0;
}

.p {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid var(--textMD);
  padding: 0 15px 0 0;
  border-radius: 6px;
  margin-top: 10px;
  background: var(--card);
}

.p .toggle-password {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

small {
  color: rgb(255, 35, 35);
}

.success {
  color: rgb(0, 170, 51);
  font-weight: 600;
}

@media (max-width: 768px) {
  .container-sub {
    display: block !important;
    padding: 0 !important;
  }

  .block-login {
    margin-top: -40px !important;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh !important;
    height: auto;
    align-items: flex-start;
  }

  .card-img-log {
    display: none;
  }

  .card-login {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .card-login {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
