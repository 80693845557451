<template>
  <router-link :to="courseUrl">
    <div class="card-info" ref="cardContainer">
      <img :src="`https://cp.doonun.com${img}`" alt="" />
      <div class="box-card-c">
        <div class="top">
          <span class="points" v-if="!hasSuivi">✦ {{ points }} pts</span>
          <span>{{ category }}</span>
        </div>
        <div class="b">
          <p class="titre">{{ titre }}</p>
          <div class="bottom">
            <div class="level-cat">
              <span><i class="fa-solid fa-chart-simple"></i> {{ level }}</span>
            </div>
            <div class="btn" v-if="!hasSuivi">
              <button @click.stop="deductPointsForCourse">
                <i class="fa-solid fa-lock"></i> Débloquer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showAlert"
        :class="{ 'alert-points': true, fadeInUp: showAlert }"
      >
        <p>❌ Vous n'avez pas assez de points pour débloquer ce cours.</p>
      </div>
    </div>
  </router-link>
  <PopupSub
    @close-popup="closePopup"
    v-if="displaySub"
    :url="`https://cp.doonun.com/uploads/lock_6039375_958aa7813b.png`"
    :alert="`Oops ! Il semblerait que vous ayez atteint la limite des cours à débloquer par semaine : 10 pour les étudiants et 5 pour les utilisateurs.`"
    :message="`Vous pourrez Débloquer d'autres cours à partir du ${fin}`"
  />
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import confetti from "../assets/js/confetti";
import PopupSub from "./PopupSub.vue";

export default {
  name: "course-d",
  props: {
    titre: String,
    points: Number,
    img: String,
    level: String,
    category: String,
    slug: String,
  },

  components: {
    PopupSub,
  },
  data() {
    return {
      hasSuivi: true,
      showAlert: false,
      displaySub: false,
      idLimit: null,
      nombre: null,
      debut: null,
      fin: null,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getUserPoints", "getToken"]),
    ...mapGetters("sub", ["isSubscribed"]),
    courseUrl() {
      if (this.getToken) {
        return this.hasSuivi ? `/cours/${this.slug}` : "/cours";
      } else {
        return "/connexion";
      }
    },

    hasSubscription() {
      return this.isSubscribed;
    },
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo", "updateUserPoints"]),

    async checkSuivi() {
      if (this.getUserInfo && this.slug) {
        try {
          const response = await axios.get("/suivis", {
            params: {
              "filters[user_id][$eq]": this.getUserInfo.id,
              "filters[slug][$eq]": this.slug,
            },
            headers: { Authorization: `Bearer ${this.getToken}` },
          });

          // Vérification renforcée de la réponse de l'API
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            // console.log("Suivi trouvé pour le slug:", response.data.data[0].attributes.slug);
            this.hasSuivi = true;
          } else {
            // console.log("Aucun suivi trouvé pour le slug:", this.slug);
            this.hasSuivi = false;
          }
        } catch (error) {
          // console.error("Erreur lors de la vérification du suivi:", error);
        }
      }
    },

    formatDate(date) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return new Date(date)
        .toLocaleDateString("fr-FR", options)
        .split("/")
        .join("/");
    },

    async getUserLimit() {
      try {
        const response = await axios.get("/limits", {
          params: {
            "filters[users_permissions_user][id][$eq]": this.getUserInfo.id,
          },
          headers: { Authorization: `Bearer ${this.getToken}` },
        });
        console.log("response", response.data);
        this.idLimit = response.data.data[0].id;
        this.nombre = response.data.data[0].attributes.nombre;
        this.debut = response.data.data[0].attributes.debut;
        this.fin = response.data.data[0].attributes.fin;
        return response.data.data[0];
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la limite de l'utilisateur :",
          error
        );
      }
    },

    async updateUserLimit(newNombre, debutDate, finDate) {
      try {
        const updatedLimit = {
          data: {
            nombre: newNombre,
            debut: debutDate,
            fin: finDate,
          },
        };
        await axios.put(`/limits/${this.idLimit}`, updatedLimit, {
          headers: { Authorization: `Bearer ${this.getToken}` },
        });
        console.log("mise à jour succès");
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de la limite de l'utilisateur :",
          error
        );
      }
    },

    async createLimit() {
      if (this.idLimit != null) {
        return;
      }
      await axios.post(
        "/limits",
        {
          data: {
            nombre: 1,
            debut: this.formatDate(new Date()).split("-").join("/"),
            fin: this.formatDate(
              new Date(new Date().setDate(new Date().getDate() + 7))
            )
              .split("-")
              .join("/"),
            users_permissions_user: this.getUserInfo.id,
          },
        },
        {
          headers: { Authorization: `Bearer ${this.getToken}` },
        }
      );
    },

    compareDates(date1, date2) {
      const formattedDate1 = new Date(
        ...date1
          .split("/")
          .map((item, index) => (index ? parseInt(item) - 1 : parseInt(item)))
      );
      const formattedDate2 = new Date(
        ...date2
          .split("/")
          .map((item, index) => (index ? parseInt(item) - 1 : parseInt(item)))
      );

      return formattedDate1 >= formattedDate2;
    },

    async deductPointsForCourse(event) {
      event.preventDefault();
      const userPoints = Number(this.getUserPoints);
      const coursePoints = Number(this.points);

      if (this.hasSubscription) {
        await this.getUserLimit();
        if (
          this.nombre < 10 ||
          this.compareDates(this.formatDate(new Date()), this.fin)
        ) {
          try {
            // Débloquer le cours sans déduire de points
            await this.createCourseProgress();
            this.hasSuivi = true;
            this.triggerConfetti();
            if (this.idLimit == null) {
              this.createLimit();
            } else if (this.idLimit != null && this.nombre < 10) {
              this.updateUserLimit(this.nombre + 1, this.debut, this.fin);
            } else if (
              this.nombre == 10 &&
              this.compareDates(this.formatDate(new Date()), this.fin)
            ) {
              this.nombre = 1;
              this.debut = this.formatDate(new Date()).split("-").join("/");
              this.fin = this.formatDate(
                new Date(new Date().setDate(new Date().getDate() + 7))
              )
                .split("-")
                .join("/");
              this.updateUserLimit(this.nombre, this.debut, this.fin);
            }
          } catch (error) {
            // console.error("Erreur lors du déblocage du cours pour un abonné:", error);
          }
        } else {
          this.displaySub = true;
        }
      } else {
        await this.getUserLimit();
        if (
          this.nombre < 5 ||
          this.compareDates(this.formatDate(new Date()), this.fin)
        ) {
          if (userPoints >= coursePoints) {
            try {
              const updatedPoints = userPoints - coursePoints;

              // Appel à l'action Vuex pour mettre à jour les points de l'utilisateur
              await this.$store.dispatch(
                "user/updateUserPoints",
                updatedPoints
              );
              await this.createCourseProgress();
              // Mettre à jour hasSuivi pour refléter le nouveau statut
              this.hasSuivi = true;
              this.triggerConfetti();
              if (this.idLimit == null) {
                this.createLimit();
              } else if (this.idLimit != null && this.nombre < 5) {
                this.updateUserLimit(this.nombre + 1);
              } else if (
                this.nombre == 5 &&
                this.compareDates(this.formatDate(new Date()), this.fin)
              ) {
                this.nombre = 1;
                this.debut = this.formatDate(new Date()).split("-").join("/");
                this.fin = this.formatDate(
                  new Date(new Date().setDate(new Date().getDate() + 7))
                )
                  .split("-")
                  .join("/");
                this.updateUserLimit(this.nombre, this.debut, this.fin);
              }
            } catch (error) {
              // console.error("Erreur lors de la déduction des points:", error);
            }
          } else {
            this.showAlert = true; // Afficher l'alerte
            setTimeout(() => {
              this.showAlert = false;
            }, 5000);
          }
        } else {
          this.displaySub = true;
        }
      }
    },

    closePopup() {
      this.displaySub = false;
    },

    triggerConfetti() {
      const cardRect = this.$refs.cardContainer.getBoundingClientRect();
      confetti({
        particleCount: 150,
        spread: 60,
        origin: {
          x: (cardRect.left + cardRect.right) / 2 / window.innerWidth,
          y: (cardRect.top + cardRect.bottom) / 2 / window.innerHeight,
        },
      });
    },

    async createCourseProgress() {
      try {
        await axios.post(
          "/suivis",
          {
            data: {
              id_chapitre: "1",
              user_id: String(this.getUserInfo.id),
              id_contenu: "0",
              pourcentage: "0",
              id_quiz: "0",
              clicks: "0",
              quiz_active: false,
              content_active: true,
              slug: String(this.slug),
            },
          },
          {
            headers: { Authorization: `Bearer ${this.getToken}` },
          }
        );
        // console.log('Suivi du cours créé avec succès');
      } catch (error) {
        // console.error("Erreur lors de la création du suivi du cours:", error);
      }
    },
  },
  mounted() {
    this.checkSuivi();
    if (this.$store.getters["user/getToken"]) {
      this.$store.dispatch("user/fetchUserInfo");
    }
  },
};
</script>

<style scoped>
.card-info {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  min-height: 250px;
  min-width: 235px;
}

@media (max-width: 576px) {
  .card-info {
    min-width: 280px;
  }
}

.card-info img {
  width: 100%;
  min-height: 160px;
  max-height: 160px;
  object-fit: cover;
  object-position: cover;
  border-radius: 10px;
}

.box-card-c {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.top span {
  font-size: 12px;
}

.points {
  color: var(--button);
  font-weight: 700;
  padding: 5px 8px;
  border-radius: 8px;
  background: var(--bgPoints);
}

.titre {
  font-size: 16px;
  font-weight: 500;
  text-align: left !important;
}

.level-cat span {
  font-size: 14px;
  line-height: 0;
}

.b {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 5px;
}

.bottom button {
  border: none;
  background: var(--text);
  color: var(--color);
  padding: 10px 12px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
}

.bottom button:hover {
  background: var(--button);
  color: #fff;
}

.alert-points {
  position: absolute;
  display: block;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  width: 100%;
  padding: var(--padding);
  border-radius: var(--br10);
  background: var(--card);
  border: 2px solid var(--body);
  z-index: 8;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}
</style>
