<template>
  <div
    class="box-avis-message slideInUp"
    v-if="isLoggedIn && !hasSubmitted && !hasGivenFeedback"
  >
    <div class="c-info-avis">
      <div class="info">
        <div>
          <Envelope />
        </div>
        <p>Que pensez-vous de Doonun ?</p>
      </div>
      <div class="right">
        <div class="link-a" @click="closeBoxAvis">
          <router-link to="/avis">
            <span>Écrire</span>
          </router-link>
        </div>
        <div class="icon-close" @click="closeBoxAvis">
          <Close />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Close from "./icons/Close.vue";
import Envelope from "./icons/Envelope.vue";

export default {
  name: "demande-v",
  components: {
    Close,
    Envelope,
  },
  data() {
    return {
      hasSubmitted: false,
      hasGivenFeedback: false, // Ajout d'un indicateur pour indiquer si l'utilisateur a déjà donné son avis
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  mounted() {
    this.checkUserFeedback();
    this.initializeAvisBox();
  },
  methods: {
    async checkUserFeedback() {
      const userInfo = this.getUserInfo;
      try {
        const response = await axios.get(
          `/avis-users?filters[users_permissions_user][id][$eq]=${userInfo.id}&populate=*`,
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );
        if (response.data && response.data.data.length > 0) {
          this.hasGivenFeedback = true; // Marquer que l'utilisateur a déjà donné son avis
          this.hasSubmitted = true;
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification de l'avis de l'utilisateur",
          error
        );
        this.hasSubmitted = false;
      }
    },
    closeBoxAvis() {
      const closeTime = new Date().getTime();
      localStorage.setItem("boxAvisCloseTime", closeTime);
      this.hasSubmitted = true;
      this.initializeAvisBox(); // Réinitialiser le comportement après la fermeture de la boîte d'avis
      // Définir un délai pour remettre hasSubmitted à false après 60 secondes
      setTimeout(() => {
        this.hasSubmitted = false;
      }, 432000000);
    },
    initializeAvisBox() {
      const closeTime = parseInt(localStorage.getItem("boxAvisCloseTime"), 10);
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - closeTime;
      if (isNaN(closeTime) || elapsedTime >= 432000000) {
        // Si le closeTime n'est pas un nombre ou si plus d'une minute s'est écoulée, réinitialiser hasSubmitted
        this.hasSubmitted = false;
      } else {
        // Sinon, définir un délai pour le reste du temps
        this.hasSubmitted = true;
        setTimeout(() => {
          this.hasSubmitted = false;
        }, 432000000 - elapsedTime);
      }
    },
  },
};
</script>

<style scoped>
.box-avis-message {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 999;
  padding: 0 10px !important;
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.c-info-avis {
  position: absolute;
  bottom: 0;
  padding: 20px;
  background: #f1ffca;
  border-radius: 15px;
  max-width: 1000px;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.info {
  display: flex;
  align-content: center;
  gap: 10px;
}

.info p {
  color: #000 !important;
}

.right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.link-a span {
  background: #000;
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
</style>
