<template>
    <div class="check-icon">
        <svg fill="currentColor" viewBox="0 0 24 24">
            <path
                d="M9.86 17.997a1.002 1.002 0 0 1-.73-.32l-4.86-5.17a1.001 1.001 0 0 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1.002 1.002 0 0 1-.73.33h-.01Z">
            </path>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'checkParams-d'
}

</script>

<style style>
.check-icon {
    display: flex;
    align-items: center;
    width: 100%;
}

.check-icon svg {
    width: 20px;
    height: 20px;
}

.check-icon svg path {
    stroke: var(--text) !important;
}
</style>