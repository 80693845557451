<template>
    <div class="container">
        <div class="box-mentions">
            <h1>Mentions légales</h1>
            <Head>
                <title>Mentions légales - Doonun</title>
                <meta name="robots" content="Noindex, nofollow">
            </Head>
            <p>Conformément à la loi sur la confiance en l'économie numérique (LCEN) du 21 juin 2004, nous tenons à informer
                nos
                utilisateurs sur l'identité des différents responsables de publications du site www.doonun.com, au besoin.

                Mentions légales concernant le propriétaire du site internet.</p>
            <ul>
                <li>Propriétaire : Doonun</li>
                <li>Contact mail : contact.doonun@gmail.com</li>
                <li>Créateur : Doonun</li>
                <li>Éditeur : Doonun – contact.doonun@gmail.com</li>
                <li>Webmaster/réalisateur du site : Doonun</li>
            </ul>
            <p>Le présent document vise à établir les conditions dans lesquelles Doonun met à disposition de ses
                utilisateurs le
                site et les services qui y sont disponibles, ainsi que l'accès et l'utilisation de ces derniers.

                Tout accès au site doonun.com implique l'acceptation pleine et entière des conditions décrites ci-après.</p>
            <h2>PROPRIÉTÉ INTELLECTUELLE</h2>
            <p>La structure générale du site Doonun, ainsi que les éléments textuels, graphiques, visuels et sonores qui le
                composent, sont la propriété de l'éditeur ou de ses partenaires. Toute reproduction, représentation ou
                exploitation, partielle ou totale, de ces contenus et services, par quelque moyen que ce soit, sans
                l'autorisation écrite préalable de Doonun et/ou de ses partenaires, est strictement interdite et
                constituerait
                une contrefaçon sanctionnée par les dispositions légales applicables.</p>
            <h2>RESPONSABILITÉ DE L'ÉDITEUR</h2>
            <p>Les informations et documents disponibles sur ce site proviennent de sources considérées comme fiables.
                Néanmoins, ils peuvent contenir des inexactitudes techniques ou des erreurs typographiques. Doonun se
                réserve le
                droit de corriger ces erreurs dès qu'elles sont portées à sa connaissance.

                L'accès au site est fourni de manière continue, sauf en cas de force majeure ou d'événements hors du
                contrôle de
                Doonun, ainsi que pour les interventions de maintenance nécessaires. Toutefois, Doonun ne garantit pas la
                disponibilité continue du site ni la qualité des transmissions ou des performances.</p>
            <h2>ACCÈS AU SITE</h2>
            <p>Doonun s'efforce de maintenir l'accès au site 24 heures sur 24, 7 jours sur 7. Cependant, l'accès peut être
                temporairement restreint pour des raisons de maintenance ou en cas de circonstances indépendantes de la
                volonté
                de Doonun.</p>
            <h2>UTILISATEURS</h2>
            <p>Les données personnelles des utilisateurs (email et pseudonyme) ne sont collectées que de manière volontaire,
                notamment lors de l'inscription au site, via le formulaire de contact ou newsletter.</p>
            <h2>DONNÉES PERSONNELLES</h2>
            <p>Doonun s'engage à respecter la vie privée de ses utilisateurs et à ne collecter que les données nécessaires
                aux
                services offerts sur son site. Les données personnelles ne seront communiquées à des tiers que sur demande
                des
                autorités compétentes ou avec le consentement de l'utilisateur.</p>
            <h2>DROIT D'ACCÈS ET DE RECTIFICATION</h2>
            <p>Conformément à la loi, les utilisateurs ont le droit d'accéder, de rectifier et de supprimer leurs
                informations
                personnelles stockées par le site.</p>
            <h2>CONTENU DU SITE ET DROITS DE REPRODUCTION</h2>
            <p>Le contenu du site est protégé par le droit d'auteur. Toute reproduction, même partielle, est strictement
                interdite sans l'autorisation préalable de Doonun.</p>
            <h2>MODIFICATION DES CONDITIONS D'UTILISATION</h2>
            <p>Doonun se réserve le droit de modifier les conditions d'utilisation du site à tout moment, sans préavis.</p>
            <h2>DROIT APPLICABLE</h2>
            <p>Le présent site et ses conditions d'utilisation sont régis par le droit français. En cas de litige, les
                tribunaux
                français seront seuls compétents. Pour toute question relative aux conditions d'utilisation du site,
                veuillez
                nous contacter à l'adresse suivante : contact.doonun@gmail.com</p>
        </div>
    </div>
</template>

<script>
import { Head } from '@vueuse/head';
export default {
    name: 'utilisations-d',
    components : {
        Head
    },
}
</script>

<style scoped>
.box-mentions {
    display: block;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    padding: 50px 40px;
    background: var(--card);
    border-radius: 10px;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}

h2 {
    margin-bottom: 20px;
}

p {
    text-align: justify;
    margin-bottom: 30px;
}

@media (max-width: 480px) {
    .container {
        margin-top: -40px;
        background: var(--card);
    }

    .box-mentions {
        padding: 40px 0;
    }
}
</style>