<template>
  <div class="tabs">
    <ul>
      <li>
        <router-link to="/">
          <Home />
        </router-link>
      </li>
      <li>
        <router-link to="/coran">
          <Coran />
        </router-link>
      </li>
      <li>
        <router-link to="/priere">
          <Pray />
        </router-link>
      </li>
      <li>
        <router-link to="/cours">
          <Cours />
        </router-link>
      </li>
      <li>
        <router-link to="/duaas">
          <Invocation />
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import Coran from "./icons/CoranTab.vue";
import Cours from "./icons/CoursTab.vue";
import Home from "./icons/Home.vue";
import Invocation from "./icons/Invocation.vue";
import Pray from "./icons/Pray.vue";
export default {
  name: "tabs-d",
  components: {
    Coran,
    Cours,
    Home,
    Invocation,
    Pray,
  },
};
</script>

<style scoped>
.tabs {
  display: none;
}

@media (max-width: 480px) {
  .tabs {
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 20px 20px 5vh 20px;
    background: var(--options-v-background-color);
    backdrop-filter: var(--options-v-blur);
    -webkit-backdrop-filter: var(--options-v-blur);
    z-index: 999;
  }

  .tabs ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .tabs ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  .tabs ul li a {
    display: inline-block;
  }
}
</style>
