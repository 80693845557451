<template>
  <div class="container">
    <div class="page-404">
      <h1>Oops !</h1>
      <h2>404 - Page non trouvée</h2>
      <Head>
        <title>404 - Page non trouvée !</title>
        <meta name="robots" content="Noindex, nofollow" />
        <link
          rel="icon"
          href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
        />
      </Head>
      <img src="https://cp.doonun.com/uploads/404_bc54f4529f.svg" alt="" />
      <p>
        Cela peut être dû à une erreur de frappe dans l'url ou à un lien qui a
        été supprimé.
      </p>
      <ul>
        <li class="link-home">
          <router-link to="/">Retourner à la page d'accueil</router-link>
        </li>
        <!-- <li>Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous <a href="/contact">contacter</a>.</li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
export default {
  name: "404-d",
  components: {
    Head,
  },
};
</script>

<style scoped>
img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  width: 100%;
  text-align: center;
  height: auto;
  object-fit: contain;
}

.page-404 {
  text-align: center;
  padding: 2rem;
}

.page-404 h1 {
  font-size: 2.5rem;
}

.page-404 h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.page-404 p {
  margin: 1rem 0;
}

.page-404 ul {
  list-style-type: none;
  padding: 0;
}

.page-404 li {
  margin: 0.5rem 0;
}

.link-home a {
  color: #fff;
  background: green;
  padding: 10px 20px;
  border-radius: 10px;
}
</style>
