<template>
  <div class="container">
    <!-- <p>Status de la transaction: {{ paymentStatus }}</p> -->
    <h1>Merci de nous avoir rejoints !</h1>
    <p class="msg-payement">
      Vous êtes désormais membre de notre communauté. Explorez dès maintenant
      toutes nos fonctionnalités.
    </p>
    <p class="msg-payement">
      Vous pouvez gérer votre abonnement sur votre page
      <router-link to="/profil">profil</router-link>.
    </p>
    <div class="img-box-payement">
      <img
        src="https://cp.doonun.com/uploads/10035134_17793582_Converti_51990b5a1b.png"
        alt=""
      />
    </div>
    <router-link to="/">
      <div class="button-back">
        <p>Revenir à l'accueil</p>
      </div>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "success-d",
  data() {
    return {
      paymentStatus: "",
      // ... autres données
    };
  },
  async mounted() {
    const params = new URLSearchParams(document.location.search);
    const checkoutSessionId = params.get("sessionId");
    try {
      const response = await axios.get(
        `https://cp.doonun.com/strapi-stripe/retrieveCheckoutSession/${checkoutSessionId}`
      );
      console.log("Session", response);
      this.paymentStatus = response.data.payment_status;
      // Traiter d'autres détails de la réponse ici
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des détails de la session de paiement:",
        error
      );
      this.paymentStatus = "Erreur";
    }
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
}

.msg-payement {
  text-align: center;
}

.msg-payement a {
  color: #5d6fba;
  font-weight: 600;
}

.img-box-payement {
  display: flex;
  justify-content: center;
}
.img-box-payement img {
  max-width: 700px;
  max-height: 700px;
  width: 100%;
  object-fit: contain;
}

.button-back {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  color: #fff;
  padding: 15px 20px;
  background: #5d6fba;
  border-radius: 8px;
  font-weight: 500;
}

.button-back p {
  text-align: center;
}
</style>
