<template>
  <div class="container">
    <h1>Livres</h1>
    <Head>
      <title>Livres - Doonun</title>
      <meta name="robots" content="Noindex, nofollow" />
    </Head>
    <div class="box-books">
      <router-link
        v-for="livre in livres"
        :key="livre.id"
        :to="`livres/${livre.url}`"
      >
        <div class="card-book">
          <img
            :src="`https://cp.doonun.com${livre.cover}`"
            :alt="livre.titre"
          />
          <h3>{{ livre.titre }}</h3>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { Head } from "@vueuse/head";
export default {
  name: "Livres-d",
  components: {
    Head,
  },
  data() {
    return {
      livres: [],
    };
  },
  created() {
    this.fetchLivres();
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),
    async fetchLivres() {
      try {
        const response = await axios.get("/livres?populate=*", {
          headers: { Authorization: `Bearer ${this.getToken}` },
        });
        this.livres = response.data.data.map((livre) => ({
          id: livre.id,
          titre: livre.attributes.titre,
          url: livre.attributes.url,
          cover: livre.attributes.cover.data.attributes.formats.medium.url,
        }));
      } catch (error) {
        console.error("Erreur lors de la récupération des livres:", error);
      }
    },
  },
};
</script>

<style scoped>
.box-books {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 20px;
  margin-top: 40px;
}

.card-book {
  background: var(--card);
  max-width: 260px;
  height: 400px;
  border-radius: 10px;
}

.card-book img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
</style>
