<template>
  <div class="container-sub">
    <div class="block-login">
      <div class="card-login">
        <h1>Doonun</h1>
        <Head>
          <title>Connexion</title>
          <meta name="robots" content="Noindex, nofollow" />
          <link
            rel="icon"
            href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
          />
        </Head>

        <div class="log-r">
          <div class="google" @click="authenticateWithGoogleBtn">
            <img
              src="https://cp.doonun.com/uploads/google_efae55871e.svg"
              alt="google"
            />
          </div>
        </div>
        <div class="a-input">
          <span></span>
          <p>Ou identifiez-vous avec</p>
          <span></span>
        </div>
        <div class="form-log">
          <form @submit.prevent="login">
            <div class="label-input">
              <input
                name="email"
                type="email"
                placeholder="Email"
                v-model="email"
                @input="clearError('email')"
              />
              <p class="err-mail">{{ errors.email }}</p>
              <div class="mdp-c">
                <input
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  placeholder="Mot de passe"
                  v-model="password"
                  @input="clearError('password')"
                />
                <span class="toggle-password" @click="togglePasswordVisibility">
                  <openEye v-if="showPassword" />
                  <CloseEye v-if="!showPassword" />
                </span>
              </div>

              <p class="err-mdp">{{ errors.password }}</p>
            </div>
            <div class="forget-rem">
              <router-link to="/oublie">
                <p>Mot de passe oublié ?</p>
              </router-link>
            </div>
            <button>Se connecter</button>
          </form>
          <router-link class="sub-c" to="/inscription"
            ><span>Créer un compte</span></router-link
          >
        </div>
      </div>
      <div class="card-img-log"></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import openEye from "../components/icons/openEye.vue";
import CloseEye from "../components/icons/closeEye.vue";
import axios from "axios";
import { Head } from "@vueuse/head";

export default {
  name: "Connexion-d",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      errors: {
        email: "",
        password: "",
      },
      token: process.env.VUE_APP_T,
    };
  },
  components: {
    openEye,
    CloseEye,
    Head,
  },
  methods: {
    ...mapActions("user", ["authenticateWithGoogle"]),
    async checkEmailExists() {
      if (!this.email) {
        this.errors.email = "Veuillez entrer une adresse email.";
        return false;
      }

      try {
        const response = await axios.get(
          `/users?filters[email][$eq]=${this.email}`,
          {
            headers: {
              Authorization: `Bearer ${String(this.token)}`,
            },
          }
        );

        console.log("reponse", response);
        if (response.data && response.data.length > 0) {
          const user = response.data[0];
          if (user.confirmed === false) {
            // Assuming 'confirmed' is a boolean field in your user model
            this.errors.email =
              "Veuillez valider votre email pour accéder à votre compte.";
            return false;
          } else {
            return true;
          }
        } else {
          this.errors.email = "Aucun compte n'est lié à cet email.";
          return false;
        }
      } catch (error) {
        console.error("Erreur lors de la vérification de l'email:", error);
        this.errors.email =
          "Impossible de vérifier l'email. Veuillez réessayer plus tard.";
        return false;
      }
    },

    async login() {
      this.errors.email = "";
      this.errors.password = "";

      if (!this.email || !this.password) {
        if (!this.email) {
          this.errors.email = "Veuillez entrer votre email.";
        }
        if (!this.password) {
          this.errors.password = "Veuillez entrer votre mot de passe.";
        }
        return;
      }

      const emailExists = await this.checkEmailExists();
      if (!emailExists) return;

      try {
        const response = await axios.post(
          "auth/local",
          {
            identifier: this.email,
            password: this.password,
          },
          {
            headers: {
              Authorization: `Bearer ${String(this.token)}`,
            },
          }
        );

        // Gestion réussie de la connexion ici

        const token = response.data.jwt;
        localStorage.setItem("jwtToken", token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        window.location.href = "/";
      } catch (error) {
        // Gestion des erreurs de connexion ici
        this.errors.password = "Mot de passe ou Email incorrect !";
      }
    },
    authenticateWithGoogleBtn() {
      this.authenticateWithGoogle();
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    clearError(field) {
      this.errors[field] = "";
    },
  },
};
</script>

<style scoped>
.container-sub {
  margin-top: -40px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--body);
}

.block-login {
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100%;
}

.card-img-log {
  width: 480px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 10px;
  /* border: 1px solid var(--textMD); */
  min-height: 570px;
  height: auto;
  background-image: url("https://cp.doonun.com/uploads/login_flower_34191413f4.png");
}

.card-login {
  width: 100%;
  max-width: 400px;
  height: auto;
  /* background-color: var(--card); */
  padding: 30px;
  border-radius: 5px;
  /* border: 1px solid var(--textMD); */
  border-right: 0;
}

.card-login h1 {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 28px;
}

.log-r {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.log-r .google {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 5px;
  border: 1px solid var(--text);
  border-radius: 6px;
  font-size: 13px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.log-r img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.a-input {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.a-input p {
  width: auto;
  font-weight: 300;
  text-align: center;
}

.a-input span {
  height: 0.5px;
  background: var(--text);
  width: 100%;
  max-width: 25%;
}

.label-input {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.label-input input {
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  border: 1px solid var(--text);
  border-radius: 6px;
  color: var(--text);
  font-weight: 500;
  font-size: 15px;
}

.label-input label {
  margin: 0px 0 10px 0;
}

.forget-rem {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-weight: 300;
  font-size: 14px;
}

.remember {
  display: flex;
  align-items: center;
  gap: 5px;
}

.form-log form button {
  margin-top: 10px;
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 6px;
  color: var(--color);
  font-weight: 600;
  background: var(--text);
  font-size: 15px;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.form-log form button:hover {
  background: var(--button);
  color: #fff;
}

.sub-c {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mdp-c {
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--card);
}

.mdp-c input {
  border: none;
  margin: 0 !important;
}

.mdp-c {
  border: 1px solid var(--text);
  padding: 0 15px 0 0;
  border-radius: 6px;
}

.toggle-password {
  cursor: pointer;
}

.err-mdp,
.err-mail {
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(255, 57, 57);
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 768px) {
  .container-sub {
    display: block !important;
    padding: 0 !important;
  }

  .block-login {
    margin-top: -40px !important;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 100vh !important;
    height: auto;
    align-items: flex-start;
  }

  .card-img-log {
    display: none;
  }

  .card-login {
    margin-top: 30px;
  }
}

@media (max-width: 480px) {
  .card-login {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}
</style>
