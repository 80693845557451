<template>
  <div class="bell-icon" @click="toggleDisplay()">
    <div class="notifs" v-if="this.notifs.length !== 0">
      <p>{{ this.notifs.length }}</p>
    </div>
    <svg
      aria-hidden="true"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="⚙ as32 as33 as34 as35 as36 asg8 asg9 ⚙ahxpi7"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.005 13H2.79a1.792 1.792 0 0 1-1.628-2.532l.477-1.051a11.583 11.583 0 0 0 1.01-4.018l.027-.41A5.34 5.34 0 0 1 8 0a5.34 5.34 0 0 1 5.324 4.989l.027.41a11.6 11.6 0 0 0 1.01 4.018l.477 1.05A1.787 1.787 0 0 1 13.21 13h-2.215c0 1.657-1.34 3-2.995 3a2.997 2.997 0 0 1-2.995-3Zm-2.48-1.912.478-1.05a13.086 13.086 0 0 0 1.14-4.539l.027-.41A3.84 3.84 0 0 1 8 1.5a3.84 3.84 0 0 1 3.83 3.589l.027.41c.104 1.57.49 3.107 1.14 4.538l.477 1.051a.291.291 0 0 1-.264.412H2.79a.291.291 0 0 1-.264-.412ZM6.504 13a1.499 1.499 0 1 0 2.995 0H6.503Z"
      ></path>
    </svg>
    <div class="box-notif" v-if="displayNotif">
      <div class="nothing" v-if="notifs.length === 0">
        <div class="close"><i class="fa-solid fa-xmark"></i></div>
        <img
          src="https://cp.doonun.com/uploads/bell_5908989_72b69c54ef.png"
          alt=""
        />
        <p>Aucune notification</p>
      </div>
      <div v-else class="lists">
        <div class="close"><i class="fa-solid fa-xmark"></i></div>
        <div class="list-notifs" v-for="notif in notifs" :key="notif.id">
          <a
            v-if="notif.attributes.otherLink"
            :href="notif.attributes.otherLink"
            target="_blank"
            @click="markNotifAsRead(notif)"
          >
            <div class="img-box">
              <img
                v-if="
                  notif.attributes.Pic &&
                  notif.attributes.Pic.data &&
                  notif.attributes.Pic.data.attributes &&
                  notif.attributes.Pic.data.attributes.formats &&
                  notif.attributes.Pic.data.attributes.formats.small
                "
                :src="`${url}${notif.attributes.Pic.data.attributes.formats.small.url}`"
                alt=""
              />
            </div>
            <p>{{ notif.attributes.Titre }}</p>
          </a>
          <router-link
            v-else
            :to="'/notif/' + notif.attributes.url"
            @click="markNotifAsRead(notif)"
          >
            <div class="img-box">
              <img
                v-if="
                  notif.attributes.Pic &&
                  notif.attributes.Pic.data &&
                  notif.attributes.Pic.data.attributes &&
                  notif.attributes.Pic.data.attributes.formats &&
                  notif.attributes.Pic.data.attributes.formats.small
                "
                :src="`${url}${notif.attributes.Pic.data.attributes.formats.small.url}`"
                alt=""
              />
            </div>
            <p>{{ notif.attributes.Titre }}</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "bell-d",
  data() {
    return {
      url: process.env.VUE_APP__URL,
      displayNotif: false,
      notifs: [],
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  methods: {
    toggleDisplay() {
      this.displayNotif = !this.displayNotif;
    },
    async fetchNotifs() {
      try {
        const [response, otherResponse] = await Promise.all([
          axios.get(`/notifs?populate=*&filters[obligatoire][$eq]=true`, {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }),
          axios.get(
            `/notifs?populate=*&filters[obligatoire][$eq]=false&sort[id]=desc`,
            {
              headers: {
                Authorization: `Bearer ${this.getToken}`,
              },
            }
          ),
        ]);
        const notifs = response.data.data;
        const otherNotifs = otherResponse.data.data;
        console.log("notifs", notifs);
        console.log("otherNotifs", otherNotifs);

        // Filter out notifications based on certain conditions
        const filteredNotifs = notifs.filter(
          (notif) =>
            !notif.attributes.users_permissions_users.data.some(
              (user) => user.id === this.getUserInfo.id
            ) ||
            (notif.attributes.obligation === true &&
              !notif.attributes.users_permissions_users.data.some(
                (user) => user.id === this.getUserInfo.id
              ))
        );
        const filteredOtherNotifs = otherNotifs.filter(
          (notif) =>
            !notif.attributes.users_permissions_users.data.some(
              (user) => user.id === this.getUserInfo.id
            ) ||
            (notif.attributes.obligation === true &&
              !notif.attributes.users_permissions_users.data.some(
                (user) => user.id === this.getUserInfo.id
              ))
        );

        // Get the 5 latest notifications, prioritizing notifs over otherNotifs
        this.notifs = [...filteredNotifs];
        const remainingSlots = 5 - this.notifs.length;
        if (remainingSlots > 0) {
          this.notifs.push(...filteredOtherNotifs.slice(0, remainingSlots));
        }
        console.log("img", this.notifs);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des notifications :",
          error
        );
      }
    },

    async markNotifAsRead(notif) {
      try {
        const userId = this.getUserInfo.id;

        // Récupérer les utilisateurs existants pour cette notification
        const existingUsers = notif.attributes.users_permissions_users.data.map(
          (user) => user.id
        );

        // Vérifier si l'utilisateur actuel est déjà dans la liste
        if (!existingUsers.includes(userId)) {
          existingUsers.push(userId);

          // Mettre à jour la notification avec la nouvelle liste d'utilisateurs
          await axios.put(
            `/notifs/${notif.id}`,
            {
              data: {
                users_permissions_users: existingUsers,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${this.getToken}`,
              },
            }
          );

          // Mettre à jour la liste de notifications après la mise à jour
          this.fetchNotifs();

          // Redirection si un autre lien est défini dans la notification
          if (notif.attributes.otherLink) {
            window.location.href = notif.attributes.otherLink;
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de la notification :",
          error
        );
      }
    },
  },
  mounted() {
    this.fetchNotifs();
  },
  watch: {
    $route() {
      // Mettre à jour la liste de notifications lorsque la route change
      this.fetchNotifs();
    },
  },
};
</script>

<style scoped>
.bell-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  outline: 20px solid transparent;
}

.nothing {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.nothing img {
  width: 40px;
  height: 40px;
  margin-right: -10px;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 97, 97, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 97, 97, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 97, 97, 0);
  }
}

.box-notif {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 10px;
  background-color: var(--card);
  border: 1px solid var(--placeholder);
  border-radius: 10px;
  padding: 10px;
  min-width: 350px;
  max-width: 350px;
  height: auto;
}

.box-notif .lists {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: var(--body);
  border-radius: 50%;
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -5px;
  opacity: 0.8;
}

.close:hover {
  background: var(--button);
  opacity: 1;
}

.close:hover i {
  color: #fff;
}

.list-notifs a {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}

.list-notifs .img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 20px;
  background: var(--body);
  border-radius: 10px;
  z-index: 1;
}

.list-notifs .img-box img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
  z-index: 2;
  border-radius: 8px;
}

.list-notifs p {
  font-size: 16px;
  line-height: 1.1em;
}

@media (max-width: 576px) {
  .box-notif {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--card);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    padding: 10px;
    width: 80%;
    min-width: 350px;
    max-width: 350px;
    height: auto;
    margin-top: 20px;
  }
}

.notifs {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;
  background: rgb(255, 97, 97);
  margin-right: -12px;
  margin-top: -10px;
  animation: pulse 2s infinite;
}
.notifs p {
  color: #fff;
  font-size: 10px;
  font-weight: 400;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.bell-icon svg {
  width: 18px;
  height: 18px;
}

.bell-icon svg path {
  fill: var(--text) !important;
  opacity: 0.8;
}

@media (max-width: 360px) {
  .bell-icon svg {
    width: 15px;
    height: 15px;
  }

  .box-notif {
    max-width: 285px;
    min-width: 285px;
    transform: translateX(-46%);
  }
}
</style>
