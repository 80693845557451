<template>
  <div class="box-edit">
    <h3 id="scroll-target">Informations du compte</h3>
    <p class="alert-g">
      👉 Si vous êtes connecté avec Google, inutile de remplir tous les champs,
      seul votre nom d'utilisateur sera modifié !
    </p>
    <div class="c-info-u">
      <form @submit.prevent="updateUserProfile">
        <label for="username">Nom d'utilisateur</label>
        <span
          v-if="usernameError && !usernameVoidError && username.length < 3"
          class="error-message"
          >Le nom d'utilisateur doit faire minimum 3 caractères !</span
        >
        <span v-if="usernameError && username.length > 20" class="error-message"
          >Le nom d'utilisateur doit faire moins de 20 caractères !</span
        >
        <span v-if="usernameVoidError" class="error-message"
          >Veuillez saisir un nom d'utilisateur !</span
        >
        <input
          type="text"
          v-model="username"
          :class="{ 'input-error': usernameVoidError || usernameError }"
          placeholder="Nom d'utilisateur"
          name="username"
          @input="checkUsernameField"
        />

        <label for="email">Email</label>
        <input
          type="text"
          v-model="email"
          placeholder="Email"
          class=""
          name="email"
          readonly
        />

        <label for="password">Mot de passe</label>
        <span
          v-if="password.length <= 0 && newpassword.length > 0"
          class="error-message"
          >Veuillez saisir votre mot de passe actuel !</span
        >
        <span v-if="incorrectPasswordError" class="error-message"
          >Le mot de passe est incorrect !</span
        >
        <div
          class="psw"
          :class="{
            'input-error': passwordError || incorrectPasswordError,
            focused: passwordFocused,
          }"
        >
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            placeholder="Mot de passe actuel"
            name="password"
            @input="debounceVerifyPassword"
            @focus="setFocus('password', true)"
            @blur="setFocus('password', false)"
          />
          <span class="toggle-password" @click="togglePasswordVisibility">
            <openEye v-if="showPassword" />
            <closeEye v-if="!showPassword" />
          </span>
        </div>
        <label for="newpassword">Nouveau mot de passe</label>
        <span
          v-if="
            password.length > 8 &&
            newPasswordLengthError &&
            incorrectPasswordError == false
          "
          class="error-message"
          >Le mot de passe est trop court (8 caractères minimum) !</span
        >
        <span
          v-if="newPasswordWeakError && newpassword.length > 3"
          class="error-message"
          >Mot de passe trop faible, veuillez le renforcer (A-z@_ etc.) !</span
        >
        <div
          class="psw"
          :class="{
            'input-error':
              (password.length > 0 &&
                newpassword.length > 0 &&
                newPasswordLengthError) ||
              newPasswordWeakError,
            focused: newPasswordFocused,
          }"
        >
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="newpassword"
            placeholder="Nouveau mot de passe"
            name="newpassword"
            @input="checkPasswordField"
            @focus="setFocus('newpassword', true)"
            @blur="setFocus('newpassword', false)"
          />
          <span class="toggle-password" @click="togglePasswordVisibility">
            <openEye v-if="showPassword" />
            <closeEye v-if="!showPassword" />
          </span>
        </div>
        <label for="confirmpassword">Confirmez le mot de passe</label>
        <span
          v-if="
            password.length > 0 &&
            passwordMismatchError &&
            newpassword.length > 0
          "
          class="error-message"
          >Le mot de passe ne correspond pas !</span
        >
        <div
          class="psw"
          :class="{
            'input-error': password.length > 0 && passwordMismatchError,
            focused: confirmPasswordFocused,
          }"
        >
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="confirmpassword"
            placeholder="Confirmez le mot de passe"
            name="confirmpassword"
            @input="checkPasswordField"
            @focus="setFocus('confirmpassword', true)"
            @blur="setFocus('confirmpassword', false)"
          />
          <span class="toggle-password" @click="togglePasswordVisibility">
            <openEye v-if="showPassword" />
            <closeEye v-if="!showPassword" />
          </span>
        </div>
        <span v-if="success" class="success-message"
          >Vos informations ont bien été mise à jour !</span
        >
        <button type="submit" v-if="isSubmitButtonEnabled">Enregistrer</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import openEye from "../icons/openEye.vue";
import closeEye from "../icons/closeEye.vue";
import axios from "axios";
export default {
  name: "edit-d",
  components: {
    openEye,
    closeEye,
  },
  data() {
    return {
      user: null,
      username: "",
      email: "",
      password: "",
      newpassword: "",
      confirmpassword: "",
      usernameError: false,
      usernameVoidError: false,
      passwordError: false,
      incorrectPasswordError: false,
      newPasswordLengthError: false,
      newPasswordWeakError: false,
      passwordMismatchError: false,
      passwordValid: false,
      passwordIsValid: null,
      success: false,
      showPassword: false,
      passwordFocused: false,
      newPasswordFocused: false,
      confirmPasswordFocused: false,
      debounceTimer: null,
      token: localStorage.getItem("jwtToken"),
    };
  },
  async mounted() {
    await this.fetchUser();
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
    ...mapGetters("sub", ["isSubscribed"]),
    hasSubscription() {
      return this.isSubscribed;
    },
    isFormValid() {
      return this.username && (this.password || !this.newpassword);
    },
    isSubmitButtonEnabled() {
      if (!this.user) {
        return false;
      }

      const isUsernameChanged = this.username !== this.user.username;
      const isPasswordFieldsFilled =
        this.password && this.newpassword && this.confirmpassword;

      return (
        isUsernameChanged ||
        (isPasswordFieldsFilled &&
          !this.incorrectPasswordError &&
          !this.newPasswordLengthError &&
          !this.newPasswordWeakError &&
          !this.passwordMismatchError)
      );
    },
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo", "updateProfile"]),
    async fetchUser() {
      try {
        if (this.getToken) {
          this.user = this.getUserInfo;
          this.username = this.user.username;
          this.email = this.user.email;
        }
      } catch (error) {
        // Handle error
      }
    },

    setFocus(field, focused) {
      if (field === "password") {
        this.passwordFocused = focused;
      } else if (field === "newpassword") {
        this.newPasswordFocused = focused;
      } else if (field === "confirmpassword") {
        this.confirmPasswordFocused = focused;
      }
    },

    checkUsernameField() {
      this.usernameError =
        this.username.length < 3 || this.username.length > 20;
      this.usernameVoidError = this.username == "";
    },

    verifyPassword() {
      if (this.password.length < 8) {
        this.incorrectPasswordError = false;
        return;
      }

      axios
        .post("/auth/local", {
          identifier: this.user.email, // Use old email here
          password: this.password,
        })
        .then(() => {
          this.incorrectPasswordError = false;
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.incorrectPasswordError = true;
          } else {
            // Handle error
          }
        });
    },

    debounceVerifyPassword() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.verifyPassword();
      }, 600);
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    checkPasswordField() {
      this.newPasswordLengthError = this.newpassword.length < 8;
      this.newPasswordWeakError =
        this.newpassword.length > 8 && !this.isStrongPassword(this.newpassword);
      this.passwordMismatchError = this.newpassword !== this.confirmpassword;
    },

    isStrongPassword(password) {
      if (password.length < 8) {
        return false;
      }

      if (!/[A-Z]/.test(password)) {
        return false;
      }

      if (!/[a-z]/.test(password)) {
        return false;
      }

      if (!/[0-9]/.test(password)) {
        return false;
      }

      return true;
    },

    async updateUserProfile() {
      try {
        const isUsernameChanged =
          this.username !== this.user.username && this.password.length <= 0;
        if (isUsernameChanged) {
          const userProfileData = {
            username: this.username,
          };

          await axios.put(`/users/${this.user.id}`, userProfileData, {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
          });
          this.success = true;
        } else {
          const userProfileData = {
            username: this.username,
          };

          if (
            this.password.length >= 8 &&
            this.incorrectPasswordError == false &&
            this.newPasswordWeakError == false
          ) {
            if (this.newpassword) {
              if (this.newpassword !== this.confirmpassword) {
                return;
              }

              const changePasswordResponse = await axios.post(
                "/auth/change-password",
                {
                  currentPassword: this.password,
                  password: this.newpassword,
                  passwordConfirmation: this.confirmpassword,
                },
                {
                  headers: {
                    Authorization: `Bearer ${this.token}`,
                    "Content-Type": "application/json",
                  },
                }
              );

              if (changePasswordResponse.status === 200) {
                this.success = true;
              } else {
                console.error("Error changing password");
                return;
              }
            }
          } else {
            return;
          }

          await axios.put(`/users/${this.user.id}`, userProfileData, {
            headers: {
              Authorization: `Bearer ${this.token}`,
              "Content-Type": "application/json",
            },
          });

          this.success = true;
        }
      } catch (error) {
        // Handle error
      }
    },
  },
  watch: {
    password(newValue) {
      if (newValue.length >= 8) {
        this.debounceVerifyPassword();
      }
    },
  },
};
</script>

<style scoped>
.box-edit {
  margin-top: 100px;
}

.box-edit h3 {
  font-size: 25px;
  margin-bottom: 30px !important;
}

@media (max-width: 800px) {
  .box-edit {
    margin-top: 30px;
  }
}

h2 {
  margin-top: 30px;
  margin-bottom: 20px;
}

.c-info-u form {
  display: flex;
  flex-direction: column;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.c-info-u form label {
  font-size: 14px;
  margin-bottom: 5px;
}

.psw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  outline: 1px solid var(--black);
  padding-right: 10px;
  margin-bottom: 15px;
}

.psw input {
  width: 100%;
  margin-bottom: 0 !important;
  outline: none !important;
}

.psw.focused {
  outline: 1px solid var(--button);
}

.psw span {
  cursor: pointer;
  width: auto;
}

.c-info-u form input {
  padding: 15px var(--padding);
  margin-bottom: 15px;
  border-radius: 5px;
  outline: 1px solid var(--black);
  background: transparent;
  color: var(--text);
  font-size: 16px;
}

.c-info-u form input:focus {
  outline: 1px solid var(--button);
  color: var(--button);
}

.c-info-u form input::placeholder {
  font-size: 16px;
}

.c-info-u form button {
  display: block;
  cursor: pointer;
  margin-left: auto;
  max-width: 140px;
  padding: 15px 20px;
  background: var(--black);
  color: var(--color);
  border-radius: 5px;
  border: none;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.error-message {
  color: red;
}

.success-message {
  color: rgb(52, 205, 1);
}

.input-error {
  outline: 1px solid red !important;
}

.input-error::placeholder {
  color: red;
}

.alert-g {
  background: #ffecd9;
  color: #000;
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
}

.theme {
  text-align: center;
  margin-top: 40px;
}

.links {
  margin-top: 20px;
}

@media (max-width: 800px) {
  .c-selected img {
    width: 75px;
    height: 75px;
  }

  .carousel-item img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 600px) {
  .carousel-container {
    justify-content: space-around;
  }

  .carousel-item img {
    width: 75px;
    height: 75px;
  }
}
</style>
