<template>
  <div class="box-setting">
    <h3 id="scroll-target">Paramètres</h3>
    <div class="list-delete">
      <div class="item-delete span coran">
        <p>Réinitialiser ma progression du Coran</p>
        <span v-if="!isResetting && !resetSuccess" @click="resetQuranProgress"
          >Réinitialiser</span
        >
        <div v-if="isResetting">
          <LoadCircle />
        </div>
        <div v-if="resetSuccess">
          <checkParams />
        </div>
      </div>
      <div class="item-delete span">
        <p>Réinitialiser la liste de mes sourates préférées</p>
        <span
          v-if="!isResettingFavorites && !resetFavoritesSuccess"
          @click="resetFavoriteSourates"
          >Réinitialiser</span
        >
        <div v-if="isResettingFavorites">
          <LoadCircle />
        </div>
        <div v-if="resetFavoritesSuccess">
          <checkParams />
        </div>
      </div>
      <div class="item-delete span">
        <p>Réinitialiser la progression de mes cours</p>
        <span
          v-if="!isResettingCourses && !resetCoursesSuccess"
          @click="resetCourseProgress"
          >Réinitialiser</span
        >
        <div v-if="isResettingCourses">
          <LoadCircle />
        </div>
        <div v-if="resetCoursesSuccess">
          <checkParams />
        </div>
      </div>
      <div class="item-delete span">
        <p>Vider le cache</p>
        <span
          v-if="!isResettingLocalStorage && !resetLocalStorageSuccess"
          @click="resetLocalStorage"
          >Réinitialiser</span
        >
        <div v-if="isResettingLocalStorage">
          <LoadCircle />
        </div>
        <div v-if="resetLocalStorageSuccess">
          <checkParams />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import LoadCircle from "../loadCircle.vue";
import checkParams from "../icons/CheckParams.vue";

export default {
  name: "setting-d",
  components: { LoadCircle, checkParams },
  data() {
    return {
      isResetting: false,
      resetSuccess: false,
      isResettingFavorites: false,
      resetFavoritesSuccess: false,
      isResettingCourses: false,
      resetCoursesSuccess: false,
      isResettingLocalStorage: false,
      resetLocalStorageSuccess: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo", "updateUserStatSourates"]),
    async resetQuranProgress() {
      this.isResetting = true;
      this.resetSuccess = false;

      if (!this.getUserInfo || !this.getUserInfo.id) {
        console.error("Erreur: Utilisateur non identifié.");
        this.isResetting = false;
        return;
      }

      try {
        const userId = this.getUserInfo.id;
        const token = this.getToken;

        // Récupérer la progression du Coran pour cet utilisateur
        const response = await axios.get(
          `/versets-checks?filters[id_user][$eq]=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const versetsChecks = response.data.data;
        let souratesChange = 0; // Initialiser souratesChange

        // Parcourir chaque versetCheck pour réinitialiser et vérifier le taux
        const updates = versetsChecks.map((versetCheck) => {
          if (versetCheck.attributes.taux === 100) {
            souratesChange += 1; // Incrémenter pour chaque taux de 100%
          }

          return axios.delete(`/versets-checks/${versetCheck.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
        });

        await Promise.all(updates);

        if (souratesChange > 0) {
          // Si souratesChange est positif, soustraire ce nombre du total des sourates lues
          await this.updateUserStatSourates(-souratesChange);
        }

        this.resetSuccess = true;
        this.isResetting = false;
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation de la progression du Coran:",
          error
        );
        this.isResetting = false;
      }
    },

    async resetFavoriteSourates() {
      this.isResettingFavorites = true; // Commence le chargement
      console.log("Début de la réinitialisation des sourates favorites");

      if (!this.getUserInfo || !this.getUserInfo.id) {
        console.error("Erreur: Utilisateur non identifié.");
        this.isResettingFavorites = false; // Arrête le chargement si l'utilisateur n'est pas identifié
        return;
      }

      try {
        const userId = this.getUserInfo.id;
        const token = this.getToken;

        setTimeout(async () => {
          const response = await axios.get(
            `/like-sourates?filters[id_users][$contains]=${userId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const updates = response.data.data.map(async (like) => {
            if (like.attributes.id_users.includes(userId.toString())) {
              const newUsers = like.attributes.id_users
                .split("|")
                .filter((id) => id !== userId.toString())
                .join("|");
              const newLikes =
                like.attributes.likes > 0 ? like.attributes.likes - 1 : 0;

              return axios.put(
                `/like-sourates/${like.id}`,
                {
                  data: {
                    likes: newLikes,
                    id_users: newUsers,
                  },
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                  },
                }
              );
            }
          });

          await Promise.all(updates);
          console.log("Réinitialisation des sourates favorites réussie");
          localStorage.removeItem("sourateData"); // Supprime les données locales après réinitialisation
          this.resetFavoritesSuccess = true; // Indique le succès de la réinitialisation
          this.isResettingFavorites = false; // Arrête le chargement
        }, 2000);
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation des sourates favorites:",
          error
        );
        this.isResettingFavorites = false; // Arrête le chargement en cas d'erreur
      }
    },
    async resetCourseProgress() {
      this.isResettingCourses = true;
      this.resetCoursesSuccess = false;

      if (!this.getUserInfo || !this.getUserInfo.id) {
        console.error(
          "Erreur: Utilisateur non identifié pour la réinitialisation des cours."
        );
        this.isResettingCourses = false;
        return;
      }

      try {
        const userId = this.getUserInfo.id;
        const token = this.getToken;

        setTimeout(async () => {
          const response = await axios.get(
            `/suivis?filters[user_id][$eq]=${userId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          const suivis = response.data.data;
          await Promise.all(
            suivis.map((suivi) => {
              const updatePayload = {
                data: {
                  id_chapitre: "1",
                  id_contenu: "0",
                  pourcentage: "0",
                  id_quiz: "0",
                  clicks: "0",
                  elements: "0",
                  quiz_active: false,
                  content_active: true,
                },
              };

              return axios.put(`/suivis/${suivi.id}`, updatePayload, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              });
            })
          );

          console.log(
            "La progression des cours a été réinitialisée avec succès."
          );
          this.resetCoursesSuccess = true;
          this.isResettingCourses = false;
        }, 2000);
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation de la progression des cours:",
          error
        );
        this.isResettingCourses = false;
      }
    },
    async resetLocalStorage() {
      this.isResettingLocalStorage = true;
      this.resetLocalStorageSuccess = false;

      setTimeout(() => {
        const keysToRemove = [
          "sourates",
          "categories",
          "allCourses",
          "versetsSourate",
          "cachedVerseList",
          "rappel",
        ];
        keysToRemove.forEach((key) => localStorage.removeItem(key));

        this.isResettingLocalStorage = false;
        this.resetLocalStorageSuccess = true;
      }, 2000);
    },
  },
};
</script>

<style scoped>
.box-setting {
  margin-top: 100px;
}

.box-setting h3 {
  font-size: 25px;
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .box-setting {
    margin-top: 30px;
  }
}

.list-delete {
  margin-top: 10px;
  margin-bottom: 30px;
}

.item-delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  border-bottom: 1px solid var(--black);
}

.span span {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 1px solid var(--text);
  color: var(--text);
  border-radius: 5px;
  padding: 6px 20px;
  width: 120px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
}

.span span:hover {
  background: var(--text);
  color: var(--color);
}

.delete-account span {
  outline: 1px solid #e23431;
  color: #e23431;
}

.delete-account span:hover {
  background: #e23431;
  color: #fff;
}
</style>
