import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    hasSubscription: false,
    offerType: "",
    subscriptionDate: "",
    oneTime: false,
    userEmail: "",
    token: localStorage.getItem("jwtToken"),
  }),
  getters: {
    isSubscribed: (state) => state.hasSubscription,
    subscriptionDetails: (state) => ({
      offerType: state.offerType,
      date: state.subscriptionDate,
      isOneTime: state.oneTime,
    }),
  },
  mutations: {
    SET_SUBSCRIPTION_STATUS(
      state,
      { hasSubscription, offerType, date, oneTime }
    ) {
      state.hasSubscription = hasSubscription;
      state.offerType = offerType;
      state.subscriptionDate = date;
      state.oneTime = oneTime;
    },
    SET_USER_EMAIL(state, email) {
      state.userEmail = email;
    },
  },
  actions: {
    async checkSubscriptionStatus({ commit, state }) {
      try {
        // Vérifier d'abord les achats uniques
        const productResponse = await axios.get(
          `https://cp.doonun.com/strapi-stripe/getProduct/3`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.VUE_APP_API_STRIPE}`,
            },
          }
        );

        const productData = productResponse.data;
        const userPurchase = productData.stripePayment.find(
          (payment) => payment.customerEmail === state.userEmail
        );
        if (userPurchase) {
          const date = new Date(userPurchase.txnDate).toLocaleDateString();
          commit("SET_SUBSCRIPTION_STATUS", {
            hasSubscription: true,
            offerType: "Offre unique à vie",
            date,
            oneTime: true,
          });
          return;
        }

        // Vérifier le statut de l'abonnement
        const response = await axios.get(
          `https://cp.doonun.com/strapi-stripe/getSubscriptionStatus/${state.userEmail}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.VUE_APP_API_STRIPE}`,
            },
          }
        );

        const subscriptionData = response.data.data[0];
        if (subscriptionData) {
          const offerType =
            subscriptionData.plan.interval === "month"
              ? "Offre mensuelle"
              : "Offre annuelle";
          const date = new Date(
            subscriptionData.start_date * 1000
          ).toLocaleDateString();
          commit("SET_SUBSCRIPTION_STATUS", {
            hasSubscription: true,
            offerType,
            date,
            oneTime: false,
          });
        } else {
          commit("SET_SUBSCRIPTION_STATUS", {
            hasSubscription: false,
            offerType: "",
            date: "",
            oneTime: false,
          });
        }
      } catch (error) {
        // console.error("Erreur lors de la vérification du statut de l'abonnement:", error);
      }
    },
  },
};
