<template>
  <div>
    <Head>
      <title>Redirect</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import { Head } from "@vueuse/head";

export default {
  name: "redirect-d",
  components: {
    Head,
  },
  methods: {
    ...mapMutations(["SET_TOKEN", "SET_USER_INFO"]),
    ...mapActions("user", ["processGoogleAuthCallback"]),

    async processCallback() {
      const searchParams = window.location.search;
      this.processGoogleAuthCallback(searchParams);
    },
  },

  mounted() {
    this.processCallback();
  },
};
</script>

<style></style>
