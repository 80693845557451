<template>
    <div class="close-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="7.781" height="7.781" viewBox="0 0 7.781 7.781">
            <g id="Groupe_1" data-name="Groupe 1" transform="translate(-116.109 -324.109)">
                <path id="Vector" d="M0,5.66,5.66,0" transform="translate(117.17 325.17)" fill="none" stroke="#292d32"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <path id="Vector-2" data-name="Vector" d="M5.66,5.66,0,0" transform="translate(117.17 325.17)" fill="none"
                    stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
        </svg>
    </div>
</template>
<script>
export default {
    name: 'close-d'
}

</script>

<style>
.close-icon {
    display: flex;
    cursor: pointer;
}

.close-icon svg {
    width: 15px;
    height: 15px;
}

.close-icon svg path {
    stroke: var(--text) !important;
    transition: .2s ease-in;
}
</style>