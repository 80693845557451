<template>
  <div class="box-fonc">
    <div class="box-fonc-info">
      <h2>Quelques une de nos fonctionnalités !</h2>
      <ul>
        <li>
          <p>
            <span>🔎</span> Lire le Coran: "Plongez dans les versets sacrés à
            votre rythme, avec des traductions et des explications pour enrichir
            votre compréhension."
          </p>
        </li>
        <li>
          <p>
            <span>🧠</span> Mémorisation et Suivi: "Des outils intuitifs pour
            mémoriser efficacement les sourates et suivre vos progrès."
          </p>
        </li>
        <li>
          <p>
            <span>🎓</span> Cours sur les Sciences Islamiques: "Débloquez des
            cours pour approfondir votre compréhension sur des sujets variés, de
            l'histoire islamique aux principes éthiques."
          </p>
        </li>
        <li>
          <p>
            <span>⚡️</span> Points de Récompense: "Gagnez des points à chaque
            étape franchie et débloquez des ressources supplémentaires pour
            enrichir votre apprentissage."
          </p>
        </li>
      </ul>
    </div>
    <div class="box-img-fonc">
      <img
        src="https://cp.doonun.com/uploads/Groupe_64_686d656dbb.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Fonc-d",
};
</script>

<style scoped>
.box-fonc {
  width: 100%;
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  gap: 30px;
}

.box-fonc h2 {
  margin-top: 0;
  font-size: 30px;
  margin-bottom: 10px;
}

.box-fonc-info h3 {
  margin-bottom: 15px;
}

.box-fonc-info ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.box-fonc-info ul li p {
  display: flex;
  align-items: top;
  gap: 5px;
}

/* .box-img-fonc {
    width: 100%;
    height: 350px;
} */

@keyframes floating {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.box-img-fonc {
  display: flex;
  justify-self: center;
  animation: floating 3s ease-in-out infinite;
}

.box-img-fonc img {
  max-width: 400px;
  width: 100%;
  min-height: 350px;
  max-height: 500px;
  object-fit: contain;
  border-radius: var(--br15);
}

@media (max-width: 768px) {
  .box-fonc {
    margin-top: 50px;
  }

  .box-img-fonc img {
    width: 250px;
  }

  .box-fonc h2 {
    text-align: center;
    margin-bottom: 25px;
  }
}
</style>
