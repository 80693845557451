<template>
    <div>
        <h1>Livres</h1>
    </div>
</template>

<script>
export default {
    name: 'livres-f',
}
</script>

<style></style>



