<template>
  <div v-if="cours">
    <div class="head-cours">
      <div class="container size-c">
        <div class="header-c">
          <div class="top-course">
            <div class="back-course">
              <router-link to="/cours">
                <Back />
              </router-link>
              <router-link to="/cours">
                <p>Tous les cours</p>
              </router-link>
            </div>
            <div class="infos-course">
              <div>
                <p>{{ cours.attributes.Category }}</p>
              </div>
              <div>
                <p>{{ cours.attributes.niveau }}</p>
              </div>
            </div>
          </div>
          <div class="new-style">
            <div class="img-bg-cours">
              <img
                :src="`https://cp.doonun.com${cours.attributes.Couverture.data.attributes.formats.medium.url}`"
                :alt="cours.attributes.titre"
              />
              <div class="pr">
                <progressCircle :taux="percentage" />
              </div>
            </div>
            <h1>{{ cours.attributes.titre }}</h1>
            <p class="desc">{{ cours.attributes.description }}</p>
            <div class="start-course">
              <router-link
                :to="`/cours/${cours.attributes.url}/${idChapitreActuel}`"
                @click="handleStartClick()"
              >
                <p v-if="percentage == 100">Refaire le cours</p>
                <p v-else>{{ aDejaCommence ? "Continuer" : "Commencer" }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="after-head"
        :style="{
          backgroundImage: `url('https://cp.doonun.com${cours.attributes.Couverture.data.attributes.formats.small.url}')`,
        }"
      ></div>
    </div>
    <div class="container-chapters">
      <div class="chapters-cours">
        <h2>Listes des chapitres</h2>
        <div
          class="lists-chapters"
          v-if="
            cours &&
            cours.attributes.chapitres &&
            cours.attributes.chapitres.data
          "
        >
          <div
            v-for="chapitre in cours.attributes.chapitres.data"
            :key="chapitre.id"
          >
            <div class="card-chapter">
              <div class="titre-c">
                <span>{{ chapitre.attributes.numero }}</span>
                <h3>{{ chapitre.attributes.titre }}</h3>
              </div>
              <div
                class="d-check-c"
                v-if="
                  chapitre.attributes.numero < idChapitreActuel ||
                  (chapitre.attributes.numero == idChapitreActuel &&
                    this.percentage == 100)
                "
              >
                <Check />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Back from "../components/icons/Back.vue";
import progressCircle from "../components/icons/progressCircle.vue";
import Check from "../components/icons/Check.vue";
import { useHead, Head } from "@vueuse/head";
import { onMounted, computed } from "vue";
import store from "../store";

export default {
  name: "coursid-d",
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("cours/fetchCoursDetails", to.params.slug)
      .then(() => {
        next();
      })
      .catch(() => {
        next("/cours");
      });
  },
  setup() {
    const cours = computed(() => store.getters["cours/getCurrentCours"]);

    onMounted(() => {
      useHead({
        title: cours.value ? cours.value.attributes.titre : "",
        meta: [
          {
            name: `description`,
            content: cours.value.attributes.description,
          },
          {
            name: "robots",
            content: "index, follow, noarchive",
          },
          {
            property: "og:title",
            content: cours.value ? cours.value.attributes.titre : "",
          },
          {
            property: "og:description",
            content: cours.value.attributes.description,
          },
          {
            property: "og:image",
            content: cours.value
              ? `https://cp.doonun.com${cours.value.attributes.Couverture.data.attributes.formats.medium.url}`
              : "",
          },
          {
            property: "og:url",
            content: `https://www.doonun.com/cours/${
              cours.value ? cours.value.attributes.url : ""
            }`,
          },
          {
            property: "og:type",
            content: "website",
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:title",
            content: cours.value ? cours.value.attributes.titre : "",
          },
          {
            name: "twitter:site",
            content: "@doonunfr",
          },
          {
            name: "twitter:creator",
            content: "@doonunfr",
          },
          {
            name: "twitter:description",
            content: cours.value.attributes.description,
          },
          {
            name: "twitter:image",
            content: cours.value
              ? `https://cp.doonun.com${cours.value.attributes.Couverture.data.attributes.formats.medium.url}`
              : "",
          },
        ],
      });
    });
  },
  components: { Back, Check, progressCircle, Head },
  data() {
    return {
      idChapitreActuel: 1,
      aDejaCommence: false,
      percentage: 0,
      idCours: null,
      idSuivi: null,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo"]),
    ...mapGetters("cours", ["getCurrentCours"]),
    cours() {
      return this.getCurrentCours;
    },
    userProgress() {
      return this.cours
        ? this.$store.getters["user/getUserProgress"](this.cours.attributes.url)
        : null;
    },
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),
    ...mapActions("cours", ["fetchCoursDetails"]),

    async handleStartClick() {
      if (this.percentage == 100) {
        await this.$store.dispatch("user/resetCourseProgress", {
          courseId: this.idSuivi,
          slug: this.cours.attributes.slug,
        });
        this.$router.push(`/cours/${this.cours.attributes.url}/1`);
      } else {
        this.$router.push(
          `/cours/${this.cours.attributes.url}/${this.idChapitreActuel}`
        );
      }
    },
  },
  async mounted() {
    await this.fetchUserInfo();
    if (this.getUserInfo) {
      await this.fetchCoursDetails(this.$route.params.slug);
    }
    if (!this.getUserInfo) {
      this.$router.push("/connexion");
    } else {
      await this.fetchCoursDetails(this.$route.params.slug);
      if (!this.userProgress) {
        this.$router.push("/cours");
      }
    }
  },
  watch: {
    userProgress(progress) {
      if (progress) {
        this.idChapitreActuel = progress.id_chapitre || 1;
        this.aDejaCommence = progress.pourcentage > 0;
        this.percentage = progress.pourcentage;
        this.idCours = this.cours.attributes.id;
        this.idSuivi = progress.idSuivi;
      } else {
        this.idChapitreActuel = 1;
        this.aDejaCommence = false;
        this.percentage = 0;
        this.idSuivi = null;
      }
    },
  },
};
</script>

<style scoped>
.head-cours {
  position: relative;
  width: 100%;
  margin-top: -40px;
  z-index: 1;
}

.after-head {
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1;
}

.header-c {
  padding: 40px 0;
}

.top-course {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.size-c {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px !important;
  width: 100%;
  z-index: 2;
}

.header-c .details-cours,
.header-c .img-bg-cours {
  width: 100%;
  z-index: 1;
}

.img-bg-cours {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-bg-cours .pr {
  position: absolute;
  bottom: 0;
  margin-bottom: -20px;
}

.img-bg-cours .pr svg {
  width: 60px;
  height: 60px;
}

.infos-course {
  display: flex;
  align-items: center;
  gap: 10px;
}

.infos-course div {
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--options-v-background-color);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
  padding: 3px 8px;
  border-radius: 8px;
}

.infos-course div p {
  color: var(--text);
  font-size: 13px;
}

.img-bg-cours img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 20px;
  min-height: 350px;
  height: 100%;
}

.chaps {
  margin-bottom: 40px !important;
}

.back-course {
  display: flex;
  align-items: center;
  gap: 10px;
}

.back-course p {
  display: inline-block;
}

.containers-chapters {
  width: 100%;
  margin-top: 20px;
}

.card-chapter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: var(--card);
  border-radius: 8px;
  margin-bottom: 10px;
  gap: 10px;
  border: 1px solid var(--placeholder);
}

.card-chapter span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: var(--text);
  border-radius: 8px;
  font-weight: 500;
  color: var(--color);
}

.card-chapter .titre-c {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.card-chapter .titre-c h3 {
  font-weight: 500 !important;
  font-size: 18px;
}

/* .card-chapter div {
  display: inline;
} */

.d-check-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-style {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.new-style h1 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.new-style .desc {
  text-align: justify;
  margin-bottom: 20px;
  font-size: 18px;
}

.new-style .start-course {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 10px;
}

.new-style .start-course a {
  display: flex;
  justify-content: center;
  background: var(--black);
  padding: 15px 10px;
  border-radius: 8px;
  min-width: 200px;
  max-width: 200px;
  transition: 0.2s;
}

.new-style .start-course a:hover {
  background: var(--button);
}

.new-style .start-course a:hover p {
  color: #fff;
}

.new-style .start-course p {
  display: inline;
  color: var(--color);
  font-weight: 600;
  font-size: 18px;
}

.container-chapters {
  max-width: 1400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  margin-bottom: 200px;
}

.chapters-cours {
  display: block;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.chapters-cours h2 {
  text-align: center;
  margin-bottom: 30px;
}

@media (max-width: 880px) {
}

@media (max-width: 480px) {
}
</style>
