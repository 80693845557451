<template>
  <div class="cours-icon-tab">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bold_note-2"
        data-name="vuesax/bold/note-2"
        transform="translate(-108 -252)"
      >
        <path
          id="Vector"
          d="M11.564,14.74c-.26.11-.54.21-.84.31l-1.58.52c-3.97,1.28-6.06.21-7.35-3.76L.514,7.86C-.766,3.89.294,1.79,4.264.51L5.8,0a12.54,12.54,0,0,0-.5,1.65l-.98,4.19c-1.1,4.71.51,7.31,5.22,8.43Z"
          transform="translate(109.986 257.42)"
          fill="#292d32"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M10.2.709,8.531.319c-3.34-.79-5.33-.14-6.5,2.28a10.474,10.474,0,0,0-.74,2.2l-.98,4.19c-.98,4.18.31,6.24,4.48,7.23l1.68.4a10.514,10.514,0,0,0,1.62.27c3.12.3,4.78-1.16,5.62-4.77l.98-4.18C15.671,3.759,14.391,1.689,10.2.709Zm-1.88,10.12a.748.748,0,0,1-.73.56,1.366,1.366,0,0,1-.19-.02l-2.91-.74a.748.748,0,1,1,.37-1.45l2.91.74A.748.748,0,0,1,8.321,10.829Zm2.93-3.38a.748.748,0,0,1-.73.56,1.366,1.366,0,0,1-.19-.02l-4.85-1.23a.748.748,0,0,1,.37-1.45l4.85,1.23A.739.739,0,0,1,11.251,7.449Z"
          transform="translate(114.969 254.501)"
          fill="#292d32"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(132 276) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "cours-d",
};
</script>

<style>
.cours-icon-tab {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.cours-icon-tab svg {
  width: 25px;
  height: 25px;
}

.router-link-exact-active .cours-icon-tab svg path {
  fill: var(--button) !important;
}

.cours-icon-tab svg path {
  fill: var(--placeholder) !important;
}
</style>
