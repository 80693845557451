<template class="target-foot">
  <div>
    <div class="start-learning" v-if="!isLoggedIn">
      <div class="footer-start">
        <div class="texts">
          <h2 class="section-title">Commencez à apprendre</h2>
          <h3 class="section-sub-heading">
            <span>Doonun</span><strong> facilite</strong> <span> votre</span>
            <strong> compréhension</strong>
            <span> de l'islam.</span>
          </h3>
        </div>
        <router-link to="/inscription" class="button">
          <span class="label">Débutez les cours</span>
        </router-link>
        <img
          class="illustration"
          src="https://cp.doonun.com/uploads/hat_6efb504ec9.png"
          alt="illustration"
          width="120"
          height="94"
        />
      </div>
    </div>
    <footer class="footer">
      <div class="inner">
        <div class="column is-logo">
          <router-link to="/" class="main-logo">
            <div class="logo">
              <img
                src="https://cp.doonun.com/uploads/logo_5c214d1798.png"
                alt="doonun"
              />
            </div>
            <div class="logo-info">
              <div class="text">Doonun</div>
              <span class="copyright"
                >© {{ currentYear }}. Tous droits réservés.</span
              >
            </div>
          </router-link>
        </div>
        <div class="column is-nav">
          <div class="column-title">Navigation</div>
          <ul>
            <li><router-link to="/">Accueil</router-link></li>
            <li><router-link to="/coran">Coran</router-link></li>
            <li><router-link to="/duaas">Duaas</router-link></li>
            <li><router-link to="/Connexion">Connexion</router-link></li>
            <li><router-link to="/Inscription">Inscription</router-link></li>
          </ul>
        </div>

        <div class="column is-nav">
          <div class="column-title">Contact</div>
          <ul class="contact">
            <li>
              <a href="mailto:contact.doonun@gmail.com"
                >contact.doonun@gmail.com</a
              >
            </li>
            <li>
              <router-link to="/contact">Formulaire de contact</router-link>
            </li>
          </ul>
          <div class="column-title">Nos réseaux</div>
          <ul class="reseaux">
            <li>
              <a href="https://discord.gg/YtjESS5SjC" target="_blank"
                ><i class="fa-brands fa-discord"></i
              ></a>
            </li>
            <li>
              <a href="https://twitter.com/Doonunfr" target="_blank"
                ><i class="fa-brands fa-x-twitter"></i
              ></a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@doonunfr" target="_blank"
                ><i class="fa-brands fa-tiktok"></i
              ></a>
            </li>
            <li>
              <a href="https://www.youtube.com/@doonunfr" target="_blank"
                ><i class="fa-brands fa-youtube"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/doonunfr/" target="_blank"
                ><i class="fa-brands fa-instagram"></i
              ></a>
            </li>
          </ul>
        </div>

        <div class="column is-nav">
          <div class="column-title">Mentions légales</div>
          <ul>
            <li>
              <router-link to="/mentions-legales"
                >Conditions générales d'utilisations</router-link
              >
            </li>
            <li>
              <router-link to="/cgv">Politique de confidentialité</router-link>
            </li>
            <li>
              <router-link to="/cookies">Politique des cookies</router-link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer-d",
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style scoped>
ul {
  list-style: none;
}

a {
  text-decoration: unset;
}

a:hover {
  text-decoration: underline;
}

ul > li > a {
  color: #fff;
}

ul > li {
  line-height: 1.5em;
}

.footer {
  display: block;
  width: 100%;
  background: #202531;
  padding: 100px 25px 50px 25px;
  z-index: 998;
}

.footer-start {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  width: 90%;
  margin: auto;
  padding: 80px 100px;
  background: #705df2;
  border-radius: 15px;
}

.start-learning {
  margin-top: 200px;
  margin-bottom: -70px;
  z-index: 999;
}

.footer .inner {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  column-gap: 20px;
  row-gap: 20px;
  max-width: 1180px;
  margin: auto;
  text-align: left;
}

.main-logo {
  position: relative;
  display: flex;
  align-items: center;
}

.main-logo .logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.main-logo .logo > img {
  display: block;
  width: 100%;
  min-width: 40px;
}

.logo-info {
  text-align: left;
  line-height: 20px;
}

.text {
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  letter-spacing: 0.18em;
  font-weight: 600;
}

.copyright {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
}

.footer .column {
  width: 100%;
  font-size: 14px;
  text-align: left;
}

.footer .column .column-title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.5em;
  color: #fff !important;
}

.footer li span {
  color: #fff !important;
}

.footer .column .column-title:not(:first-child) {
  margin-top: 1em;
}

.illustration {
  object-fit: contain;
  left: 8%;
  position: absolute;
  top: -28px;
  animation: floating 3s ease-in-out infinite;
}

.contact li {
  margin-bottom: 5px;
}

@keyframes floating {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

a.button {
  display: inline-flex;
  position: relative;
  height: 60px;
  margin-right: 20px;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background: #fff;
  padding: 0 30px;
  text-decoration: unset;
  border-radius: 36px;
  box-shadow: 0px 24px 74px rgb(60 0 189 / 20%);
}

a.button .label {
  color: #705df2;
  font-size: 16px;
  font-weight: 700 !important;
}

.section-title {
  font-size: 44px;
  font-weight: 700;
  color: #fff !important;
}

.section-sub-heading {
  font-size: 22px;
  font-weight: 400;
  color: #fff !important;
}

.section-sub-heading span {
  opacity: 0.6;
}

.section-sub-heading strong {
  font-weight: 600;
}

.reseaux {
  display: flex;
  align-items: center;
  gap: 25px;
}

.reseaux i {
  font-size: 20px;
}

@media only screen and (max-width: 990px) and (min-width: 200px) {
  body {
    height: 200vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .footer .inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .start-learning {
    position: relative;
    top: -50px;
  }

  h2 {
    margin-top: 40px;
  }

  .footer-start {
    width: 90%;
    display: block;

    padding: 30px 20px;
    margin: 30px auto -10px auto;
  }

  .section-title {
    font-size: 30px;
  }

  .section-sub-heading {
    font-size: 18px;
  }

  footer.footer {
    padding-top: 50px;
    padding-bottom: 120px;
  }

  a.button {
    height: 50px;
    margin-top: 10px;
  }

  .column.is-logo {
    order: 4;
  }
}
</style>
