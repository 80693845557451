<template>
  <div class="server-error">
    <h1>Erreur Serveur</h1>
    <Head>
      <title>Erreur Serveur</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
    <h2>500 - Erreur Interne du Serveur</h2>
    <p>
      Un problème est survenu et votre demande n'a pas pu être traitée. Nous
      travaillons pour résoudre le problème.
    </p>
    <p>
      Veuillez essayer de
      <a href="javascript:location.reload()">rafraîchir la page</a> ou revenir
      plus tard.
    </p>
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
export default {
  name: "ServerError",
  components: {
    Head,
  },
};
</script>

<style scoped>
.server-error {
  text-align: center;
  padding: 2rem;
}

.server-error h1 {
  font-size: 2.5rem;
}

.server-error h2 {
  font-size: 1.5rem;
  margin: 1rem 0;
}

.server-error p {
  margin: 1rem 0;
}
</style>
