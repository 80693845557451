<template>
  <div class="cont-card">
    <div class="card-avis">
      <div>
        <div class="info-u-v">
          <div class="picture-user">
            <img :src="img" alt="" />
          </div>
          <p class="pseudo-name">{{ name }}</p>
        </div>
        <p class="avis">“{{ avis }}”</p>
      </div>
      <div class="rating">
        <p>
          <span class="note">{{ note }}</span
          ><span class="nt">/5</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "card-a",
  props: {
    img: String,
    name: String,
    avis: String,
    note: String,
  },
};
</script>

<style scoped>
.cont-card {
  max-width: 400px;
  min-width: 400px;
  width: 100%;
  height: 100%;
}

@media (max-width: 576px) {
  .cont-card {
    min-width: 320px;
  }

  .avis {
    font-size: 16px;
  }
}

.card-avis {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: var(--card);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  gap: 10px;
  cursor: grab;
}

.picture-user {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--body);
}

.picture-user img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.info-u-v {
  display: flex;
  align-items: center;
  gap: 20px;
}

.info-u-v p {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
}

.avis {
  margin-top: 15px;
  text-align: left !important;
}

.rating p {
  text-align: right;
}

.rating .note {
  font-size: 25px;
  font-weight: 700;
  color: #efbb01;
}

.nt {
  font-weight: 700;
}
</style>
