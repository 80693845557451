<template>
    <div class="lien" @click="copyLink">
        <span class="tooltip" v-if="showTooltip">Lien copié 🥰</span>
        <svg id="vuesax_outline_link-2" data-name="vuesax/outline/link-2" xmlns="http://www.w3.org/2000/svg" width="23.782"
            height="23.782" viewBox="0 0 23.782 23.782">
            <g id="link-2" transform="translate(0 0)">
                <path id="Vector"
                    d="M6.439,11.215A6.436,6.436,0,0,1,1.88.225a.743.743,0,0,1,1.05,1.05,4.945,4.945,0,1,0,7.006-.01.743.743,0,1,1,1.05-1.05,6.459,6.459,0,0,1,0,9.116A6.373,6.373,0,0,1,6.439,11.215Z"
                    transform="translate(2.47 10.09)" fill="#292d32" />
                <path id="Vector-2" data-name="Vector"
                    d="M10.89,11.626a.735.735,0,0,1-.525-.218.748.748,0,0,1,0-1.05,5.2,5.2,0,1,0-7.353,0,.743.743,0,1,1-1.05,1.05,6.688,6.688,0,1,1,9.463.01A.792.792,0,0,1,10.89,11.626Z"
                    transform="translate(8.007 2.405)" fill="#292d32" />
                <path id="Vector-3" data-name="Vector" d="M0,0H23.782V23.782H0Z" fill="none" opacity="0" />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Lien-d',
    props: {
        versetLink: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            showTooltip: false
        };
    },
    methods: {
        copyLink() {
            const textArea = document.createElement("textarea");
            textArea.value = this.versetLink;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("copy");
            document.body.removeChild(textArea);
            this.showTooltip = true;
            setTimeout(() => {
                this.showTooltip = false;
            }, 1000); // Cachez le tooltip après 2 secondes
        }
    }
}
</script>

<style scoped>
.lien {
    position: relative;
}

.tooltip {
    position: absolute;
    bottom: 35px;
    right: -45px;
    font-size: 14px;
    background: var(--card);
    width: 110px;
    color: var(--text);
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    /* Assurez-vous que l'opacité est à 1 */
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: var(--card);
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}


.lien svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.lien svg path {
    fill: var(--text);
}
</style>