<template>
  <div class="pr-s">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M4 12L20 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99996 17C8.99996 17 4.00001 13.3176 4 12C3.99999 10.6824 9 7 9 7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Precedent-d",
};
</script>

<style scoped>
.pr-s {
  display: flex;
  align-items: center;
}

.pr-s a {
  display: flex;
  align-items: center;
}

.pr-s svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.pr-s svg path {
  stroke: var(--text);
}
</style>
