<template>
  <div class="box-themes">
    <div
      v-for="(theme, index) in themes"
      :key="index"
      class="theme-item"
      :style="{ backgroundColor: theme.card }"
      @click="
        changeTheme(
          theme.card,
          theme.button,
          theme.body,
          theme.head,
          theme.option,
          theme.truncated,
          theme.shadow,
          theme.bglog,
          theme.bgPoints
        )
      "
    ></div>
  </div>
  <div v-if="themeError" class="theme-error">
    <p class="message-sub">
      {{ themeErrorMessage }}
      <router-link to="/tarifs">Cliquez-ici</router-link>
    </p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "theme-d",
  data() {
    return {
      token: localStorage.getItem("jwtToken"),
      themes: [
        {
          body: "#FFF2F2",
          card: "#FFE9E9",
          button: "#FF16A3",
          bgPoints: "#FFE9E9",
          head: "#FFCBCB",
          option: "rgb(255, 233, 233, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(255, 233, 233, 0) 0%, rgba(255, 233, 233, 1) 100%)",
          bglog: "#FFCBCB",
          shadow: "rgb(255, 22, 163, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#FFFBEC",
          card: "#FFF8DC",
          button: "#FFCC06",
          bgPoints: "#FFF8DC",
          head: "#F9E395",
          option: "rgb(255, 248, 220, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(255, 248, 220, 0) 0%, rgba(255, 248, 220, 1) 100%)",
          bglog: "#F9E395",
          shadow: "rgb(255, 204, 6, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#F1F9FF",
          card: "#E9F5FF",
          button: "#50AEFF",
          bgPoints: "#E9F5FF",
          head: "#95CEFF",
          option: "rgb(233, 245, 255, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(233, 245, 255, 0) 0%, rgba(233, 245, 255, 1) 100%)",
          bglog: "#95CEFF",
          shadow: "rgb(80, 174, 255, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#F5F8EF",
          card: "#EBF3DE",
          button: "#6C9834",
          bgPoints: "#EBF3DE",
          head: "#AABF8F",
          option: "rgb(235, 243, 222, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(235, 243, 222, 0) 0%, rgba(235, 243, 222, 1) 100%)",
          bglog: "#AABF8F",
          shadow: "rgb(108, 152, 52, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#EDE7F9",
          card: "#E5DAFB",
          button: "#9100FF",
          bgPoints: "#E5DAFB",
          head: "#A378EE",
          option: "rgb(229, 218, 251, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(229, 218, 251, 0) 0%, rgba(229, 218, 251, 1) 100%)",
          bglog: "#A378EE",
          shadow: "rgb(145, 0, 255, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#F9F5E7",
          card: "#FBF0DA",
          button: "#FF891D",
          bgPoints: "#FBF0DA",
          head: "#EEBB78",
          option: "rgb(251, 240, 218, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(251, 240, 218, 0) 0%, rgba(251, 240, 218, 1) 100%)",
          bglog: "#EEBB78",
          shadow: "rgb(255, 137, 29, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#EBE2DD",
          card: "#E5D7D0",
          button: "#806255",
          bgPoints: "#E5D7D0",
          head: "#88706B",
          option: "rgb(229, 215, 208, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(229, 215, 208, 0) 0%, rgba(229, 215, 208, 1) 100%)",
          bglog: "#88706B",
          shadow: "rgb(128, 98, 85, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#FFFFFF",
          card: "#F8F9F4",
          button: "#D9F27E",
          bgPoints: "#F8F9F4",
          head: "#D9F27E",
          option: "rgb(248, 249, 244, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(248, 249, 244, 0) 0%, rgba(248, 249, 244, 1) 100%)",
          bglog: "#D9F27E",
          shadow: "rgb(217, 242, 126, 0.56) 0px 22px 70px 4px",
        },
        {
          body: "#f6f6f6",
          card: "#fff",
          button: "#563df1",
          bgPoints: "#e8e8ff",
          head: "#E5E5E5",
          option: "rgb(255, 255, 255, 0.6)",
          truncated:
            "linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)",
          bglog: "#E5E5E5",
          shadow: "rgb(86, 61, 241, 0.56) 0px 22px 70px 4px",
        },
      ],
      themeError: false,
      themeErrorMessage: "",
    };
  },
  async mounted() {
    await this.fetchUser();
  },

  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
    ...mapGetters("sub", ["isSubscribed"]),
    hasSubscription() {
      return this.isSubscribed;
    },
  },

  methods: {
    ...mapActions("user", ["fetchUserInfo", "updateProfile"]),
    async fetchUser() {
      try {
        if (this.getToken) {
          this.user = this.getUserInfo;
          this.username = this.user.username;
          this.email = this.user.email;
        }
      } catch (error) {
        // Handle error
      }
    },
    changeTheme(
      cardColor,
      buttonColor,
      bodyColor,
      headColor,
      optionColor,
      truncatedColor,
      shadowplayColor,
      bglogoColor,
      bgPointsColor
    ) {
      if (!this.hasSubscription) {
        this.themeError = true;
        this.themeErrorMessage =
          "Cette fonctionnalité est reservée aux abonnés ! Veuillez souscrire à un abonnement, pour pouvoir changer de thème.";
        setTimeout(() => {
          this.themeError = false;
          this.themeErrorMessage = "";
        }, 10000);
        return;
      }
      // Mettre à jour les variables CSS du thème
      document.documentElement.style.setProperty("--card", cardColor);
      document.documentElement.style.setProperty("--button", buttonColor);
      document.documentElement.style.setProperty("--body", bodyColor);
      document.documentElement.style.setProperty("--headProfil", headColor);
      document.documentElement.style.setProperty("--bglogo", bglogoColor);
      document.documentElement.style.setProperty("--bgPoints", bgPointsColor);
      document.documentElement.style.setProperty(
        "--options-v-background-color",
        optionColor
      );
      document.documentElement.style.setProperty("--truncated", truncatedColor);
      document.documentElement.style.setProperty(
        "--shadowPlay",
        shadowplayColor
      );

      // Sauvegarder les couleurs dans le localStorage
      localStorage.setItem(
        "themeColors",
        JSON.stringify({
          card: cardColor,
          button: buttonColor,
          body: bodyColor,
          head: headColor,
          option: optionColor,
          truncated: truncatedColor,
          bglogo: bglogoColor,
          shadow: shadowplayColor,
          bgPoints: bgPointsColor,
        })
      );
    },
  },
};
</script>

<style scoped>
.box-themes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.box-themes div {
  min-width: 30px;
  height: 30px;
  border-radius: 12px;
  border: 2px solid var(--placeholder);
  cursor: pointer;
  transition: 0.2s ease;
}

.box-themes div:hover {
  border: 2px solid var(--button);
  transform: scale(1.05);
}

.theme-error {
  background: #ffef89;
  border: 2px solid #ffd900;
  padding: 10px;
  border-radius: 10px;
}

.theme-error p {
  color: #000;
}

.theme-error p a {
  font-weight: 700;
  color: blue;
}
</style>
