<template>
  <div class="custom-audio-player">
    <audio
      ref="audio"
      :src="file"
      @timeupdate="updateProgress"
      @ended="handleEnded"
    ></audio>
    <div class="timer">
      <span class="current-time">{{ formatTime(currentTime) }}</span
      >/
      <span class="total-time">{{ formatTime(totalTime) }}</span>
    </div>
    <div class="audio-controls">
      <input
        type="range"
        ref="progressBar"
        class="progress-bar"
        @input="seekTo"
        :value="currentProgress"
      />
    </div>
    <div class="control-audio">
      <div class="left" @click="toggleLoop">
        <Loop v-if="!isLooping" />
        <StopLoop v-else />
      </div>
      <div class="center" @click="togglePlayback">
        <Play v-if="!isPlaying" />
        <Pause v-else />
      </div>
      <div class="right" @click="toggleMute">
        <Volume v-if="!isMuted" />
        <VolumeOff v-else />
      </div>
    </div>
  </div>
</template>

<script>
import Play from "../components/icons/audios/Play.vue";
import Pause from "../components/icons/audios/Pause.vue";
import Loop from "../components/icons/audios/Loop.vue";
import StopLoop from "../components/icons/audios/StopLoop.vue";
import Volume from "../components/icons/audios/Volume.vue";
import VolumeOff from "../components/icons/audios/VolumeOff.vue";
export default {
  name: "player-d",
  data() {
    return {
      isPlaying: false,
      isLooping: false,
      currentTime: 0,
      totalTime: 0,
      currentProgress: 0,
      isMuted: false,
    };
  },
  props: {
    file: String,
    autoPlay: Boolean,
    loop: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Play,
    Pause,
    Loop,
    StopLoop,
    VolumeOff,
    Volume,
  },
  methods: {
    togglePlayback() {
      const audio = this.$refs.audio;
      if (audio) {
        // Vérifie si `audio` n'est pas null
        if (this.isPlaying) {
          audio.pause();
        } else {
          audio.play();
        }
        this.isPlaying = !this.isPlaying;
      }
    },

    toggleLoop() {
      const audio = this.$refs.audio;
      if (audio) {
        // Vérifie si `audio` n'est pas null
        audio.loop = !audio.loop;
        this.isLooping = audio.loop;
      }
    },
    updateProgress() {
      const audio = this.$refs.audio;
      if (audio) {
        // Vérifie si `audio` n'est pas null
        this.currentTime = audio.currentTime;
        this.totalTime = audio.duration;
        this.currentProgress = (this.currentTime / this.totalTime) * 100;
        this.$refs.progressBar.style.setProperty(
          "--progress-width",
          `${this.currentProgress}%`
        );
      }
    },

    seekTo(event) {
      const audio = this.$refs.audio;
      if (audio) {
        // Vérifie si `audio` n'est pas null
        const newPosition = (event.target.value / 100) * this.totalTime;
        audio.currentTime = newPosition;
      }
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    handleEnded() {
      const audio = this.$refs.audio;
      if (audio) {
        // Vérifie si `audio` n'est pas null
        if (this.isLooping) {
          audio.currentTime = 0;
          audio.play();
        } else {
          this.isPlaying = false;
        }
      }
    },
    toggleMute() {
      const audio = this.$refs.audio;
      if (audio) {
        // Vérifie si `audio` n'est pas null
        if (this.isMuted) {
          audio.volume = 1;
        } else {
          audio.volume = 0;
        }

        this.isMuted = !this.isMuted;
      }
    },
  },
  mounted() {
    const audio = this.$refs.audio;
    audio.addEventListener("timeupdate", this.updateProgress);
    audio.addEventListener("loadedmetadata", () => {
      this.totalTime = audio.duration;
    });
  },
  beforeUnmount() {
    const audio = this.$refs.audio;

    audio.removeEventListener("timeupdate", this.updateProgress);
  },
};
</script>

<style scoped>
.custom-audio-player {
  text-align: center;
}

.audio-controls {
  display: flex;
  align-items: center;
}

.control-audio {
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.control-audio .left,
.control-audio .right {
  cursor: pointer;
}

.progress-bar {
  flex-grow: 1;
}

.progress-bar {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: #ecf1fd;
  --progress-width: 0%;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: var(--button);
  border-radius: 50%;
  border: none;
  margin-top: -4.5px;
}

.progress-bar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: var(--button);
  border-radius: 50%;
  border: none;
}

.progress-bar::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(30, 0, 255, 0.1);
}

.progress-bar:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(30, 0, 255, 0.2);
}

.progress-bar:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(30, 0, 255, 0.2);
}

.progress-bar::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(30, 0, 255, 0.1);
}

.progress-bar:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(30, 0, 255, 0.2);
}

.progress-bar:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(30, 0, 255, 0.2);
}

.progress-bar::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    var(--button) var(--progress-width),
    #ecf1fd 0
  );
  height: 6px;
  border-radius: 15px;
}

.progress-bar::-moz-range-track {
  background: linear-gradient(
    to right,
    var(--button) var(--progress-width),
    #ecf1fd 0
  );
  height: 6px;
  border-radius: 15px;
}

.progress-bar::-ms-track {
  background: linear-gradient(
    to right,
    var(--button) var(--progress-width),
    #ecf1fd 0
  );
  height: 6px;
  border-radius: 15px;
}

.timer {
  margin-top: 10px;
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
}

.current-time,
.total-time {
}
</style>
