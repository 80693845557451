<template>
  <div>
    <ul class="list-quran" v-if="likedSourates.length > 0">
      <li
        class="card-fav"
        v-for="sourate in likedSourates"
        :key="sourate.Numero"
      >
        <router-link
          :to="`/sourate/${sourate.Numero}`"
          @click.capture="handleRouterLinkClick"
        >
          <div class="left-fav">
            <span>{{ sourate.Numero }}</span>
            <p>{{ sourate.Phonetique }}</p>
          </div>

          <div class="con-like">
            <svg
              @click.prevent="toggleLike($event, sourate)"
              height="32"
              width="32"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0H24V24H0z" fill="none"></path>
              <path
                d="M16.5 3C19.538 3 22 5.5 22 9c0 7-7.5 11-10 12.5C9.5 20 2 16 2 9c0-3.5 2.5-6 5.5-6C9.36 3 11 4 12 5c1-1 2.64-2 4.5-2z"
              ></path>
            </svg>
          </div>
        </router-link>
      </li>
    </ul>
    <div v-else>
      <p style="text-align: center">
        Vous n'avez pas ajouté de sourate dans vos favoris !
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SourateLiked",
  data() {
    return {
      likedSourates: [],
    };
  },
  created() {
    this.fetchLikedSourates();
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),
    async fetchLikedSourates() {
      try {
        const userId = this.getUserInfo.id;

        const response = await this.$axios.get("/like-sourates", {
          headers: {
            Authorization: `Bearer ${this.getToken}`,
          },
        });

        const userLikedSourates = response.data.data.filter(
          (sourate) =>
            sourate.attributes.id_users &&
            sourate.attributes.id_users.split("|").includes(userId.toString())
        );

        const detailsPromises = userLikedSourates.map(async (likedSourate) => {
          const sourateResponse = await this.$axios.get(
            `/sourates/${likedSourate.attributes.id_sourate}`
          );
          return {
            Numero: sourateResponse.data.data.attributes.Numero,
            Phonetique: sourateResponse.data.data.attributes.Phonetique,
          };
        });

        this.likedSourates = await Promise.all(detailsPromises);
      } catch (error) {
        // console.error("Erreur lors de la récupération des sourates likées :", error);
      }
    },
    async toggleLike(event, sourate) {
      event.stopPropagation();
      if (!sourate.Numero) {
        console.error("Numero de sourate est undefined");
        return;
      }
      try {
        const response = await this.$axios.get(
          `/like-sourates/${sourate.Numero}`,
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );

        let attributes = response.data.data.attributes;
        let users = (attributes.id_users || "").split("|").filter((id) => id);
        let likes = attributes.likes || 0;
        const userId = this.getUserInfo.id.toString();
        const index = users.indexOf(userId);

        if (index !== -1) {
          users = users.filter((id) => id !== userId);
          likes -= 1; // Décrémenter le compteur de likes
        }

        await this.$axios.put(
          `/like-sourates/${sourate.Numero}`,
          {
            data: {
              id_users: users.join("|"),
              likes: likes,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (index !== -1) {
          this.likedSourates = this.likedSourates.filter(
            (s) => s.Numero !== sourate.Numero
          );
        }

        const sourateData =
          JSON.parse(localStorage.getItem("sourateData")) || {};
        const currentSourateData = sourateData[sourate.Numero];

        if (currentSourateData) {
          // Préparation de la liste des utilisateurs ayant liké la sourate
          let users = currentSourateData.id_users.split("|").filter((id) => id);
          const index = users.indexOf(userId);

          if (index !== -1) {
            // L'utilisateur a déjà liké cette sourate, donc on enlève son like
            users = users.filter((id) => id !== userId);

            // Mise à jour des données de la sourate dans le localStorage
            sourateData[sourate.Numero] = {
              ...currentSourateData,
              likes: currentSourateData.likes - 1,
              id_users: users.join("|"),
            };

            // Mise à jour du localStorage avec les nouvelles données
            localStorage.setItem("sourateData", JSON.stringify(sourateData));

            // Mise à jour de la liste des sourates likées dans le composant
            this.likedSourates = this.likedSourates.filter(
              (s) => s.Numero !== sourate.Numero
            );
          }
        }
      } catch (error) {
        // console.error("Erreur lors de la mise à jour du like :", error);
      }
    },
    handleRouterLinkClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.list-quran {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 15px;
  padding-bottom: 20px;
}

.list-quran::-webkit-scrollbar {
  height: 8px;
}

.list-quran::-webkit-scrollbar-track {
  background: transparent;
}

.list-quran::-webkit-scrollbar-thumb {
  background: var(--placeholder);
  transition: 0.2s ease-out;
}

.list-quran::-webkit-scrollbar-thumb:hover {
  background: var(--text);
}

@media (max-width: 576px) {
  .list-quran::-webkit-scrollbar {
    display: none;
  }

  .list-quran {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .list-quran {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }

  .list-quran {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
}

.list-quran li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1 25%;
  min-width: 300px;
}

.list-quran li a {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

.card-fav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 10px 20px 10px 10px;
  background: var(--card);
  border-radius: 10px;
  margin-bottom: 10px;
}

.left-fav {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-fav span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 10px;
  background: var(--body);
  font-size: 16px;
  font-weight: 700;
  color: var(--text);
  border-radius: 10px;
}

.left-fav p {
  font-size: 18px;
  font-weight: 700;
}

.con-like {
  --red: rgb(255, 91, 137);
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.con-like {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 400ms cubic-bezier(0.68, -0.55, 0.27, 2.5),
    border-color 400ms ease-in-out;
  word-spacing: -2px;
}

@keyframes movingBorders {
  0% {
    border-color: #fce4e4;
  }

  50% {
    border-color: #ffd8d8;
  }

  90% {
    border-color: #fce4e4;
  }
}

.con-like:hover {
  transform: scale(105%);
  animation: movingBorders 3s infinite;
}

.con-like:hover svg {
  fill: var(--text);
}

.con-like svg {
  fill: rgb(255, 110, 110);
}

@keyframes beatingHeart {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(1.15);
  }

  30% {
    transform: scale(1);
  }

  45% {
    transform: scale(1.15);
  }

  60% {
    transform: scale(1);
  }
}

.con-like:hover svg {
  transform: scale(105%);
  border-color: #ffd8d8;
  animation: beatingHeart 1.2s infinite;
}
</style>
