<template>
  <div class="memo-icon">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_bubble" data-name="vuesax/linear/bubble" transform="translate(-172 -636)">
                <g id="bubble">
                    <path id="Vector" d="M10.26,5.13A5.13,5.13,0,1,1,5.13,0,5.13,5.13,0,0,1,10.26,5.13Z"
                        transform="translate(182.46 638)" fill="none" stroke="#292d32" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector" d="M6.16,3.08A3.08,3.08,0,1,1,3.08,0,3.08,3.08,0,0,1,6.16,3.08Z"
                        transform="translate(175.28 649.28)" fill="none" stroke="#292d32" stroke-width="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M5.12,2.56A2.56,2.56,0,1,1,2.56,0,2.56,2.56,0,0,1,5.12,2.56Z"
                        transform="translate(186.06 652.88)" fill="none" stroke="#292d32" stroke-width="1.5" />
                    <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 636)" fill="none"
                        opacity="0" />
                    <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 636)" fill="none"
                        opacity="0" />
                </g>
            </g>
        </svg> -->
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_linear_gps"
        data-name="vuesax/linear/gps"
        transform="translate(-556 -252)"
      >
        <g id="gps">
          <path
            id="Vector"
            d="M15,7.5A7.5,7.5,0,1,1,7.5,0,7.5,7.5,0,0,1,15,7.5Z"
            transform="translate(560.5 256.5)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z"
            transform="translate(565 261)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,2V0"
            transform="translate(568 254)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M2,0H0"
            transform="translate(558 264)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0V2"
            transform="translate(568 272)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M0,0H2"
            transform="translate(576 264)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-7"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(580 276) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "memo-d",
};
</script>

<style>
.memo-icon {
  display: flex;
  cursor: pointer;
}

.memo-icon svg {
  width: 20px;
  height: 20px;
}

.memo-icon svg path {
  stroke: var(--text) !important;
  transition: 0.2s ease-in;
}

.memo-icon svg:hover {
  fill: var(--button);
}
</style>
