<template>
  <div class="box-why">
    <div class="box-img-why">
      <img
        src="https://cp.doonun.com/uploads/Groupe_54_6d33e40bb4.png"
        alt=""
      />
    </div>
    <div class="box-why-info">
      <h2>Pourquoi Doonun ?</h2>
      <p class="speach-h2">
        Conçu pour les chercheurs de vérité, Doonun vous guide dans votre
        apprentissage des sciences islamiques avec facilité et profondeur.
      </p>
      <h3>Points Clés:</h3>
      <ul>
        <li>
          <p>
            <span>✅</span> Accès immédiat à des ressources fiables et
            authentiques
          </p>
        </li>
        <li>
          <p>
            <span>✅</span> Progression personnalisée pour suivre votre voyage
            spirituel
          </p>
        </li>
        <li>
          <p>
            <span>✅</span> Communauté de soutien pour partager et grandir
            ensemble
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Why-d",
};
</script>

<style scoped>
.box-why {
  width: 100%;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: center;
  gap: 30px;
}

.box-why h2 {
  margin-top: 0;
  font-size: 30px;
  margin-bottom: 10px;
}

.box-why-info .speach-h2 {
  margin-bottom: 20px;
}

.box-why-info h3 {
  margin-bottom: 15px;
}

.box-why-info ul {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.box-why-info ul li p {
  display: flex;
  align-items: top;
  gap: 5px;
}

/* .box-img-why {
    width: 100%;
    height: 350px;
} */

@keyframes floating {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.box-img-why {
  animation: floating 3s ease-in-out infinite;
}

.box-img-why img {
  width: 100%;
  min-height: 350px;
  max-height: 500px;
  object-fit: contain;
  border-radius: var(--br15);
}

@media (max-width: 768px) {
  .box-img-why {
    display: flex;
    justify-content: center;
  }

  .box-img-why img {
    width: 300px;
  }

  .box-why h2 {
    text-align: center;
    margin-bottom: 25px;
  }
}
</style>
