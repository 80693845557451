<template>
  <div class="days">
    <div class="top">
      <div class="img-t">
        <img
          src="https://cp.doonun.com/uploads/calendar_6284933_57a923b3c3.png"
          alt=""
        />
        <h3>{{ getCurrentMonth() }} - {{ getHijriDate() }}</h3>
      </div>
      <router-link to="/priere">
        <i class="fa-solid fa-arrow-right-long"></i>
      </router-link>
    </div>
    <div class="bottom">
      <div
        class="item-m"
        :class="{
          'ramadan-background': isRamadan(day),
          'shawwal-background': isShawwal(day),
          'white-day-background': isWhiteDay(day),
          'fasting-muharam': isFastingMouharram(day),
          'dhul-hijah': isDhulHija(day),
          'eid-adah': isEidAlAdha(day),
          isSacredMonth: isSacredMonth(day),
        }"
        v-for="(day, index) in prayersMoth.slice(startIndex, startIndex + 3)"
        :key="day.date"
      >
        <p class="number">
          {{ formatNumber(startIndex + 1 + index) }}
        </p>
        <p class="month-hijri">
          {{
            getDayOfWeek(startIndex + index, currentMonthIndex, currentYear)
          }}. {{ day.number }}
        </p>
        <p class="month-hijri">{{ day.month }}</p>
        <img
          :class="{
            'white-day-background': isWhiteDay(day) && !isDhulHija(day),
          }"
          src="https://cp.doonun.com/uploads/white_flag_1805888_baefe51658.png"
          alt=""
        />
        <img
          :class="{
            sacredMonth:
              !isWhiteDay(day) &&
              !isFastingMouharram(day) &&
              !isWeekDay(startIndex + index, currentMonthIndex, currentYear) &&
              isSacredMonth(day),
          }"
          src="https://cp.doonun.com/uploads/bless_3c8d0d3a01.png"
          alt=""
        />
        <img
          :class="{
            isWeekDay: isWhiteDay(day)
              ? false
              : isWeekDay(startIndex + index, currentMonthIndex, currentYear),
          }"
          src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
          alt=""
        />

        <img
          :class="{
            'eid-adah': isEidAlAdha(day),
          }"
          src="https://cp.doonun.com/uploads/garland_3511257_66b1b5142f.png"
          alt=""
        />
        <img
          :class="{
            'shawwal-background': isShawwal(day),
          }"
          src="https://cp.doonun.com/uploads/garland_3511257_66b1b5142f.png"
          alt=""
        />
        <img
          :class="{ mouharam: isFastingMouharram(day) }"
          src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
          alt=""
        />
        <img
          :class="{ 'ramadan-background': isRamadan(day) }"
          src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
          alt=""
        />
        <img
          :class="{
            'is-dhul-hijah': isEidAlAdha(day) ? false : isDhulHija(day),
          }"
          src="https://cp.doonun.com/uploads/route_10741176_eefc662f1e.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "three-d",
  data() {
    return {
      prayers: [],
      prayersMoth: [],
      showPopup: false,
      selectedCountry: "France",
      selectedCity: "",
      cityIndex: {},
      selectedDay: null,
      showPopupInfoBox: false,
      currentMonthIndex: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      changeMonthG: 0,
      changeMonthH: 1,
      changeMonthHi: 0,
      currentHijriYear: null,
      isFinishVisible: true,
      countries: [],
      cities: [],
      allCountries: [],
      countriesData: {},
      startIndex: null,
    };
  },
  watch: {},

  created() {
    this.fetchCountries();
    this.loadSelectedData();

    // Créez l'index des villes en fonction de leur première lettre
    this.cityIndex = {};

    if (!this.selectedCity) {
      this.fetchPrayerMonth();
    }

    const today = new Date();
    const todayString = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;

    // Trouver l'index de la première occurrence de la date actuelle
    this.startIndex = this.prayersMoth.findIndex(
      (day) => day.date === todayString
    );
  },

  computed: {},

  methods: {
    loadSelectedData() {
      const selectedCountry = localStorage.getItem("selectedCountry");
      const selectedCity = localStorage.getItem("selectedCity");

      if (selectedCountry && selectedCity) {
        this.selectedCountry = selectedCountry;
        this.selectedCity = selectedCity;
        this.fetchCities();
      }
    },
    async fetchCountries() {
      try {
        const response = await axios.get("https://cp.doonun.com/api/pays/1");
        if (response.status === 200) {
          this.allCountries = Object.keys(response.data.data.attributes.Pays);
          this.countriesData = response.data.data.attributes.Pays; // Stockez les données du pays ici
          this.countries = this.allCountries.map((country) => ({
            value: country,
            text: country,
          }));
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la liste des pays :",
          error
        );
      }
    },

    async fetchCities() {
      try {
        this.loading = true;
        const countryData = this.countriesData[this.selectedCountry];
        if (countryData) {
          const cities = countryData.map((city) => ({
            value: city,
            text: city,
          }));

          // Créez l'index des villes en fonction de leur première lettre
          this.cityIndex = {};
          cities.forEach((city) => {
            const firstChar = city.text.charAt(0).toLowerCase();
            if (!this.cityIndex[firstChar]) {
              this.cityIndex[firstChar] = [];
            }
            this.cityIndex[firstChar].push(city);
          });

          if (cities.length > 10) {
            this.cities = [];
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.cities = cities;
          } else {
            this.cities = cities;
          }
        }
        this.loading = false;
        this.filteredCitiesData = this.cities;
        // Sélectionnez la ville correctement lors du chargement des données
        if (this.selectedCity) {
          const cityIndex = this.cities.findIndex(
            (city) => city.text === this.selectedCity
          );
          if (cityIndex !== -1) {
            this.cities[cityIndex].selected = true;
          }
        }

        // Une fois que les villes sont chargées, vous pouvez appeler fetchPrayerTimes() si une ville est déjà sélectionnée
        if (this.selectedCity) {
          this.fetchPrayerMonth();
        }
      } catch (error) {
        this.loading = false;
        console.error(
          "Erreur lors de la récupération de la liste des villes pour le pays sélectionné :",
          error
        );
      }
    },

    getGregorianYear(monthOffset) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + monthOffset);

      return currentDate.getFullYear();
    },

    getCurrentMonth(offset = this.changeMonthG) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + offset);

      this.currentMonthIndex = currentDate.getMonth();
      this.currentYear = currentDate.getFullYear();

      const monthFormatter = new Intl.DateTimeFormat("fr-FR", {
        month: "long",
      });
      const currentMonth = monthFormatter.format(currentDate);

      return `${currentMonth.charAt(0).toUpperCase()}${currentMonth.slice(1)} ${
        this.currentYear
      }`;
    },

    getDayOfWeek(day, month, year) {
      const targetDate = new Date(year, month, day);
      const dayOfWeekIndex = targetDate.getDay();
      const weekdays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
      const dayOfWeek = weekdays[dayOfWeekIndex];

      return dayOfWeek;
    },
    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async fetchPrayerMonth() {
      try {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + this.changeMonthH;
        const params = {
          city: this.selectedCity ? this.selectedCity : "Paris",
          country: this.selectedCountry,
          method: 3,
        };

        const url = `https://api.aladhan.com/v1/calendarByCity/${year}/${month}`;
        const response = await axios.get(url, { params });

        if (response.status === 200 && response.data && response.data.data) {
          const data = response.data.data;
          const prayerData = data.map((day) => {
            return {
              date: day.date.gregorian.date,
              month: day.date.hijri.month.en,
              number: day.date.hijri.day,
              year: day.date.hijri.year,
              Fajr: day.timings.Fajr.split(" ")[0],
              Shuruq: day.timings.Sunrise.split(" ")[0],
              Dhuhr: day.timings.Dhuhr.split(" ")[0],
              Asr: day.timings.Asr.split(" ")[0],
              Maghrib: day.timings.Maghrib.split(" ")[0],
              Isha: day.timings.Isha.split(" ")[0],
            };
          });

          this.prayersMoth = prayerData;

          // Ajoutez un log pour voir le contenu de prayersMoth
          console.log("prayersMoth", this.prayersMoth);

          // Trouver l'index de la première occurrence de la date actuelle
          const today = new Date();

          this.startIndex = this.prayersMoth.findIndex((day) => {
            const parts = day.date.split("-"); // Sépare la date en parties [jour, mois, année]
            const dayOfMonth = parts[0];
            const monthOfYear = parts[1];
            const year = parts[2];

            // Compare les parties de la date avec la date actuelle
            return (
              parseInt(dayOfMonth, 10) === today.getDate() &&
              parseInt(monthOfYear, 10) === today.getMonth() + 1 &&
              parseInt(year, 10) === today.getFullYear()
            );
          });

          console.log("index", this.startIndex);
        } else {
          console.error(
            "Erreur lors de la récupération des données de calendrier"
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des horaires de prière pour le mois :",
          error
        );
      }
    },

    formatNumber(number) {
      return number < 10 ? "0" + number : number;
    },

    isFinish(index, currentMonth, currentYear) {
      const targetDate = new Date(currentYear, currentMonth, index + 2);
      const today = new Date();
      return today > targetDate;
    },

    toggleFinish() {
      this.isFinishVisible = !this.isFinishVisible;
      const elements = document.querySelectorAll(".isFinish");
      for (const element of elements) {
        element.style.display = this.isFinishVisible ? "block" : "none";
      }
    },

    loadFinishState() {
      const finishState = localStorage.getItem("isFinishVisible");
      if (finishState !== null) {
        this.isFinishVisible = JSON.parse(finishState);
      }
    },

    isWeekDay(day, month, year) {
      const targetDate = new Date(year, month, day);
      const dayOfWeekIndex = targetDate.getDay();
      const weekdays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
      const dayOfWeek = weekdays[dayOfWeekIndex];

      return dayOfWeek === "Lun" || dayOfWeek === "Jeu";
    },
    showPopupInfo(day) {
      this.selectedDay = day;
      this.showPopupInfoBox = true;
    },

    isRamadan(day) {
      return day.month === "Ramaḍān";
    },

    isShawwal(day) {
      return day.month === "Shawwāl" && day.number === "01";
    },

    isWhiteDay(day) {
      return ["13", "14", "15"].includes(day.number);
    },
    isFastingMouharram(day) {
      return day.month === "Muḥarram" && ["09", "10"].includes(day.number);
    },
    isSacredMonth(day) {
      const sacredMonths = [
        "Muḥarram",
        "Rajab",
        "Dhū al-Qaʿdah",
        "Dhū al-Ḥijjah",
      ];

      // Vérifiez si le mois actuel fait partie des mois sacrés
      return sacredMonths.includes(day.month);
    },
    isDhulHija(day) {
      return (
        day.month === "Dhū al-Ḥijjah" &&
        [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
        ].includes(day.number)
      );
    },
    isEidAlAdha(day) {
      return day.month === "Dhū al-Ḥijjah" && ["10"].includes(day.number);
    },

    getHijriDate() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + this.changeMonthHi);

      const hijriDate = Intl.DateTimeFormat("en-u-ca-islamic", {
        month: "long",
        year: "numeric",
      }).formatToParts(currentDate);

      let hijriMonth = "";
      let hijriYear = "";

      for (const part of hijriDate) {
        if (part.type === "month") {
          hijriMonth = part.value;
        } else if (part.type === "year") {
          hijriYear = part.value;
        }
      }

      this.currentHijriYear = parseInt(hijriYear, 10); // Stockez l'année Hijri actuelle

      return `${hijriMonth} ${hijriYear}`;
    },

    getHijriYear(monthOffset) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + monthOffset);

      const hijriDate = Intl.DateTimeFormat("en-u-ca-islamic", {
        year: "numeric",
      }).formatToParts(currentDate);

      let hijriYear = "";

      for (const part of hijriDate) {
        if (part.type === "year") {
          hijriYear = part.value;
        }
      }

      return parseInt(hijriYear, 10);
    },

    getMonthsInHijriYear(hijriYear) {
      return this.isLeapYearInHijriCalendar(hijriYear) ? 13 : 12;
    },

    isLeapYearInHijriCalendar(hijriYear) {
      return (hijriYear - 1) % 30 < 11 || (hijriYear - 1) % 30 === 29;
    },
  },
};
</script>

<style scoped>
.days {
  padding: 20px;
  background: var(--card);
  border-radius: 10px;
}

.days .top {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.days .img-t {
  display: flex;
  align-items: top;
  gap: 10px;
}

.days img {
  width: 40px;
  height: 40px;
}

.days h3 {
  font-size: 18px;
  font-weight: 800;
}

.bottom {
  margin-top: 10px;
  display: flex;
  align-items: stretch;
  gap: 10px;
}

.bottom div {
  position: relative;
  flex-grow: 1;
  padding: 10px;
  background: var(--body);
  border-radius: 10px;
}

.bottom div p {
  font-size: 13px;
}

.item-m img {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 10px;
}

.white-day-background {
  background: #fae8b4 !important;
  color: #000 !important;
}

img.white-day-background {
  display: block;
}

img.sacredMonth {
  display: block;
}

.ramadan-background {
  background: #cdf9d1 !important;
  color: #000 !important;
}

img.isWeekDay {
  display: block;
}

img.mouharam {
  display: block;
}

img.isWeekDay img.white-day-background {
  display: none;
}

img.ramadan-background {
  display: block;
}

.fasting-muharam {
  background: #ccc8ff !important;
  color: #000 !important;
}

.dhul-hijah {
  background: #ffbed9 !important;
  color: #000 !important;
}

img.is-dhul-hijah {
  display: block;
}

.shawwal-background {
  background: #bce1fd !important;
  color: #000 !important;
}

img.shawwal-background {
  display: block;
}

.eid-adah {
  background: #bce1fd !important;
  color: #000 !important;
}

img.eid-adah {
  display: block !important;
}

.month-hijri {
  font-weight: 500;
  font-size: 16px;
}

.item-m .number {
  font-size: 18px;
  font-weight: 700;
}

.item-m .h {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  gap: 3px;
  justify-content: end;
}

.item-m .h span {
  text-align: center;
  font-weight: 600;
  border: 1px solid var(--text);
  padding: 3px 3px;
  border-radius: 8px;
  flex-grow: 1;
  width: 55px;
}

.isFinish {
  position: relative;
  opacity: 0.5;
}
</style>
