import { createStore } from "vuex";
// import user from './modules/user';
import ui from "./modules/ui";
import sourate from "./modules/sourate";
// import cours from './modules/cours';
import duaa from "./modules/duaa";
import coursModule from "./modules/cours";
import userModule from "./modules/user";
import sub from "./modules/sub";
import priere from "./modules/priere";

export default createStore({
  modules: {
    // user,
    ui,
    sourate,
    // cours,
    duaa,
    sub,
    cours: coursModule,
    user: userModule,
    priere,
  },
  state: {
    avatarId: null,
  },
  mutations: {
    SET_AVATAR_ID(state, newId) {
      state.avatarId = newId;
    },
  },
  actions: {
    updateAvatarId({ commit }, newId) {
      commit("SET_AVATAR_ID", newId);
    },
  },
});
