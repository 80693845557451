<template>
  <div>
    <div class="h-rank">
      <h2 class="title-c-section">Classement</h2>
      <router-link to="/classement">
        <span><i class="fa-solid fa-arrow-right"></i></span>
      </router-link>
    </div>
    <div class="box-ranking">
      <div
        class="item-rank"
        v-for="(user, index) in usersRanking.slice(0, 5)"
        :key="index"
      >
        <div class="num-rank">
          <p>#{{ user.rank }}</p>
        </div>
        <div class="rank">
          <div class="rank-info">
            <img class="profil-user" :src="user.avatar" :alt="user.username" />
            <p>{{ user.username }}</p>
          </div>
          <div class="hassanate-rank">
            <p>
              {{ formatPoints(user.points) }}
              <Blitz />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Blitz from "./icons/Blitz.vue";

export default {
  name: "Classement-d",
  components: {
    Blitz,
  },
  computed: {
    ...mapState("user", ["usersRanking"]),
  },
  created() {
    this.fetchRanking();
  },
  methods: {
    ...mapActions("user", ["fetchRanking"]),
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return points.toString();
      }
    },
  },
};
</script>

<style>
.container {
  margin-bottom: 20px;
}

.title-c-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.box-ranking {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  gap: 10px;
}

.item-rank {
  width: 100%;
  padding: 10px;
  background-color: var(--card);
  height: 60px;
  border-radius: var(--br10);
}

.num-rank {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20;
  left: 20;
  width: 30px;
  height: 30px;
  padding: 15px;
  background: var(--body);
  border-radius: var(--br30);
}

.num-rank p {
  color: var(--text);
  font-weight: 800;
  font-size: 15px;
  line-height: 0;
}

.rank {
  padding: 0 0 0 20px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.rank-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rank-info p {
  word-break: break-all;
  font-weight: 700;
}

.rank-info img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.hassanate-rank p {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
}

.h-rank {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.h-rank span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 600;
  gap: 10px;
  background: var(--card);
  padding: 20px;
  transition: 0.2s;
}

.h-rank span:hover {
  background: var(--button);
  color: #fff;
}
</style>
