<template>
  <div class="container">
    <div v-if="!sourate">
      <Loading />
    </div>
    <div class="container-s" v-else>
      <div class="back">
        <router-link :to="`/coran`">
          <Back />
        </router-link>
        <router-link :to="`/coran`">
          <p>Revenir</p>
        </router-link>
      </div>
      <div class="info-s">
        <div class="contexte">
          <Head>
            <title>
              Sourate {{ sourate.Phonetique }} : {{ sourate.Titre }} :
              {{ sourate.Arabe }} - Doonun
            </title>
            <meta
              name="description"
              :content="`Découvrez la sourate ${sourate.Phonetique} en arabe et français, avec tajweed, phonétique et tafsir. Cliquez sur les mots pour écouter leur prononciation. Mémorisez chaque verset facilement avec Doonun.`"
            />
            <meta
              property="og:title"
              :content="`Sourate ${sourate.Phonetique} : ${sourate.Titre} : ${sourate.Arabe}`"
            />
            <meta
              property="og:url"
              :content="`https://www.doonun.com/sourate/${this.$route.params.id}`"
            />
            <meta
              property="og:description"
              :content="`Découvrez la sourate ${sourate.Phonetique} en arabe et français, avec tajweed, phonétique et tafsir. Cliquez sur les mots pour écouter leur prononciation. Mémorisez chaque verset facilement avec Doonun.`"
            />
            <meta property="og:type" content="website" />
            <meta
              property="og:image"
              content="https://cp.doonun.com/uploads/sourate_a3bfc2f69f.png"
            />
            <meta name="robots" content="index, follow" />

            <!-- Twitter -->
            <meta
              name="twitter:title"
              :content="`Sourate ${sourate.Phonetique} : ${sourate.Titre} : ${sourate.Arabe}`"
            />
            <meta
              name="twitter:description"
              :content="`Découvrez la sourate ${sourate.Phonetique} en arabe et français, avec tajweed, phonétique et tafsir. Cliquez sur les mots pour écouter leur prononciation. Mémorisez chaque verset facilement avec Doonun.`"
            />
            <meta
              name="twitter:image"
              content="https://cp.doonun.com/uploads/sourate_a3bfc2f69f.png"
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@doonunfr" />
            <meta name="twitter:creator" content="@doonunfr" />
            <link
              rel="icon"
              href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
            />
          </Head>
          <h1>{{ sourate.Numero }} {{ sourate.Phonetique }}</h1>
          <h2>
            <span class="ti-r">{{ sourate.Arabe }}</span> : {{ sourate.Titre }}
          </h2>
          <div class="c-down" v-if="sourate && sourate.Contexte">
            <div
              id="markdown"
              class="markdown-body"
              :class="{ truncated: !showFullText }"
              v-html="$md.render(sourate.Contexte)"
            ></div>
            <div class="button-c">
              <p class="open-c" v-if="!showFullText" @click="toggleText">
                Lire la suite
              </p>
              <p class="close-c" v-else @click="toggleText">
                Fermer le contexte
              </p>
            </div>
          </div>
        </div>
        <div
          class="basmallah"
          v-if="this.$route.params.id !== '1' && this.$route.params.id !== '9'"
        >
          <Basmallah />
        </div>

        <div
          class="versets"
          :id="verset.attributes.numero"
          v-for="verset in sourate.versets.data.slice(0, loadedVersets)"
          :key="verset.id"
        >
          <div class="top-v">
            <p class="num-v">{{ verset.attributes.numero }}</p>
            <div class="link">
              <Memo @click="toggleMemoPopup(verset.attributes)" />
              <Share :verset="verset" :sourate="sourate" />
              <Lien
                :versetLink="`https://www.doonun.com/sourate/${sourate.Numero}#${verset.attributes.numero}`"
              />
              <Copy @copyClicked="copyText(verset)" />
            </div>
          </div>
          <div v-if="showArabe">
            <p class="arabe">
              <span
                class="word-arabic"
                v-for="(word, index) in verset.attributes.arabe.split(' ')"
                :key="index"
                @click="playWordAudio(verset, index, $event)"
              >
                {{ word }}&nbsp;
              </span>
            </p>
          </div>
          <p
            class="taj"
            v-if="showTajweed"
            v-html="verset.attributes.Tajweed"
          ></p>
          <p class="phonetic" v-if="showPhonetique">
            {{ verset.attributes.phonetique }}
          </p>
          <p class="french" v-if="showFrancais">{{ verset.attributes.fr }}</p>
          <p class="tafsir" v-if="showTafsir">
            <span class="taf-span">Tafsir</span> {{ verset.attributes.tafsir }}
          </p>
          <div class="kathir-check">
            <div class="play">
              <ul>
                <li class="audio-fr" @click="playAudio(verset, 'fr', $event)">
                  <component :is="verset.isPlayingFr ? 'Pause' : 'Play'" />
                  <img
                    src="https://cp.doonun.com/uploads/france_c3692c4e73.png"
                    alt=""
                  />
                </li>
                <li class="audio-ar" @click="playAudio(verset, 'ar', $event)">
                  <component :is="verset.isPlayingAr ? 'Pause' : 'Play'" />
                  <img
                    src="https://cp.doonun.com/uploads/ar_d5a49e3344.png"
                    alt=""
                  />
                </li>
              </ul>
            </div>
            <!-- <router-link
              :to="`/ibn-kathir/${sourate.Numero}/${verset.attributes.numero}`"
            >
              <p class="k-s">Voir Ibn Kathir</p>
            </router-link> -->
            <div class="check" v-if="isUserConnected">
              <div class="checkbox-wrapper-13">
                <input
                  type="checkbox"
                  :disabled="isCheckboxDisabled(verset.attributes.numero)"
                  :checked="checkedVersets.includes(verset.attributes.numero)"
                  @change="toggleVersetCheck(verset.attributes.numero)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="options-v">
          <div class="container-options" ref="containerOptions">
            <div
              v-if="showTextOptions"
              :class="{
                'text-option fadeInUp': showTextOptions,
                fadeOutDown: !showTextOptions,
              }"
            >
              <fontSize />
              <ul>
                <li class="toggle-lang txt">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showFrancais" />
                  </div>
                  <p class="chFr">Français</p>
                </li>

                <li class="toggle-lang txt">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showArabe" />
                  </div>
                  <p class="chAr">Arabe</p>
                </li>

                <li class="toggle-lang txt">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showPhonetique" />
                  </div>
                  <p class="chPn">Phonetic</p>
                </li>

                <li class="toggle-lang txt">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" @change="toggleTafsir" />
                  </div>
                  <p class="chTfr">Tafsir</p>
                </li>
                <!-- 
                <li class="toggle-lang txt">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showTajweed" />
                  </div>
                  <p class="chAr">Tjw</p>
                </li> -->
              </ul>
            </div>
            <ul>
              <li class="precedent">
                <router-link
                  v-if="previousSourate"
                  :to="`/sourate/${previousSourate}`"
                >
                  <Precedent />
                </router-link>
                <span class="nN" v-else>...</span>
              </li>
              <span
                class="op-V"
                @mousemove="handleMouseMove"
                ref="opVContainer"
              >
                <li class="fr-audio audio" @click="playAllAudio('fr')">
                  <component :is="isPlayingFr ? 'Pause' : 'Play'" />
                  <img
                    src="https://cp.doonun.com/uploads/france_c3692c4e73.png"
                    alt=""
                  />
                </li>
                <li class="ar-audio audio" @click="playAllAudio('ar')">
                  <component :is="isPlayingAr ? 'Pause' : 'Play'" />
                  <img
                    src="https://cp.doonun.com/uploads/ar_d5a49e3344.png"
                    alt=""
                  />
                </li>
                <li class="all-audio audio" @click="playAllAudio('all')">
                  <component :is="isPlayingAll ? 'Pause' : 'Play'" />
                  <img
                    src="https://cp.doonun.com/uploads/france_c3692c4e73.png"
                    alt=""
                  />
                  <img
                    src="https://cp.doonun.com/uploads/ar_d5a49e3344.png"
                    alt=""
                  />
                </li>
                <li class="open-options" @click="toggleTextOptio()">
                  <i v-if="!showTextOptions" class="fa-solid fa-gear"></i>
                  <i v-else class="fa-solid fa-angle-down"></i>
                </li>
                <li class="toggle-lang txt mb-display">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showFrancais" />
                  </div>
                  <p class="chFr">Français</p>
                </li>

                <li class="toggle-lang txt mb-display">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" @change="toggleTafsir" />
                  </div>
                  <p class="chTfr">Tafsir</p>
                </li>

                <li class="toggle-lang txt mb-display">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showArabe" />
                  </div>
                  <p class="chAr">Arabe</p>
                </li>

                <li class="toggle-lang txt mb-display">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showTajweed" />
                  </div>
                  <p class="chAr">Tajweed</p>
                </li>

                <li class="toggle-lang txt mb-display">
                  <div class="checkbox-wrapper-13">
                    <input type="checkbox" v-model="showPhonetique" />
                  </div>
                  <p class="chPn">Phonétique</p>
                </li>
              </span>
              <li class="next">
                <router-link v-if="nextSourate" :to="`/sourate/${nextSourate}`">
                  <Suivant />
                </router-link>
                <span class="nN" v-else>...</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <PopupMemo
      ref="popupMemo"
      v-if="showMemoPopup"
      :show="showMemoPopup"
      :phonetic="currentPhoneticVerset"
      :arabic="currentArabicVerset"
      :audio="currentAudioPath"
      :numero="currentNumeroVerset"
      @update:show="showMemoPopup = $event"
    ></PopupMemo>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import Share from "../components/icons/Share.vue";
import Lien from "../components/icons/Lien.vue";
import Copy from "../components/icons/Copy.vue";
import Memo from "../components/icons/Memo.vue";
import Precedent from "../components/icons/Precedent.vue";
import Suivant from "../components/icons/Suivant.vue";
import Play from "../components/icons/Play.vue";
import Pause from "../components/icons/Pause.vue";
import Loading from "../components/Loading.vue";
import PopupMemo from "../components/PopupMemo.vue";
import fontSize from "../components/fontSize.vue";
import Basmallah from "../components/icons/Basmallah.vue";
import Back from "../components/icons/Back.vue";
import { Head } from "@vueuse/head";

export default {
  name: "Sourate-d",
  components: {
    Share,
    Lien,
    Copy,
    Play,
    Precedent,
    Suivant,
    Loading,
    Pause,
    Memo,
    PopupMemo,
    Basmallah,
    Back,
    Head,
    fontSize,
  },
  data() {
    return {
      sourate: null,
      showFullText: false,
      showArabe: true,
      showTajweed: false,
      showPhonetique: false,
      showFrancais: true,
      showTafsir: false,
      showTextOptions: false,
      user: null,
      checkedVersets: [],
      markdown: null,
      audioElement: null,
      audioLang: null,
      isAudioPlaying: false,
      currentAudio: null,
      currentAudioWord: null,
      isPlaying: false,
      isPlayingFr: false,
      isPlayingAr: false,
      isPlayingAll: false,
      currentAudioAll: null,
      isPaused: false,
      lastAudio: null,
      audioFilesForCurrentSourate: null,
      showMemoPopup: false,
      currentArabicVerset: null,
      currentNumeroVerset: null,
      currentPhoneticVerset: null,
      currentAudioPath: null,
      loadedVersets: 5,
      localSourate: {},
    };
  },
  watch: {
    showTajweed(newVal) {
      if (newVal) {
        // Si Tajweed est coché
        this.showArabe = false; // Masquer Arabe
      }
    },
    showArabe(newVal) {
      if (newVal) {
        this.showTajweed = false;
      }
    },

    "$route.params.id": "loadSourate",

    $route: {
      immediate: true,
      handler(to, from) {
        if (to.hash) {
          this.scrollToHash(to.hash);
        } else if (from && to.path !== from.path) {
          window.scrollTo(0, 0);
        }
      },
    },

    sourate(newSourate, oldSourate) {
      // Vérifiez si oldSourate est non null et si le numéro de la nouvelle sourate est différent de l'ancienne
      if (oldSourate && newSourate.Numero !== oldSourate.Numero) {
        this.resetCheckedVersets();
        this.stopAndResetAudio();
        this.loadMoreVersets();
        this.plusVues();
      } else if (!oldSourate) {
        // Ajoutez une vérification pour s'assurer que l'utilisateur est connecté avant de tenter de récupérer les versets cochés
        if (this.isUserConnected) {
          this.fetchCheckedVersets();
        }
      }
    },
  },

  methods: {
    ...mapActions("user", [
      "fetchUserInfo",
      "updateUserPoints",
      "updateUserStatSourates",
    ]),
    async fetchVues() {
      try {
        const response = await this.$axios.get(
          `/vues-sourates/${this.sourate.Numero}`
        );
        console.log("response :", response.data.data.attributes.vues);
        const dbVues = response.data.data.attributes.vues || 0;
        this.localSourate.vues = dbVues;
      } catch (error) {
        console.error("Erreur lors de la récupération des vues:", error);
        this.localSourate.vues = 0;
      }
    },

    updateLocalSourateData(numero, data) {
      const existingData =
        JSON.parse(localStorage.getItem(`sourateData`)) || {};
      existingData[numero] = { ...existingData[numero], ...data };
      localStorage.setItem(`sourateData`, JSON.stringify(existingData));
    },

    async plusVues() {
      console.log("La méthode plusVues est appelée");
      console.log("Num", this.sourate.Numero);
      try {
        // Récupérer la valeur actuelle des vues avant de l'incrémenter
        await this.fetchVues();

        console.log("Avant la mise à jour : ", Number(this.localSourate.vues));
        const newVues = (this.localSourate.vues || 0) + 1;
        const response = await this.$axios.put(
          `/vues-sourates/${Number(this.sourate.Numero)}`,
          {
            data: {
              vues: newVues,
            },
          }
        );
        console.log("Réponse de la requête PUT : ", response);
        console.log("Après la mise à jour : ", Number(newVues));
        console.log("succès");

        // Mettez à jour les données locales avec les vues
        this.localSourate.vues = newVues;
        this.updateLocalSourateData(Number(this.sourate.Numero), {
          vues: newVues,
        });
      } catch (error) {
        console.error("Erreur lors de la mise à jour des vues:", error);
        this.localSourate.vues -= 1;
      }
    },

    loadMoreVersets() {
      // Chargez le reste des versets
      this.loadedVersets = this.sourate.versets.data.length;
    },
    toggleTafsir() {
      const closestVerset = this.getClosestVersetToTop();
      this.showTafsir = !this.showTafsir;

      this.$nextTick(() => {
        if (closestVerset) {
          this.scrollToVerset(closestVerset);
        }
      });
    },

    getClosestVersetToTop() {
      let closestVersetId = null;
      let smallestDistance = Infinity;

      this.sourate.versets.data.forEach((verset) => {
        const versetElement = document.getElementById(verset.attributes.numero);
        if (versetElement) {
          const distance = versetElement.getBoundingClientRect().top;
          if (distance > 0 && distance < smallestDistance) {
            smallestDistance = distance;
            closestVersetId = verset.attributes.numero;
          }
        }
      });

      return closestVersetId;
    },

    resetCheckedVersets() {
      // Réinitialise checkedVersets pour la nouvelle sourate
      this.checkedVersets = [];
      // Si nécessaire, chargez l'état correct des checkboxes pour la nouvelle sourate ici
      this.fetchCheckedVersets(); // Cette fonction devrait être ajustée pour être appelée seulement si l'utilisateur est connecté.
    },
    handleMouseMove(event) {
      this.$nextTick(() => {
        const container = this.$refs.opVContainer;
        if (!container) {
          return;
        }

        const containerWidth = container.clientWidth;
        const mouseX = event.clientX - container.getBoundingClientRect().left;

        const scrollSpeed = 100;
        const scrollAreaWidth = 100;

        if (mouseX < scrollAreaWidth) {
          container.scrollTo({
            left: container.scrollLeft - scrollSpeed,
            behavior: "smooth",
          });
        } else if (mouseX > containerWidth - scrollAreaWidth) {
          container.scrollTo({
            left: container.scrollLeft + scrollSpeed,
            behavior: "smooth",
          });
        }
      });
    },

    scrollToHash(hash) {
      if (hash.match(/^#\d+/)) {
        const elementId = hash.substring(1);
        this.$nextTick(() => {
          this.scrollToElement(elementId);
        });
      }
    },

    scrollToElement(elementId) {
      const attempts = 10;
      let currentAttempt = 0;
      const scroll = () => {
        const element = document.getElementById(elementId);
        if (element) {
          const windowHeight = window.innerHeight;
          const elementHeight = element.clientHeight;
          const elementPosition =
            element.offsetTop - (windowHeight - elementHeight) / 2;
          window.scrollTo({ top: elementPosition, behavior: "smooth" });
        } else if (currentAttempt < attempts) {
          setTimeout(scroll, 500);
          currentAttempt++;
        }
      };

      scroll();
    },

    toggleMemoPopup(versetAttributes) {
      this.currentArabicVerset = versetAttributes.arabe;
      this.currentPhoneticVerset = versetAttributes.phonetique;
      this.currentNumeroVerset = versetAttributes.numero;
      const audioPath = `https://audio.doonun.com/sourates/${this.$route.params.id}/ar/${versetAttributes.numero}.mp3`;
      this.currentAudioPath = audioPath;
      this.showMemoPopup = !this.showMemoPopup;
    },

    getConfig() {
      const token = localStorage.getItem("jwtToken");
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    },

    renderMarkdown(content) {
      return this.md.render(content);
    },

    toggleText() {
      this.showFullText = !this.showFullText;
      window.scrollTo(0, 0);
    },
    toggleTextOptio() {
      this.showTextOptions = !this.showTextOptions;
    },

    getAudioPath(verset, lang) {
      if (!verset || !verset.attributes) {
        console.error("Verset invalide:", verset);
        return null;
      }
      const sourateNumero = this.sourate.Numero;
      const versetNumero = verset.attributes.numero;
      const path = `https://audio.doonun.com/sourates/${sourateNumero}/${lang}/${versetNumero}.mp3`;
      return path;
    },

    playAudio(verset, lang) {
      if (this.isPlayingAll) {
        if (this.audioLang !== lang) {
          // Si tous les audios sont en cours de lecture et la langue de l'audio actuel est différente, ignorer cet audio.
          return;
        }
      }

      if (this.isPlayingAll) {
        if (this.currentAudio) {
          this.currentAudio.pause();
          this.currentAudio = null;
        }
        this.isPlayingFr = false;
        this.isPlayingAr = false;
        this.isPlayingAll = false;
      }

      if (this.audioElement) {
        if (
          this.audioLang === lang &&
          this.lastAudio === verset.attributes.numero
        ) {
          if (this.audioElement.paused) {
            this.audioElement.play();
            this.updateVersetState(verset, lang, true);
            this.isAudioPlaying = true;
          } else {
            this.audioElement.pause();
            this.updateVersetState(verset, lang, false);
            this.isAudioPlaying = false;
          }
          return;
        } else {
          this.audioElement.pause();
          this.updateVersetState(verset, lang, false);
          this.isAudioPlaying = false;
        }
      }

      const audioPath = this.getAudioPath(verset, lang);

      if (audioPath) {
        if (!this.audioElement) {
          this.audioElement = new Audio(audioPath);
          this.audioLang = lang;

          this.audioElement.addEventListener("ended", () => {
            this.updateVersetState(verset, lang, false);
            this.isAudioPlaying = false;
          });
        } else {
          this.audioElement.src = audioPath;
          this.audioLang = lang;
        }

        this.audioElement.play();
        this.updateVersetState(verset, lang, true);
        this.isAudioPlaying = true;
      } else {
        // console.error("Chemin d'accès audio invalide.");
      }

      this.lastAudio = verset.attributes.numero;
      // console.log(this.lastAudio);
    },

    playAllAudio(lang) {
      if (this.isAudioPlaying) {
        this.audioElement.pause();
        this.updateVersetState(this.lastAudio, this.audioLang, false);
        this.isAudioPlaying = false;
      }

      this.isPlayingFr = false;
      this.isPlayingAr = false;
      this.isPlayingAll = false;

      if (this.currentAudio) {
        if (this.currentLang === lang) {
          if (this.isPaused) {
            this.resumeAudio();
            this[
              `isPlaying${lang.charAt(0).toUpperCase() + lang.slice(1)}`
            ] = true;
          } else {
            this.pauseAudio();
            this[
              `isPlaying${lang.charAt(0).toUpperCase() + lang.slice(1)}`
            ] = false;
          }
          return;
        } else {
          if (
            this.lastAudio === this.sourate.Numero &&
            this.audioLang === lang
          ) {
            this.resumeAudio();
            this[
              `isPlaying${lang.charAt(0).toUpperCase() + lang.slice(1)}`
            ] = true;
            return;
          } else {
            this.currentAudio.pause();
            this.currentAudio = null;
            this.isPaused = false;
          }
        }
      }

      this.currentLang = lang;

      this[`isPlaying${lang.charAt(0).toUpperCase() + lang.slice(1)}`] = true;

      if (this.sourate && this.sourate.versets && this.sourate.versets.data) {
        const versets = this.sourate.versets.data;
        this.playNextAudio(versets, 0, lang);
      }
    },

    pauseAudio() {
      if (this.currentAudio && !this.isPaused) {
        this.currentAudio.pause();
        this.isPaused = true;
      }
    },

    resumeAudio() {
      if (this.currentAudio && this.isPaused) {
        this.currentAudio.play();
        this.isPaused = false;
      }
    },

    playNextAudio(versets, index, lang) {
      let startIndex = this.lastAudio ? parseInt(this.lastAudio, 10) : index;
      if (index < versets.length) {
        const verset = versets[startIndex];
        let audioPathAr =
          lang === "all" ? this.getAudioPath(verset, "ar") : null;
        let audioPathFr =
          lang === "all" ? this.getAudioPath(verset, "fr") : null;

        this.scrollToVerset(verset.attributes.numero);

        const continuePlaying = () => {
          this.lastAudio = startIndex + 1;
          this.playNextAudio(versets, startIndex + 1, lang);
        };

        if (lang === "all") {
          if (audioPathAr) {
            this.playAudioPath(audioPathAr, () => {
              if (audioPathFr) {
                this.playAudioPath(audioPathFr, continuePlaying);
              } else {
                continuePlaying();
              }
            });
          } else if (audioPathFr) {
            this.playAudioPath(audioPathFr, continuePlaying);
          } else {
            continuePlaying();
          }
          return;
        }

        // Pour les cas 'ar' et 'fr', le traitement reste inchangé
        let audioPath = this.getAudioPath(verset, lang);
        if (audioPath) {
          this.playAudioPath(audioPath, continuePlaying);
        } else {
          continuePlaying();
        }
      } else {
        // Gestion de fin de la liste des audios
        if (lang === "fr" || lang === "ar") {
          this[
            `isPlaying${lang.charAt(0).toUpperCase() + lang.slice(1)}`
          ] = false;
        } else if (lang === "all") {
          this.isPlayingAll = false;
        }
        window.location.reload(); // Considérez une approche alternative pour éviter de recharger la page
      }
    },

    playAudioPath(audioPath, callback) {
      if (this.currentAudio) {
        this.currentAudio.pause();
      }

      const audioElement = new Audio(audioPath);
      this.currentAudio = audioElement;
      this.isPaused = false;

      audioElement.play();

      audioElement.addEventListener("ended", () => {
        // Assurez-vous que l'audio n'est pas en pause avant d'appeler le callback
        if (!this.isPaused && callback && typeof callback === "function") {
          callback();
        }
      });
    },

    getWordAudioPath(verset, wordIndex) {
      if (!verset || !verset.attributes) {
        console.error("Verset invalide:", verset);
        return null;
      }

      const sourateDossier = String(this.sourate.Numero);
      const versetFile = String(verset.attributes.numero).padStart(3, "0");
      const sourateNumero = String(this.sourate.Numero).padStart(3, "0");
      const versetNumero = String(verset.attributes.numero).padStart(3, "0");
      const wordNumber = String(wordIndex + 1).padStart(3, "0");

      const audioPath = `https://audio.doonun.com/mots/${sourateDossier}/${versetFile}/${sourateNumero}_${versetNumero}_${wordNumber}.mp3`;

      return audioPath;
    },

    playWordAudio(verset, wordIndex, event) {
      event.preventDefault();

      // Arrêtez et réinitialisez les audios groupés, arabes ou français s'ils sont en cours de lecture
      if (
        this.isAudioPlaying ||
        this.isPlayingAll ||
        this.isPlayingAr ||
        this.isPlayingFr
      ) {
        this.stopAndResetAudio();
      }

      const audioPath = this.getWordAudioPath(verset, wordIndex);

      if (!audioPath) {
        console.error("Chemin audio invalide:", audioPath);
        return; // Arrêter l'exécution si le chemin audio est invalide
      }

      if (this.currentAudioWord) {
        this.currentAudioWord.pause();
        this.currentAudioWord.currentTime = 0;
      }

      this.currentAudioWord = new Audio(audioPath);
      this.currentAudioWord.onerror = () => {
        console.error("Erreur lors de la lecture audio:", audioPath);
        // Gérer l'erreur ici si nécessaire
      };

      this.currentAudioWord.play();
      this.lastPlayedVersetId = verset.id;

      // Réinitialisez les indicateurs de lecture des audios groupés, arabes et français
      this.isPlayingAll = false;
      this.isPlayingAr = false;
      this.isPlayingFr = false;
    },

    stopAndResetAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0;
        this.currentAudio = null;
      }
      if (this.audioElement) {
        this.audioElement.pause();
        this.audioElement.currentTime = 0;
        this.audioElement = null;
      }
      this.isAudioPlaying = false;
      this.isPlaying = false;
      this.isPlayingFr = false;
      this.isPlayingAr = false;
      this.isPlayingAll = false;
      this.currentAudioAll = null;
      this.isPaused = false;
      this.lastAudio = null;
      // Réinitialisez ici tout autre état lié à l'audio si nécessaire
    },

    scrollToVerset(versetId) {
      const element = document.getElementById(versetId);
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    },

    // Fonction auxiliaire pour mettre à jour l'état des versets
    updateVersetState(versetOrId, lang, isPlaying) {
      let versetToChange;

      // Si on reçoit un ID
      if (typeof versetOrId === "number") {
        versetToChange = this.sourate.versets.data.find(
          (v) => v.attributes.numero === versetOrId
        );
      } else {
        versetToChange = versetOrId;
      }

      if (!versetToChange) {
        // console.error('Verset introuvable pour l\'ID donné:', versetOrId);
        return;
      }

      versetToChange.isPlaying = isPlaying;

      this.sourate.versets.data.forEach((v) => {
        v.isPlayingFr = lang === "fr" && v === versetToChange && isPlaying;
        v.isPlayingAr = lang === "ar" && v === versetToChange && isPlaying;
      });
    },

    loadSourate() {
      const id = this.$route.params.id;
      this.stopAndResetAudio();
      axios
        .get(`/sourates/${id}?populate=*`)
        .then((response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.attributes
          ) {
            this.sourate = response.data.data.attributes;
            if (response.data.data.versets && response.data.data.versets.data) {
              this.sourate.versets = response.data.data.versets.data; // Accéder aux versets
            }
            this.resetCheckedVersets(); // S'assurer que cette ligne est bien exécutée après avoir chargé la sourate
          }
        })
        .catch((error) => {
          error;
          // console.error("Erreur lors de la récupération de la sourate:", error);
        });
    },

    async fetchCheckedVersets() {
      // Ajoutez une vérification pour s'assurer que l'utilisateur n'est pas null avant de continuer
      if (!this.user) {
        return; // Sortez de la fonction si aucun utilisateur n'est connecté
      }

      const userId = this.user.id; // Assurez-vous que l'utilisateur est connecté

      try {
        const response = await axios.get(
          `/versets-checks?filters[id_user][$eq]=${userId}&filters[id_sourate][$eq]=${this.sourate.Numero}`,
          this.getConfig()
        );
        if (
          response.data &&
          response.data.data &&
          response.data.data[0] &&
          response.data.data[0].attributes
        ) {
          // Assurez-vous que la chaîne n'est pas vide avant de convertir en nombres
          const list_verset = response.data.data[0].attributes.list_verset;
          if (list_verset) {
            this.checkedVersets = list_verset
              .split("|")
              .map(Number)
              .filter((n) => n > 0); // Filtrer pour s'assurer qu'aucun '0' n'est ajouté
          } else {
            this.checkedVersets = [];
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des versets cochés:",
          error
        );
      }
    },

    async updateCheckedVersetsInBackend() {
      try {
        // 1. Récupérez l'entrée correspondante de la base de données
        const response = await axios.get(
          `/versets-checks?filters[id_user][$eq]=${this.user.id}&filters[id_sourate][$eq]=${this.sourate.Numero}`,
          this.getConfig()
        );

        // Calcul du pourcentage
        let taux = 0;
        if (this.sourate && this.sourate.versets && this.sourate.versets.data) {
          const totalVersetCount = this.sourate.versets.data.length;
          const checkedVersetCount = this.checkedVersets.length;
          taux = Math.round((checkedVersetCount / totalVersetCount) * 100); // Convertir le taux en pourcentage et arrondir à l'entier le plus proche
        }

        // 2. Structurez votre payload
        const payload = {
          data: {
            id_user: this.user.id.toString(),
            id_sourate: this.sourate.Numero.toString(),
            list_verset: this.checkedVersets.join("|"),
            taux: taux, // Le taux est maintenant un entier
          },
        };

        // console.log("Payload avant envoi:", payload);

        if (!response.data || !response.data.data || !response.data.data[0]) {
          // Si aucune entrée correspondante n'est trouvée, créez une nouvelle entrée
          const createResponse = await axios.post(
            `/versets-checks`,
            payload,
            this.getConfig()
          );
          // console.log(createResponse);
          if (createResponse.status === 200) {
            // console.log("Nouvelle entrée créée avec succès");
          } else {
            // console.error("Erreur lors de la création de la nouvelle entrée:", createResponse.data);
          }
        } else {
          // Si une entrée correspondante est trouvée, mettez-la à jour
          const id = response.data.data[0].id;
          const updateResponse = await axios.put(
            `/versets-checks/${id}`,
            payload,
            this.getConfig()
          );
          // console.log(updateResponse);
          if (updateResponse.status === 200) {
            // console.log("Verset mis à jour avec succès");
          } else {
            // console.error("Erreur lors de la mise à jour:", updateResponse.data);
          }
        }
      } catch (error) {
        // console.error("Erreur lors de la mise à jour des versets cochés:", error);
        if (error.response && error.response.data) {
          // console.error("Détails de l'erreur:", error.response.data);
        }
      }
    },

    isCheckboxDisabled(versetId) {
      const versetIndex = this.sourate.versets.data.findIndex(
        (v) => v.attributes.numero === versetId
      );
      const previousVersetId =
        versetIndex > 0
          ? this.sourate.versets.data[versetIndex - 1].attributes.numero
          : null;
      const nextVersetId =
        versetIndex < this.sourate.versets.data.length - 1
          ? this.sourate.versets.data[versetIndex + 1].attributes.numero
          : null;

      // Désactiver si le verset précédent n'est pas coché
      if (previousVersetId && !this.checkedVersets.includes(previousVersetId)) {
        return true;
      }

      // Désactiver si ce verset est coché et que le verset suivant est également coché
      if (
        this.checkedVersets.includes(versetId) &&
        nextVersetId &&
        this.checkedVersets.includes(nextVersetId)
      ) {
        return true;
      }

      return false;
    },

    async toggleVersetCheck(versetId) {
      // Convertir versetId en nombre pour assurer la cohérence des types
      versetId = Number(versetId);

      // Trouver les index et identifiants des versets précédent et suivant
      const versetIndex = this.sourate.versets.data.findIndex(
        (v) => v.attributes.numero === versetId
      );
      const previousVersetId =
        versetIndex > 0
          ? this.sourate.versets.data[versetIndex - 1].attributes.numero
          : null;
      const nextVersetId =
        versetIndex < this.sourate.versets.data.length - 1
          ? this.sourate.versets.data[versetIndex + 1].attributes.numero
          : null;

      if (this.checkedVersets.includes(versetId)) {
        // Empêcher de décocher si le verset suivant est coché
        if (nextVersetId && this.checkedVersets.includes(nextVersetId)) {
          alert(
            "Vous ne pouvez pas décocher ce verset tant que le suivant est coché."
          );
          return;
        }
        // Supprimer l'identifiant du verset de la liste des versets cochés
        this.checkedVersets = this.checkedVersets.filter(
          (id) => id !== versetId
        );
      } else {
        // Permettre de cocher le premier verset sans condition ou empêcher de cocher si le verset précédent n'est pas coché
        if (
          versetIndex !== 0 &&
          previousVersetId &&
          !this.checkedVersets.includes(previousVersetId)
        ) {
          alert("Vous devez d'abord cocher le verset précédent.");
          return;
        }
        // Ajouter l'identifiant du verset à la liste des versets cochés
        this.checkedVersets.push(versetId);
        // Trier les identifiants des versets pour assurer l'ordre correct
        this.checkedVersets.sort((a, b) => a - b);
      }

      // Mettre à jour les données côté serveur après modification de la liste des versets cochés
      await this.updateCheckedVersetsInBackend();

      // Mise à jour conditionnelle des points de l'utilisateur et des statistiques
      if (this.isUserConnected) {
        const pointsChange = this.checkedVersets.includes(versetId) ? 10 : -10;
        await this.updateUserPoints(pointsChange);

        if (
          this.sourate.versets.data.length === this.checkedVersets.length ||
          !this.checkedVersets.includes(versetId)
        ) {
          await this.updateUserStatSourates(pointsChange > 0 ? 1 : -1);
        }
      }
    },

    async updateUserPoints(pointsChange) {
      try {
        // Récupérez les points actuels de l'utilisateur
        const currentPoints = this.getUserPoints;
        const updatedPoints = Number(currentPoints) + Number(pointsChange);

        // Mise à jour des points dans le store Vuex
        await this.$store.dispatch("user/updateUserPoints", updatedPoints);

        // Optionnellement, mettez à jour l'état de l'utilisateur localement
        this.user.points = updatedPoints;
      } catch (error) {
        // console.error("Erreur lors de la mise à jour des points de l'utilisateur:", error);
      }
    },

    copyText(verset) {
      const textToCopy = `${verset.attributes.arabe}
    
    ${verset.attributes.fr}
    
    ${verset.attributes.tafsir}
                    `;
      const textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      // alert("Texte copié dans le presse-papiers !");
    },

    checkForVersetInUrl() {
      // Vérifiez si l'URL contient un fragment
      const versetId = window.location.hash.substring(1);
      if (versetId) {
        // Utilisez la méthode `scrollToVerset` pour faire défiler jusqu'à l'élément
        this.$nextTick(() => {
          this.scrollToVerset(versetId);
        });
      }
    },
  },

  async mounted() {
    try {
      const id = this.$route.params.id; // Récupérer l'ID de la sourate depuis la route

      // Vérification de la connexion de l'utilisateur
      const token = localStorage.getItem("jwtToken");
      if (token) {
        const userResponse = await this.$axios.get("users/me", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.user = userResponse.data;
      }

      // Récupérer le tableau `versetsSourate` depuis le localStorage ou créer un nouveau tableau s'il n'existe pas
      let versetsSourate =
        JSON.parse(localStorage.getItem("versetsSourate")) || [];

      // Rechercher si la sourate est déjà stockée
      const existingIndex = versetsSourate.findIndex((item) => item.id === id);

      if (existingIndex !== -1) {
        // Si la sourate existe déjà, utilisez ses données
        this.sourate = versetsSourate[existingIndex].data;
      } else {
        // Si aucune donnée n'est présente dans le localStorage pour la sourate sélectionnée, faites la requête pour obtenir les données
        const response = await axios.get(`/sourates/${id}?populate=*`);

        if (
          response.data &&
          response.data.data &&
          response.data.data.attributes
        ) {
          this.sourate = response.data.data.attributes;
          if (response.data.data.versets && response.data.data.versets.data) {
            this.sourate.versets = response.data.data.versets.data; // Accéder aux versets
          }

          // Ajouter la nouvelle sourate au tableau
          versetsSourate.push({ id, data: this.sourate });

          // Si le tableau dépasse la limite de 5, retirez le premier élément
          if (versetsSourate.length > 5) {
            versetsSourate.shift();
          }

          // Stocker le tableau mis à jour dans le localStorage
          localStorage.setItem(
            "versetsSourate",
            JSON.stringify(versetsSourate)
          );
        }
      }

      if (this.isUserConnected) {
        this.fetchCheckedVersets();
      }

      this.checkForVersetInUrl();

      this.audioFilesForCurrentSourate = this.getAudioFilesForSourate(
        this.sourate.Numero
      );
    } catch (error) {
      // console.error("Erreur lors de la récupération de la sourate:", error);
    }

    this.loadMoreVersets();
    this.plusVues();
  },
  created() {
    this.localSourate = { ...this.sourate };
  },

  computed: {
    ...mapGetters("user", ["getUserInfo", "getUserPoints", "getToken"]),
    previousSourate() {
      // Assurez-vous que le numéro de sourate est un nombre et qu'il est supérieur à 1
      if (
        this.sourate &&
        typeof this.sourate.Numero === "number" &&
        this.sourate.Numero > 1
      ) {
        return this.sourate.Numero - 1;
      }
      return null; // Retourne null si nous sommes à la première sourate
    },

    nextSourate() {
      // Assurez-vous que le numéro de sourate est un nombre et qu'il est inférieur à 114 (nombre total de sourates)
      if (
        this.sourate &&
        typeof this.sourate.Numero === "number" &&
        this.sourate.Numero < 114
      ) {
        return this.sourate.Numero + 1;
      }
      return null; // Retourne null si nous sommes à la dernière sourate
    },
    isUserConnected() {
      return this.user && Object.keys(this.user).length > 0;
    },
  },
};
</script>

<style scoped>
.container-s {
  margin: 0 auto;
  width: 100%;
  max-width: 1100px;
  border-radius: 15px;
}

.container-s h1,
.container-s h2 {
  text-align: center;
  font-weight: 700;
}

.container-s h1 {
  font-size: 40px;
}

.container-s h2 {
  margin-top: 0;
}

.info-s .ti-r {
  font-family: "Noto Sans Arabic", sans-serif;
  font-weight: 600;
  margin-bottom: 30px;
}

.container-s h2 {
  font-size: 30px;
}

.contexte {
  background: var(--card);
  padding: 50px 60px;
  border-radius: 15px;
}

.contexte h3 {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 20px;
  font-weight: 600;
}

.contexte #markdown h4,
.contexte #markdown p {
  margin-bottom: 20px;
  font-size: 18px;
}

.contexte #markdown h3 {
  font-size: 20px;
}

.truncated {
  position: relative;
  height: 150px;
  overflow: hidden !important;
}

.truncated::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0; /* Ajoutez ceci */
  right: 0; /* Ajoutez ceci */
  height: 100px;
  background: var(--truncated);
  z-index: 1;
}

.expand-icon {
  cursor: pointer;
  margin-left: 10px;
}

.button-c {
  display: flex;
  justify-content: center;
}

.c-down .open-c,
.c-down .close-c {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px 20px;
  background: var(--body);
  border-radius: 30px;
  font-weight: 600;
}

.basmallah {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: -30px;
  padding: 20px 20px 30px 20px;
  background: var(--card);
  border-radius: 15px 15px 0 0;
}

.basmallah img {
  max-width: 250px;
  width: 100%;
}

.versets {
  margin-top: 20px;
  border-radius: 15px;
  padding: 20px 30px;
  background: var(--card);
}

.versets .top-v {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  height: 40px;
}

.versets .top-v .num-v {
  font-size: 20px;
  font-weight: 700;
}

.versets .top-v .link {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.versets .arabe,
.versets .taj {
  text-align: right;
  font-size: 40px;
  margin-top: 10px;
  word-wrap: break-word;
}

.versets .phonetic,
.versets .french,
.versets .tafsir {
  margin-top: 10px;
  font-size: 18px;
}

/* .versets .phonetic {
  text-align: justify;
}

.versets .french {
  text-align: justify;
} */

.versets .tafsir span {
  background: #ff6370;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.versets .arabe,
.versets .taj {
  font-family: "uthmanic", sans-serif;
  font-size: 35px;
  font-weight: 400;
}

.versets .taj {
  font-size: 35px !important;
  font-family: "uthmanic", sans-serif !important;
  font-weight: 400;
}

.versets .arabe span {
  cursor: pointer;
  font-size: 35px;
  font-weight: 500;
}

.versets .arabe span:hover {
  color: #ff6370;
}

.versets hr {
  margin-top: 20px;
  height: 1px;
  border: none;
  background-color: #000000;
}

.kathir-check {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  height: 40px;
}

.kathir-check .check,
.kathir-check .play {
  display: flex;
  align-items: center;
}

.kathir-check .play ul,
.kathir-check .play li {
  display: flex;
  align-items: center;
}

.kathir-check .play ul {
  gap: 15px;
}

.kathir-check .play li {
  gap: 5px;
  padding: 5px;
  outline: 1px solid var(--card);
  border-radius: 5px;
  transition: 0.2s ease;
}

.kathir-check .play li:hover {
  cursor: pointer;
  outline: 1px solid var(--button);
  border-radius: 5px;
}

.kathir-check .play li img {
  width: 20px;
  height: 20px;
}

.options-v {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  width: 100%;
  min-height: 80px;
  background: var(--options-v-background-color);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
  border-radius: 10px;
}

.precedent,
.next {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 36px;
  height: 36px;
}

.precedent .nN,
.next .nN {
  margin-top: -6px;
}

.options-v .checkbox-wrapper-13 {
  display: flex;
  align-items: center;
  height: auto;
}

.options-v .checkbox-wrapper-13 p {
  line-height: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.options-v .checkbox-wrapper-13 input[type="checkbox"]:checked {
  background: #222222;
  border-color: #222222;
}

.options-v .container-options {
  position: relative;
  width: 100%;
  padding: 10px 20px;
}

.options-v ul,
.options-v li,
.options-v .play-audios ul,
.options-v .play-audios ul li,
.options-v .op-V {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  z-index: 2;
}

.options-v .op-V li,
.text-option li {
  height: 36px;
  z-index: 1;
}

.options-v .op-V .audio {
  cursor: pointer !important;
  outline: 1px solid var(--card);
  transition: 0.2s ease;
}

.options-v .op-V .audio:hover {
  outline: 1px solid var(--button) !important;
}

.options-v ul {
  display: flex;
  justify-content: center;
  gap: 10px;
  transition: 0.2s ease;
}

.text-option ul {
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 5px;
  gap: 7px;
}

.text-option p {
  font-weight: 600;
}

.options-v .play-audios ul {
  gap: 5px;
}

.options-v ul li {
  padding: 8px;
  background: var(--card);
  border-radius: 10px;
}

.options-v img {
  width: 20px;
  height: 20px;
}

.back {
  display: none;
}

.open-options {
  display: none !important;
}

.open-options:hover i {
  color: var(--button);
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@media screen and (max-width: 1440px) {
  .container-s {
    margin: 0 auto;
    padding: 20px 0;
  }

  .options-v .container-options .op-V {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    overflow-y: hidden;
  }

  .options-v .container-options .op-V::-webkit-scrollbar {
    display: none;
  }

  .container-s h1 {
    font-size: 32px;
  }

  .container-s h2 {
    font-size: 24px;
  }

  .contexte h3 {
    font-size: 18px;
  }

  .contexte #markdown h4,
  .contexte #markdown p {
    font-size: 16px;
  }

  .versets .arabe,
  .versets .taj {
    font-size: 24px;
  }

  .versets .phonetic,
  .versets .french,
  .versets .tafsir {
    font-size: 18px;
  }
}

@media screen and (max-width: 900px) {
  .contexte {
    padding: 50px 30px;
  }
}

@media (max-width: 480px) {
  .open-options {
    display: block !important;
  }

  .back {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-top: 20px;
  }

  .back p {
    display: inline-block;
  }

  .contexte {
    padding: 20px 20px 30px 20px !important;
  }

  .container {
    padding: 0 0px;
    background: var(--card);
    margin-top: -40px;
  }

  .versets {
    border-top: 0.5px solid var(--body);
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 20px 20px 0px 20px;
    border-radius: 0;
  }

  .options-v .container-options {
    padding: 10px 0 5vh 0;
  }

  .options-v {
    padding: 0 20px;
  }

  .options-v img {
    width: 15px;
    height: 15px;
  }

  .options-v .op-V {
    gap: 5px;
  }

  .options-v ul {
    justify-content: space-between !important;
    gap: 5px;
  }

  .options-v li {
    gap: 5px;
    flex-grow: 1;
  }

  .options-v li i {
    margin-bottom: -1px;
  }

  .contexte {
    padding: 50px 20px;
  }

  .mb-display {
    display: none !important;
  }
}
</style>
