<template>
  <Head>
    <title>Notification</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="boites">
    <div class="container-app">
      <div class="left">
        <div class="img-bx">
          <img
            src="https://cp.doonun.com/uploads/wedding_bells_388811_bd2ea309de.png"
            alt=""
          />
          <h1>Notification</h1>
        </div>
        <div
          id="markdown"
          class="markdown-body"
          v-html="$md.render(String(notifs.Texte))"
        ></div>
      </div>
      <div class="right">
        <TimePray />
        <ThreeDays />
        <RankingList />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import ThreeDays from "../components/ThreeDays.vue";
import TimePray from "../components/TimePray.vue";
import RankingList from "../components/RankingList.vue";
import { Head } from "@vueuse/head";
export default {
  name: "notification-d",
  components: {
    ThreeDays,
    TimePray,
    RankingList,
    Head,
  },
  data() {
    return {
      notifs: [],
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  methods: {
    async findNotif() {
      if (this.getUserInfo && this.$route.params.slug) {
        try {
          const response = await axios.get("/notifs", {
            params: {
              "filters[url][$eq]": this.$route.params.slug,
            },
            headers: { Authorization: `Bearer ${this.getToken}` },
          });
          this.notifs = response.data.data[0].attributes;
          console.log("this notif : ", response);
        } catch (error) {
          // console.error("Erreur lors de la vérification de notif:", error);
        }
      }
    },
  },
  mounted() {
    this.findNotif();
  },
  watch: {
    $route() {
      this.findNotif();
    },
  },
};
</script>

<style scoped>
.boites {
  max-width: 1400px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.container-app {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 10px;
  margin-bottom: 100px;
}

.container-app .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  flex: 1 1 70%;
  background: var(--card);
  border-radius: 10px;
  padding: 20px;
}

.container-app .right {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  gap: 10px;
  width: 25%;
}

@media (max-width: 1400px) {
  .container-app {
    padding: 0 20px;
  }
}

@media (max-width: 1200px) {
  .container-app .left {
    width: 65%;
  }
  .container-app .right {
    width: 30%;
  }
}

@media (max-width: 992px) {
  .container-app .left,
  .container-app .right {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container-app {
    flex-direction: column;
  }
}

h2 {
  text-align: center;
}

.img-bx {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-bx img {
  width: 40px;
  height: 40px;
}
</style>
