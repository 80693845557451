<template>
    <a :href="tweetLink" target="_blank" rel="noopener noreferrer">
        <div class="share">
            <svg id="vuesax_outline_send-2" data-name="vuesax/outline/send-2" xmlns="http://www.w3.org/2000/svg"
                width="23.782" height="23.782" viewBox="0 0 23.782 23.782">
                <g id="send-2" transform="translate(0)">
                    <path id="Vector"
                        d="M11.733,19.1c-1.169,0-2.824-.822-4.132-4.756L6.887,12.2l-2.14-.713C.822,10.181,0,8.526,0,7.356S.822,4.532,4.747,3.214L13.159.41c2.1-.7,3.855-.5,4.935.575s1.288,2.834.585,4.935l-2.8,8.413C14.557,18.276,12.9,19.1,11.733,19.1ZM5.212,4.631c-2.755.922-3.736,2.012-3.736,2.725s.981,1.8,3.736,2.715l2.5.832a.727.727,0,0,1,.466.466l.832,2.5c.912,2.755,2.012,3.736,2.725,3.736s1.8-.981,2.725-3.736l2.8-8.413c.505-1.526.416-2.775-.228-3.419s-1.893-.723-3.409-.218Z"
                        transform="translate(2.358 2.335)" fill="#292d32" />
                    <path id="Vector-2" data-name="Vector"
                        d="M.741,5.041a.735.735,0,0,1-.525-.218.748.748,0,0,1,0-1.05L3.763.216a.743.743,0,1,1,1.05,1.05L1.266,4.823A.719.719,0,0,1,.741,5.041Z"
                        transform="translate(9.278 9.228)" fill="#292d32" />
                    <path id="Vector-3" data-name="Vector" d="M0,0H23.782V23.782H0Z" fill="none" opacity="0" />
                </g>
            </svg>
        </div>
    </a>
</template>

<script>
export default {
    name: 'Share-d',
    props: ['verset', 'sourate'], // Assurez-vous que ces props sont passés au composant
    computed: {
        tweetLink() {
            if (!this.verset || !this.sourate || !this.verset.attributes || !this.sourate.Phonetique) {
                return null; // Retourne null si les données nécessaires ne sont pas disponibles
            }
            const baseText = `Découvrez le verset ${this.verset.attributes.numero} de la sourate ${this.sourate.Phonetique} sur doonun.`;
            const url = `https://doonun.com/sourate/${this.sourate.Numero}#${this.verset.attributes.numero}`;
            return `https://twitter.com/intent/tweet?text=${encodeURIComponent(baseText)}&url=${encodeURIComponent(url)}`;
        }
    }
}
</script>

<style>
.share svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.share svg path {
    fill: var(--text);
}
</style>