<template>
  <div class="home-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bold_home"
        data-name="vuesax/bold/home"
        transform="translate(-556 -188)"
      >
        <g id="home">
          <path
            id="Vector"
            d="M19,6.01,12.45.77A3.765,3.765,0,0,0,7.9.76L1.35,6.01A3.917,3.917,0,0,0,.04,9.44L1.3,16.98A3.716,3.716,0,0,0,4.87,20h10.6a3.773,3.773,0,0,0,3.58-3.03l1.26-7.54A4.018,4.018,0,0,0,19,6.01ZM10.92,16a.75.75,0,0,1-1.5,0V13a.75.75,0,0,1,1.5,0Z"
            transform="translate(557.83 190)"
            fill="#292d32"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(580 212) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "home-d",
};
</script>

<style>
.home-icon {
  display: flex;
  align-items: center;
  width: 100%;
}

.home-icon svg {
  width: 25px;
  height: 25px;
}

.router-link-exact-active .home-icon svg path {
  fill: var(--button) !important;
}

.home-icon svg path {
  fill: var(--placeholder) !important;
}
</style>
