<template>
  <div class="popup">
    <div class="popup-content">
      <div class="header">
        <h2>Mémorisation</h2>
        <div class="changeTxt">
          <input id="checkboxInput" type="checkbox" v-model="showPhonetic" />
          <label class="toggleSwitch" for="checkboxInput"> </label>
        </div>
      </div>
      <div class="content">
        <div class="close-popup" @click="closePopup">
          <closePopup />
        </div>
        <div class="s1" v-if="step === 1">
          <div
            v-if="
              countStep1 === 0 &&
              attemptedToMoveToNextStep == true &&
              !userWentBack
            "
            class="alert-message unvalid"
            ref="alert1"
          >
            <p>
              Veuillez jouer au moins une fois l'audio avant de passer à l'étape
              suivante !
            </p>
          </div>
          <div
            v-else-if="
              countStep1 === 1 &&
              attemptedToMoveToNextStep == false &&
              !userWentBack
            "
            class="alert-message valid-step"
            ref="alert1"
          >
            <p>Étape validé ✅ ! Veuillez poursuivre vers la prochaine !</p>
          </div>
          <h3>Combien de fois voulez-vous répéter l'audio de ce verset ?</h3>
          <input
            type="number"
            v-model="repetitions"
            min="0"
            max="100"
            @input="limitRepetitions"
          />

          <div class="c-btn">
            <div class="play-btn" @click="toggleAudio">
              <component :is="isAudioPlaying ? 'Pause' : 'Play'" />
              {{ isAudioPlaying ? "Pause" : "Ecouter" }}
            </div>
          </div>
          <p class="arabe arabic-s1" v-if="!showPhonetic">{{ arabic }}</p>
          <p class="phonetic phonetic-s1" v-else>{{ phonetic }}</p>
        </div>
        <div v-if="step === 2">
          <div
            v-if="
              countStep2 === 0 &&
              attemptedToMoveToNextStep == true &&
              !userWentBack
            "
            class="alert-message unvalid"
            ref="alert2"
          >
            <p>
              Veuillez cliquez sur l'audio d'un mot avant de passer à l'étape
              suivante !
            </p>
          </div>
          <div
            v-else-if="
              countStep2 === 1 &&
              attemptedToMoveToNextStep == false &&
              !userWentBack
            "
            class="alert-message valid-step"
            ref="alert2"
          >
            <p>Étape validé ✅ ! Veuillez poursuivre vers la prochaine !</p>
          </div>
          <h3>Cliquez sur un mot pour écouter sa prononciation.</h3>
          <p class="arabe arabic as2 cc-s" v-if="!showPhonetic && arabic">
            <span
              class="w"
              v-for="(word, index) in arabic.split(' ')"
              :key="index"
              @click="playWordAudio(index + 1)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <p class="phonetic ps2 cc-s" v-else-if="showPhonetic && phonetic">
            <span
              class="w"
              v-for="(word, index) in phonetic.split(' ')"
              :key="index"
              @click="playWordAudio(index + 1)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <span v-if="showPhonetic && phonetic"
            >Pour la phonétique, il se peut que certains mots ne soient pas
            reliés à des audios à cause de la découpe de la phrase, veuillez les
            signaler dans la page contact.</span
          >
        </div>
        <div v-if="step === 3">
          <div
            v-if="
              countStep3 === 0 &&
              attemptedToMoveToNextStep == true &&
              !userWentBack
            "
            class="alert-message unvalid"
            ref="alert3"
          >
            <p>
              Veuillez afficher au moins une fois les mots avant de passer à
              l'étape suivante !
            </p>
          </div>
          <div
            v-else-if="
              countStep3 === 1 &&
              attemptedToMoveToNextStep == false &&
              !userWentBack
            "
            class="alert-message valid-step"
            ref="alert3"
          >
            <p>Étape validé ✅ ! Veuillez poursuivre vers la prochaine !</p>
          </div>
          <h3>Récitez et complétez les mots manquants du verset !</h3>
          <p class="arabe arabic as2 st-c" v-if="!showPhonetic && arabic">
            <span
              v-for="(word, index) in arabic.split(' ')"
              :key="index"
              :class="assignRandomClass(index)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <p class="phonetic ps2 st-c" v-else-if="showPhonetic && phonetic">
            <span
              v-for="(word, index) in phonetic.split(' ')"
              :key="index"
              :class="assignRandomClass(index)"
            >
              <span>{{ word }}&nbsp;</span>
            </span>
          </p>
          <div class="c-btn">
            <div class="play-btn btn-p3" @click="changeClassOnClick">
              <openEye />
              Dévoiler les mots
            </div>
          </div>
          <p class="decount">{{ countdown }}</p>
        </div>

        <div v-if="step === 4">
          <div
            v-if="
              countStep4 === 0 &&
              attemptedToMoveToNextStep == true &&
              !userWentBack
            "
            class="alert-message unvalid"
            ref="alert4"
          >
            <p>
              Pour valider cette étape, il faut mettre le verset dans le bon
              ordre !
            </p>
          </div>
          <div
            v-else-if="
              countStep4 === 1 &&
              attemptedToMoveToNextStep == false &&
              !userWentBack
            "
            class="alert-message valid-step"
            ref="alert4"
          >
            <p>Étape validé ✅ ! Veuillez poursuivre vers la prochaine !</p>
          </div>

          <h3>
            Cliquez sur les mots pour remettre le verset dans le bon ordre.
          </h3>
          <p class="order-verse-a" v-if="!showPhonetic">
            <span
              v-for="(word, index) in orderedArabicWords"
              :key="'ordered-ar-' + index"
              :class="{
                'word-wrong': incorrectWords.has(index),
              }"
              >{{ word }}
            </span>
          </p>
          <p class="order-verse-p" v-else>
            <span
              v-for="(word, index) in orderedPhoneticWords"
              :key="'ordered-ph-' + index"
              :class="{ 'word-wrong': incorrectWords.has(index) }"
              >{{ word }}&nbsp;</span
            >
          </p>
          <p v-if="!showPhonetic" class="arabic as2 ap3">
            <span
              class="touch-word"
              v-for="wordObj in shuffledWords"
              :key="'shuffled-ar-' + wordObj.id"
              @click="moveWordToOrder(wordObj.id)"
            >
              <span>{{ wordObj.word }}&nbsp;</span>
            </span>
          </p>
          <p v-else class="phonetic ps2 pp3">
            <span
              class="touch-word"
              v-for="wordObj in shuffledWords"
              :key="'shuffled-ph-' + wordObj.id"
              @click="moveWordToOrder(wordObj.id)"
            >
              <span>{{ wordObj.word }}&nbsp;</span>
            </span>
          </p>
          <div class="alert-order-v">
            <span v-if="isOrderCorrect === true"
              >Bravo 😄, vous avez réussi à mettre le verset dans le bon ordre
              !</span
            >
            <span v-else-if="isOrderCorrect === false"
              >Oops 😔 ! L'ordre du verset n'est pas bon !</span
            >
          </div>
        </div>
        <div v-if="step === 5">
          <h3>
            🎊 Félicitation ! Vous avez réussi toutes les étapes de mémorisation
            de ce verset !
          </h3>
          <div class="animation-s-svg">
            <CheckAnimation />
          </div>
          <div class="btn-finalStep">
            <span @click="closePopup">Continuer la lecture </span>
          </div>
        </div>
      </div>
      <div class="footer">
        <ul>
          <li>
            <div class="btn" @click="previousStep" v-if="step > 1">
              <Precedent />
            </div>
          </li>
          <li class="prog">
            <div class="progression">
              <div class="prg" :style="{ width: progressBarWidth }"></div>
            </div>
            <span>{{ progressBarWidth }}</span>
          </li>
          <li>
            <div class="btn" @click="nextStep" v-if="step < 5">
              <Suivant />
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import closePopup from "./icons/ClosePopup.vue";
import Suivant from "./icons/Suivant.vue";
import Precedent from "./icons/Precedent.vue";
import Play from "./icons/Play.vue";
import Pause from "./icons/Pause.vue";
import openEye from "./icons/openEye.vue";
import CheckAnimation from "./icons/CheckAnimation.vue";
import correctSoundPath from "../assets/audios/quiz/correct.wav";
import incorrectSoundPath from "../assets/audios/quiz/error.mp3";

export default {
  name: "MemoSourate",
  props: {
    show: Boolean,
    phonetic: String,
    arabic: String,
    audio: String,
    numero: String,
  },
  components: {
    closePopup,
    Suivant,
    Precedent,
    Play,
    Pause,
    openEye,
    CheckAnimation,
  },
  data() {
    return {
      step: 1,
      repetitions: 1,
      isAudioPlaying: false,
      audioElement: null,
      audioPlayCount: 0,
      showPhonetic: false,
      shuffledWords: [],
      orderedArabicWords: [],
      orderedPhoneticWords: [],
      isOrderCorrect: null,
      countdown: 10,
      incorrectWords: new Set(),
      audioFiles: [],
      alertMessage: null,
      attemptedToMoveToNextStep: false,
      countStep1: 0,
      countStep2: 0,
      countStep3: 0,
      countStep4: 0,
      currentAudioElement: null,
      userWentBack: false,
      validatedSteps: [],
    };
  },

  methods: {
    toggleAudio() {
      if (this.isAudioPlaying) {
        this.pauseAudio();
      } else {
        this.playAudio();
      }
    },

    limitRepetitions() {
      if (this.repetitions > 100) {
        this.repetitions = 100;
      }
    },

    playAudio() {
      if (this.repetitions === 0) {
        // Ne lance pas l'audio si repetitions est égal à 0
        return;
      }

      if (!this.isAudioPlaying) {
        this.audioPlayCount = this.repetitions;
      }

      if (!this.audioElement && this.audio) {
        this.audioElement = new Audio(this.audio);

        this.audioElement.addEventListener("ended", () => {
          if (this.audioPlayCount > 1) {
            this.audioPlayCount--;
            this.repetitions--;
            this.audioElement.play();
          } else {
            this.isAudioPlaying = false;

            this.audioPlayCount--;
            this.repetitions--;

            if (this.audioPlayCount < 0) {
              this.audioPlayCount = 0;
            }
          }
          this.countStep1++;
          this.checkAndSetAttemptedToMoveToNextStep();
        });
      }

      if (this.audioElement && !this.isAudioPlaying) {
        this.isAudioPlaying = true;
        this.audioElement.play();
      }
    },

    pauseAudio() {
      if (this.audioElement && this.isAudioPlaying) {
        this.isAudioPlaying = false;
        this.audioElement.pause();
      }
    },

    formatVerseNumber(numero) {
      // Assurez-vous que le numéro a au moins trois chiffres en ajoutant des zéros
      const formattedNumero = numero.toString().padStart(3, "0");
      return formattedNumero;
    },

    playWordAudio(index) {
      // Vérifiez s'il y a un audio en cours de lecture
      if (this.audioElement && !this.audioElement.paused) {
        // Arrêtez la lecture de l'audio précédent
        this.audioElement.pause();
      }

      // Obtenez le numéro de la sourate et le numéro du verset
      const surahId = this.$route.params.id;
      const verseNumber = this.formatVerseNumber(this.numero);
      const numberSurah = this.formatVerseNumber(surahId);
      const indexV = this.formatVerseNumber(index);

      const audioPath = `https://audio.doonun.com/mots/${String(
        surahId
      )}/${String(verseNumber)}/${String(numberSurah)}_${String(
        verseNumber
      )}_${String(indexV)}.mp3`;

      // Utilisez la référence à l'élément audio existant ou créez-en un nouveau
      if (!this.audioElement || this.audioElement.src !== audioPath) {
        this.audioElement = new Audio(audioPath);
        this.audioElement.addEventListener("error", () => {
          console.error("Erreur de chargement du fichier audio :", audioPath);
        });
      }

      // Jouez le nouvel audio
      this.audioElement.play();

      this.countStep2++;
      this.checkAndSetAttemptedToMoveToNextStep();
    },

    checkAndSetAttemptedToMoveToNextStep() {
      if (
        this.countStep1 >= 1 ||
        this.countStep2 >= 1 ||
        this.countStep3 >= 1 ||
        this.countStep4 >= 1
      ) {
        this.attemptedToMoveToNextStep = false;

        setTimeout(() => {
          this.attemptedToMoveToNextStep = true;
        }, 2000);
      }
    },

    nextStep() {
      if (this.step < 5) {
        if (
          (this.step === 1 && this.countStep1 >= 1) ||
          (this.step === 2 && this.countStep2 >= 1) ||
          (this.step === 3 && this.countStep3 >= 1) ||
          (this.step === 4 && this.countStep4 >= 1)
        ) {
          this.step++;
          this.attemptedToMoveToNextStep = false;
        } else {
          // Définissez attemptedToMoveToNextStep sur true
          this.attemptedToMoveToNextStep = true;
          // Masquez l'alerte d'erreur après 2 secondes
          setTimeout(() => {
            this.attemptedToMoveToNextStep = false;
          }, 2000); // 2000 millisecondes (2 secondes)
        }
      }

      this.userWentBack = false;
    },

    previousStep() {
      if (this.step > 1) {
        this.step--;
      }
      this.userWentBack = true;
    },
    closePopup() {
      this.$emit("update:show", false);
      this.step = 1;
    },
    assignRandomClass(index) {
      return index % 2 === 0 ? "msqtxt" : "";
    },

    changeClassOnClick() {
      const spans = document.querySelectorAll(".msqtxt");

      spans.forEach((span) => {
        span.classList.remove("msqtxt");
        span.classList.add("msqtxtVisible");
      });

      let countdown = 10;

      const timer = setInterval(() => {
        // Mettre à jour la valeur de countdown et afficher
        countdown--;
        this.countdown = countdown;

        if (countdown === 0) {
          clearInterval(timer); // Arrêter la temporisation lorsque le décompte atteint 0
          spans.forEach((span) => {
            span.classList.remove("msqtxtVisible");
            span.classList.add("msqtxt");
          });
          this.countdown = 10;
          this.countStep3++;
          this.checkAndSetAttemptedToMoveToNextStep();
        }
      }, 1000); // 1000 millisecondes (1 seconde)
    },

    shuffleWords() {
      let words;
      if (this.showPhonetic) {
        words = this.phonetic.split(/\s+/).filter((word) => word.trim() !== "");
      } else {
        words = this.arabic.split(/\s+/).filter((word) => word.trim() !== "");
      }
      words = words.map((word, index) => ({ id: index + Math.random(), word })); // Ajouter un identifiant unique
      for (let i = words.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [words[i], words[j]] = [words[j], words[i]];
      }
      this.shuffledWords = words;
      this.remainingWordsToMove = words.length;
    },

    moveWordToOrder(wordId) {
      const wordObj = this.shuffledWords.find((w) => w.id === wordId);
      if (!wordObj) return; // S'assurer que l'objet existe

      if (this.showPhonetic) {
        this.orderedPhoneticWords.push(wordObj.word);
      } else {
        this.orderedArabicWords.push(wordObj.word);
      }
      this.shuffledWords = this.shuffledWords.filter((w) => w.id !== wordId);
      this.remainingWordsToMove--;

      if (this.shuffledWords.length <= 0) {
        this.remainingWordsToMove = 0;
        this.checkOrder();
      }
    },

    resetOrder() {
      this.orderedArabicWords = [];
      this.orderedPhoneticWords = [];
      this.isOrderCorrect = null;

      // Réinitialiser les mots incorrects
      this.incorrectWords.clear();

      // Réinitialiser l'état visuel des mots
      this.resetWordsVisualState();

      // Mélanger à nouveau les mots
      this.shuffleWords();
    },

    resetWordsVisualState() {
      const wordElements = document.querySelectorAll(".word-wrong");
      wordElements.forEach((element) => {
        element.classList.remove("word-wrong");
      });
    },

    playSound(audioPath) {
      const audio = new Audio(audioPath);
      audio
        .play()
        .catch((error) => console.error("Error playing the sound:", error));
    },

    checkOrder() {
      let originalWords = this.showPhonetic
        ? this.phonetic.split(" ")
        : this.arabic.split(" ");
      let orderedWords = this.showPhonetic
        ? this.orderedPhoneticWords
        : this.orderedArabicWords;

      this.incorrectWords.clear();
      this.isOrderCorrect = true;

      orderedWords.forEach((word, index) => {
        if (word !== originalWords[index]) {
          this.incorrectWords.add(index);
          this.isOrderCorrect = false;
        }
      });

      if (this.isOrderCorrect) {
        // Jouer le son correct
        this.playSound(correctSoundPath);
        this.countStep4++;
        this.checkAndSetAttemptedToMoveToNextStep();
      } else {
        // Jouer le son d'erreur
        this.playSound(incorrectSoundPath);
        setTimeout(() => {
          this.resetOrder();
        }, 2000); // Attendre 2 secondes avant de réinitialiser
      }
    },

    resetState() {
      // Réinitialiser l'audio
      this.pauseAudio();
      this.isAudioPlaying = false;
      this.audioPlayCount = 0;
      this.countdown = 10;

      if (this.step === 4) {
        this.shuffleWords();
        this.orderedArabicWords = [];
        this.orderedPhoneticWords = [];
        this.isOrderCorrect = null;
        this.incorrectWords.clear();
      }
    },
  },
  beforeUnmount() {
    if (this.audioElement) {
      this.audioElement.pause();
      this.audioElement.src = "";
      this.audioElement = null;
    }
  },
  computed: {
    progressBarWidth() {
      switch (this.step) {
        case 1:
          return "0%";
        case 2:
          return "25%";
        case 3:
          return "50%";
        case 4:
          return "75%";
        case 5:
          return "100%";
        default:
          return "0%";
      }
    },
  },
  watch: {
    step(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.resetState();
      }
    },
    showPhonetic() {
      this.resetState();
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--card);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  /* position: relative; */
  background-color: var(--card);
  width: 100%;
  max-width: 1200px;
  max-height: 100%;
  min-height: 90%;
  display: flex;
  flex-direction: column;
  border-radius: var(--br10);
}

.header,
.footer {
  background-color: var(--card);
  padding: 10px 20px;
  text-align: center;
  border-radius: var(--br10);
}

.changeTxt {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#checkboxInput {
  display: none;
}

.toggleSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 17px;
  background-color: #e5e7eb;
  border-radius: 20px;
  cursor: pointer;
  transition-duration: 0.2s;
}

.toggleSwitch::after {
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0px;
  background-color: #c7c9cd;
  border-radius: 50%;
  transition-duration: 0.2s;
  border: 2px solid #c7c9cd;
}

#checkboxInput:checked + .toggleSwitch::after {
  transform: translateX(100%);
  transition-duration: 0.2s;
  background: #e5e7eb;
  border: 2px solid #e5e7eb;
}

#checkboxInput:checked + .toggleSwitch {
  background-color: #c7c9cd;
  transition-duration: 0.2s;
}

.close-popup {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  margin-top: 20px;
}

.content {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  overflow-y: auto;
  padding: 10px 30px;
  text-align: center;
  flex-grow: 1;
}

.content::-webkit-scrollbar {
  display: none;
}

.content::-webkit-scrollbar {
  display: none;
}

.content {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer et Edge */
}

.content {
  overflow: -moz-scrollbars-none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer et Edge */
}

.content {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer et Edge */
  overflow: -moz-scrollbars-none; /* Firefox */
}

.s1 input {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 150px;
  height: 150px;
  background: var(--button);
  color: #fff;
  border-radius: 100px;
  text-align: center;
  font-size: 3rem;
  font-weight: 700;
}

.s1 input::-webkit-outer-spin-button,
.s1 input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.c-btn {
  display: flex;
  justify-content: center;
}

.play-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: var(--body);
  border-radius: 50px;
  padding: 8px 15px;
  font-weight: 500;
  transition: 0.2s;
}

.play-btn:hover {
  padding: 8px 20px;
  outline: 1px solid var(--button);
}

.arabe {
  font-family: "Uthmanic", Courier, monospace;
}

.phonetic {
  font-size: 1.2rem;
}

.arabic-s1 {
  margin-top: 30px;
  font-size: 1.7rem;
}

.phonetic-s1 {
  font-size: 1.2rem;
  margin-top: 30px;
}

.as2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 2px;
  max-width: 800px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 1.7rem;
  font-family: "Uthmanic", Courier, monospace;
}

.st-c {
  user-select: none;
}

.cc-s .w {
  padding: 3px 5px;
  background: var(--body);
  border-radius: 10px;
}

.cc-s .w:hover {
  background: var(--button);
  color: #fff;
  cursor: pointer;
}

.ps2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2px;
  max-width: 800px;
  margin-top: 30px;
  user-select: none;
  margin-bottom: 20px;
}

.msqtxt {
  background: var(--body);
  padding: 5px;
  border-radius: 10px;
  color: var(--body);
}

.msqtxtVisible {
  color: var(--button);
  padding: 5px;
  border-radius: 10px;
  background-color: var(--body);
}

.btn-p3 {
  margin-top: 30px;
}

.decount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  font-size: 1.5rem;
  border-radius: 60px;
  font-weight: 700;
  width: 100px;
  height: 100px;
  background: var(--body);
}

.order-verse-a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 2px;
  max-width: 800px;
  font-size: 1.7rem;
  font-family: "Uthmanic", Courier, monospace;
  margin-top: 30px;
  margin-bottom: 30px;
}

.order-verse-p {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 1.2rem;
}

.touch-word {
  background: var(--body);
  border-radius: 10px;
  cursor: pointer;
}

.touch-word:hover {
  background: var(--button);
  color: #fff;
}

.word-wrong {
  color: red;
}

.ap3 span,
.pp3 span {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--body); */
  padding: 3px;
  /* border-radius: 10px; */
}

/* .ap3 span:hover,
.pp3 span:hover {
    background-color: var(--button);
    color: #fff;
} */

.footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.footer li {
  display: flex;
  align-items: center;
}

.btn {
  padding: 10px;
  background-color: var(--body);
  border-radius: 5px;
  cursor: pointer;
}

.prog {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
}

.prog span {
  font-weight: 600;
}

.progression {
  background-color: var(--body);
  border-radius: 5px;
  width: 50%;
  height: 10px;
}

.prg {
  background-color: var(--button);
  box-shadow: rgba(0, 0, 255, 0.311) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  height: 100%;
  border-radius: 5px;
  transition: 0.2s;
}

.animation-s-svg {
  margin: 30px 0;
}

.btn-finalStep {
  display: flex;
  justify-content: center;
}

.btn-finalStep span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: var(--body);
  border-radius: 50px;
  padding: 10px 30px;
  width: auto;
  transition: 0.2s;
  font-weight: 600;
  cursor: pointer;
}

.btn-finalStep span:hover {
  outline: 1px solid var(--button);
}

.alert-message {
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  text-align: center;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--card);
}

.alert-message p {
  font-weight: 600;
}

.unvalid {
  background: #fef2f3;
  color: #944955;
}

.valid-step {
  background: #f2fbf6;
  color: #485e52;
}

@media (max-width: 480px) {
  .content {
    padding: 10px 20px;
  }
}
</style>
