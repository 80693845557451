<template>
  <div>
    <Tabs />
    <Head>
      <router-link to="/hadith">Hadithhadith</router-link>
      <title>Horaires de prières - Ville - Doonun</title>
      <meta
        name="description"
        :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
      />
      <meta property="og:title" :content="`Horaires de prières - Ville`" />
      <meta property="og:url" :content="`https://www.doonun.com/priere`" />
      <meta
        property="og:description"
        :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://cp.doonun.com/uploads/2148590247_f23daa8ddc.jpg"
      />
      <meta name="robots" content="index, follow" />

      <!-- Twitter -->
      <meta
        name="twitter:title"
        :content="`Horaires de prières - Ville - Doonun`"
      />
      <meta
        name="twitter:description"
        :content="`Retrouvez facilement les horaires de prières de votre ville sur Doonun.`"
      />
      <meta
        name="twitter:image"
        content="https://cp.doonun.com/uploads/2148590247_f23daa8ddc.jpg"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@doonunfr" />
      <meta name="twitter:creator" content="@doonunfr" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
    <div class="container">
      <div class="prayers-hours">
        <div class="container-box-pray">
          <div class="left">
            <div class="top">
              <p class="change" @click.self="openPopup">
                <span @click.self="openPopup">
                  <i v-if="selectedCity" class="fa-solid fa-location-dot"></i>
                  <i v-else class="fa-solid fa-plus"></i>
                </span>
                <span @click.self="openPopup" class="city-change">
                  {{ selectedCity || "Ajouter votre Ville" }}
                </span>
                <br />
                <span>{{ new Date().toLocaleDateString() }}</span>
              </p>
              <img :src="getCurrentPrayerImage()" alt="" />
            </div>
            <div class="bottom">
              <p class="actual-pray">
                <span class="angle-pray">{{ angle }}° - Prochaine prière</span>
                <br />
                <span class="prayname">{{ getCurrentPrayer() }}</span>
                <span class="at"> à </span>
                <span class="hourpray" :style="{ color: countdownBgColor }">{{
                  getCurrentPrayerTime()
                }}</span>
              </p>
              <p class="countdown" :style="{ color: countdownBgColor }">
                -{{ countdown }}
              </p>
            </div>
          </div>
          <div class="right">
            <div class="item" v-for="prayer in prayers" :key="prayer.salat">
              <p class="salat">{{ prayer.salat }}</p>
              <p class="hour">{{ prayer.heure }}</p>
            </div>
          </div>
        </div>
        <h1>{{ getCurrentMonth() }} - {{ getHijriDate() }}</h1>
        <div class="navigation-hijri">
          <div class="left-date" @click="decrementMonths">
            <i class="fa-solid fa-chevron-left"></i>
          </div>
          <div class="right-date" @click="incrementMonths">
            <i class="fa-solid fa-chevron-right"></i>
          </div>
        </div>
        <div class="hours-month">
          <div class="box-month-pr">
            <div
              class="item-m"
              @click="showPopupInfo(day)"
              :class="{
                'ramadan-background': isRamadan(day),
                'shawwal-background': isShawwal(day),
                'white-day-background': isWhiteDay(day),
                'fasting-muharam': isFastingMouharram(day),
                'dhul-hijah': isDhulHija(day),
                'eid-adah': isEidAlAdha(day),
                'is-day': isToday(index, currentMonthIndex, currentYear),
                isSacredMonth: isSacredMonth(day),
                isFinish: isFinish(index, currentMonthIndex, currentYear),
              }"
              v-for="(day, index) in prayersMoth"
              :key="day.date"
            >
              <p class="number">
                {{ getDayOfWeek(index, currentMonthIndex, currentYear) }}.
                {{ formatNumber(index + 1) }}
              </p>
              <p class="month-hijri">{{ day.number }} - {{ day.month }}</p>
              <p class="h">
                <span class="f">F. {{ day.Fajr }}</span>
                <span class="s">S. {{ day.Shuruq }}</span>
                <span class="d">D. {{ day.Dhuhr }}</span>
                <span class="a">A. {{ day.Asr }}</span>
                <span class="m">M. {{ day.Maghrib }}</span>
                <span class="i">I. {{ day.Isha }}</span>
              </p>
              <img
                :class="{
                  'white-day-background': isWhiteDay(day) && !isDhulHija(day),
                }"
                src="https://cp.doonun.com/uploads/white_flag_1805888_baefe51658.png"
                alt=""
              />
              <img
                :class="{
                  sacredMonth:
                    !isWhiteDay(day) &&
                    !isFastingMouharram(day) &&
                    !isWeekDay(index, currentMonthIndex, currentYear) &&
                    isSacredMonth(day),
                }"
                src="https://cp.doonun.com/uploads/bless_3c8d0d3a01.png"
                alt=""
              />
              <img
                :class="{
                  isWeekDay: isWhiteDay(day)
                    ? false
                    : isWeekDay(index, currentMonthIndex, currentYear),
                }"
                src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
                alt=""
              />

              <img
                :class="{
                  'eid-adah': isEidAlAdha(day),
                }"
                src="https://cp.doonun.com/uploads/garland_3511257_66b1b5142f.png"
                alt=""
              />
              <img
                :class="{
                  'shawwal-background': isShawwal(day),
                }"
                src="https://cp.doonun.com/uploads/garland_3511257_66b1b5142f.png"
                alt=""
              />
              <img
                :class="{ mouharam: isFastingMouharram(day) }"
                src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
                alt=""
              />
              <img
                :class="{ 'ramadan-background': isRamadan(day) }"
                src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
                alt=""
              />
              <img
                :class="{
                  'is-dhul-hijah': isEidAlAdha(day) ? false : isDhulHija(day),
                }"
                src="https://cp.doonun.com/uploads/route_10741176_eefc662f1e.png"
                alt=""
              />
            </div>
          </div>

          <div class="before-close-finish">
            <i
              v-if="isFinishVisible"
              class="fa-regular fa-eye"
              @click="toggleFinish"
            ></i>
            <i v-else class="fa-regular fa-eye-slash" @click="toggleFinish"></i>
          </div>
        </div>
        <div class="popup" v-if="showPopup" @click.self="closePopup">
          <div class="popup-content">
            <h2>Changer de Ville</h2>
            <div class="list-search-country">
              <div class="input-country-city">
                <input
                  type="text"
                  placeholder="Rechercher un pays"
                  v-model="searchCountryQuery"
                />
                <input
                  type="text"
                  placeholder="Rechercher une ville"
                  v-model="searchCityQuery"
                />
              </div>
              <div class="results-city-c">
                <ul class="country-lists">
                  <li
                    v-for="country in filteredCountries"
                    :key="country.value"
                    @click="
                      selectedCountry = country;
                      clearCities();
                      fetchCities();
                    "
                  >
                    <i class="fa-solid fa-earth-africa"></i> {{ country }}
                  </li>
                </ul>
                <loadCircle v-if="loading" class="loader" />
                <div v-else>
                  <ul>
                    <li
                      v-for="city in filteredCities"
                      :key="city.value"
                      @click="
                        selectedCity = city.text;
                        closePopup();
                      "
                    >
                      <i class="fa-solid fa-location-dot"></i> {{ city.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="showPopupInfoBox"
      class="popup-backdrop"
      @click="showPopupInfoBox = false"
    >
      <PopupInfoBox
        :selectedDay="selectedDay"
        @close="showPopupInfoBox = false"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loadCircle from "../components/loadCircle.vue";
import Tabs from "../components/Tabs.vue";
import { Head } from "@vueuse/head";
import PopupInfoBox from "../components/PopupInfoBox.vue";

export default {
  name: "horaires-d",
  components: {
    loadCircle,
    Tabs,
    Head,
    PopupInfoBox,
  },
  data() {
    return {
      prayers: [],
      prayersMoth: [],
      showPopup: false,
      selectedCountry: "France",
      selectedCity: "",
      countries: [],
      cities: [],
      allCountries: [],
      countriesData: {},
      angle: "18",
      countdown: "",
      countdownBgColor: "#D3F2D7",
      images: {
        Fajr: "https://cp.doonun.com/uploads/cloudy_1146808_24384f3f5b.png",
        Dhuhr: "https://cp.doonun.com/uploads/sun_2698240_23269981eb.png",
        Asr: "https://cp.doonun.com/uploads/clouds_8324591_491ed4fcda.png",
        Maghrib: "https://cp.doonun.com/uploads/dawn_1146828_d7d8b3e5dc.png",
        Isha: "https://cp.doonun.com/uploads/cloudy_night_9755348_802ce5fb24.png",
      },
      searchCountryQuery: "",
      searchCityQuery: "",
      loading: false,
      filteredCitiesData: [],
      cityFilterTimeout: null,
      cityIndex: {},
      selectedDay: null,
      showPopupInfoBox: false,
      currentMonthIndex: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      changeMonthG: 0,
      changeMonthH: 1,
      changeMonthHi: 0,
      currentHijriYear: null,
      isFinishVisible: true,
    };
  },
  watch: {
    selectedCountry(newValue) {
      this.searchCountryQuery = newValue;
      this.saveSelectedData();
    },
    selectedCity(newValue) {
      this.searchCityQuery = newValue;
      this.saveSelectedData();
      this.fetchPrayerTimes();
      this.fetchPrayerMonth();
    },

    searchCityQuery(newValue) {
      const searchQuery = newValue
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .trim();

      // Annuler le timeout précédent avant d'en créer un nouveau
      clearTimeout(this.cityFilterTimeout);

      const delay = searchQuery.length < 3 ? 150 : 300; // Ajuste le délai en fonction de la longueur de l'input

      // Réinitialiser this.filteredCitiesData à une liste vide avant de démarrer le nouveau timeout
      this.filteredCitiesData = [];

      this.cityFilterTimeout = setTimeout(() => {
        const firstChar = searchQuery.charAt(0);
        const possibleMatches = this.cityIndex[firstChar] || [];

        this.filteredCitiesData = possibleMatches.filter((city) => {
          const cityName = city.text.toLowerCase(); // `normalize` et `toLowerCase` ont déjà été appliqués lors de l'initialisation
          return (
            cityName.startsWith(searchQuery) || cityName.includes(searchQuery)
          );
        });
      }, delay);
    },
  },

  created() {
    this.fetchCountries();
    this.loadSelectedData();
    this.searchCountryQuery = this.selectedCountry;

    // Créez l'index des villes en fonction de leur première lettre
    this.cityIndex = {};

    if (!this.selectedCity) {
      this.fetchPrayerTimes();
      this.fetchPrayerMonth();
    }

    setInterval(this.updateCountdown, 1000);
  },

  computed: {
    filteredCountries() {
      return this.allCountries.filter((country) =>
        country.toLowerCase().includes(this.searchCountryQuery.toLowerCase())
      );
    },
    filteredCities() {
      return this.filteredCitiesData;
    },
  },

  methods: {
    clearCities() {
      this.cities = [];
      this.filteredCitiesData = [];
      this.selectedCity = "";
    },
    loadSelectedData() {
      const selectedCountry = localStorage.getItem("selectedCountry");
      const selectedCity = localStorage.getItem("selectedCity");

      if (selectedCountry && selectedCity) {
        this.selectedCountry = selectedCountry;
        this.selectedCity = selectedCity;
        this.fetchCities();
      }
    },
    async fetchCountries() {
      try {
        const response = await axios.get("https://cp.doonun.com/api/pays/1");
        if (response.status === 200) {
          this.allCountries = Object.keys(response.data.data.attributes.Pays);
          this.countriesData = response.data.data.attributes.Pays; // Stockez les données du pays ici
          this.countries = this.allCountries.map((country) => ({
            value: country,
            text: country,
          }));
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la liste des pays :",
          error
        );
      }
    },

    async fetchCities() {
      try {
        this.loading = true;
        const countryData = this.countriesData[this.selectedCountry];
        if (countryData) {
          const cities = countryData.map((city) => ({
            value: city,
            text: city,
          }));

          // Créez l'index des villes en fonction de leur première lettre
          this.cityIndex = {};
          cities.forEach((city) => {
            const firstChar = city.text.charAt(0).toLowerCase();
            if (!this.cityIndex[firstChar]) {
              this.cityIndex[firstChar] = [];
            }
            this.cityIndex[firstChar].push(city);
          });

          if (cities.length > 10) {
            this.cities = [];
            await new Promise((resolve) => setTimeout(resolve, 1000));
            this.cities = cities;
          } else {
            this.cities = cities;
          }
        }
        this.loading = false;
        this.filteredCitiesData = this.cities;
        // Sélectionnez la ville correctement lors du chargement des données
        if (this.selectedCity) {
          const cityIndex = this.cities.findIndex(
            (city) => city.text === this.selectedCity
          );
          if (cityIndex !== -1) {
            this.cities[cityIndex].selected = true;
          }
        }

        // Une fois que les villes sont chargées, vous pouvez appeler fetchPrayerTimes() si une ville est déjà sélectionnée
        if (this.selectedCity) {
          this.fetchPrayerTimes();
          this.fetchPrayerMonth();
        }
      } catch (error) {
        this.loading = false;
        console.error(
          "Erreur lors de la récupération de la liste des villes pour le pays sélectionné :",
          error
        );
      }
    },

    saveSelectedData() {
      localStorage.setItem("selectedCountry", this.selectedCountry);
      localStorage.setItem("selectedCity", this.selectedCity);
    },

    async fetchPrayerTimes() {
      try {
        const today = new Date().toISOString().slice(0, 10);
        const address = this.selectedCity ? this.selectedCity : "Paris";
        const response = await axios.get(
          "https://api.aladhan.com/v1/timingsByAddress/" + today,
          {
            params: {
              address: address + "," + this.selectedCountry, // Utilisation de la ville actuelle sélectionnée
              method: 3,
            },
          }
        );
        if (response.status === 200) {
          const timings = response.data.data.timings;
          // Création d'un objet de mappage pour renommer certaines prières
          const prayerRenameMap = {
            Sunrise: "Shuruq",
            Firstthird: "1/3 de la nuit",
            Lastthird: "3/3 de la nuit",
          };
          // Filtrez pour ne garder que les prières qui nous intéressent
          const filteredTimings = {};
          const prayersOfInterest = [
            "Fajr",
            "Sunrise",
            "Dhuhr",
            "Asr",
            "Maghrib",
            "Isha",
            "Firstthird",
            "Lastthird",
          ];
          prayersOfInterest.forEach((prayer) => {
            if (timings[prayer]) {
              const prayerName = prayerRenameMap[prayer] || prayer;
              filteredTimings[prayerName] = timings[prayer];
            }
          });
          this.prayers = Object.entries(filteredTimings).map(
            ([salat, heure]) => ({ salat, heure })
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des horaires de prière :",
          error
        );
      }
    },

    incrementMonths() {
      this.changeMonthG++;
      this.changeMonthH++;
      this.changeMonthHi++;

      const currentGregorianYear = new Date().getFullYear();
      const newGregorianYear = this.getGregorianYear(this.changeMonthHi);

      if (newGregorianYear > currentGregorianYear) {
        this.changeMonthG = 0;
        this.changeMonthH = 1;
        this.changeMonthHi = 0;
      }

      this.fetchPrayerMonth();
    },

    decrementMonths() {
      this.changeMonthG--;
      this.changeMonthH--;
      this.changeMonthHi--;
      console.log(this.changeMonthH); // removed the extra decrement here

      const currentHijriYear = this.currentHijriYear;
      const newHijriYear = this.getHijriYear(this.changeMonthHi);

      // Get the current Gregorian year before and after decrementing the months
      const currentGregorianYear = new Date().getFullYear();
      const newGregorianYear = this.getGregorianYear(this.changeMonthHi);

      if (
        newHijriYear < currentHijriYear ||
        newGregorianYear < currentGregorianYear
      ) {
        this.changeMonthG = 0;
        this.changeMonthH = 1;
        this.changeMonthHi = 0;
      }

      this.fetchPrayerMonth();
    },

    getGregorianYear(monthOffset) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + monthOffset);

      return currentDate.getFullYear();
    },

    getCurrentMonth(offset = this.changeMonthG) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + offset);

      this.currentMonthIndex = currentDate.getMonth();
      this.currentYear = currentDate.getFullYear();

      const monthFormatter = new Intl.DateTimeFormat("fr-FR", {
        month: "long",
      });
      const currentMonth = monthFormatter.format(currentDate);

      return `${currentMonth.charAt(0).toUpperCase()}${currentMonth.slice(1)} ${
        this.currentYear
      }`;
    },

    getDayOfWeek(day, month, year) {
      const targetDate = new Date(year, month, day);
      const dayOfWeekIndex = targetDate.getDay();
      const weekdays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
      const dayOfWeek = weekdays[dayOfWeekIndex];

      return dayOfWeek;
    },
    getDaysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async fetchPrayerMonth() {
      try {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + this.changeMonthH;
        console.log("month", month);
        const params = {
          city: this.selectedCity ? this.selectedCity : "Paris", // Remplacez 'London' par la valeur de la variable contenant le nom de la ville
          country: this.selectedCountry, // Remplacez 'United Kingdom' par la valeur de la variable contenant le nom du pays
          method: 3,
        };

        const url = `https://api.aladhan.com/v1/calendarByCity/${year}/${month}`;
        const response = await axios.get(url, { params });

        console.log("url", response);

        if (response.status === 200 && response.data && response.data.data) {
          const data = response.data.data;
          const prayerData = data.map((day) => {
            return {
              date: day.date.gregorian.date,
              month: day.date.hijri.month.en,
              number: day.date.hijri.day,
              year: day.date.hijri.year,
              Fajr: day.timings.Fajr.split(" ")[0],
              Shuruq: day.timings.Sunrise.split(" ")[0],
              Dhuhr: day.timings.Dhuhr.split(" ")[0],
              Asr: day.timings.Asr.split(" ")[0],
              Maghrib: day.timings.Maghrib.split(" ")[0],
              Isha: day.timings.Isha.split(" ")[0],
            };
          });

          // Assigner les données récupérées à votre variable de prières
          this.prayersMoth = prayerData;
        } else {
          console.error(
            "La réponse de l'API ne contient pas les données attendues"
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des horaires de prière pour le mois :",
          error
        );
      }
    },

    formatNumber(number) {
      return number < 10 ? "0" + number : number;
    },

    isToday(index, currentMonth, currentYear) {
      const targetDate = new Date(currentYear, currentMonth, index + 1);
      const today = new Date();
      return today.toDateString() === targetDate.toDateString();
    },

    isFinish(index, currentMonth, currentYear) {
      const targetDate = new Date(currentYear, currentMonth, index + 2);
      const today = new Date();
      return today > targetDate;
    },

    toggleFinish() {
      this.isFinishVisible = !this.isFinishVisible;
      const elements = document.querySelectorAll(".isFinish");
      for (const element of elements) {
        element.style.display = this.isFinishVisible ? "block" : "none";
      }
    },

    loadFinishState() {
      const finishState = localStorage.getItem("isFinishVisible");
      if (finishState !== null) {
        this.isFinishVisible = JSON.parse(finishState);
      }
    },

    isWeekDay(day, month, year) {
      const targetDate = new Date(year, month, day);
      const dayOfWeekIndex = targetDate.getDay();
      const weekdays = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];
      const dayOfWeek = weekdays[dayOfWeekIndex];

      return dayOfWeek === "Lun" || dayOfWeek === "Jeu";
    },
    showPopupInfo(day) {
      this.selectedDay = day;
      this.showPopupInfoBox = true;
    },

    isRamadan(day) {
      return day.month === "Ramaḍān";
    },

    isShawwal(day) {
      return day.month === "Shawwāl" && day.number === "01";
    },

    isWhiteDay(day) {
      return ["13", "14", "15"].includes(day.number);
    },
    isFastingMouharram(day) {
      return day.month === "Muḥarram" && ["09", "10"].includes(day.number);
    },
    isSacredMonth(day) {
      const sacredMonths = [
        "Muḥarram",
        "Rajab",
        "Dhū al-Qaʿdah",
        "Dhū al-Ḥijjah",
      ];

      // Vérifiez si le mois actuel fait partie des mois sacrés
      return sacredMonths.includes(day.month);
    },
    isDhulHija(day) {
      return (
        day.month === "Dhū al-Ḥijjah" &&
        [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
        ].includes(day.number)
      );
    },
    isEidAlAdha(day) {
      return day.month === "Dhū al-Ḥijjah" && ["10"].includes(day.number);
    },

    getHijriDate() {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + this.changeMonthHi);

      const hijriDate = Intl.DateTimeFormat("en-u-ca-islamic", {
        month: "long",
        year: "numeric",
      }).formatToParts(currentDate);

      let hijriMonth = "";
      let hijriYear = "";

      for (const part of hijriDate) {
        if (part.type === "month") {
          hijriMonth = part.value;
        } else if (part.type === "year") {
          hijriYear = part.value;
        }
      }

      this.currentHijriYear = parseInt(hijriYear, 10); // Stockez l'année Hijri actuelle

      return `${hijriMonth} ${hijriYear}`;
    },

    getHijriYear(monthOffset) {
      const currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + monthOffset);

      const hijriDate = Intl.DateTimeFormat("en-u-ca-islamic", {
        year: "numeric",
      }).formatToParts(currentDate);

      let hijriYear = "";

      for (const part of hijriDate) {
        if (part.type === "year") {
          hijriYear = part.value;
        }
      }

      return parseInt(hijriYear, 10);
    },

    getMonthsInHijriYear(hijriYear) {
      return this.isLeapYearInHijriCalendar(hijriYear) ? 13 : 12;
    },

    isLeapYearInHijriCalendar(hijriYear) {
      return (hijriYear - 1) % 30 < 11 || (hijriYear - 1) % 30 === 29;
    },

    getCurrentPrayer() {
      const currentTime = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      // Filtrer uniquement les cinq prières de la journée
      const prayersOfInterest = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];
      const filteredPrayers = this.prayers.filter((prayer) =>
        prayersOfInterest.includes(prayer.salat)
      );

      let currentPrayer = "Fajr"; // Définir Fajr comme valeur par défaut
      for (const prayer of filteredPrayers) {
        if (currentTime < prayer.heure) {
          currentPrayer = prayer.salat;
          break;
        }
      }

      return currentPrayer;
    },

    getCurrentPrayerTime() {
      const currentPrayer = this.getCurrentPrayer();
      // Utiliser les prières filtrées pour trouver l'heure de la prière actuelle
      const filteredPrayers = this.prayers.filter((prayer) =>
        ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"].includes(prayer.salat)
      );
      const prayer = filteredPrayers.find(
        (item) => item.salat === currentPrayer
      );
      return prayer ? prayer.heure : "";
    },

    getNextPrayerTime() {
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const currentTimeInMinutes = currentHours * 60 + currentMinutes;

      let nextPrayerTime = null;
      let smallestDifference = 24 * 60; // Maximum possible difference in minutes

      // Filter only the five prayers of the day
      const prayersOfInterest = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];
      const filteredPrayers = this.prayers.filter((prayer) =>
        prayersOfInterest.includes(prayer.salat)
      );

      let isIshaPassed = false;
      for (let i = 0; i < filteredPrayers.length; i++) {
        const prayer = filteredPrayers[i];
        const [hours, minutes] = prayer.heure.split(":").map(Number);
        const prayerTimeInMinutes = hours * 60 + minutes;

        if (
          prayer.salat === "Isha" &&
          currentTimeInMinutes > prayerTimeInMinutes
        ) {
          isIshaPassed = true;
        }

        if (!isIshaPassed) {
          const difference = prayerTimeInMinutes - currentTimeInMinutes;
          if (difference > 0 && difference < smallestDifference) {
            smallestDifference = difference;
            nextPrayerTime = new Date(
              currentTime.setHours(hours, minutes, 0, 0)
            );
          }
        } else {
          if (prayer.salat === "Fajr") {
            const difference =
              prayerTimeInMinutes + 24 * 60 - currentTimeInMinutes;
            if (difference < smallestDifference) {
              smallestDifference = difference;
              nextPrayerTime = new Date(
                currentTime.setHours(hours, minutes, 0, 0)
              );
              // Add 1 day to the Fajr time
              nextPrayerTime.setDate(nextPrayerTime.getDate() + 1);
            }
          }
        }
      }

      return nextPrayerTime ? nextPrayerTime.getTime() : null;
    },

    updateCountdown() {
      const nextPrayerTime = this.getNextPrayerTime();
      if (nextPrayerTime) {
        const now = new Date().getTime();
        const timeDifference = nextPrayerTime - now; // en millisecondes

        let hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Ajouter un zéro devant les nombres inférieurs à 10 pour les heures, minutes et secondes
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.countdown = `${hours}:${minutes}:${seconds}`;

        // Déterminer la couleur de fond en fonction du temps restant
        if (hours >= 2) {
          this.countdownBgColor = "#4FD777"; // Plus de 2 heures
        } else if (hours < 2 && minutes >= 1) {
          this.countdownBgColor = "#FAB03C"; // Entre 20 minutes et 2 heures
        } else if (hours < 1 && minutes < 20) {
          this.countdownBgColor = "red"; // Moins de 20 minutes
        }

        // Mettre à jour le DOM avec le compte à rebours
        this.$el.querySelector(".countdown").textContent = this.countdown;
      }
    },

    getCurrentPrayerImage() {
      const nextPrayer = this.getNextPrayerTime();
      if (nextPrayer) {
        const nextPrayerName = this.prayers.find((prayer) => {
          const [hours, minutes] = prayer.heure.split(":").map(Number);
          const prayerTime = new Date();
          prayerTime.setHours(hours, minutes, 0, 0);
          return prayerTime.getTime() === nextPrayer;
        }).salat;
        return this.images[nextPrayerName] || this.images.Fajr;
      } else {
        return this.images.Fajr; // Si aucune prochaine prière n'est trouvée, afficher Fajr par défaut
      }
    },

    openPopup() {
      this.showPopup = true;
      this.fetchCities();
    },
    closePopup() {
      if (this.$refs.countrySelect && this.$refs.countrySelect.$refs.options) {
        this.$refs.countrySelect.$refs.options.blur();
      }
      if (this.$refs.citySelect && this.$refs.citySelect.$refs.options) {
        this.$refs.citySelect.$refs.options.blur();
      }
      this.showPopup = false;
    },
  },
};
</script>

<style scoped>
.box-prayers {
  position: relative;
  margin-top: 100px;
  margin-bottom: 60px;
  border-radius: 10px;
  z-index: 1;
}

h1 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 20px;
  font-weight: 800;
  background: var(--card);
  padding: 20px 5px;
  border-radius: 10px;
}

.container-box-pray {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.container-box-pray .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  width: 500px;
  background: var(--card);
  padding: 20px;
  border-radius: 10px;
  flex-grow: 1;
  transition: 0.2s;
}

.container-box-pray .left .top {
  display: flex;
  justify-content: space-between;
}

.container-box-pray .left .bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.container-box-pray .left .bottom .angle-pray {
  font-weight: 500;
  color: var(--placeholder);
}
.container-box-pray .left .bottom .prayname {
  font-size: 1.8em;
  font-weight: 700;
}

.container-box-pray .left .bottom .at {
  font-weight: 700;
  font-size: 20px;
  margin: 0 5px;
}
.container-box-pray .left .bottom .hourpray {
  font-size: 1.8em;
  font-weight: 700;
}
.container-box-pray .left .bottom .countdown {
  line-height: 2.1em;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 1px;
}

.container-box-pray .left .top .city-change {
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
}

.container-box-pray .left .top p span i {
  font-size: 18px;
}

.container-box-pray .left img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-top: -40px;
}

.container-box-pray .right {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 10px;
  flex-grow: 1;
}

.container-box-pray .right .item {
  flex-grow: 1;
  width: 130px;
  padding: 10px;
  background-color: var(--card);
  border-radius: 10px;
}

.container-box-pray .right .item p {
  font-size: 16px;
  color: var(--text);
}

.container-box-pray .right .item .salat {
  font-weight: 700;
}

.container-box-pray .right .item .hour {
  font-weight: 400 !important;
}

.after-box-prayers {
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1;
}

.infos-prayers {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.left .time-pray {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.left .time-pray .actual-pray {
  font-size: 30px;
  font-weight: 600;
}

.left .time-pray .count-pray {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #000;
  padding: 10px 15px 10px 10px;
  border-radius: 10px;
}

.left .time-pray .count-pray p {
  display: inline;
  line-height: 0;
  font-weight: 600;
}

.city-degree {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.date p {
  text-align: end;
}

.hours-prayers {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-content: flex-end;
  gap: 20px;
}

.popup-content h2 {
  position: relative;
  z-index: 2;
}

.popup-content .input-country-city {
  position: relative;
  display: flex;
  gap: 10px;
  z-index: 2;
}

.popup-content .input-country-city input {
  width: 50%;
  border: 1px solid var(--placeholder);
  border-radius: 8px;
  padding: 10px;
  color: var(--text);
  font-weight: 500;
  margin-bottom: 5px;
}

.popup-content ul {
  position: relative;
  z-index: 2;
  max-height: 400px;
  overflow-y: overlay;
}

.popup-content .country-lists {
  min-height: 40px;
  max-height: 40px;
}

.popup-content ul li {
  padding: 5px 10px;
  border: 1px solid var(--placeholder);
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: 0.2s;
}

.popup-content ul li:hover {
  background: var(--button);
  color: #fff;
  border: 1px solid var(--button);
}

.popup-content ul::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.loader {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.box-month-pr {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 10px;
  align-self: stretch;
}

.item-m {
  position: relative;
  flex-grow: 1;
  width: 150px;
  min-height: 150px;
  padding: 15px;
  background: var(--card);
  border-radius: 15px;
  cursor: pointer;
  transition: 0.2s ease;
}

.item-m:hover {
  transform: scale(1.05);
  opacity: 1;
}

.item-m img {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-top: 10px;
}

.white-day-background {
  background: #fae8b4;
  color: #000 !important;
}

img.white-day-background {
  display: block;
}

img.sacredMonth {
  display: block;
}

.ramadan-background {
  background: #cdf9d1;
  color: #000 !important;
}

img.isWeekDay {
  display: block;
}

img.mouharam {
  display: block;
}

img.isWeekDay img.white-day-background {
  display: none;
}

img.ramadan-background {
  display: block;
}

.fasting-muharam {
  background: #ccc8ff;
  color: #000 !important;
}

.dhul-hijah {
  background: #ffbed9;
  color: #000 !important;
}

img.is-dhul-hijah {
  display: block;
}

.shawwal-background {
  background: #bce1fd !important;
  color: #000 !important;
}

img.shawwal-background {
  display: block;
}

.eid-adah {
  background: #bce1fd !important;
  color: #000 !important;
}

img.eid-adah {
  display: block !important;
}

.is-day {
  outline: 2px solid var(--text);
}

.month-hijri {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 10px;
}

.item-m .number {
  font-size: 18px;
  font-weight: 700;
}

.item-m .h {
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  gap: 3px;
  justify-content: end;
}

.item-m .h span {
  text-align: center;
  font-weight: 600;
  border: 1px solid var(--text);
  padding: 3px 3px;
  border-radius: 8px;
  flex-grow: 1;
  width: 55px;
  color: var(--text);
}

.isFinish {
  position: relative;
  opacity: 0.5;
}

.before-close-finish {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 0;
  margin-left: -20px;
  background: var(--card);
  padding: 10px 5px;
  height: 30px;
  width: 30px;
  transition: 0.2s;
  cursor: pointer;
  z-index: 10;
  margin-bottom: -20px;
  border-radius: 50%;
}

.before-close-finish i {
  font-size: 14px;
}

.before-close-finish:hover {
  background: var(--button);
  color: #fff;
}

/* .f {
  color: #5b44f4;
}
.s {
  color: #987801;
}
.d {
  color: #5db71a;
}
.a {
  color: #f00049;
}
.m {
  color: #ffa800;
}
.i {
  color: #3762ff;
} */

@media (max-width: 1280px) {
  .container-box-pray .left {
    width: 450px;
  }

  .container-box-pray .right {
    width: 600px;
  }
}

@media (max-width: 768px) {
  .container-box-pray .right {
    align-items: stretch;
  }
  .container-box-pray .right .item {
    flex: 1;
    width: 80px;
  }

  .infos-prayers {
    align-items: flex-start;
  }

  .right {
    align-items: flex-end;
    width: 100%;
  }

  .left .time-pray .actual-pray {
    margin-top: -10px;
  }

  .left img {
    margin-top: -60px;
  }

  .hours-prayers {
    grid-template-columns: 1fr;
  }

  .hours-prayers .time {
    margin-top: -30px;
    margin-bottom: 0;
  }

  .left .time-pray {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .hours-prayers {
    grid-template-columns: auto 1fr auto;
  }
}

.hours-prayers .time {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: -30px;
}

.lists-prayers {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  gap: 20px;
}

.lists-prayers .salat {
  margin-bottom: 10px;
  font-weight: 700;
}

.lists-prayers .hour {
  font-size: 18px;
  font-weight: 600;
  padding: 20px 8px;
  border-radius: 8px;
  background: var(--options-v-background-color);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
  color: var(--text);
  text-align: center;
  line-height: 0;
}

.change {
  cursor: pointer;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  padding: 20px;
}

.popup-content {
  background-color: var(--card);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  min-width: 300px;
  max-width: 500px;
  width: 100%;
}

.popup-content h2 {
  margin-bottom: 10px;
}

.popup-content input,
.popup-content select,
.popup-content button {
  margin-bottom: 10px;
}

h2 {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 20px;
}

.hours-month {
  position: relative;
  margin-top: 10px;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.popup-info-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--card);
  border-radius: 10px;
  padding: 20px;
  max-width: 90%;
  width: 800px;
  max-height: 75%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  overflow: auto;
}

.popup-info-box::-webkit-scrollbar {
  width: 10px !important;
  display: none !important;
}

.popup-info-box::-webkit-scrollbar-thumb {
  background: var(--card) !important;
}

.popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.prayers-hours {
  position: relative;
}

.navigation-hijri {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  right: 0;
  left: 0;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}

.navigation-hijri div {
  background: var(--card);
  opacity: 0.3;
  transition: 0.2s;
}

.navigation-hijri div:hover {
  opacity: 1;
}

.navigation-hijri .right-date,
.navigation-hijri .left-date {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  padding: 20px;
}

.navigation-hijri div i {
  font-size: 20px;
}
</style>
