export default {
  state: {
    angle: "",
    nextPrayer: "",
    nextPrayerTime: "",
    remainingTime: "",
  },
  mutations: {
    setPrayerData(state, data) {
      console.log("Données transférées :", data); // Ajoutez cette ligne
      state.angle = data.angle;
      state.nextPrayer = data.nextPrayer;
      state.nextPrayerTime = data.nextPrayerTime;
      state.remainingTime = data.remainingTime;
    },
  },
  actions: {
    setPrayerData({ commit }, data) {
      commit("setPrayerData", data);
    },
  },
};
