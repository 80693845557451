<template>
  <div class="popup-info-box">
    <div v-if="isRamadan(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <div v-if="isRamadanDay(selectedDay) === 'A'">
        <p>
          Le mois de Ramadan est le neuvième mois du calendrier Hijri et est
          considéré comme le mois le plus important de l'année pour les
          musulmans. Il est marqué par le jeûne du lever au coucher du soleil,
          qui est l'un des cinq piliers de l'Islam. Ce mois est une période de
          purification spirituelle, de dévotion accrue, de générosité et de
          rapprochement avec Allah. Les récompenses spirituelles sont
          multipliées pendant ce mois. Celui qui jeûne avec foi et sincérité
          durant ce mois, verra tous ses péchés antérieurs pardonnés.
        </p>
        <p>Le Prophète Muhammad (Paix et bénédictions sur lui) a dit :</p>
        <p class="citation">
          "Quand arrive le mois de Ramadan, les portes du Paradis sont ouvertes
          et les portes de l'Enfer sont fermées, et les démons sont enchaînés."
          (Sahih al-Bukhari, 1899)
        </p>
      </div>
      <div v-else-if="isRamadanDay(selectedDay) === 'B'">
        <p>
          Nous sommes dans les dix dernières nuits du Ramadan. Ces nuits sont
          particulièrement importantes, car c'est durant cette période que se
          trouve Laylat al-Qadr, ou la Nuit du Destin. Les musulmans sont
          encouragés à passer ces nuits en prière et en adoration, car les
          récompenses sont encore plus grandes.
        </p>
        <p>Â’ishah (qu’Allah l’agrée) relate :</p>
        <p class="citation">
          Lorsqu’arrivaient les dix derniers jours du mois de Ramadan, le
          Messager d’Allah (sur lui la paix et le salut) veillait la nuit, il
          réveillait ses épouses et serrait la ceinture de son pagne.<br />
          Authentique. - Rapporté par Al-Bukhârî et Muslim
        </p>
      </div>
      <div v-else-if="isRamadanDay(selectedDay) === 'C'">
        <p>
          Laylat al-Qadr est la nuit où le Coran a été révélé pour la première
          fois au Prophète Muhammad (Paix et bénédictions sur lui). Cette nuit
          est considérée comme meilleure que mille mois en termes de
          bénédictions et de récompenses. Les musulmans doivent passer cette
          nuit en prière, en lecture du Coran et en invocations. ʽÂ’ishah, la
          mère des croyants (qu'Allah l'agrée) relate que le Prophète (sur lui
          la paix et le salut) a dit :
        </p>
        <p class="citation">
          Cherchez la nuit du destin dans les nuits impaires des dix dernières
          nuits. Authentique. - Rapporté par Al-Bukhârî et Muslim.
        </p>
        <p>
          Ces nuits impaires sont le 21, 23, 25, 27 et 29. Celle qui a le plus
          de chance d’être la nuit du destin, c’est la vingt-septième nuit,
          conformément à la parole de plusieurs compagnons du Prophète qui ont
          dit :
        </p>
        <p class="citation">C’est la vingt-septième nuit.</p>
        <p>
          Il ne faut pas négliger les autres nuits, car la nuit du 27 n'est pas
          une certitude. La nuit du destin peut survenir durant ces nuits
          impaires, il ne faut pas tout miser sur le 27 au risque de perdre la
          récompense. Le prophète a dit :
        </p>
        <p class="citation">
          Quiconque veille la Nuit du Destin avec foi et espoir en la récompense
          divine, ses péchés antérieurs lui seront pardonnés. Authentique. -
          Rapporté par Al-Bukhârî et Muslim.
        </p>
      </div>
    </div>
    <!-- <div v-else-if="isWeekDay(selectedDay.number, currentMonthIndex, currentYear)">
      <div class="top">
        <img src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png" alt="" />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Selon la Sunnah, jeûner le Lundi et le Jeudi de chaque semaine apporte
        des récompenses spéciales. D'après Abou Houreira (qu'Allah l'agrée), le
        Prophète (que la prière d'Allah et Son salut soient sur lui) jeûnait le
        lundi et le jeudi. Quelqu'un lui a dit :
      </p>
      <p class="citation">
        Ô Messager d'Allah ! Tu jeûnes le lundi et le jeudi ? Le Prophète (que
        la prière d'Allah et Son salut soient sur lui) a dit: « Certes le lundi
        et le jeudi Allah pardonne à chaque musulman sauf à deux personnes qui
        s'évitent (1), Il dit (2): Laisse ces deux jusqu'à ce qu'ils se
        réconcilient ». Par Ibn Maja dans ses Sounan n°1740.
      </p>
      <p>Dans un autre hadith le prophète a dit :</p>
      <p class="citation">
        Les actions sont présentées (à Allah) chaque lundi et jeudi, et je
        désire que mes actions soient présentées alors que je suis en état de
        jeûne.(Sahih al-Bukhari, 1876)
      </p>
    </div> -->
    <div v-else-if="isFastingMouharram(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Le mois de Muharram, le premier mois du calendrier de l'Hégire, est une
        période particulièrement sacrée dans l'islam, comme mentionné dans le
        Coran et les hadiths authentiques. Allah a spécifié quatre mois comme
        sacrés, dont Muharram est l'un d'eux. Le Prophète Muhammad (que la paix
        et les bénédictions d'Allah soient sur lui) a souligné l'importance de
        ce mois, indiquant que le jeûne pendant Muharram est le meilleur après
        celui du Ramadan.
      </p>
      <p class="citation">
        « le meilleur jeûne après celui du ramadan est le jeûne effectué pendant
        le mois d’Allah al-Mouharram. Et la meilleure prière faite après la
        prière obligatoire est celle effectuée dans la nuit» (rapporté par
        Mouslim, 1163)
      </p>
      <p>
        Le jeûne d'Achoura, célébré le 10ème jour de Muharram, trouve ses
        racines dans la tradition prophétique. Après son arrivée à Médine, le
        Prophète Muhammad a constaté que les juifs jeûnaient ce jour pour
        commémorer la libération de Moïse et de son peuple par Allah.
        Reconnaissant la convergence spirituelle entre l'islam et la prophétie
        de Moïse, le Prophète a prescrit aux musulmans de jeûner également ce
        jour. Il est recommandé de jeûner à la fois le 9 et le 10 de Muharram
        pour se distinguer des pratiques des autres communautés et suivre
        l'exemple prophétique. L'une des bénédictions de ce jeûne est
        l'expiation des péchés de l'année précédente, comme le rapporte le
        prophète :
      </p>
      <p class="citation">
        « Ce jeûne efface les péchés de l'année précédente. » (Mouslim)
      </p>
    </div>
    <div v-else-if="isShawwal(selectedDay)">
      <div v-if="selectedDay.number === '01'">
        <div class="top">
          <img
            src="https://cp.doonun.com/uploads/garland_3511257_66b1b5142f.png"
            alt=""
          />
          <h2>
            {{ selectedDay.number }} {{ selectedDay.month }} - Eid Al-fitr
          </h2>
        </div>
        <p>
          Eid Al-Fitr marque la fin du mois de Ramadan et le début du mois de
          Shawwal. C'est un jour de célébration et de joie, où les musulmans se
          rassemblent pour prier, partager des repas, des cadeaux et donner de
          la charité aux nécessiteux. Il est interdit jeuner durant ce jour et
          l'homme et la femme doivent assister à la prière de l’Aïd.
        </p>
        <p>
          Ce qu'il faut faire durant cette journée de fête : Prendre un bain
          rituel (le Ghousl), manger avant de sortir, faire le takbir tout le
          long du chemin vers la mosquée, se féliciter mutuellement, porter ses
          plus beaux vêtements et changer de chemin en revenant du lieu de
          prière. Le prophète a dit :
        </p>
        <p class="citation">
          "Chaque peuple a son jour de fête, et ce jour est le vôtre." (Rapporté
          par Bukhari et Muslim)
        </p>
      </div>
      <div v-else>
        <div class="top">
          <img
            src="https://cp.doonun.com/uploads/dates_4492903_88ec9c8900.png"
            alt=""
          />
          <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
        </div>
        <p>
          Il est recommandé de jeûner six jours au cours du mois de Shawwal,
          après la fin du Ramadan. Selon la Sunnah, jeûner ces six jours
          équivaut à jeûner une année entière. Le Prophète Muhammad (Paix et
          bénédictions sur lui) a dit :
        </p>
        <p class="citation">
          Quiconque jeûne le mois de Ramadan, puis le suit en jeûnant six jours
          du mois de Shawwal, cela équivaut à jeûner toute l'année." (Sahih
          Muslim, 1164)
        </p>
      </div>
    </div>
    <div v-else-if="isEidAlAdha(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/garland_3511257_66b1b5142f.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }} Eid Al-Adha</h2>
      </div>
      <p>
        Eid Al-Adha, la fête du Sacrifice, a lieu le 10ème jour de Dhul Hijjah.
        C'est un jour de célébration et de commémoration du sacrifice du
        Prophète Ibrahim (Paix sur lui). Les musulmans effectuent des prières
        spéciales, sacrifient un animal et partagent la viande avec les
        nécessiteux. Le Prophète Muhammad (Paix et bénédictions sur lui) a dit :
      </p>
      <p class="citation">
        Certes le plus grand jour auprès d'Allah est le jour du sacrifice puis
        le jour du qar (le 11ème jour de Dhoul Hijja). Par Abou Daoud dans ses
        Sounan n°1765
      </p>
      <p>
        Tout comme pour l'Aïd al-Fitr, il est prohibé de jeûner pendant l'Aïd
        al-Adha. Il est recommandé de prendre un bain rituel, d'utiliser le
        Siwak, de revêtir ses plus beaux vêtements et de se parfumer (pour les
        hommes). Tous les musulmans sont encouragés à se rendre à la mosquée,
        même les femmes indisposées, bien qu'elles ne participent pas à la
        prière. Si possible, il est préférable de ne pas manger avant d'avoir
        consommé de la viande de l'animal sacrifié.
      </p>
    </div>
    <div v-else-if="isEightDayDhulHijja(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/route_10741176_eefc662f1e.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
        <p>
          Le premier jour du Hajj, appelé Yawm al-Tarwiyah (jour de
          l'Etanchement de la Soif), est marqué par la préparation des pèlerins.
          Ils boivent beaucoup d'eau, remplissent leurs réservoirs et veillent à
          ce que leurs animaux soient nourris et hydratés pour le voyage à
          venir. Pour ceux effectuant le Hajj al-Tamattou, ils retirent leurs
          vêtements d'Ihram après la Omra, prennent une douche et récitent des
          prières spécifiques avant de revêtir à nouveau les vêtements d'Ihram.
          Les femmes doivent porter des vêtements islamiques modestes avec un
          voile et découvrir leurs mains et visages.
        </p>
        <p>
          Pour ceux effectuant le Hajj al-Qiran ou le Hajj al-Ifrad, ils restent
          déjà en état d'Ihram après la Omra et doivent éviter les produits
          parfumés lorsqu'ils prennent une douche. Ensuite, les pèlerins se
          dirigent vers Mina en bus ou en marchant à travers les tunnels
          piétonniers. Une fois à Mina, ils s'installent dans leurs tentes et
          prient les prières raccourcies. Ils passent la nuit à Mina, marquant
          ainsi la fin de leur premier jour de Hajj.
        </p>
      </div>
    </div>
    <div v-else-if="isDayDhulNine(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/route_10741176_eefc662f1e.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Le deuxième jour du Hajj, le 9 Dhul Hijjah, les pèlerins se rendent à
        Muzdalifah. Ce lieu est appelé ainsi en raison de son association avec
        la proximité et est également connu sous le nom de al-Mashar al-Haram.
        Après le coucher du soleil, le Prophète Muhammad (que la paix soit sur
        lui) quitta Arafat pour Muzdalifah, où il récita la Talbiyah et
        accomplit les prières du Maghrib et de l'Icha, raccourcies à deux
        rak'ahs chacune. Certains membres vulnérables de sa famille furent
        autorisés à quitter Muzdalifah avant l'aube.
      </p>
      <p class="citation">
        Lorsque vous quitterez Arafat, rappelez-vous d’Allah à al-Mashar
        al-Haram. Et rappelez-vous de Lui, comme Il vous a guidés, alors que
        vous étiez auparavant parmi les égarés. [Surah al-Baqarah 2:198]
      </p>
      <p>
        Le Prophète se reposa jusqu'à l'aube, puis dirigea la prière du Fajr
        avant de se rendre à al-Mashar al-Haram pour l'adoration. Il ramassa
        ensuite des cailloux pour le Rami des Jamarat à Mina au cours des
        prochains jours. À Muzdalifah, il n'y a pas de tentes ni d'autres
        installations d'hébergement. Les pèlerins doivent se préparer à passer
        la nuit en plein air. Les prières du Maghrib et de l'Icha sont
        accomplies ensemble, suivies de la Witr. Les personnes considérées comme
        faibles peuvent quitter Muzdalifah entre le coucher de la lune et
        l'aube.
      </p>
      <p>
        Après la prière du Fajr, les pèlerins font le Wouqouf, une courte
        adoration, puis se préparent à quitter Muzdalifah pour Mina. Il est
        conseillé de partir tôt le matin pour éviter les embouteillages. Les
        pèlerins doivent également collecter des cailloux pour le Rami avant de
        quitter Muzdalifah.
      </p>
    </div>
    <div v-else-if="isDayDhulEleven(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/route_10741176_eefc662f1e.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Le quatrième jour du Hajj, le 11 Dhul Hijjah, marque le début des Ayyam
        al-Tashriq, les Jours de Séchage de la Viande. Ces jours sont dédiés à
        la consommation de nourriture et de boissons. La lapidation des Jamarat
        doit être effectuée entre midi le 11 et l'aube le 12.
      </p>
      <p class="citation">
        Les jours de Tashriq sont les jours où l’on mange et où l’on boit.
        [Raconté dans Sahih Muslim]
      </p>
      <p>
        La lapidation des stèles doit être faite dans l'ordre : d'abord la
        petite stèle, suivie de la stèle du milieu, puis de la grande stèle. Les
        prières et les invocations doivent être faites à l'écart de la foule, en
        direction de la Qibla. Il est recommandé de lapider lorsque la foule est
        moins dense, de préférence avant ou après la prière du Asr.
      </p>
      <p>
        Après la lapidation, les pèlerins retournent à leur camp à Mina pour
        passer le reste de la journée en adoration. Ils peuvent également
        rattraper tout rite qu'ils n'auraient pas accompli les jours précédents.
      </p>
    </div>
    <div v-else-if="isDayDhulTwelve(selectedDay)">
      <div class="top">
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Le cinquième jour du Hajj, le 12 Dhul Hijjah, implique la même activité
        que la veille : la lapidation des trois Jamarat. Cette action doit être
        effectuée entre midi le 12 et l'aube le 13. Il est crucial de compléter
        tous les rites avant le coucher du soleil pour éviter des pénalités pour
        tout rite manqué. Si vous décidez de quitter Mina pour La Mecque après
        avoir accompli la lapidation, assurez-vous de le faire avant le coucher
        du soleil, car partir après est déconseillé mais pas interdit.
        Cependant, si vous êtes encore à Mina au début de la prière du Fajr le
        13 Dhul Hijjah, il est alors obligatoire de lapider ce jour-là sous
        peine de pénalités.
      </p>
      <p>
        Il est recommandé de se rendre à La Mecque à pied, bien que le transport
        routier soit également possible mais souvent chaotique. Faire une pause
        à Wadi al-Mouhassab sur le chemin du retour vers La Mecque est une
        Sunna, mais son omission n'entraîne pas de pénalité. Anas bin Malik I a
        raconté :
      </p>
      <p class="citation">
        Le Prophète ﷺ fit les prières des Dhuhr, Asr, Maghrib et Icha et dormit
        un moment à un endroit appelé al-Mouhassab, puis se rendit à la Kaaba et
        fit le Tawaf autour d’elle. [Raconté dans Sahih al-Bukhari]
      </p>
    </div>
    <div v-else-if="isDayDhulTreize(selectedDay)">
      <div class="top">
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Le sixième jour du Hajj, le 13 Dhul Hijjah, est recommandé de rester à
        Mina et d'y accomplir la lapidation des trois Jamarat. Cette action doit
        être réalisée entre le début de la prière du Fajr et le coucher du
        soleil. Si vous choisissez de rester à Mina, assurez-vous d'avoir
        suffisamment de provisions, car la plupart des pèlerins ont quitté les
        lieux. Après la lapidation, quittez Mina pour La Mecque, soit en voiture
        privée, soit à pied. Avant de quitter La Mecque, assurez-vous de
        réaliser le Tawaf al-Wida (ou Tawaf al-Sadr), également connu sous le
        nom de Tawaf d'adieu. Ce Tawaf est obligatoire et doit être effectué
        avant de quitter les limites du Haram. Les femmes en période de
        menstruation et les résidents du Haram ne sont pas tenus de le faire.
        Ibn Abbas I a raconté :
      </p>
      <p class="citation">
        On ordonna aux gens d’accomplir le Tawaf de la Kaaba (Tawaf al-Wida)
        comme dernière chose avant de quitter (La Mecque), à l’exception des
        femmes en période de menstruation qui furent excusées. [Raconté dans
        Sahih al-Bukhari]
      </p>
      <p>
        Le Tawaf al-Wida peut être accompli à tout moment après le Tawaf
        al-Ziyarah, mais il est préférable de le faire avant de quitter La
        Mecque. Après avoir effectué les sept circuits du Tawaf et deux rak'ahs
        de salah au Maqam Ibrahim, votre Hajj est officiellement terminé. Que
        votre Hajj soit accepté. Amen.
      </p>
    </div>
    <div v-else-if="isFirstDayDhulHijja(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/route_10741176_eefc662f1e.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Parmi les périodes bénies, il y a les dix premiers jours du mois de
        Dhul-Hijjah, qui sont les meilleurs jours de l’année. Ces jours sont
        meilleurs que ceux du mois de Ramadan, bien que les nuits du mois de
        Ramadan soient supérieures à celles du mois de Dhul-Hijjah. Dans le
        Coran, Allah a juré par les dix premières nuits de Dhul-Hijjah :
      </p>
      <p class="citation">Par l'Aube, Et par les dix nuits. Coran 89:1-2</p>
      <p>Ibn ‘Abbâs a rapporté que le Prophète a dit :</p>
      <p class="citation">
        Il n’y a pas de jours où les bonnes œuvres sont plus aimées d’Allah que
        durant ces dix jours.
      </p>
      <p>
        C'est exclusivement pendant cette période qu'il est possible d'accomplir
        le Hajj, et c'est le seul moment où tous les actes de dévotion sont
        combinés. Il est donc recommandé, durant ces jours, pour ceux ayant la
        capacité de réaliser le Hajj, d'intensifier la lecture du Coran, de
        jeûner neuf jours, de multiplier les prières, les invocations et les
        rappels d'Allah, ainsi que les aumônes, de sacrifier un animal, de se
        repentir et d'éviter les péchés.
      </p>
    </div>
    <div v-else-if="isWhiteDay(selectedDay)">
      <div class="top">
        <img
          src="https://cp.doonun.com/uploads/white_flag_1805888_baefe51658.png"
          alt=""
        />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }} - Jour blanc</h2>
      </div>
      <p>
        Les 13ème, 14ème et 15ème jours de chaque mois lunaire sont connus sous
        le nom de jours blancs. Il est recommandé de jeûner ces jours, car cela
        apporte des récompenses spéciales. Le Prophète Muhammad (Paix et
        bénédictions sur lui) a dit :
      </p>
      <p class="citation">
        Le jeûne de trois jours chaque mois est comme le jeûne continue et les
        jours blancs sont: le matin du 13ème jour, du 14ème et du 15ème. Nasai
        n°2420
      </p>
      <p class="citation">
        Quiconque jeûne un jour pour Allah, Allah, exalté soit-Il, en
        récompense, éloignera la chaleur de l’Enfer de son visage d'une distance
        parcourue en soixante-dix ans. (Al-Bukhârî et Muslim)
      </p>
      <p class="citation">
        Le jeûne de trois jours de chaque mois équivaut à un jeûne perpétuel.
        Authentique. - Rapporté par Al-Bukhârî et Muslim
      </p>
    </div>
    <div v-else-if="isSacredMonth(selectedDay)">
      <div class="top">
        <img src="https://cp.doonun.com/uploads/bless_3c8d0d3a01.png" alt="" />
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Les mois de Muharram, Rajab, Dhul Qa'dah et Dhul Hijjah sont les quatre
        mois sacrés du calendrier Hijri. Il est interdit de se livrer à des
        actes de violence ou de guerre pendant ces mois. Allah dit :
      </p>
      <p class="citation">
        Les mois auprès d’Allah sont au nombre de douze dans le Livre d’Allah,
        le jour où Il a créé les cieux et la terre ; quatre d’entre eux sont
        sacrés. Telle est la religion droite, alors ne soyez pas injustes envers
        vous-mêmes durant ces mois. Coran 9:36
      </p>
      <p class="citation">
        Ils t'interrogent sur le fait de faire la guerre pendant les mois
        sacrés. Dis : "Y combattre est un péché grave, mais plus grave encore
        auprès d'Allah est de faire obstacle au sentier d'Allah, d'être impie
        envers Celui-ci et la Mosquée sacrée, et d'expulser de là ses habitants.
        Coran 2:217
      </p>
    </div>
    <p v-else-if="isDhulHija(selectedDay)">Ceci est un jour du Dhul Hijjah.</p>
    <div v-else>
      <div class="top">
        <h2>{{ selectedDay.number }} {{ selectedDay.month }}</h2>
      </div>
      <p>
        Ceci est un jour du mois de {{ selectedDay.month }}. Il n'y a rien de
        particulier à connaître sur ce jour.
      </p>
    </div>
    <div class="bottom">
      <button @click="$emit('close')">Fermer</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PopupInfoBox",
  props: {
    selectedDay: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isRamadan(day) {
      return day.month === "Ramaḍān";
    },
    isRamadanDay(day) {
      const dayNumber = Number(day.number);
      if (dayNumber < 20) {
        return "A";
      } else if (dayNumber >= 20 && ![21, 23, 25, 27, 29].includes(dayNumber)) {
        return "B";
      } else {
        return "C";
      }
    },

    isShawwal(day) {
      return day.month === "Shawwāl";
    },

    isWhiteDay(day) {
      return ["13", "14", "15"].includes(day.number);
    },

    isFastingMouharram(day) {
      return day.month === "Muḥarram" && ["09", "10"].includes(day.number);
    },

    isFirstDayDhulHijja(day) {
      return (
        day.month === "Dhū al-Ḥijjah" &&
        ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"].includes(
          day.number
        )
      );
    },
    isEightDayDhulHijja(day) {
      return day.month === "Dhū al-Ḥijjah" && ["08"].includes(day.number);
    },
    isDayDhulNine(day) {
      return day.month === "Dhū al-Ḥijjah" && ["09"].includes(day.number);
    },
    isDayDhulEleven(day) {
      return day.month === "Dhū al-Ḥijjah" && ["11"].includes(day.number);
    },
    isDayDhulTwelve(day) {
      return day.month === "Dhū al-Ḥijjah" && ["12"].includes(day.number);
    },
    isDayDhulTreize(day) {
      return day.month === "Dhū al-Ḥijjah" && ["13"].includes(day.number);
    },

    isSacredMonth(day) {
      const sacredMonths = [
        "Muḥarram",
        "Rajab",
        "Dhū al-Qaʿdah",
        "Dhū al-Ḥijjah",
      ];

      return sacredMonths.includes(day.month);
    },

    isDhulHija(day) {
      return (
        day.month === "Dhū al-Ḥijjah" &&
        ["08", "09", "10", "11", "12"].includes(day.number)
      );
    },

    isEidAlAdha(day) {
      return day.month === "Dhū al-Ḥijjah" && ["10"].includes(day.number);
    },
    // getDayOfWeek(day, month, year) {
    //   const targetDate = new Date(year, month, day);
    //   const dayOfWeekIndex = targetDate.getDay();
    //   const weekdays = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
    //   const dayOfWeek = weekdays[dayOfWeekIndex];

    //   return dayOfWeek;
    // },

    // isWeekDay(day, month, year) {
    //   const dayOfWeek = this.getDayOfWeek(day, month, year);
    //   return dayOfWeek === "Lun" || dayOfWeek === "Jeu";
    // },
  },
};
</script>

<style scoped>
.popup-info-box {
  position: relative;
}

h2 {
  max-width: 79%;
}

.popup-info-box .top {
  position: sticky;
  top: -20px;
  width: 100%;
  background: var(--card);
  padding: 10px 0;
  margin-bottom: 10px;
}
.popup-info-box .bottom {
  position: sticky;
  bottom: -20px;
  width: 100%;
  background: var(--card);
  padding: 10px 0;
}

.popup-info-box p {
  text-align: justify;
}

.popup-info-box img {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 20px;
  width: 40px;
  height: 40px;
}

.popup-info-box div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
}

.citation {
  background: #fff8dd;
  border-left: 3px solid #ffee9c;
  padding: 10px;
  color: #000;
  font-style: italic;
  font-weight: 500;
}

.popup-info-box button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border: none;
  background: var(--text);
  color: var(--color);
  padding: 20px 30px;
  border-radius: 30px;
  line-height: 0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}
</style>
