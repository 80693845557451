<template>
  <div class="container">
    <div v-if="hasSubmitted">
      <div class="success-submit">
        <p class="emoji">👋😊</p>
        <p>
          Nous vous remercions pour votre prise de contact. Nous avons bien reçu
          votre demande et nous nous engageons à vous répondre dans les
          meilleurs délais. Nous vous contacterons très prochainement.
        </p>
        <svg
          id="Layer_2_00000046331051492253980580000002717726990971649980_"
          enable-background="new 0 0 512 512"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <linearGradient
            id="SVGID_1_"
            gradientTransform="matrix(1 0 0 -1 0 514.18)"
            gradientUnits="userSpaceOnUse"
            x1="239.575"
            x2="239.575"
            y1="10.26"
            y2="506.09"
          >
            <stop offset=".5" stop-color="#f56f00" />
            <stop offset="1" stop-color="#ffa514" />
          </linearGradient>
          <linearGradient
            id="SVGID_00000148645468016082575840000010248618282718368407_"
            gradientTransform="matrix(1 0 0 -1 0 514.18)"
            gradientUnits="userSpaceOnUse"
            x1="239.76"
            x2="239.76"
            y1="50.86"
            y2="449.08"
          >
            <stop offset=".3" stop-color="#f5d8b5" />
            <stop offset=".41" stop-color="#f8e4ca" />
            <stop offset=".54" stop-color="#fcefdf" />
            <stop offset=".67" stop-color="#fef6eb" />
            <stop offset=".8" stop-color="#fff9f0" />
          </linearGradient>
          <linearGradient id="lg1">
            <stop offset=".2" stop-color="#f27100" />
            <stop offset=".8" stop-color="#ffcc4a" />
          </linearGradient>
          <linearGradient
            id="SVGID_00000121244032655907086810000006982814109624355495_"
            gradientTransform="matrix(1 0 0 -1 0 514.18)"
            gradientUnits="userSpaceOnUse"
            x1="281.848"
            x2="540.693"
            xlink:href="#lg1"
            y1="-32.018"
            y2="226.828"
          />
          <linearGradient
            id="SVGID_00000035503449505629757090000013486996879136545409_"
            gradientTransform="matrix(-1 0 0 -1 511.36 514.18)"
            gradientUnits="userSpaceOnUse"
            x1="314.708"
            x2="573.553"
            xlink:href="#lg1"
            y1="-32.018"
            y2="226.828"
          />
          <linearGradient
            id="SVGID_00000105405817748900356370000013772421478867740595_"
            gradientTransform="matrix(1 0 0 -1 0 514.18)"
            gradientUnits="userSpaceOnUse"
            x1="239.575"
            x2="239.575"
            y1="10.3"
            y2="228.303"
          >
            <stop offset="0" stop-color="#fca800" />
            <stop offset="1" stop-color="#ffe736" />
          </linearGradient>
          <linearGradient
            id="SVGID_00000025437289982117218880000016061999324186908319_"
            gradientTransform="matrix(1 0 0 -1 0 514.18)"
            gradientUnits="userSpaceOnUse"
            x1="366.333"
            x2="487.007"
            y1="349.357"
            y2="228.683"
          >
            <stop offset="0" stop-color="#42c0ff" />
            <stop offset=".32" stop-color="#30adf1" />
            <stop offset=".98" stop-color="#017dd0" />
            <stop offset="1" stop-color="#007ccf" />
          </linearGradient>
          <g id="Icon">
            <g id="_83">
              <g>
                <path
                  d="m478.5 225.2v227.6c0 28.3-22.9 51.2-51.2 51.2h-375.5c-28.3 0-51.2-22.9-51.2-51.2v-227.6l202.8-202.2c20-19.9 52.3-19.9 72.3 0z"
                  fill="url(#SVGID_1_)"
                />
                <path
                  d="m91.9 65.1h295.8c28.3 0 51.2 22.9 51.2 51.2v295.8c0 28.3-22.9 51.2-51.2 51.2h-295.8c-28.3 0-51.2-22.9-51.2-51.2v-295.8c0-28.3 22.9-51.2 51.2-51.2z"
                  fill="url(#SVGID_00000148645468016082575840000010248618282718368407_)"
                />
                <path
                  d="m478.5 225.2v227.6c0 28.3-22.9 51.2-51.2 51.2h-187.7l-.3-39.5z"
                  fill="url(#SVGID_00000121244032655907086810000006982814109624355495_)"
                />
                <path
                  d="m0 225.2v227.6c0 28.3 22.9 51.2 51.2 51.2h187.7l.3-39.5z"
                  fill="url(#SVGID_00000035503449505629757090000013486996879136545409_)"
                />
                <path
                  d="m463.7 488.8c-8.1 8.1-18.9 13.6-30.9 14.9l-382.8.2c-13.5-.5-25.7-6.2-34.5-15.1l187.9-187.9c20-20 52.4-20 72.4 0z"
                  fill="url(#SVGID_00000105405817748900356370000013772421478867740595_)"
                />
              </g>
              <g id="Badge">
                <circle
                  cx="426.7"
                  cy="225.2"
                  fill="url(#SVGID_00000025437289982117218880000016061999324186908319_)"
                  r="85.3"
                />
                <path
                  d="m440.6 273.7h-22.6v-71.7l-22.1 6.5v-17.1l42.6-14.7h2.1z"
                  fill="#fff"
                />
              </g>
            </g>
          </g>
        </svg>
        <router-link to="/"><button>Revenir à l'accueil</button></router-link>
      </div>
    </div>
    <div v-else class="box-contact">
      <div>
        <h1>Contactez-nous !</h1>
        <Head>
          <title>Contactez-nous</title>
          <meta name="robots" content="Noindex, nofollow" />
          <link
            rel="icon"
            href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
          />
        </Head>
        <form @submit.prevent="submitForm" ref="form">
          <div class="form-group">
            <input
              type="text"
              id="name"
              placeholder="Nom"
              v-model="form.name"
              required
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              id="email"
              placeholder="Email"
              v-model="form.email"
              required
            />
          </div>
          <div class="form-group">
            <select id="subject" v-model="form.subject" required>
              <option style="display: none" value="">
                Selectionnez un sujet
              </option>
              <option value="Abonnement">
                Abonnement - Pour toute question liée à l'abonnement.
              </option>
              <option value="Bug">
                Signaler un bug - Aidez-nous à améliorer Doonun en signalant des
                bugs.
              </option>
              <option value="Partenariat">
                Demande de partenariat - Explorez les opportunités de
                partenariat.
              </option>
              <option value="Question">
                Question - Posez-nous vos questions.
              </option>
              <option value="Suggestion">
                Suggestion de fonctionnalité - Proposez de nouvelles idées.
              </option>
              <option value="Contribution">
                Contribuer à Doonun - Rejoignez notre équipe de contributeurs.
              </option>
            </select>
          </div>
          <div class="form-group">
            <textarea
              id="message"
              placeholder="Votre message"
              v-model="form.message"
              required
            ></textarea>
          </div>
          <button type="submit">Envoyer</button>
        </form>
      </div>
      <div class="img">
        <img
          src="https://cp.doonun.com/uploads/Fischier_2_4x_3bc0d649fb.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Head } from "@vueuse/head";

export default {
  name: "contact-d",
  components: {
    Head,
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },
      hasSubmitted: false,
    };
  },
  methods: {
    async submitForm() {
      if (this.$refs.form.checkValidity()) {
        try {
          await axios.post(
            "/contacts",
            {
              data: {
                Sujet: this.form.subject,
                Nom: this.form.name,
                Email: this.form.email,
                Message: this.form.message,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.VUE_APP_T}`,
              },
            }
          );
          this.hasSubmitted = true;
        } catch (error) {
          console.error("Erreur lors de la soumission de l'avis", error);
        }
      } else {
        console.error("Le formulaire n'est pas valide");
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
}

.box-contact {
  margin-top: 60px;
  max-width: 900px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;
}

.box-contact img {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

form {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form input,
form textarea,
form select {
  border: 1px solid var(--text);
  background: var(--card);
  color: var(--text);
  resize: none;
  border-radius: 6px;
  padding: 15px 10px;
  width: 100%;
  font-size: 16px;
}

form textarea {
  padding: 20px;
  min-height: 180px;
  font-weight: 400;
  font-size: 18px;
}

form button {
  background: var(--text);
  border: none;
  padding: 15px 20px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 6px;
  color: var(--color);
  cursor: pointer;
  transition: 0.2s;
}

form button:hover {
  background: var(--button);
  color: #fff;
}

.success-submit {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.success-submit p {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.success-submit .emoji {
  font-size: 30px;
}

.success-submit svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
}

.success-submit a {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.success-submit button {
  border: none;
  padding: 15px 30px;
  background: var(--text);
  color: var(--color);
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  transition: 0.2s;
}

.success-submit button:hover {
  background: var(--button);
  color: #fff;
}

@media (max-width: 480px) {
  .img {
    display: flex;
    justify-content: center;
  }
}
</style>
