export default {
  state: () => ({
    darkMode: localStorage.getItem("navbarDarkMode") === "true",
  }),
  mutations: {
    TOGGLE_DARK_MODE(state) {
      state.darkMode = !state.darkMode;
      localStorage.setItem("navbarDarkMode", state.darkMode.toString());
    },
  },
  actions: {
    toggleDarkMode({ commit }) {
      commit("TOGGLE_DARK_MODE");
    },
  },
  getters: {
    isDarkMode: (state) => state.darkMode,
  },
};
