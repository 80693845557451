<template>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="container-rank">
    <div class="container">
      <div class="top-rank">
        <!-- Placer le deuxième (index 1) à gauche -->
        <div v-if="usersRanking[1]" class="r-2">
          <div class="img-user">
            <img
              class="user-img"
              :src="usersRanking[1].avatar"
              :alt="usersRanking[1].username"
            />
          </div>
          <div></div>
          <div class="cont-num">
            <span class="number">#2</span>
          </div>
          <div class="c-name">
            <span class="name">{{ usersRanking[1].username }}</span>
          </div>

          <div class="links-user top-links">
            <a
              v-if="usersRanking[1].lien && usersRanking[1].lien.Twitter"
              :href="usersRanking[1].lien.Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a
              v-if="usersRanking[1].lien && usersRanking[1].lien.Instagram"
              :href="usersRanking[1].lien.Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              v-if="usersRanking[1].lien && usersRanking[1].lien.Tiktok"
              :href="usersRanking[1].lien.Tiktok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-tiktok"></i>
            </a>
            <a
              v-if="usersRanking[1].lien && usersRanking[1].lien.Lien"
              :href="usersRanking[1].lien.Lien"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-solid fa-link"></i>
            </a>
          </div>
          <div class="c-points">
            <span class="points">
              {{ formatPoints(usersRanking[1].points) }}
              <Blitz />
            </span>
          </div>
        </div>

        <!-- Placer le premier (index 0) au centre -->
        <div v-if="usersRanking[0]" class="r-1">
          <div class="img-user">
            <img
              class="user-img"
              :src="usersRanking[0].avatar"
              :alt="usersRanking[0].username"
            />
            <img src="../assets/img/crown.png" alt="" class="crown" />
          </div>
          <div class="cont-num">
            <span class="number">#1</span>
          </div>
          <div class="c-name">
            <span class="name">{{ usersRanking[0].username }}</span>
          </div>

          <div class="links-user top-links">
            <a
              v-if="usersRanking[0].lien && usersRanking[0].lien.Twitter"
              :href="usersRanking[0].lien.Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a
              v-if="usersRanking[0].lien && usersRanking[0].lien.Instagram"
              :href="usersRanking[0].lien.Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              v-if="usersRanking[0].lien && usersRanking[0].lien.Tiktok"
              :href="usersRanking[0].lien.Tiktok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-tiktok"></i>
            </a>
            <a
              v-if="usersRanking[0].lien && usersRanking[0].lien.Lien"
              :href="usersRanking[0].lien.Lien"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-solid fa-link"></i>
            </a>
          </div>
          <div class="c-points">
            <span class="points">
              {{ formatPoints(usersRanking[0].points) }}
              <Blitz />
            </span>
          </div>
        </div>

        <!-- Placer le troisième (index 2) à droite -->
        <div v-if="usersRanking[2]" class="r-3">
          <div class="img-user">
            <img
              class="user-img"
              :src="usersRanking[2].avatar"
              :alt="usersRanking[2].username"
            />
          </div>
          <div class="cont-num">
            <span class="number">#3</span>
          </div>
          <div class="c-name">
            <span class="name">{{ usersRanking[2].username }}</span>
          </div>

          <div class="links-user top-links">
            <a
              v-if="usersRanking[2].lien && usersRanking[2].lien.Twitter"
              :href="usersRanking[2].lien.Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a
              v-if="usersRanking[2].lien && usersRanking[2].lien.Instagram"
              :href="usersRanking[2].lien.Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a
              v-if="usersRanking[2].lien && usersRanking[2].lien.Tiktok"
              :href="usersRanking[2].lien.Tiktok"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-brands fa-tiktok"></i>
            </a>
            <a
              v-if="usersRanking[2].lien && usersRanking[2].lien.Lien"
              :href="usersRanking[2].lien.Lien"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa-solid fa-link"></i>
            </a>
          </div>
          <div class="c-points">
            <span class="points">
              {{ formatPoints(usersRanking[2].points) }}
              <Blitz />
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="usersRanking[0]"
      class="img-bg-rank"
      :style="{ backgroundImage: `url('${usersRanking[0].avatar}')` }"
    ></div>
  </div>
  <div class="container">
    <h1>TOP 100</h1>
    <div class="container-ranking">
      <!-- Boucle sur les utilisateurs à partir du 4ème pour les cartes standards -->
      <div
        class="cardRank"
        v-for="user in usersRanking.slice(3, 100)"
        :key="user.id"
      >
        <div class="infos-user">
          <div class="picture-user">
            <img :src="user.avatar" :alt="user.username" />
            <div class="rank-num">#{{ user.rank }}</div>
          </div>
          <div class="name-user">
            <p>{{ user.username }}</p>
            <div class="links-user">
              <a
                v-if="user.lien && user.lien.Twitter"
                :href="user.lien.Twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-twitter"></i>
              </a>
              <a
                v-if="user.lien && user.lien.Instagram"
                :href="user.lien.Instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-instagram"></i>
              </a>
              <a
                v-if="user.lien && user.lien.Tiktok"
                :href="user.lien.Tiktok"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-brands fa-tiktok"></i>
              </a>
              <a
                v-if="user.lien && user.lien.Lien"
                :href="user.lien.Lien"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="fa-solid fa-link"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="points-user">
          <p>{{ formatPoints(user.points) }}</p>
          <Blitz />
        </div>
      </div>
    </div>
  </div>
  <Tabs />
</template>

<script>
import { mapState, mapActions } from "vuex";
import Blitz from "../components/icons/Blitz.vue";
import { useHead, Head } from "@vueuse/head";
import Tabs from "../components/Tabs.vue";
export default {
  name: "ranking-d",
  setup() {
    useHead({
      title: "Classement - Doonun",
      meta: [
        {
          name: `description`,
          content:
            "Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins.",
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: "Classement - Doonun",
        },
        {
          property: "og:description",
          content:
            "Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins.",
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/ranking_c0c7b68144.png",
        },
        {
          property: "og:url",
          content: "https://www.doonun.com/classement",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "Classement - Doonun",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content:
            "Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins.",
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/ranking_c0c7b68144.png",
        },
      ],
    });
  },
  components: {
    Blitz,
    Head,
    Tabs,
  },
  computed: {
    ...mapState("user", ["usersRanking"]),
  },
  created() {
    this.fetchRanking();
  },
  methods: {
    ...mapActions("user", ["fetchRanking"]),
    formatPoints(points) {
      if (points >= 1000000) {
        return (points / 1000000).toFixed(1) + "M";
      } else if (points >= 1000) {
        return (points / 1000).toFixed(1) + "K";
      } else {
        return points.toString();
      }
    },
  },
};
</script>

<style scoped>
.container-rank {
  margin-top: -40px;
  position: relative;
}

.img-bg-rank {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(50px);
  opacity: 0.5;
  z-index: -1 !important;
}

.top-rank {
  padding-top: 120px;
  padding-bottom: 20px;
  max-width: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.r-2 .img-user,
.r-1 .img-user,
.r-3 .img-user {
  display: flex;
  justify-content: center;
  width: 150px;
  height: 150px;
  background: var(--card);
  border-radius: 60%;
  z-index: 1;
}

.r-2 .img-user img,
.r-1 .img-user .user-img,
.r-3 .img-user img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: 50%;
}

.r-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-top: -80px;
}

.r-2,
.r-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.r-1 .user-img {
  z-index: 2 !important;
}

.crown {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1 !important;
  margin-top: -40px;
  width: 70px;
  height: 70px;
  transform: rotate(20deg);
}

.cont-num {
  display: flex;
  justify-content: center;
}

.top-rank .number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 20px;
  width: 50px;
  height: 50px;
  background: var(--body);
  border-radius: 50%;
  margin-top: -40px;
  z-index: 5 !important;
}

.top-rank .c-name {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.top-rank .c-name span {
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.top-rank .c-points {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  margin-right: -10px;
  margin-top: -10px;
}

.top-rank .c-points span {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 700;
  font-size: 25px;
}

h1 {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 700;
}

.container-ranking {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  margin-top: 30px;
}

.cardRank {
  padding: 15px 15px;
  background: var(--card);
  border-radius: var(--br15);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.infos-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rank-num {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -8px;
  margin-top: -8px;
  background: var(--body);
  padding: 5px 6px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 800;
}

.rank-num span {
  font-size: 14px;
  font-weight: 700;
  line-height: 0;
}

.picture-user {
  display: flex;
  align-items: stretch;
  position: relative;
  width: 50px;
  height: 50px;
  background: var(--body);
  border-radius: var(--br10);
}

.picture-user img {
  object-fit: cover;
  object-position: center;
  width: 50px;
  height: 100%;
  border-radius: var(--br10);
}

.name-user {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 100%;
  justify-content: space-between;
  gap: 10px;
}

.name-user p {
  font-size: 18px;
  font-weight: 500;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1rem;
}

.points-user {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.points-user p {
  font-size: 23px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .top-rank {
    padding-top: 80px;
    font-size: 16px;
    text-align: center;
  }

  .r-2 .img-user,
  .r-1 .img-user,
  .r-3 .img-user {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  .r-1 {
    margin-top: -50px;
  }

  .crown {
    width: 60px;
    height: 60px;
    margin-top: -30px;
  }

  .top-rank .number {
    font-size: 16px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
  }

  .top-rank .c-name span,
  .top-rank .c-points span {
    font-size: 16px;
  }

  .name-user {
    flex: 0 0 0% !important;
  }

  .name-user p {
    width: 150px;
  }

  .points-user p {
    font-size: 18px;
  }
}

.links-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.links-user i {
  font-size: 16px;
}

.links-user a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.links-user a:hover i {
  color: var(--button);
}

.top-links {
  margin-top: -10px;
  margin-bottom: 10px;
}
</style>
