<template>
  <div class="container box-f-c">
    <div class="box-f" v-if="cours">
      <h1>
        Bravo ! <br />
        Vous avez terminé le cours
      </h1>
      <h2>{{ cours.titre }}</h2>
      <p class="m-f">
        Continuez votre apprentissage et gagnez des points pour débloquer
        d'autres cours 😉. <br /><span>+{{ this.points }} points</span>
      </p>
      <div class="Check-f">
        <CheckAnimation />
      </div>
      <router-link to="/cours">
        <div class="back-f">
          <p><i class="fa-solid fa-arrow-left-long"></i> Voir les cours</p>
        </div>
      </router-link>
      <Head>
        <title>Fin du cours</title>
        <meta name="robots" content="Noindex, nofollow" />
        <link
          rel="icon"
          href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
        />
      </Head>
    </div>
  </div>
  <canvas id="confetti" ref="confettiCanvas"> </canvas>
</template>

<script>
import CheckAnimation from "../components/icons/CheckAnimation.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import { Head } from "@vueuse/head";
export default {
  name: "fin-d",
  data() {
    return {
      W: 0,
      H: 0,
      maxConfettis: 25,
      particles: [],
      possibleColors: [
        "#ff7336",
        "#f9e038",
        "#02cca4",
        "#383082",
        "#fed3f5",
        "#b1245a",
        "#f2733f",
      ],
      animationFrameId: null,
      cours: null,
      token: localStorage.getItem("jwtToken"),
      user: null,
      slug: null,
      points: null,
      idCours: null,
      termine: null,
    };
  },
  components: {
    CheckAnimation,
    Head,
  },
  mounted() {
    this.initCanvas();
    this.initConfettis();
    this.startAnimation();
    window.addEventListener("resize", this.handleResize);
    this.checkAndUpdateProgress(); // Au lieu de fetchCoursFin directement
  },
  beforeUnmount() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getUserPoints", "getToken"]),
  },
  methods: {
    ...mapActions("user", [
      "fetchUserInfo",
      "updateUserPoints",
      "updateUserStatCourses",
    ]),

    async fetchCoursFin() {
      this.slug = this.$route.params.slug;
      try {
        const response = await axios.get("/courses", {
          params: {
            "filters[url][$eq]": this.slug,
            fields: ["titre", "points", "gain"],
          },
        });
        console.log(response);
        if (response.data.data.length > 0) {
          this.cours = response.data.data[0].attributes;
          this.points = this.cours.gain;
          // console.log(this.points);
        } else {
          // console.error("Aucun cours trouvé pour le slug:", this.slug);
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des Cours:", error);
      }
    },

    async fetchSuivis() {
      try {
        const existingProgressResponse = await axios.get("/suivis", {
          params: {
            "filters[user_id][$eq]": this.getUserInfo.id,
            "filters[slug][$eq]": this.slug,
          },
          headers: { Authorization: `Bearer ${this.getToken}` },
        });

        // Vérifiez d'abord si la réponse contient les données
        if (
          existingProgressResponse.data &&
          existingProgressResponse.data.data &&
          existingProgressResponse.data.data.length > 0
        ) {
          let progressData = existingProgressResponse.data.data[0].attributes;
          this.termine = progressData.termine;
          this.idCours = existingProgressResponse.data.data[0].id;
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération de la progression:", error);
      }
    },

    async updateSuivis() {
      try {
        // Assurez-vous que l'ID du suivi est disponible
        if (!this.idCours) {
          // console.error("ID du suivi non disponible.");
          return;
        }

        const updateData = {
          termine: String(1),
          pourcentage: String(100),
        };

        await axios.put(
          `/suivis/${this.idCours}`,
          { data: updateData },
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {
        // console.error("Erreur lors de la mise à jour du suivi:", error);
      }
    },

    // Ajoutez cette méthode dans vos méthodes
    async updateUserHassanates() {
      try {
        if (!this.getUserInfo || !this.getUserInfo.id) {
          // console.error("ID utilisateur non disponible.");
          return;
        }

        const existingHassanatesResponse = await axios.get("/hassanates", {
          params: {
            "filters[id_user][$eq]": this.getUserInfo.id,
          },
          headers: { Authorization: `Bearer ${this.getToken}` },
        });

        if (
          existingHassanatesResponse.data &&
          existingHassanatesResponse.data.data &&
          existingHassanatesResponse.data.data.length > 0
        ) {
          let existingHassanates =
            existingHassanatesResponse.data.data[0].attributes;
          let updatedPoints =
            Number(existingHassanates.points) + Number(this.points);

          // Utiliser Vuex pour mettre à jour les points
          await this.$store.dispatch("user/updateUserPoints", updatedPoints);
        } else {
          await axios.post(
            "/hassanates",
            {
              data: {
                id_user: String(this.getUserInfo.id),
                points: Number(this.points),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${this.getToken}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
      } catch (error) {
        // console.error("Erreur lors de la mise à jour des points hassanates:", error);
      }
    },

    async checkAndUpdateProgress() {
      await this.getUserInfo;
      if (this.getUserInfo && this.getUserInfo.id) {
        await this.fetchCoursFin();
        await this.fetchSuivis();

        if (this.termine === null) {
          await this.updateUserHassanates();
          // Mettez à jour termine pour refléter le changement
          this.termine = String(1);
          await this.updateSuivis();
          const cours = 1;
          await this.updateUserStatCourses(cours);
        } else {
          // console.log('Les points ont déjà été attribués');
          await this.updateSuivis();
        }
      } else {
        // console.error("L'ID de l'utilisateur n'est pas disponible.");
      }
    },

    initCanvas() {
      const canvas = this.$refs.confettiCanvas;
      this.W = window.innerWidth;
      this.H = canvas.clientHeight;
      canvas.width = this.W;
      canvas.height = this.H;
    },
    randomFromTo(from, to) {
      return Math.floor(Math.random() * (to - from + 1) + from);
    },
    createConfettiParticle() {
      const context = this.$refs.confettiCanvas.getContext("2d");
      return {
        x: Math.random() * this.W,
        y: Math.random() * this.H - this.H,
        r: this.randomFromTo(11, 33),
        d: Math.random() * this.maxConfettis + 11,
        color:
          this.possibleColors[
            Math.floor(Math.random() * this.possibleColors.length)
          ],
        tilt: Math.floor(Math.random() * 33) - 11,
        tiltAngleIncremental: Math.random() * 0.07 + 0.05,
        tiltAngle: 0,

        draw() {
          context.beginPath();
          context.lineWidth = this.r / 2;
          context.strokeStyle = this.color;
          context.moveTo(this.x + this.tilt + this.r / 3, this.y);
          context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);
          context.stroke();
        },
      };
    },
    initConfettis() {
      for (let i = 0; i < this.maxConfettis; i++) {
        this.particles.push(this.createConfettiParticle());
      }
    },
    draw() {
      const context = this.$refs.confettiCanvas.getContext("2d");
      context.clearRect(0, 0, this.W, this.H);

      for (let particle of this.particles) {
        particle.draw();
      }

      for (let i = 0; i < this.maxConfettis; i++) {
        let particle = this.particles[i];
        particle.tiltAngle += particle.tiltAngleIncremental;
        particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
        particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

        if (
          particle.x > this.W + 30 ||
          particle.x < -30 ||
          particle.y > this.H
        ) {
          this.particles[i] = this.createConfettiParticle();
        }
      }

      this.animationFrameId = requestAnimationFrame(this.draw);
    },
    startAnimation() {
      this.animationFrameId = requestAnimationFrame(this.draw);
    },
    handleResize() {
      this.initCanvas();
    },
  },
};
</script>

<style scoped>
#confetti {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  height: 100%;
  position: fixed;
  top: 0;
  pointer-events: none;
}

.box-f-c {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-f {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 80vh;
  padding: var(--padding);
  background: var(--card);
  border-radius: var(--br10);
}

.box-f h1,
.box-f h2 {
  margin: 0;
}

.Check-f {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Check-f img {
  width: 150px !important;
  width: 150px !important;
  object-fit: cover;
  object-position: center;
}

.m-f {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 18px;
}

.m-f span {
  font-weight: 700;
  color: orange;
}

.back-f {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  background: var(--black);
  padding: 10px 30px;
  color: var(--color);
  border-radius: 50px;
  transition: 0.2s;
}

.back-f i {
  transition: 0.2s;
}
.back-f:hover i {
  margin-right: 5px;
}

.back-f:hover {
  background: var(--button);
  color: var(--white);
}

.back-f p {
  display: inline;
  font-weight: 600;
}

@media (max-width: 480px) {
  .container {
    background: var(--card);
    padding: 0;
    margin-top: -40px;
  }

  .box-f {
    margin-top: 30px;
    padding-bottom: 400px;
  }
}
</style>
