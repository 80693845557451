<template>
    <div>
        <h1>Hadiths</h1>
    </div>
</template>

<script>
export default {
    name: 'hadith-f',
}
</script>

<style></style>