<template>
  <div class="container">
    <div class="coran">
      <h1>Coran</h1>
      <div class="coran-search">
        <form @submit.prevent>
          <input
            type="search"
            v-model="recherche"
            @input="filtrerSourates"
            placeholder="Rechercher par numéro ou nom..."
          />
        </form>
      </div>
      <div class="container-card">
        <Reprendre v-if="!recherche" />
        <Card
          v-for="sourate in souratesFiltrees"
          :key="sourate.id"
          :sourate="sourate.attributes"
          :taux="versetsCheck[sourate.attributes.Numero] || 0"
        />
      </div>
    </div>
  </div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <Tabs />
</template>

<script>
import axios from "axios";
import Card from "../components/Card.vue";
import { useHead, Head } from "@vueuse/head";
import Reprendre from "../components/Reprendre.vue";
import Tabs from "../components/Tabs.vue";
export default {
  name: "Coran-d",
  setup() {
    useHead({
      title: "Coran en ligne - Doonun",
      meta: [
        {
          name: `description`,
          content:
            "Lisez et écoutez les sourates du Coran en arabe et en français sur Doonun, avec transcription phonétique et Tafsir. Suivez votre progression et explorez différentes fonctionnalités.",
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: "Accueil - Doonun",
        },
        {
          property: "og:description",
          content:
            "Lisez et écoutez les sourates du Coran en arabe et en français sur Doonun, avec transcription phonétique et Tafsir. Suivez votre progression et explorez différentes fonctionnalités.",
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/CORAN_e6d55279b0.png",
        },
        {
          property: "og:url",
          content: "https://www.doonun.com/coran",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "Accueil - Doonun",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content:
            "Lisez et écoutez les sourates du Coran en arabe et en français sur Doonun, avec transcription phonétique et Tafsir. Suivez votre progression et explorez différentes fonctionnalités.",
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/CORAN_e6d55279b0.png",
        },
      ],
    });
  },
  components: {
    Card,
    Head,
    Reprendre,
    Tabs,
  },
  data() {
    return {
      sourates: [],
      recherche: "",
      user: null,
      versetsCheck: {},
      currentPage: 1,
      pageSize: 10,
      hasMorePages: true,
    };
  },
  computed: {
    souratesFiltrees() {
      const recherche = this.recherche.toLowerCase();
      return this.sourates.filter(
        (sourate) =>
          sourate.attributes.Numero.toString().includes(recherche) ||
          sourate.attributes.Titre.toLowerCase().includes(recherche) ||
          sourate.attributes.Arabe.toLowerCase().includes(recherche) ||
          sourate.attributes.Revelation.toLowerCase().includes(recherche) ||
          sourate.attributes.Phonetique.toLowerCase().includes(recherche)
      );
    },
  },
  async mounted() {
    await this.initializeData();
  },
  methods: {
    async initializeData() {
      try {
        this.user = await this.fetchUser();

        const cachedSourates = this.getSouratesFromLocalStorage();
        if (cachedSourates) {
          this.sourates = cachedSourates;
        } else {
          await this.fetchAllSourates();
          this.saveSouratesToLocalStorage(this.sourates);
        }

        if (this.user) {
          await this.fetchData();
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des informations:", error);
      }
    },
    async fetchUser() {
      try {
        const token = localStorage.getItem("jwtToken");
        if (token) {
          const { data } = await axios.get("users/me", {
            headers: { Authorization: `Bearer ${token}` },
          });
          return data;
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
      }
      return null;
    },
    async fetchPageOfSourates(page) {
      if (!this.hasMorePages) return;
      try {
        const {
          data: { data: fetchedSourates },
        } = await axios.get("/sourates", {
          params: {
            "pagination[page]": page,
            "pagination[pageSize]": this.pageSize,
            fields: [
              "Titre",
              "Revelation",
              "Phonetique",
              "Nombre",
              "Numero",
              "Arabe",
            ],
          },
        });
        this.sourates = [...this.sourates, ...fetchedSourates];
        this.hasMorePages = fetchedSourates.length === this.pageSize;
        this.currentPage++;
      } catch (error) {
        // console.error("Erreur lors de la récupération des sourates:", error);
      }
    },
    async fetchData() {
      if (!this.user) return;
      try {
        const {
          data: { data: items },
        } = await axios.get("/versets-checks", {
          params: {
            "filters[id_user][$eq]": this.user.id,
            fields: ["id_sourate", "taux"],
          },
        });
        this.versetsCheck = items.reduce((acc, item) => {
          acc[item.attributes.id_sourate] = item.attributes.taux;
          return acc;
        }, {});
      } catch (error) {
        // console.error(`Erreur lors de la récupération des données de taux d'avancement:`, error);
      }
    },
    async fetchAllSourates() {
      this.hasMorePages = true;
      this.currentPage = 1;
      this.sourates = [];
      while (this.hasMorePages) {
        await this.fetchPageOfSourates(this.currentPage);
      }
    },
    saveSouratesToLocalStorage(sourates) {
      localStorage.setItem("sourates", JSON.stringify(sourates));
    },
    getSouratesFromLocalStorage() {
      const sourates = localStorage.getItem("sourates");
      return sourates ? JSON.parse(sourates) : null;
    },
    updateTaux(sourateId, newTaux) {
      this.$set(this.versetsCheck, sourateId, newTaux);
    },
  },
};
</script>

<style>
.banner-quran {
  width: 100%;
  height: 120px;
  border-radius: var(--br10);
  object-fit: cover;
  object-position: center;
  margin-bottom: 40px;
}

.coran h1 {
  text-align: center;
  font-weight: 700;
  margin-bottom: 20px;
}

.container-card {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  align-items: stretch;
  justify-content: left;
  gap: 15px;
  margin-top: 20px;
}

.coran-search form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.coran-search form input {
  width: 100%;
  max-width: 600px;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text);
  font-family: "Poppins", "Inter", sans-serif;
}

.coran-search form input::placeholder {
  color: var(--placeholder);
}
</style>
