import axios from 'axios';

export default {
  namespaced: true,
  state: () => ({
    sourates: [],
    sourateDetails: {},
    userProgress: {},
  }),
  getters: {
    getSourates: state => state.sourates,
    getSourateDetails: state => state.sourateDetails,
    getUserProgress: state => (sourateId) => state.userProgress[sourateId] || 0,
    getTopFourSourates: state => state.sourates.slice(0, 4), // Ajout du getter pour les 4 premières sourates
  },
  mutations: {
    SET_SOURATES(state, sourates) {
      state.sourates = sourates;
    },
    SET_SOURATE_DETAILS(state, { sourateId, details }) {
      state.sourateDetails[sourateId] = details;
    },
    UPDATE_USER_PROGRESS(state, { sourateId, progress }) {
      state.userProgress[sourateId] = progress;
    },
  },
  actions: {
    async fetchSourates({ commit }) {
      try {
        const response = await axios.get('/sourates');
        commit('SET_SOURATES', response.data.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des sourates:", error);
      }
    },
    async fetchSourateDetails({ commit }, sourateId) {
      try {
        const likesResponse = await axios.get(`/like-sourates/${sourateId}`);
        const vuesResponse = await axios.get(`/vues-sourates/${sourateId}`);
        commit('SET_SOURATE_DETAILS', {
          sourateId,
          details: {
            likes: likesResponse.data.data.attributes.likes,
            vues: vuesResponse.data.data.attributes.vues,
          }
        });
      } catch (error) {
        error
        // console.error("Erreur lors de la récupération des détails de la sourate:", error);
      }
    },
    async updateUserProgress({ commit }, { sourateId, newProgress }) {
      try {
        await axios.put(`/user-progress/${sourateId}`, { progress: newProgress });
        commit('UPDATE_USER_PROGRESS', { sourateId, progress: newProgress });
      } catch (error) {
        error
        // console.error("Erreur lors de la mise à jour de la progression de la sourate:", error);
      }
    },
    async toggleLike({ commit, state }, { sourateId, userId }) {
      try {
        let currentLikes = state.sourateDetails[sourateId]?.likes || 0;
        let userLikes = state.sourateDetails[sourateId]?.userLikes || [];
        let updatedLikes;

        if (userLikes.includes(userId)) {
          // L'utilisateur a déjà aimé la sourate, retire son like
          updatedLikes = currentLikes - 1;
          userLikes = userLikes.filter(id => id !== userId);
        } else {
          // L'utilisateur n'a pas encore aimé la sourate, ajoute son like
          updatedLikes = currentLikes + 1;
          userLikes = [...userLikes, userId];
        }

        await axios.put(`/like-sourates/${sourateId}`, { likes: updatedLikes, userLikes: userLikes });

        commit('SET_SOURATE_DETAILS', {
          sourateId,
          details: {
            ...state.sourateDetails[sourateId],
            likes: updatedLikes,
            userLikes: userLikes,
          }
        });
      } catch (error) {
        error
        // console.error("Erreur lors de la mise à jour des likes de la sourate:", error);
      }
    },
    async updateVues({ commit, state }, sourateId) {
      try {
        const currentVues = state.sourateDetails[sourateId]?.vues || 0;
        const updatedVues = currentVues + 1;
        // Exemple d'appel API pour mettre à jour les vues
        await axios.put(`/vues-sourates/${sourateId}`, { vues: updatedVues });

        // Mise à jour du store
        commit('SET_SOURATE_DETAILS', {
          sourateId,
          details: {
            ...state.sourateDetails[sourateId],
            vues: updatedVues,
          }
        });
      } catch (error) {
        error
        // console.error("Erreur lors de la mise à jour des vues de la sourate:", error);
      }
    },
  }
};
