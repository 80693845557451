<template>
  <span class="conts" v-if="lastVerset > 0">
    <div class="box-loding" v-if="isLoading">
      <LoadCircle />
    </div>
    <router-link v-else :to="`/sourate/${lastVerseNumber}#${lastVerset}`">
      <div class="verse-resume">
        <span class="pr">
          <progressCircle :taux="lastVerseProgress" />
        </span>
        <h4>{{ lastVerset }}</h4>
        <h4>{{ lastVerseSourate }}</h4>
        <p>{{ lastVerseText }}</p>
        <div class="reprendre">Reprendre la lecture</div>
      </div>
    </router-link>
  </span>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import progressCircle from "../components/icons/progressCircle.vue";
import LoadCircle from "../components/loadCircle.vue";
// import Loading from "./Loading.vue";
export default {
  name: "reprendre-sourate",
  components: {
    progressCircle,
    LoadCircle,
    // Loading,
  },
  data() {
    return {
      user: null,
      token: localStorage.getItem("jwtToken"),
      lastVerse: 0, // Corrigez le type de lastVerse
      lastVerseProgress: 0,
      lastVerseNumber: "",
      lastVerset: "",
      lastVerseSourate: "",
      lastVerseText: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),

    async fetchCheckedVersets() {
      this.isLoading = true;
      if (this.getUserInfo && this.getUserInfo.id) {
        const userId = this.getUserInfo.id;
        try {
          const response = await axios.get(
            `/versets-checks?filters[id_user][$eq]=${userId}&populate=*`,
            {
              headers: {
                Authorization: `Bearer ${this.getToken}`,
              },
            }
          );

          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const lastChecked =
              response.data.data[response.data.data.length - 1];
            this.lastVerseProgress = lastChecked.attributes.taux;
            this.lastVerseNumber = lastChecked.attributes.id_sourate;
            const versetList = lastChecked.attributes.list_verset
              .split("|")
              .map(Number); // Convertir en nombres
            versetList.sort((a, b) => a - b); // Trier en ordre croissant
            this.lastVerset = versetList[versetList.length - 1]; // Obtenir le dernier élément

            // Faire une requête supplémentaire pour les informations de la sourate
            const sourateResponse = await axios.get(
              `/sourates/${this.lastVerseNumber}`,
              {
                params: {
                  populate: ["Phonetique", `versets.numero.${this.lastVerset}`],
                },
              }
            );
            if (sourateResponse.data && sourateResponse.data.data) {
              const sourateData = sourateResponse.data.data;
              this.lastVerseSourate = sourateData.attributes.Phonetique;

              const versetData = sourateData.attributes.versets.data.find(
                (v) => v.attributes.numero === parseInt(this.lastVerset)
              );
              this.lastVerseText = versetData
                ? versetData.attributes.fr
                : "Texte non trouvé";
            }
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations:",
            error
          );
        } finally {
          this.isLoading = false;
        }
      } else {
        console.error("Utilisateur non défini");
      } // Assurez-vous que l'utilisateur est connecté
    },
  },
  created() {
    // Appeler la méthode fetchCheckedVersets dans le hook created
    this.fetchCheckedVersets();
  },
};
</script>

<style scoped>
.conts {
  display: inline;
  width: 100%;
  min-width: 235px;
  min-height: 180px;
  background: var(--card);
  border-radius: 10px;
}
.verse-resume {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px 0;
  padding: var(--padding);
  border-radius: var(--br10);
  background: var(--card);
  transition: 0.2s;
  height: 100%;
}

.box-loding {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--br10);
  width: 100%;
  min-width: 235px;
  min-height: 180px;
  height: 100%;
  background: var(--card);
}

.reprendre {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  color: var(--color);
  background: var(--text);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  transition: 0.2s ease-in-out;
}

.reprendre:hover {
  background: var(--button);
  color: #fff;
}

.verse-resume p {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.resume:hover {
  background: var(--button);
}

.pr {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 20px;
  margin-right: 20px;
}

.pr svg {
  width: 50px !important;
  height: 50px !important;
}
</style>
