<template>
  <div>
    <div class="a-container">
      <div class="a" @click="decreaseFontSize">A-</div>
      <input
        type="range"
        v-model="fontSizeFactor"
        min="1"
        max="2"
        step="0.1"
        @input="updateFontSizes"
      />
      <div class="a" @click="increaseFontSize">A+</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "font-size",
  data() {
    return {
      fontSizeFactor: 1,
      baseFontSizes: {},
    };
  },
  methods: {
    updateFontSizes() {
      const maxFontSize = 30;
      const classes = [
        ".taj",
        ".french",
        ".word-arabic",
        ".arabe",
        ".phonetic",
        ".tafsir",
        ".taf-span",
        ".num-v",
      ];

      classes.forEach((classe) => {
        const elements = document.querySelectorAll(classe);

        for (let i = 0; i < elements.length; i += 1) {
          const baseFontSize =
            this.baseFontSizes[classe] ||
            parseFloat(
              window
                .getComputedStyle(elements[i], null)
                .getPropertyValue("font-size")
            );

          this.baseFontSizes[classe] = baseFontSize;

          const newFontSize =
            baseFontSize +
            (this.fontSizeFactor - 1) * (maxFontSize - baseFontSize);

          elements[i].style.fontSize = `${Math.min(
            newFontSize,
            maxFontSize
          )}px`;
        }
      });
    },
    increaseFontSize() {
      this.fontSizeFactor += 0.1;
      if (this.fontSizeFactor > 2) {
        this.fontSizeFactor = 2;
      }
      this.updateFontSizes();
    },
    decreaseFontSize() {
      this.fontSizeFactor -= 0.1;
      if (this.fontSizeFactor < 1) {
        this.fontSizeFactor = 1;
      }
      this.updateFontSizes();
    },
  },
  mounted() {
    const savedFontSizeFactor = localStorage.getItem("fontSizeFactor");
    if (savedFontSizeFactor) {
      this.fontSizeFactor = parseFloat(savedFontSizeFactor);
      this.updateFontSizes();
    } else {
      this.updateFontSizes();
    }
  },
};
</script>

<style scoped>
.a-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background: var(--card);
  border-radius: 10px;
  margin-bottom: 5px;
  font-weight: 600;
  gap: 20px;
}
.a {
  cursor: pointer;
}

input[type="range"] {
  display: inline-block;
  margin-bottom: auto;
  margin-top: auto;
  width: 100%;
  height: 2px;
  outline: none;
  border-radius: 10px;
}
</style>
