<template>
    <div class="back-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_arrow-left" data-name="vuesax/linear/arrow-left" transform="translate(-684 -188)">
                <g id="arrow-left">
                    <path id="Vector" d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none"
                        stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-2" data-name="Vector" d="M16.83,0H0" transform="translate(687.67 200)" fill="none"
                        stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                    <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(708 212) rotate(180)"
                        fill="none" opacity="0" />
                </g>
            </g>
        </svg>

    </div>
</template>
<script>
export default {
    name: 'back-d'
}

</script>

<style>
.back-icon {
    display: flex;
    align-items: center;
}

.back-icon svg path {
    width: 20px;
    height: 20px;
    stroke: var(--text) !important;
}
</style>