<template>
  <div class="boites">
    <div class="container-app">
      <div class="left">
        <div class="size-box-v">
          <h1>Rechercher dans le coran</h1>
          <div class="loading" v-if="isLoading">
            <loadCircle />
          </div>
          <div v-else>
            <p class="resultats">{{ filteredVerse.length }} Résultats</p>
            <div class="input-search-v">
              <div class="course-search">
                <form @submit.prevent="fetchFilteredVerse">
                  <input
                    type="search"
                    placeholder="Rechercher un verset..."
                    v-model="recherche"
                  />
                </form>
              </div>
            </div>
            <div class="list-result-v">
              <!-- Affichez le message si filteredVerse est vide -->
              <div v-if="filteredVerse.length === 0">
                <p style="text-align: center">Aucun résultat trouvé.</p>
              </div>
              <!-- Sinon, affichez les versets -->
              <versetCard
                v-else
                v-for="verse in paginatedVerseList"
                :key="verse.id"
                :phonetique="verse.Titre"
                :sourate="verse.Numero"
                :numero="verse.numero"
                :trad="verse.Trad"
                :french="verse.Traduction"
                :arabe="verse.Arabe"
                :search-term="recherche"
              />
            </div>
          </div>
          <div class="pag">
            <Paginations
              :total-items="filteredVerse.length"
              :items-per-page="itemsPerPage"
              :current-page="currentPage"
              @page-changed="handlePageChange"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <TimePray />
        <ThreeDays />
        <RankingList />
      </div>
    </div>
  </div>
  <div class="container"></div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <Tabs />
</template>

<script>
import axios from "axios";
import versetCard from "../components/versetCard.vue";
import Paginations from "../components/Paginations.vue";
import loadCircle from "../components/loadCircle.vue";
import { useHead, Head } from "@vueuse/head";
import Tabs from "../components/Tabs.vue";
import ThreeDays from "../components/ThreeDays.vue";
import TimePray from "../components/TimePray.vue";
import RankingList from "../components/RankingList.vue";

export default {
  name: "verset-d",
  setup() {
    useHead({
      title: "Rechercher dans le Coran",
      meta: [
        {
          name: `description`,
          content:
            "Trouvez facilement les versets et les sourates que vous cherchez dans le Coran avec notre moteur de recherche avancé. Recherchez dans le Coran en utilisant des mots-clés, des numéros de sourate ou de verset, ou même des phrases complètes. Notre moteur de recherche puissant et précis vous aidera à trouver exactement ce que vous cherchez en un rien de temps. Découvrez également des fonctionnalités de filtrage avancées pour affiner votre recherche et trouver les résultats les plus pertinents. Essayez notre moteur de recherche dès maintenant pour explorer le Coran comme jamais auparavant.",
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: "Recherche - Doonun",
        },
        {
          property: "og:description",
          content:
            "Trouvez rapidement les versets et sourates du Coran avec notre moteur de recherche avancé sur Doonun. Utilisez des mots-clés, des numéros de sourate ou de verset, et même des phrases complètes. Affinez votre recherche avec nos filtres avancés pour des résultats précis. Explorez le Coran comme jamais auparavant en essayant notre moteur de recherche dès maintenant.",
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/versets_b4704a977d.png",
        },
        {
          property: "og:url",
          content: "https://www.doonun.com/versets",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "Recherche - Doonun",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content:
            "Trouvez rapidement les versets et sourates du Coran avec notre moteur de recherche avancé sur Doonun. Utilisez des mots-clés, des numéros de sourate ou de verset, et même des phrases complètes. Affinez votre recherche avec nos filtres avancés pour des résultats précis. Explorez le Coran comme jamais auparavant en essayant notre moteur de recherche dès maintenant.",
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/versets_b4704a977d.png",
        },
      ],
    });
  },
  components: {
    versetCard,
    Paginations,
    loadCircle,
    Head,
    Tabs,
    ThreeDays,
    TimePray,
    RankingList,
  },
  data() {
    return {
      recherche: "",
      currentPage: 1,
      itemsPerPage: 200,
      verseList: [],
      isLoading: false,
    };
  },
  computed: {
    filteredVerse() {
      const searchString = this.recherche.toLowerCase();
      return this.verseList.filter((verse) => {
        return (
          verse.Traduction.toLowerCase().includes(searchString) ||
          verse.Arabe.toLowerCase().includes(searchString)
        );
      });
    },
    paginatedVerseList() {
      let start = (this.currentPage - 1) * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      return this.filteredVerse.slice(start, end);
    },
  },
  methods: {
    async fetchFilteredVerse() {
      try {
        this.isLoading = true; // Définissez isLoading sur true
        const cachedData = localStorage.getItem("cachedVerseList");
        if (cachedData) {
          this.verseList = JSON.parse(cachedData);
        } else {
          // Faire la requête réseau
          const response = await axios.get("/sourates?populate=*");
          // Mettez à jour 'verseList' avec les données de la réponse
          this.verseList = response.data.data.flatMap((sourate) => {
            const sourateAttributes = sourate.attributes;
            if (
              sourateAttributes.versets &&
              Array.isArray(sourateAttributes.versets.data)
            ) {
              return sourateAttributes.versets.data.map((verset) => {
                return {
                  id: verset.id,
                  Numero: sourateAttributes.Numero,
                  Titre: sourateAttributes.Phonetique,
                  Trad: sourateAttributes.Titre,
                  Arabe: verset.attributes.arabe,
                  Phonetique: verset.attributes.phonetique,
                  Traduction: verset.attributes.fr,
                  numero: verset.attributes.numero,
                };
              });
            } else {
              return [];
            }
          });
          localStorage.setItem(
            "cachedVerseList",
            JSON.stringify(this.verseList)
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des versets:", error);
      } finally {
        this.isLoading = false; // Définissez isLoading sur false
      }
    },

    handlePageChange(newPage) {
      this.currentPage = newPage;
    },
  },
  mounted() {
    this.fetchFilteredVerse();
  },
};
</script>

<style scoped>
.boites {
  max-width: 1400px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.container-app {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 10px;
  margin-bottom: 100px;
}

.container-app .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  flex: 1 1 70%;
  background: var(--card);
  border-radius: 10px;
  padding: 20px;
}

.container-app .right {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  gap: 10px;
  width: 25%;
}

@media (max-width: 1400px) {
  .container-app {
    padding: 0 20px;
  }
}

@media (max-width: 1200px) {
  .container-app .left {
    width: 65%;
  }
  .container-app .right {
    width: 30%;
  }
}

@media (max-width: 992px) {
  .container-app .left,
  .container-app .right {
    width: 100%;
  }

  .container-app .left {
    border-radius: 0 0 10px 10px;
  }
}

@media (max-width: 576px) {
  .container-app {
    flex-direction: column;
    padding: 0;
    margin-top: -40px;
  }

  .container-app .right {
    padding: 0 20px;
  }
}
.container {
  padding-bottom: 30px;
}

h1 {
  font-size: 25px;
  margin-bottom: 15px;
  text-align: center;
  margin-top: 30px;
}

.loading {
  display: flex;
  justify-content: center;
}

.size-box-v {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1100px;
  width: 100%;
}

.resultats {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.course-search form {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.course-search form input {
  width: 100%;
  max-width: 600px;
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid var(--placeholder);
  font-weight: 400;
  font-size: 14px;
  color: var(--text);
  font-family: "Poppins", "Inter", sans-serif;
}

.course-search form input::placeholder {
  color: var(--placeholder);
}

.pag {
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  .container {
    margin-top: -40px;
    padding: 40px 0 150px 0;
    border-radius: 0 0 20px 20px;
  }

  form {
    padding: 0;
  }
}
</style>
