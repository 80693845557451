<template>
  <div class="box-confirm">
    <svg
      id="Capa_1"
      enable-background="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                d="m351.097 158.708h-257.146v76.64l97.632 86.575c18.789-12.213 43.085-12.215 61.876-.008l97.637-86.578v-76.629z"
                fill="#a4fcc4"
              />
            </g>
          </g>
          <path
            d="m418.876 153.739-153.955-126.285c-24.567-20.153-60.228-20.15-84.791.004l-153.874 126.245c-15.775 6.818-26.256 22.659-26.256 39.952v244.385c0 23.982 19.489 43.493 43.444 43.493h358.159c5.522 0 10-4.477 10-10s-4.478-10-10-10h-358.159c-2.035 0-4.01-.261-5.894-.752l155.266-127.387c17.215-14.123 42.205-14.125 59.422-.003l118.175 96.937c4.269 3.503 10.571 2.88 14.073-1.389 3.503-4.27 2.881-10.571-1.39-14.074l-113.951-93.471 154.48-135.744c.924 2.529 1.423 5.233 1.423 8.006v183.956c0 5.523 4.478 10 10 10s10-4.477 10-10v-183.957c.001-17.252-10.448-33.08-26.172-39.916zm-398.876 284.301v-244.385c0-2.77.497-5.471 1.418-7.996l154.482 135.744-153.855 126.23c-1.314-2.931-2.045-6.178-2.045-9.593zm172.887-128.334-157.301-138.221c.787-.359 1.525-.818 2.195-1.368l155.034-127.197c17.216-14.124 42.206-14.124 59.422-.003l155.105 127.229c.647.531 1.357.978 2.114 1.331l-157.301 138.222c-18.62-9.179-40.651-9.176-59.268.007z"
            fill="#020288"
          />
        </g>
        <g>
          <ellipse
            cx="435.049"
            cy="422.596"
            fill="#a4fcc4"
            rx="76.951"
            ry="77.062"
          />
          <g>
            <path
              d="m433.034 445.195c-2.696 0-5.288-1.09-7.178-3.038l-13.284-13.696c-3.845-3.964-3.748-10.295.217-14.141 3.964-3.845 10.294-3.749 14.141.216l5.165 5.326 10.276-13.587c3.331-4.405 9.604-5.275 14.008-1.943 4.405 3.332 5.275 9.603 1.943 14.008l-17.312 22.888c-1.761 2.328-4.45 3.771-7.363 3.949-.204.011-.409.018-.613.018z"
              fill="#020288"
            />
          </g>
        </g>
      </g>
    </svg>
    <h1>Votre email a bien été confirmer !</h1>
    <router-link to="/connexion">
      <p>Veuillez vous connecter</p>
    </router-link>
    <Head>
      <title>Confirmation</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
  </div>
</template>

<script>
import { Head } from "@vueuse/head";
export default {
  name: "confim-d",
  components: {
    Head,
  },
};
</script>

<style scoped>
.box-confirm {
  margin-top: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  height: 100vh;
  background-color: var(--card);
  padding: 20px;
  border-radius: 0 0 30px 30px;
}

.box-confirm svg {
  margin-top: -100px;
  width: 100px;
  height: 100px;
}

.box-confirm svg path {
  fill: var(--text);
}

.box-confirm a {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background: var(--button);
  border-radius: 8px;
}

.box-confirm a:hover {
  background: #000;
}

.box-confirm a p {
  display: inline;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
</style>
