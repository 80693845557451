<template>
  <div class="container">
    <div class="back">
      <router-link :to="`/duaas/${this.$route.params.slug}`">
        <Back />
      </router-link>
      <router-link :to="`/duaas/${this.$route.params.slug}`">
        <p>Revenir</p>
      </router-link>
    </div>
    <div class="boites">
      <div class="container-app" v-if="selectedDuaa">
        <div class="left">
          <div class="top-d">
            <h1>{{ selectedDuaa.name }}</h1>
            <Share :textToShare="selectedDuaa.name" />
          </div>
          <div
            class="box-list-duaas"
            v-if="selectedDuaa.duaas && selectedDuaa.duaas.length"
          >
            <div
              class="card-dua"
              v-for="duaa in selectedDuaa.duaas"
              :key="duaa.id"
            >
              <p class="arabe" v-if="duaa.arab">{{ duaa.arab }}</p>
              <p class="transcript" v-if="duaa.transcript">
                {{ duaa.transcript }}
              </p>
              <p class="translite" v-if="duaa.translate">
                {{ duaa.translate }}
              </p>
              <div class="source" v-if="duaa.source">
                <p class="ref">{{ duaa.source }}</p>
              </div>
              <div class="c-player" v-if="duaa.urlMp3">
                <Player :file="duaa.urlMp3" />
              </div>
            </div>
          </div>
          <!-- <div class="nothing" v-else>
          <p>Aucun dua associé à ce titre n'a été trouvé.</p>
        </div>
        <div class="nothing" v-else>
          <p>Le titre n'a pas été trouvé ou est en cours de chargement.</p>
        </div> -->
        </div>
        <div class="right">
          <TimePray />
          <ThreeDays />
          <RankingList />
        </div>
      </div>
    </div>
  </div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
</template>

<script>
import Player from "../components/Player.vue";
import Back from "../components/icons/Back.vue";
import Share from "../components/Share.vue";
import { mapGetters } from "vuex";
import { computed } from "vue";
import { useHead, Head } from "@vueuse/head";
import store from "../store";
import ThreeDays from "../components/ThreeDays.vue";
import TimePray from "../components/TimePray.vue";
import RankingList from "../components/RankingList.vue";

export default {
  name: "titre-d",
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("fetchTitreDuaaBySlug", to.params.subSlug)
      .then(() => {
        next();
      })
      .catch(() => {
        next("/duaas");
      });
  },
  setup() {
    const selectedDuaa = computed(() => store.getters["duaaDetails"]);

    useHead({
      title: selectedDuaa.value ? selectedDuaa.value.name : "",
      meta: [
        {
          name: `description`,
          content: selectedDuaa.value.duaas[0].translate,
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: selectedDuaa.value ? selectedDuaa.value.name : "",
        },
        {
          property: "og:description",
          content: selectedDuaa.value.duaas[0].translate,
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/duaas_73117ce2be.png",
        },
        {
          property: "og:url",
          content: `https://www.doonun.com/duaas/${
            selectedDuaa.value ? selectedDuaa.value.slug : ""
          }`,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: selectedDuaa.value ? selectedDuaa.value.name : "",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content: selectedDuaa.value.duaas[0].translate,
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/duaas_73117ce2be.png",
        },
      ],
    });

    return {
      selectedDuaa,
    };
  },
  components: {
    Player,
    Back,
    Share,
    Head,
    ThreeDays,
    TimePray,
    RankingList,
  },
  data() {
    return {
      isLooping: false,
    };
  },
  computed: {
    // Utilisez mapGetters pour récupérer les données du store
    ...mapGetters({
      selectedDuaa: "duaaDetails",
    }),
  },
  created() {
    this.$store.dispatch("fetchTitreDuaaBySlug", this.$route.params.subSlug);
  },
};
</script>

<style scoped>
.boites {
  max-width: 1400px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
}

.container-app {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 10px;
  margin-bottom: 100px;
}

.container-app .left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70%;
  flex: 1 1 70%;
  background: var(--card);
  border-radius: 10px;
  padding: 20px;
}

.container-app .right {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  gap: 10px;
  width: 25%;
}

.container {
  padding: 0;
}

@media (max-width: 1400px) {
  .container-app,
  .back {
    padding: 0 20px;
  }

  .container {
    max-width: 1400px;
  }
}

@media (max-width: 1200px) {
  .container-app .left {
    width: 65%;
  }
  .container-app .right {
    width: 30%;
  }
}

@media (max-width: 992px) {
  .container-app .left,
  .container-app .right {
    width: 100%;
  }

  .container-app .left {
    border-radius: 0 0 10px 10px;
  }

  .container {
    padding-top: 40px;
    margin-top: -40px;
    background: var(--card);
  }
}

@media (max-width: 576px) {
  .container-app {
    flex-direction: column;
    padding: 0;
  }

  .container-app .right {
    padding: 0 20px;
  }
}
.top-d {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -10px;
  padding: 40px 40px 0 40px;
  max-width: 1100px;
  background: var(--card);
  border-radius: 10px;
}

h1 {
  text-align: left;
  margin-bottom: 30px;
}

.box-list-duaas {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card-dua {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 40px 40px;
  margin-bottom: 10px;
  background: var(--card);
  border-radius: var(--br10);
}

.source {
  padding: 10px 15px;
  background: #fdf6ef;
  border-radius: 10px;
  /* border: 1px solid #a784ff; */
}

.source p {
  color: #000;
  font-weight: 500;
}

.transcript,
.translite {
  font-size: 18px;
}

.translite {
  margin-bottom: 20px;
}

.arabe {
  font-size: 30px;
  text-align: right;
  font-family: "uthmanic", Courier, monospace;
}

.nothing {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.nothing p {
  text-align: center;
}

.card-dua .c-player {
  display: block;
  margin-right: auto;
  width: 100%;
}

.back {
  max-width: 1400px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
}

.back p {
  display: inline-block;
}

@media (max-width: 480px) {
  .container {
    padding-top: 40px;
    margin-top: -40px;
    background: var(--card);
  }
  .card-dua,
  .top-d {
    padding: 20px 0px !important;
  }

  .card-dua {
    gap: 20px;
  }

  h1 {
    font-size: 1.5rem;
    text-align: center;
  }

  .arabe {
    text-align: center;
  }
}
</style>
