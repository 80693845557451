<template class="box-app">
  <Navbar @toggle-dark-mode="darkMode = $event" />
  <router-view> </router-view>
  <Up />
  <PopupTarifs />
  <Footer></Footer>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Footer from "./components/Footer.vue";
import PopupTarifs from "./components/PopupTarifs.vue";
import Up from "./components/Up.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    PopupTarifs,
    Up,
  },
  data() {
    return {
      darkMode: false,
      deferredPrompt: null,
      showInstallButton: false,
    };
  },

  watch: {
    darkMode(newVal) {
      if (newVal) {
        document.body.setAttribute("data-theme", "dark");
        localStorage.setItem("darkMode", "true");
      } else {
        document.body.removeAttribute("data-theme");
        localStorage.setItem("darkMode", "false");
      }
    },
  },

  methods: {
    installApp() {
      // Afficher l'invite d'installation lorsque l'utilisateur clique sur le bouton
      if (this.deferredPrompt) {
        // Installer automatiquement sans confirmation de l'utilisateur
        this.deferredPrompt.prompt();
        // Réinitialiser deferredPrompt après l'installation
        this.deferredPrompt = null;
        // Masquer le bouton d'installation après l'installation
        this.showInstallButton = false;
      }
    },
  },

  mounted() {
    const storedDarkMode = localStorage.getItem("navbarDarkMode");
    this.darkMode = storedDarkMode !== null ? storedDarkMode === "true" : false;
    window.addEventListener("beforeinstallprompt", (event) => {
      // Empêcher l'affichage de la fenêtre d'installation automatique
      event.preventDefault();
      // Stocker l'événement pour l'utiliser plus tard
      this.deferredPrompt = event;
      // Afficher le bouton d'installation
      this.showInstallButton = true;
    });
  },
};
</script>

<style scoped>
.box-app {
  position: relative;
}
</style>
