<template>
  <div class="card-info" ref="cardContainer">
    <img :src="`${img}`" alt="" />
    <div class="box-card-c">
      <div class="top">
        <span>{{ category }}</span>
      </div>
      <div class="b">
        <p class="titre">{{ titre }}</p>
        <div class="bottom">
          <div class="level-cat">
            <span><i class="fa-solid fa-chart-simple"></i> {{ level }}</span>
          </div>
          <div class="btn">
            <progressCircle :taux="percent" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import progressCircle from "./icons/progressCircle.vue";
export default {
  name: "card-profil-course",
  props: {
    titre: String,
    points: Number,
    img: String,
    level: String,
    category: String,
    slug: String,
    percent: String,
  },
  components: {
    progressCircle,
  },
};
</script>

<style scoped>
.card-info {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  min-height: 250px;
  min-width: 235px;
}

@media (max-width: 576px) {
  .card-info {
    min-width: 280px;
  }
}

.card-info img {
  width: 100%;
  min-height: 160px;
  max-height: 160px;
  object-fit: cover;
  object-position: cover;
  border-radius: 10px;
}

.box-card-c {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  justify-content: space-between;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.top span {
  font-size: 12px;
}

.titre {
  font-size: 16px;
  font-weight: 500;
  text-align: left !important;
}

.level-cat span {
  font-size: 14px;
  line-height: 0;
}

.b {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 5px;
}
</style>
