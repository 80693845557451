<template>
  <div class="pray-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bold_note"
        data-name="vuesax/bold/note"
        transform="translate(-172 -316)"
      >
        <g id="note">
          <path
            id="Vector"
            d="M.75,4.29A.749.749,0,0,1,0,3.54V.75a.75.75,0,0,1,1.5,0V3.53A.751.751,0,0,1,.75,4.29Z"
            transform="translate(179.54 318)"
            fill="#292d32"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M.75,4.29A.749.749,0,0,1,0,3.54V.75a.75.75,0,1,1,1.5,0V3.53A.751.751,0,0,1,.75,4.29Z"
            transform="translate(186.96 318)"
            fill="#292d32"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M17.07,1a1.01,1.01,0,0,0-1.61.81v.1a2.343,2.343,0,0,1-2.01,2.37,2.253,2.253,0,0,1-2.49-2.24V1a1,1,0,0,0-1-1H9.04a1,1,0,0,0-1,1V2.04A2.219,2.219,0,0,1,7.01,3.92a2.694,2.694,0,0,1-.29.16,1.343,1.343,0,0,1-.3.12,1.587,1.587,0,0,1-.39.08,1.927,1.927,0,0,1-.48,0,1.587,1.587,0,0,1-.39-.08,1.877,1.877,0,0,1-.3-.12,2.694,2.694,0,0,1-.29-.16A2.467,2.467,0,0,1,3.54,1.91v-.1A1.015,1.015,0,0,0,2.07.91c-.01.01-.02.01-.03.02A.673.673,0,0,0,1.93,1c-.03.03-.07.05-.1.08a4.318,4.318,0,0,0-.74.74,2.474,2.474,0,0,0-.28.38.138.138,0,0,0-.03.04,3.5,3.5,0,0,0-.24.42c-.02.02-.03.03-.03.05a2.43,2.43,0,0,0-.16.37.843.843,0,0,0-.06.14,3.88,3.88,0,0,0-.14.45,3.117,3.117,0,0,0-.09.44c-.02.11-.03.22-.04.34-.01.14-.02.28-.02.42v8.76A4.87,4.87,0,0,0,4.87,18.5h9.26A4.87,4.87,0,0,0,19,13.63V4.87A4.839,4.839,0,0,0,17.07,1ZM9.5,13.92H4.86a.755.755,0,0,1,0-1.51H9.5a.755.755,0,0,1,0,1.51Zm2.78-3.71H4.86a.755.755,0,0,1,0-1.51h7.42a.755.755,0,1,1,0,1.51Z"
            transform="translate(174.5 319.5)"
            fill="#292d32"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(196 340) rotate(180)"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "prayer-d",
};
</script>

<style scoped>
.pray-icon {
  display: flex;
  align-items: center;
  width: 100%;
}

.pray-icon svg {
  width: 25px;
  height: 25px;
}

.router-link-exact-active .pray-icon svg path {
  fill: var(--button) !important;
}

.pray-icon svg path {
  fill: var(--placeholder) !important;
}
</style>
