<template>
  <div class="containe">
    <div class="lists-avatars">
      <div class="box-avatar" v-for="(image, index) in images" :key="index">
        <img
          :src="`https://cp.doonun.com${image.url}`"
          alt="Avatar"
          @click="selectImage(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "slider-avatar",
  components: {},
  data() {
    return {
      user: null,
      userAvatar: null,
      avatarId: null,
      images: [],
      token: localStorage.getItem("jwtToken"),
    };
  },
  watch: {},
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),

    selectedImage() {
      return this.userAvatar;
    },
  },
  methods: {
    ...mapActions(["updateAvatarId"]),
    ...mapActions("user", ["fetchUserInfo", "updateProfile"]),
    async fetchUser() {
      try {
        if (this.getToken) {
          this.user = this.getUserInfo;
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
      }
    },
    async fetchAvatars() {
      try {
        const response = await axios.get("/avatars", {
          params: {
            populate: ["users_permissions_users", "Avatar"],
          },
        });

        if (response.data && response.data.data.length > 0) {
          this.images = response.data.data.map((item) => {
            return {
              url: item.attributes.Avatar.data.attributes.formats.small.url,
              id: item.id,
            };
          });

          const userAvatarData = response.data.data.find((item) =>
            item.attributes.users_permissions_users.data.some(
              (u) => u.id === this.user.id
            )
          );

          const defaultAvatarUrl = "/uploads/plant_6a63b26a77.png";
          this.userAvatar = userAvatarData
            ? userAvatarData.attributes.Avatar.data.attributes.formats.small.url
            : defaultAvatarUrl;

          // console.log('Response : ', response)
        } else {
          // console.error("Aucun avatar trouvé");
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des avatars:", error);
      }
    },
    async selectImage(index) {
      const selectedAvatar = this.images[index];
      this.userAvatar = selectedAvatar.url;
      this.avatarId = selectedAvatar.id;
      await this.updateUserAvatarRelation(this.avatarId);
    },

    async updateUserAvatarRelation(avatarId) {
      try {
        await axios.put(
          `/users/${this.user.id}?populate=*`,
          {
            avatar: avatarId,
          },
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        );
        this.updateAvatarId(avatarId);
        console.log("Relation avatar-utilisateur mise à jour avec succès");
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour de la relation avatar-utilisateur",
          error
        );
      }
    },
  },
  async mounted() {
    await this.fetchUser();
    this.fetchAvatars();
  },
};
</script>

<style scoped>
.lists-avatars {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  gap: 10px;
}

.lists-avatars img {
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  flex-grow: 1;
  outline: 2px solid var(--body);
  transition: 0.2s ease;
}

.lists-avatars img:hover {
  outline: 2px solid var(--button);
  transform: scale(1.05);
}
</style>
