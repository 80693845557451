import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    userInfo: null,
    userProgress: {},
    userCourses: [],
    userPoints: 0,
    userRank: "",
    token: localStorage.getItem("jwtToken") || process.env.VUE_APP_T,
    usersRanking: [],
    userLinks: {
      twitterLink: "",
      instagramLink: "",
      tiktokLink: "",
      websiteLink: "",
    },
  }),
  getters: {
    isLoggedIn: (state) => !!state.token,
    getUserInfo: (state) => state.userInfo,
    getUserProgress: (state) => (courseId) => state.userProgress[courseId],
    getUserCourses: (state) => state.userCourses,
    getToken: (state) => state.token,
    getUserPoints: (state) => state.userPoints,
    getUserRank: (state) => state.userRank,
    getUserCours: (state) => state.userCours,
    getUserSourates: (state) => state.userSourates,
    getUserLinks: (state) => state.userLinks,
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem("jwtToken", token);
    },
    CLEAR_USER_STATE(state) {
      state.userInfo = null;
      state.userProgress = {};
      state.userCourses = [];
      state.token = "";
      localStorage.removeItem("jwtToken");
    },
    UPDATE_USER_PROGRESS(state, { courseId, progress, idSuivi }) {
      if (!state.userProgress[courseId]) {
        state.userProgress[courseId] = {};
      }
      state.userProgress[courseId] = { ...progress, idSuivi };
    },
    SET_USER_COURSES(state, courses) {
      state.userCourses = courses;
    },
    SET_USER_POINTS(state, points) {
      // console.log("Mise à jour des points de l'utilisateur:", points);
      state.userPoints = points;
    },
    SET_USER_SOURATES(state, sourates) {
      state.userSourates = sourates;
    },
    SET_USER_COURS(state, cours) {
      state.userCours = cours;
    },
    SET_USER_RANK(state, rank) {
      state.userRank = rank;
    },
    SET_USERS_RANKING(state, usersRanking) {
      state.usersRanking = usersRanking;
    },
    SET_USER_LINKS(state, links) {
      state.userLinks = links;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post("/auth/local", credentials);
        commit("SET_USER_INFO", response.data.user);
        commit("SET_TOKEN", response.data.jwt);
      } catch (error) {
        console.error("Erreur lors de la connexion:", error);
        commit("CLEAR_USER_STATE");
      }
    },

    authenticateWithGoogle() {
      const googleAuthUrl = "https://cp.doonun.com/api/connect/google";
      window.location.href = googleAuthUrl;
    },

    // Action pour traiter le callback de Google
    async processGoogleAuthCallback({ commit }, searchParams) {
      try {
        const response = await axios.get(
          `/auth/google/callback${searchParams}`
        );
        const token = response.data.jwt;
        localStorage.setItem("jwtToken", token);
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        commit("SET_TOKEN", token);

        // Ajoutez ici le code pour récupérer les informations de l'utilisateur
        const userInfoResponse = await axios.get("/users/me", {
          headers: { Authorization: `Bearer ${token}` },
        });
        commit("SET_USER_INFO", userInfoResponse.data);

        // Redirection n'est plus nécessaire ici si elle est gérée ailleurs
        window.location.href = "/profil";
      } catch (error) {
        console.error("Error processing Google auth:", error);
      }
    },

    logout({ commit }) {
      commit("CLEAR_USER_STATE");
    },

    async initializeAuthState({ commit }) {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        commit("SET_TOKEN", token);
        // Faites un appel API pour récupérer les informations de l'utilisateur
        try {
          const userInfoResponse = await axios.get("/users/me?populate=*", {
            headers: { Authorization: `Bearer ${token}` },
          });
          commit("SET_USER_INFO", userInfoResponse.data);
          // Ici, vous pouvez également appeler d'autres actions comme fetchUserPoints si nécessaire.
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur au démarrage:",
            error
          );
          // Gérer les cas d'erreur, par exemple en supprimant le token corrompu et les données utilisateur du localStorage
          localStorage.removeItem("jwtToken");
          commit("CLEAR_USER_STATE");
        }
      } else {
        // Si aucun token n'est trouvé, nettoyer l'état et le localStorage
        localStorage.removeItem("userInfo");
        commit("CLEAR_USER_STATE");
      }
    },

    updateUserInformation({ commit }, updatedUserInfo) {
      commit("SET_USER_INFO", updatedUserInfo);
    },

    async updateProfile({ commit }, userProfileData) {
      try {
        const response = await axios.put(
          "/users/me?populate=*",
          userProfileData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            },
          }
        );
        commit("SET_USER_INFO", response.data);
        // Mise à jour réussie, dispatch l'action pour mettre à jour l'état
        this.$store.dispatch("updateUserInformation", response.data);
      } catch (error) {
        console.error("Erreur lors de la mise à jour du profil:", error);
      }
    },

    async fetchUserInfo({ commit, dispatch, state }) {
      if (state.token) {
        try {
          const response = await axios.get("/users/me?populate=*", {
            headers: { Authorization: `Bearer ${state.token}` },
          });
          commit("SET_USER_INFO", response.data);
          // Appeler fetchUserPoints après avoir chargé les informations de l'utilisateur
          await dispatch("fetchUserPoints");
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur:",
            error
          );
          commit("CLEAR_USER_STATE");
        }
      }
    },

    async fetchUserCourses({ commit, state }) {
      if (state.token) {
        try {
          const response = await axios.get("/courses", {
            params: {
              "filters[user_id][$eq]": [state.userInfo.id],
              "filters[slug][$eq]": [this.$route.params.slug],
            },
          });
          commit("SET_USER_COURSES", response.data);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des cours de l'utilisateur:",
            error
          );
        }
      }
    },
    async resetCourseProgress({ commit, state }, { courseId, slug }) {
      try {
        const resetData = {
          id_chapitre: String(1),
          id_contenu: String(0),
          pourcentage: String(0),
          id_quiz: String(0),
          indexError: String(0),
          clicks: String(0),
          quiz_active: false,
          content_active: true,
          termine: String(1),
        };

        const params = {
          "filters[user_id][$eq]": [state.userInfo.id],
          "filters[slug][$eq]": [slug],
        };

        const response = await axios.put(
          `/suivis/${courseId}`,
          { data: resetData },
          {
            params: params,
            headers: {
              Authorization: `Bearer ${state.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        commit("UPDATE_USER_PROGRESS", { courseId: slug, progress: resetData });
        console.log("Progression réinitialisée:", response);
      } catch (error) {
        console.error(
          "Erreur lors de la réinitialisation de la progression:",
          error
        );
      }
    },

    async fetchUserPoints({ commit, state }) {
      try {
        const existingHassanatesResponse = await axios.get("/hassanates", {
          params: {
            "filters[id_user][$eq]": state.userInfo.id,
          },
          headers: { Authorization: `Bearer ${state.token}` },
        });

        if (
          existingHassanatesResponse.data.data &&
          existingHassanatesResponse.data.data.length > 0
        ) {
          // Si les points existent, les mettre à jour en utilisant la mutation SET_USER_POINTS
          const attributes = existingHassanatesResponse.data.data[0].attributes;
          const userPoints = attributes.points;
          const userSourates = attributes.sourates;
          const userCours = attributes.cours;
          const userRank = attributes.rang; // Assurez-vous que l'attribut 'rang' est correctement retourné par l'API

          // Utilisez commit pour invoquer les mutations appropriées
          commit("SET_USER_POINTS", userPoints);
          commit("SET_USER_SOURATES", userSourates);
          commit("SET_USER_COURS", userCours);
          commit("SET_USER_RANK", userRank);
        } else {
          // Si aucun point n'a été trouvé, définissez les points, sourates, cours et rang de l'utilisateur à 0 ou à une valeur appropriée
          commit("SET_USER_POINTS", 0);
          commit("SET_USER_SOURATES", 0);
          commit("SET_USER_COURS", 0);
          commit("SET_USER_RANK", 0); // ou une autre valeur qui indique 'non classé' ou 'inconnu'
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération ou de la création des points de l'utilisateur:",
          error
        );
      }
    },

    async updateUserPoints({ dispatch, state }, newPoints) {
      try {
        // Assurez-vous que newPoints est un nombre et s'il est négatif, mettez-le à 0
        newPoints = Number(newPoints);
        if (newPoints < 0) {
          newPoints = 0;
        }

        const existingHassanatesResponse = await axios.get("/hassanates", {
          params: {
            "filters[id_user][$eq]": state.userInfo.id,
          },
          headers: { Authorization: `Bearer ${state.token}` },
        });

        if (
          existingHassanatesResponse.data &&
          existingHassanatesResponse.data.data &&
          existingHassanatesResponse.data.data.length > 0
        ) {
          let idHassanate = existingHassanatesResponse.data.data[0].id;

          await axios.put(
            `/hassanates/${idHassanate}`,
            { data: { points: newPoints } },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          // Aucun enregistrement de points trouvé, donc en créer un nouveau
          await axios.post(
            "/hassanates",
            {
              data: {
                id_user: String(state.userInfo.id),
                points: newPoints,
                users_permissions_user: state.userInfo.id,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour ou de la création des points de l'utilisateur:",
          error
        );
      }

      // Après la mise à jour ou la création, rafraîchir les points de l'utilisateur
      await dispatch("fetchUserPoints");
    },

    async updateUserStatSourates({ commit, state }, newSourateChange) {
      try {
        const existingHassanatesResponse = await axios.get("/hassanates", {
          params: {
            "filters[id_user][$eq]": state.userInfo.id,
          },
          headers: { Authorization: `Bearer ${state.token}` },
        });

        if (
          existingHassanatesResponse.data &&
          existingHassanatesResponse.data.data &&
          existingHassanatesResponse.data.data.length > 0
        ) {
          let idHassanate = existingHassanatesResponse.data.data[0].id;
          let currentSourates =
            existingHassanatesResponse.data.data[0].attributes.sourates;

          const updatedSourates = currentSourates + newSourateChange;

          const updateData = { data: { sourates: updatedSourates } };

          await axios.put(`/hassanates/${idHassanate}`, updateData, {
            headers: {
              Authorization: `Bearer ${state.token}`,
              "Content-Type": "application/json",
            },
          });

          commit("SET_USER_SOURATES", updatedSourates);
        } else {
          console.log(
            "Aucun enregistrement Hassanates trouvé pour l'utilisateur."
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des sourates de l'utilisateur:",
          error
        );
      }
    },

    async updateUserStatCourses({ commit, state }, newCourseChange) {
      try {
        const existingHassanatesResponse = await axios.get("/hassanates", {
          params: {
            "filters[id_user][$eq]": state.userInfo.id,
          },
          headers: { Authorization: `Bearer ${state.token}` },
        });

        if (
          existingHassanatesResponse.data &&
          existingHassanatesResponse.data.data &&
          existingHassanatesResponse.data.data.length > 0
        ) {
          let idHassanate = existingHassanatesResponse.data.data[0].id;
          let currentCours =
            existingHassanatesResponse.data.data[0].attributes.cours;

          const updatedCours = currentCours + newCourseChange;

          const updateData = { data: { cours: updatedCours } };

          await axios.put(`/hassanates/${idHassanate}`, updateData, {
            headers: {
              Authorization: `Bearer ${state.token}`,
              "Content-Type": "application/json",
            },
          });

          commit("SET_USER_COURS", updatedCours);
        } else {
          console.log(
            "Aucun enregistrement Hassanates trouvé pour l'utilisateur."
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la mise à jour des cours de l'utilisateur:",
          error
        );
      }
    },

    async fetchRanking({ commit }) {
      // Ajouter dispatch avec commit
      try {
        const usersResponse = await axios.get("/users", {
          params: {
            populate: ["avatar", "lien", "hassanate"],
            "filters[hassanate][rang][$lte]": 100,
            sort: "hassanate.rang:asc",
          },
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_T}`,
          },
        });

        if (usersResponse.data && Array.isArray(usersResponse.data)) {
          const usersInfo = await Promise.all(
            usersResponse.data.map(async (user) => {
              let avatarUrl =
                "https://cp.doonun.com/uploads/plant_6a63b26a77.png"; // URL par défaut

              if (user.avatar) {
                const avatarId = user.avatar.id;
                try {
                  const avatarResponse = await axios.get(
                    `/avatars/${avatarId}?populate=*`
                  );
                  avatarUrl = `https://cp.doonun.com${avatarResponse.data.data.attributes.Avatar.data.attributes.formats.small.url}`; // Supposition de la structure de réponse
                } catch (error) {
                  console.error(
                    "Erreur lors de la récupération des détails de l'avatar:",
                    error
                  );
                  // avatarUrl reste l'URL par défaut si la requête échoue
                }
              }

              const lien = user.lien || null;
              const hassanate = user.hassanate || null;

              return {
                id: user.id,
                username: user.username,
                avatar: avatarUrl,
                lien: lien,
                points: hassanate ? hassanate.points : 0,
                rank: hassanate ? hassanate.rang : null,
              };
            })
          );

          commit("SET_USERS_RANKING", usersInfo);
          //
        } else {
          console.error(
            "La réponse des utilisateurs est invalide:",
            usersResponse.data
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération du classement des utilisateurs:",
          error
        );
      }
    },

    async addUserLinks({ state }, linksData) {
      try {
        const formattedData = {
          data: {
            Twitter: linksData.twitterLink,
            Instagram: linksData.instagramLink,
            Tiktok: linksData.tiktokLink,
            Lien: linksData.websiteLink,
            users_permissions_user: state.userInfo.id,
          },
        };

        const response = await axios.post("/liens", formattedData, {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        console.log("Liens ajoutés avec succès:", response);
      } catch (error) {
        console.error("Erreur lors de l'ajout des liens:", error);
      }
    },
    async fetchUserLinks({ commit, state }) {
      try {
        const response = await axios.get("/liens", {
          params: {
            "filters[users_permissions_user][id][$eq]": state.userInfo.id,
          },
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        });
        if (response.data.data.length > 0) {
          commit("SET_USER_LINKS", response.data.data[0]);
        } else {
          // Aucun lien trouvé, renvoyer une valeur par défaut ou ne rien faire
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des liens:", error);
        throw error; // Vous pouvez propager l'erreur pour la gérer dans le composant
      }
    },

    async updateUserLinks({ state, dispatch }, linksData) {
      try {
        let linksId = state.userLinks.id;

        if (!linksId) {
          // Si les liens n'existent pas encore, en créer de nouveaux
          const formattedData = {
            data: {
              Twitter: linksData.twitterLink,
              Instagram: linksData.instagramLink,
              Tiktok: linksData.tiktokLink,
              Lien: linksData.websiteLink,
              users_permissions_user: state.userInfo.id,
            },
          };

          const response = await axios.post("/liens", formattedData, {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          });

          console.log("Liens ajoutés avec succès:", response);
          linksId = response.data.id;
        } else {
          // Si les liens existent déjà, les mettre à jour
          const formattedData = {
            data: {
              Twitter: linksData.twitterLink,
              Instagram: linksData.instagramLink,
              Tiktok: linksData.tiktokLink,
              Lien: linksData.websiteLink,
            },
          };

          const response = await axios.put(`/liens/${linksId}`, formattedData, {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          });

          console.log("Liens mis à jour avec succès:", response);
        }

        // Mettre à jour les liens dans le state
        dispatch("fetchUserLinks");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des liens:", error);
        throw error;
      }
    },
  },
};
