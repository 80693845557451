<template>
  <div class="container">
    <Head>
      <title>Profil - Doonun</title>
      <meta name="robots" content="Noindex, nofollow" />
      <link
        rel="icon"
        href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
      />
    </Head>
    <div class="back">
      <router-link to="/">
        <i class="fa-solid fa-arrow-left"></i>
      </router-link>
      <router-link to="/">
        <p>Revenir</p>
      </router-link>
    </div>
    <div class="profile-container">
      <div class="left-profil">
        <div class="profile-header">
          <div class="profil-info">
            <div class="profile-picture">
              <img
                :src="`https://cp.doonun.com${avatar}`"
                alt="Profile Picture"
              />
            </div>
            <div class="edit-container">
              <div class="edit" @click="changeComponent('AvatarAndTheme')">
                <i class="fa-solid fa-plus"></i>
              </div>
            </div>
            <span class="profile-name">{{ username }}</span>
          </div>
          <div class="hass">
            <span>{{ userPoints }} Points</span>
          </div>
          <ul>
            <li>
              <Rank />
              <p>Rang</p>
              <span>{{ userRank || "0" }}</span>
            </li>
            <li>
              <Cours />
              <p>Cours</p>
              <span>{{ userCours || "0" }}</span>
            </li>
            <li>
              <Coran />
              <p>Sourates</p>
              <span>{{ userSourates || "0" }}</span>
            </li>
          </ul>
        </div>

        <div class="profile-sidebar-menu">
          <div class="profile-link" @click="changeComponent('Edit')">
            <span class="icon">
              <EditP />
            </span>
            <span class="txt-p">Modifier le profil</span>
          </div>
          <div class="profile-link" @click="changeComponent('LinksP')">
            <span class="icon">
              <Links />
            </span>
            <span class="txt-p">Mes réseaux</span>
          </div>
          <div class="profile-link" @click="changeComponent('Abonnement')">
            <span class="icon">
              <SubA />
            </span>
            <span class="txt-p">Mon abonnement</span>
          </div>
          <div class="profile-link" @click="changeComponent('Parametres')">
            <span class="icon">
              <Params />
            </span>
            <span class="txt-p">Paramètres du compte</span>
          </div>
          <div class="profile-link" @click="logout">
            <span class="icon">
              <Logout />
            </span>
            <span class="txt-p">Déconnexion</span>
          </div>
        </div>
      </div>

      <div class="right-profil">
        <div>
          <div class="navigation-section">
            <ul>
              <li @click="changeComponent('Reprendre')">Reprendre</li>
              <!-- <li @click="changeComponent('Reprendre')">Mes sourates</li> -->
              <!-- <li @click="changeComponent('Livres')">Mes livres</li> -->
            </ul>
          </div>
          <div class="containt-profil">
            <component :is="currentComponent"></component>
          </div>
        </div>
      </div>
    </div>
    <div class="avis-popup">
      <DemandeAvis />
    </div>
  </div>
  <div class="head-profil"></div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import Reprendre from "../components/Profil/Reprendre.vue";
import Hadiths from "../components/Profil/Hadiths.vue";
import Abonnement from "../components/Profil/Abonnement.vue";
import Livres from "../components/Profil/Livres.vue";
import Parametres from "../components/Profil/Parametres.vue";
import Edit from "../components/Profil/Edit.vue";
import LinksP from "../components/Profil/LinksP.vue";
import AvatarAndTheme from "../components/Profil/AvatarAndTheme.vue";
import Rank from "../components/icons/Rank.vue";
import Coran from "../components/icons/Coran.vue";
import Cours from "../components/icons/Cours.vue";
import EditP from "../components/icons/EditP.vue";
import Links from "../components/icons/Links.vue";
import Params from "../components/icons/Params.vue";
import Logout from "../components/icons/Logout.vue";
import SubA from "../components/icons/SubA.vue";
import DemandeAvis from "../components/DemandeAvis.vue";
import { Head } from "@vueuse/head";
import SliderAvatar from "../components/SliderAvatar.vue";

export default {
  name: "profil-d",
  components: {
    Reprendre,
    Livres,
    Abonnement,
    Hadiths,
    Parametres,
    Edit,
    Coran,
    Cours,
    Rank,
    EditP,
    Logout,
    SubA,
    Params,
    DemandeAvis,
    Head,
    SliderAvatar,
    AvatarAndTheme,
    Links,
    LinksP,
  },
  data() {
    return {
      progress: 93,
      currentComponent: "Reprendre",
      user: null,
      idAvatar: null,
      username: "",
      avatar: "",
      token: localStorage.getItem("jwtToken"),
    };
  },
  computed: {
    ...mapState(["avatarId"]),
    ...mapGetters("user", [
      "getUserInfo",
      "getUserPoints",
      "getUserCours",
      "getUserRank",
      "getUserSourates",
      "getToken",
    ]),
    userPoints() {
      return this.getUserPoints;
    },
    userCours() {
      return this.getUserCours;
    },
    userRank() {
      return this.getUserRank;
    },
    userSourates() {
      return this.getUserSourates;
    },
  },
  async beforeMount() {
    await this.fetchUserPoints();
    await this.fetchUser();
    this.fetchUserInfo();
    this.fetchAvatars();
  },

  watch: {
    avatarId(newId) {
      if (newId) {
        this.fetchAvatarById(newId);
      }
    },
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo", "fetchUserPoints"]),
    changeComponent(componentName) {
      this.currentComponent = componentName;
      this.scrollToH3();
    },
    scrollToH3() {
      const target = document.getElementById("scroll-target");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    },
    logout() {
      // Supprimez le token JWT du localStorage
      localStorage.removeItem("jwtToken");

      // Réinitialisez les en-têtes par défaut d'Axios
      this.$axios.defaults.headers.common["Authorization"] = "";

      // Rechargez entièrement la page et redirigez vers la page de connexion
      window.location.href = "/connexion";
    },
    async fetchUser() {
      try {
        if (this.getToken) {
          this.user = this.getUserInfo;
          this.username = this.user.username;
          this.idAvatar = this.user.avatar.id;
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des informations de l'utilisateur:", error);
      }
    },
    async fetchAvatars() {
      // Utilisez une valeur par défaut pour idAvatar si elle est nulle
      const id = this.idAvatar || 23;

      try {
        const response = await axios.get(`/avatars/${id}?populate=*`);
        this.avatar =
          response.data.data.attributes.Avatar.data.attributes.formats.small.url;
      } catch (error) {
        // console.error("Erreur lors de la récupération des avatars:", error);
        // En cas d'erreur, vous pouvez définir une URL d'avatar par défaut ici
      }
    },

    async fetchAvatarById(avatarId) {
      // Utilisez une valeur par défaut pour avatarId si elle est nulle
      const id = avatarId || 23;

      try {
        const response = await axios.get(`/avatars/${id}?populate=*`, {
          headers: { Authorization: `Bearer ${this.getToken}` },
        });
        this.avatar =
          response.data.data.attributes.Avatar.data.attributes.formats.small.url;
      } catch (error) {
        // console.error("Erreur lors de la récupération de l'avatar:", error);
        // En cas d'erreur, vous pouvez définir une URL d'avatar par défaut ici
      }
    },
  },
};
</script>

<style scoped>
.profile-container {
  display: flex;
  width: 100%;
  max-width: 1400px;
  gap: 30px;
  margin: 30px auto;
}

.profile-container .right-profil {
  width: 75%;
  flex: 1 1 70%;
  margin-bottom: 30px;
}

.profile-container .left-profil {
  flex: 1 1 20%;
  gap: 10px;
  width: 25%;
}

@media (max-width: 1200px) {
  .profile-container .right-profil {
    flex: 1 1 70%;
    width: 70%;
  }
  .profile-container .left-profil {
    flex: 1 1 25%;
    width: 25%;
  }

  .container {
    max-width: 1400px !important;
  }
}

@media (max-width: 992px) {
  .profile-container .right-profil {
    flex: 1 1 65%;
    width: 65%;
  }
  .profile-container .left-profil {
    flex: 1 1 30%;
    width: 30%;
  }
}

@media (max-width: 800px) {
  .profile-container {
    flex-direction: column;
  }
  .profile-container .right-profil,
  .profile-container .left-profil {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .profile-container {
    flex-direction: column;
  }
}

.profile-header {
  position: relative;
  padding: var(--padding);
  background: var(--card);
  border-radius: var(--br10);
}

.profile-header::after {
  display: inline;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  width: 95%;
  height: 40px;
  background: var(--card);
  border-radius: 0 0 10px 10px;
  z-index: -1;
  margin-bottom: -10px;
  opacity: 0.7;
}

.profil-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.profile-picture {
  position: relative;
  width: 130px;
  height: 130px;
  border-radius: 10px;
  background: var(--body);
}

.profile-picture img {
  object-fit: cover;
  object-position: center center;
  width: 130px;
  height: 130px;
  border-radius: 10px;
}

.edit-container {
  margin-top: -35px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.edit-container .edit {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  /* bottom: 0;
  right: auto;
  left: auto; */
  background: rgb(0, 166, 255);
  border-radius: 50%;
  cursor: pointer;
  padding: 15px;
  border: 2px solid var(--body);
  color: #fff;
  font-weight: 600;
}

.profile-name {
  font-size: 1.2em;
  font-weight: bold;
}

.left-profil ul {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

.left-profil ul li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.left-profil ul li p {
  font-size: 14px;
}

.left-profil ul li span {
  font-size: 20px;
  font-weight: 700;
}

.hass {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.hass span {
  background: orange;
  color: var(--white);
  padding: 5px 15px;
  border-radius: var(--br30);
  font-size: 13px;
}

.profile-sidebar-menu {
  margin-top: 20px;
  display: grid;
  gap: 20px;
  background: var(--card);
  padding: var(--padding);
  border-radius: var(--br10);
}

.profile-sidebar-menu span {
  cursor: pointer;
}

.right-profil {
  /* background: var(--card);
    padding: var(--padding);
    border-radius: var(--br10); */
}

.right-profil > div {
  width: 100%;
}

.right-profil .navigation-section ul {
  display: flex;
  justify-content: end;
  gap: 20px;
  margin-top: 20px !important;
  color: #fff !important;
}

.right-profil .navigation-section ul li {
  cursor: pointer;
}

.right-profil h3 {
  margin-bottom: 20px;
}

.profile-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.avis-popup {
  display: flex;
  justify-content: center;
}

@media (max-width: 800px) {
  .right-profil .navigation-section ul {
    color: var(--text) !important;
  }
}

/* Pour les tablettes */
@media (max-width: 768px) {
  .profile-sidebar-menu {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    border-radius: 0;
    padding: 20px 30px 5vh 30px;
    margin-top: 20px;
    background: var(--options-v-background-color);
    backdrop-filter: var(--options-v-blur);
    -webkit-backdrop-filter: var(--options-v-blur);
    z-index: 999;
    width: 100%;
  }

  .profile-sidebar-menu .txt-p {
    display: none;
  }

  .left-profil ul {
    padding-bottom: 20px !important;
  }

  .navigation-section ul {
    margin: 30px 0;
    justify-content: end;
  }

  .profile-picture {
    border-radius: 60px;
    background: var(--body);
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .profile-picture img {
    object-fit: cover;
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
}

/* Pour les petits appareils mobiles */

.head-profil {
  position: absolute;
  z-index: -1;
  top: 0;
  width: 100%;
  height: 250px;
  background: var(--headProfil);
}

.back {
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.back p {
  display: inline-block;
  color: #fff;
}

.back i {
  color: #fff;
}
</style>
