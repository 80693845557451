<template>
  <div class="invoc-icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="vuesax_bold_magic-star"
        data-name="vuesax/bold/magic-star"
        transform="translate(-556 -188)"
      >
        <g id="magic-star">
          <path
            id="Vector"
            d="M0,0H24V24H0Z"
            transform="translate(556 188)"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M15.189,2.14l-.07,3.79a2.081,2.081,0,0,0,.74,1.52l2.48,1.88c1.59,1.2,1.33,2.67-.57,3.27l-3.23,1.01a2.084,2.084,0,0,0-1.25,1.31l-.77,2.94c-.61,2.32-2.13,2.55-3.39.51l-1.76-2.85a2.058,2.058,0,0,0-1.68-.88l-3.34.17c-2.39.12-3.07-1.26-1.51-3.08l1.98-2.3a2.081,2.081,0,0,0,.37-1.77L2.169,4.42c-.59-1.9.47-2.95,2.36-2.33l2.95.97a2.087,2.087,0,0,0,1.67-.26L12.229.58C13.9-.61,15.229.09,15.189,2.14Z"
            transform="translate(558.101 190)"
            fill="#292d32"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M4.308,3.247,1.277.218a.75.75,0,0,0-1.06,1.06l3.03,3.03a.748.748,0,0,0,1.06,0A.754.754,0,0,0,4.308,3.247Z"
            transform="translate(573.133 205.223)"
            fill="#292d32"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
export default {
  name: "invoc-d",
};
</script>

<style>
.invoc-icon {
  display: flex;
  align-items: center;
  width: 100%;
}

.invoc-icon svg {
  width: 25px;
  height: 25px;
}

.router-link-exact-active .invoc-icon svg path {
  fill: var(--button) !important;
}

.invoc-icon svg path {
  fill: var(--placeholder) !important;
}
</style>
