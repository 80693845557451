<template>
  <div class="up" v-show="visible">
    <button class="Btn" @click="scrollToTop">
      <svg height="1.2em" class="arrow" viewBox="0 0 512 512">
        <path
          d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "UpButton",
  data() {
    return {
      visible: false,
      lastScrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // Call handleScroll initially to determine initial visibility
    this.handleScroll();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > this.lastScrollTop) {
        // Scrolling down
        this.visible = false;
      } else {
        // Scrolling up
        this.visible = scrollTop > 0; // Only show when scrollTop is greater than 0
      }
      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
    },
  },
};
</script>

<style scoped>
.up {
  display: flex;
  justify-content: center;
  padding: 20px;
  position: fixed;
  bottom: 65px;
  right: 30px;
  z-index: 999;
}

.Btn {
  width: 45px;
  height: 45px;
  background: var(--options-v-background-color);
  backdrop-filter: var(--options-v-blur);
  -webkit-backdrop-filter: var(--options-v-blur);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: none;
}

.arrow path {
  fill: var(--text);
}

.Btn:hover .text {
  opacity: 1;
  transition-duration: 0.7s;
}

.Btn:hover .arrow {
  animation: slide-in-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-in-bottom {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .up {
    bottom: 110px;
  }
}
</style>
