<template>
  <Tabs />
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="whithout-login" v-if="!isLoggedIn">
    <Hero />
    <div class="container">
      <Classement />
      <Pourquoi />
      <Fonctionnalite />
      <Tarifs />
      <Avis />
      <Faq />
    </div>
  </div>
  <div class="boites" v-if="isLoggedIn">
    <div class="container-app">
      <div class="left">
        <!-- <News /> -->
        <div class="title-cours">
          <div class="title">
            <h3>Nouveaux cours</h3>
          </div>
          <router-link to="/cours"
            ><i class="fa-solid fa-arrow-right-long"></i>
          </router-link>
        </div>
        <div class="list-courses">
          <div v-for="course in lastFiveCours" :key="course.titre">
            <NewCardCourse
              :titre="course.titre"
              :points="Number(course.points)"
              :category="course.Category"
              :img="course.Couverture.data.attributes.formats.medium.url"
              :level="course.niveau"
              :slug="course.url"
            />
          </div>
        </div>
        <div class="title-quran">
          <div class="title">
            <h3>Les plus lu</h3>
          </div>
          <router-link to="/coran"
            ><i class="fa-solid fa-arrow-right-long"></i>
          </router-link>
        </div>
        <div class="list-quran">
          <Card
            v-for="sourate in sourates"
            :key="sourate.id"
            :sourate="sourate"
            :taux="versetsCheck[sourate.Numero] || 0"
          />
        </div>
      </div>
      <div class="right">
        <TimePray />
        <ThreeDays />
        <RankingList />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import Hero from "../components/Hero.vue";
import Classement from "../components/Classement.vue";
import Pourquoi from "../components/Pourquoi.vue";
import Fonctionnalite from "../components/Fonctionnalite.vue";
import Tarifs from "../components/Tarifs.vue";
import Avis from "../components/Avis.vue";
import Faq from "../components/Faq.vue";
import { useHead, Head } from "@vueuse/head";
import Tabs from "../components/Tabs.vue";
import TimePray from "../components/TimePray.vue";
import RankingList from "../components/RankingList.vue";
// import News from "../components/News.vue";
import NewCardCourse from "../components/NewCardCourse.vue";
import Card from "../components/Card.vue";
import ThreeDays from "../components/ThreeDays.vue";

export default {
  name: "Accueil-d",
  setup() {
    useHead({
      title: "Accueil - Doonun",
      meta: [
        {
          name: `description`,
          content:
            "Découvrez le Coran en ligne gratuitement sur notre plateforme. Apprenez le Tafsir des sourates, suivez des cours de sciences islamiques et explorez le Coran en arabe et français avec audio. Renforcez votre foi avec les invocations islamiques et la Citadelle du musulman. Rejoignez-nous pour approfondir vos connaissances islamiques.",
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: "Accueil - Doonun",
        },
        {
          property: "og:description",
          content:
            "Découvrez le Coran en ligne gratuitement sur notre plateforme. Apprenez le Tafsir des sourates, suivez des cours de sciences islamiques et explorez le Coran en arabe et français avec audio. Renforcez votre foi avec les invocations islamiques et la Citadelle du musulman. Rejoignez-nous pour approfondir vos connaissances islamiques.",
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/doonun_00407a689c.png",
        },
        {
          property: "og:url",
          content: "https://www.doonun.com",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "Accueil - Doonun",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content:
            "Découvrez le Coran en ligne gratuitement sur notre plateforme. Apprenez le Tafsir des sourates, suivez des cours de sciences islamiques et explorez le Coran en arabe et français avec audio. Renforcez votre foi avec les invocations islamiques et la Citadelle du musulman. Rejoignez-nous pour approfondir vos connaissances islamiques.",
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/doonun_00407a689c.png",
        },
      ],
    });
  },

  components: {
    Hero,
    Classement,
    Pourquoi,
    Tarifs,
    Fonctionnalite,
    Avis,
    Faq,
    Card,
    Head,
    Tabs,
    TimePray,
    RankingList,
    // News,
    NewCardCourse,
    ThreeDays,
  },
  data() {
    return {
      sourates: [],
      versetsCheck: {},
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
      lastFiveCours: "cours/lastFiveCours",
    }),
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  created() {
    this.$store.dispatch("cours/fetchCoursList");
  },
  async mounted() {
    await this.initializeData();
  },

  beforeUnmount() {},
  methods: {
    async initializeData() {
      if (this.getUserInfo && this.getUserInfo.id) {
        await this.fetchData();
        await this.fetchDataVerse();
      }
    },
    ...mapActions("user", ["fetchUserInfo"]),
    async fetchData() {
      try {
        const responseSourate = await axios.get("/sourates", {
          params: {
            fields: ["Numero", "Arabe", "Phonetique", "Revelation", "Nombre"],
            populate: ["vues_sourate", "like_sourate"],
          },
        });

        const sortedSourates = responseSourate.data.data
          .sort((a, b) => {
            const vuesA = a.attributes.vues_sourate
              ? a.attributes.vues_sourate.data.attributes.vues
              : 0;
            const vuesB = b.attributes.vues_sourate
              ? b.attributes.vues_sourate.data.attributes.vues
              : 0;
            return vuesB - vuesA;
          })
          .map((sourate) => ({
            Numero: sourate.attributes.Numero,
            Arabe: sourate.attributes.Arabe,
            Phonetique: sourate.attributes.Phonetique,
            Revelation: sourate.attributes.Revelation,
            Nombre: sourate.attributes.Nombre,
            id: sourate.id,
            likes: sourate.attributes.like_sourate
              ? sourate.attributes.like_sourate.data.attributes.likes
              : 0,
            vues: sourate.attributes.vues_sourate
              ? sourate.attributes.vues_sourate.data.attributes.vues
              : 0,
          }));

        this.sourates = sortedSourates.slice(0, 4);
        // console.log("sourates", this.sourates);
      } catch (error) {
        // console.error(`Erreur lors de la récupération des sourates :`, error);
      }
    },

    async fetchDataVerse() {
      if (!this.getUserInfo.id) return;
      try {
        const {
          data: { data: items },
        } = await axios.get("/versets-checks", {
          params: {
            "filters[id_user][$eq]": this.getUserInfo.id,
            fields: ["id_sourate", "taux"],
          },
        });
        this.versetsCheck = items.reduce((acc, item) => {
          acc[item.attributes.id_sourate] = item.attributes.taux;
          return acc;
        }, {});
      } catch (error) {
        // console.error(`Erreur lors de la récupération des données de taux d'avancement:`, error);
      }
    },
  },
};
</script>

<style scoped>
.boites {
  max-width: 1400px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.container-app {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Augmenté l'écart (gap) */
  margin-bottom: 100px;
  padding: 30px 0; /* Ajouté un remplissage (padding) */
}

.container-app .left {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 70%;
  flex: 1 1 70%;
  margin-bottom: 30px;
}

.container-app .right {
  display: flex;
  flex-direction: column;
  flex: 1 1 25%;
  gap: 20px;
  width: 25%;
}

@media (max-width: 1400px) {
  .container-app {
    padding: 0 20px;
  }
}

@media (max-width: 1200px) {
  .container-app .left {
    width: 65%;
  }
  .container-app .right {
    width: 30%;
  }
}

@media (max-width: 992px) {
  .container-app .left,
  .container-app .right {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .container-app {
    flex-direction: column;
  }
}

.title-cours .title {
  margin-top: 0;
  font-size: 24px; /* Augmenté la taille de la police */
}

.title-cours,
.title-quran {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-cours .title,
.title-quran .title {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
}

.title-cours a,
.title-quran a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--card);
  font-size: 16px;
  transition: background-color 0.3s;
}

.title-cours a:hover,
.title-quran a:hover {
  background-color: var(--text); /* Ajouté un style pour le survol (hover) */
  color: var(--color);
}

.title-cours img,
.title-quran img {
  width: 40px;
  height: 40px;
}

.list-courses {
  flex-direction: row-reverse;
}

.list-courses,
.list-quran {
  overflow-x: auto;
  display: flex;
  gap: 15px;
  padding-bottom: 20px;
}

.list-courses::-webkit-scrollbar,
.list-quran::-webkit-scrollbar {
  height: 8px;
}

.list-courses::-webkit-scrollbar-track,
.list-quran::-webkit-scrollbar-track {
  background: transparent;
}

.list-courses::-webkit-scrollbar-thumb,
.list-quran::-webkit-scrollbar-thumb {
  background: var(--placeholder);
}

.list-courses::-webkit-scrollbar-thumb:hover,
.list-quran::-webkit-scrollbar-thumb:hover {
  background: var(--text);
}

.list-courses > div {
  flex: 1 1 25%;
  cursor: grab;
}

@media (max-width: 576px) {
  .list-courses::-webkit-scrollbar,
  .list-quran::-webkit-scrollbar {
    display: none;
  }
  .list-courses,
  .list-quran {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
</style>
