<template>
  <div class="heure-priere">
    <div class="top">
      <p>
        <i class="fa-solid fa-location-dot"></i> {{ selectedCity || "Paris" }}
      </p>
      <router-link to="/priere">
        <i class="fa-solid fa-arrow-right-long"></i>
      </router-link>
    </div>
    <p class="angle">{{ angle }}° - Prochaine prière</p>
    <p class="heure">{{ getCurrentPrayer() }} à {{ getCurrentPrayerTime() }}</p>
    <div class="bottom">
      <img :src="getCurrentPrayerImage()" alt="" />
      <p class="countdown" :style="{ color: countdownBgColor }">
        -{{ countdown }}
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "time-pray",
  data() {
    return {
      prayers: [],
      showPopup: false,
      selectedCountry: "France",
      selectedCity: "",
      countries: [],
      cities: [],
      allCountries: [],
      countriesData: {},
      angle: "18",
      countdown: "",
      countdownBgColor: "#D3F2D7",
      images: {
        Fajr: "https://cp.doonun.com/uploads/cloudy_1146808_24384f3f5b.png",
        Dhuhr: "https://cp.doonun.com/uploads/sun_2698240_23269981eb.png",
        Asr: "https://cp.doonun.com/uploads/clouds_8324591_491ed4fcda.png",
        Maghrib: "https://cp.doonun.com/uploads/dawn_1146828_d7d8b3e5dc.png",
        Isha: "https://cp.doonun.com/uploads/cloudy_night_9755348_802ce5fb24.png",
      },
      loading: false,
      filteredCitiesData: [],
      cityFilterTimeout: null,
      cityIndex: {},
      selectedDay: null,
      showPopupInfoBox: false,
      currentMonthIndex: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      changeMonthG: 0,
      changeMonthH: 1,
      changeMonthHi: 0,
      currentHijriYear: null,
      isFinishVisible: true,
    };
  },
  watch: {
    selectedCountry(newValue) {
      this.searchCountryQuery = newValue;
    },
    selectedCity(newValue) {
      this.searchCityQuery = newValue;
      this.fetchPrayerTimes();
    },
  },

  created() {
    this.loadSelectedData();

    // Créez l'index des villes en fonction de leur première lettre
    this.cityIndex = {};

    if (!this.selectedCity) {
      this.fetchPrayerTimes();
    }

    setInterval(this.updateCountdown, 1000);
  },

  computed: {
    filteredCountries() {
      return this.allCountries.filter((country) =>
        country.toLowerCase().includes(this.searchCountryQuery.toLowerCase())
      );
    },
    filteredCities() {
      return this.filteredCitiesData;
    },
  },

  methods: {
    loadSelectedData() {
      const selectedCountry = localStorage.getItem("selectedCountry");
      const selectedCity = localStorage.getItem("selectedCity");

      if (selectedCountry && selectedCity) {
        this.selectedCountry = selectedCountry;
        this.selectedCity = selectedCity;
      }
    },

    async fetchPrayerTimes() {
      try {
        const today = new Date().toISOString().slice(0, 10);
        const address = this.selectedCity ? this.selectedCity : "Paris";
        const response = await axios.get(
          "https://api.aladhan.com/v1/timingsByAddress/" + today,
          {
            params: {
              address: address + "," + this.selectedCountry, // Utilisation de la ville actuelle sélectionnée
              method: 3,
            },
          }
        );
        if (response.status === 200) {
          const timings = response.data.data.timings;
          // Création d'un objet de mappage pour renommer certaines prières
          const prayerRenameMap = {
            Sunrise: "Shuruq",
            Firstthird: "1/3 de la nuit",
            Lastthird: "3/3 de la nuit",
          };
          // Filtrez pour ne garder que les prières qui nous intéressent
          const filteredTimings = {};
          const prayersOfInterest = [
            "Fajr",
            "Sunrise",
            "Dhuhr",
            "Asr",
            "Maghrib",
            "Isha",
            "Firstthird",
            "Lastthird",
          ];
          prayersOfInterest.forEach((prayer) => {
            if (timings[prayer]) {
              const prayerName = prayerRenameMap[prayer] || prayer;
              filteredTimings[prayerName] = timings[prayer];
            }
          });
          this.prayers = Object.entries(filteredTimings).map(
            ([salat, heure]) => ({ salat, heure })
          );
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des horaires de prière :",
          error
        );
      }
    },

    getCurrentPrayer() {
      const currentTime = new Date().toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
      // Filtrer uniquement les cinq prières de la journée
      const prayersOfInterest = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];
      const filteredPrayers = this.prayers.filter((prayer) =>
        prayersOfInterest.includes(prayer.salat)
      );

      let currentPrayer = "Fajr"; // Définir Fajr comme valeur par défaut
      for (const prayer of filteredPrayers) {
        if (currentTime < prayer.heure) {
          currentPrayer = prayer.salat;
          break;
        }
      }

      return currentPrayer;
    },

    getCurrentPrayerTime() {
      const currentPrayer = this.getCurrentPrayer();
      // Utiliser les prières filtrées pour trouver l'heure de la prière actuelle
      const filteredPrayers = this.prayers.filter((prayer) =>
        ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"].includes(prayer.salat)
      );
      const prayer = filteredPrayers.find(
        (item) => item.salat === currentPrayer
      );
      return prayer ? prayer.heure : "";
    },

    getNextPrayerTime() {
      const currentTime = new Date();
      const currentHours = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const currentTimeInMinutes = currentHours * 60 + currentMinutes;

      let nextPrayerTime = null;
      let smallestDifference = 24 * 60; // Maximum possible difference in minutes

      // Filter only the five prayers of the day
      const prayersOfInterest = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];
      const filteredPrayers = this.prayers.filter((prayer) =>
        prayersOfInterest.includes(prayer.salat)
      );

      let isIshaPassed = false;
      for (let i = 0; i < filteredPrayers.length; i++) {
        const prayer = filteredPrayers[i];
        const [hours, minutes] = prayer.heure.split(":").map(Number);
        const prayerTimeInMinutes = hours * 60 + minutes;

        if (
          prayer.salat === "Isha" &&
          currentTimeInMinutes > prayerTimeInMinutes
        ) {
          isIshaPassed = true;
        }

        if (!isIshaPassed) {
          const difference = prayerTimeInMinutes - currentTimeInMinutes;
          if (difference > 0 && difference < smallestDifference) {
            smallestDifference = difference;
            nextPrayerTime = new Date(
              currentTime.setHours(hours, minutes, 0, 0)
            );
          }
        } else {
          if (prayer.salat === "Fajr") {
            const difference =
              prayerTimeInMinutes + 24 * 60 - currentTimeInMinutes;
            if (difference < smallestDifference) {
              smallestDifference = difference;
              nextPrayerTime = new Date(
                currentTime.setHours(hours, minutes, 0, 0)
              );
              // Add 1 day to the Fajr time
              nextPrayerTime.setDate(nextPrayerTime.getDate() + 1);
            }
          }
        }
      }

      return nextPrayerTime ? nextPrayerTime.getTime() : null;
    },

    updateCountdown() {
      const nextPrayerTime = this.getNextPrayerTime();
      if (nextPrayerTime) {
        const now = new Date().getTime();
        const timeDifference = nextPrayerTime - now; // en millisecondes

        let hours = Math.floor(
          (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        let minutes = Math.floor(
          (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
        );
        let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        // Ajouter un zéro devant les nombres inférieurs à 10 pour les heures, minutes et secondes
        hours = hours < 10 ? `0${hours}` : hours;
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        seconds = seconds < 10 ? `0${seconds}` : seconds;

        this.countdown = `${hours}:${minutes}:${seconds}`;

        // Déterminer la couleur de fond en fonction du temps restant
        if (hours >= 2) {
          this.countdownBgColor = "#4FD777"; // Plus de 2 heures
        } else if (hours < 2 && minutes >= 1) {
          this.countdownBgColor = "#FAB03C"; // Entre 20 minutes et 2 heures
        } else if (hours < 1 && minutes < 20) {
          this.countdownBgColor = "red"; // Moins de 20 minutes
        }

        // Mettre à jour le DOM avec le compte à rebours
        this.$el.querySelector(".countdown").textContent = this.countdown;
      }
    },

    getCurrentPrayerImage() {
      const nextPrayer = this.getNextPrayerTime();
      if (nextPrayer) {
        const nextPrayerName = this.prayers.find((prayer) => {
          const [hours, minutes] = prayer.heure.split(":").map(Number);
          const prayerTime = new Date();
          prayerTime.setHours(hours, minutes, 0, 0);
          return prayerTime.getTime() === nextPrayer;
        }).salat;
        return this.images[nextPrayerName] || this.images.Fajr;
      } else {
        return this.images.Fajr; // Si aucune prochaine prière n'est trouvée, afficher Fajr par défaut
      }
    },
  },
};
</script>

<style scoped>
.heure-priere {
  background: var(--card);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
}

.heure-priere p {
  font-weight: 500;
  font-size: 14px;
}

.heure-priere .top {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
}

.heure-priere .top p {
  font-size: 16px;
  font-weight: 600;
}

.heure-priere .heure {
  font-size: 20px;
  font-weight: 800;
}

.heure-priere .bottom {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 20px;
  margin-top: 10px;
}

.heure-priere .bottom img {
  width: 40px;
  height: 40px;
}

.heure-priere .bottom p {
  font-size: 16px;
  font-weight: 700;
}
</style>
