<template>
  <div>
    <h2>Ce qu'ils pensent de Doonun</h2>
    <div class="list-avis">
      <div v-for="avis in avisList" :key="avis.id">
        <CardAvis
          :img="avis.img"
          :name="avis.name"
          :avis="avis.avis"
          :note="avis.note"
        />
      </div>
    </div>
    <div class="cont-img-faq">
      <img
        src="https://cp.doonun.com/uploads/32246988_m011t0438_g_interrogation_box_07sep22_cc48db95cf.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import CardAvis from "./CardAvis.vue";
import axios from "axios";

export default {
  name: "avis-d",
  components: {
    CardAvis,
  },
  data: () => ({
    avisList: [],
  }),
  mounted() {
    this.fetchAvisAndAvatars();
  },
  methods: {
    async fetchAvisAndAvatars() {
      try {
        const avisResponse = await axios.get("/avis-users?populate=*", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_T}`,
          },
        });
        const avatarsResponse = await axios.get("/avatars?populate=*", {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_T}`,
          },
        });

        // Mapping des avatars par user ID pour un accès rapide
        const avatarsByUserId = avatarsResponse.data.data.reduce(
          (acc, avatar) => {
            const userId =
              avatar.attributes.users_permissions_users.data.length > 0
                ? avatar.attributes.users_permissions_users.data[0].id
                : null;
            if (userId) {
              acc[
                userId
              ] = `https://cp.doonun.com${avatar.attributes.Avatar.data.attributes.formats.small.url}`;
            }
            return acc;
          },
          {}
        );

        this.avisList = avisResponse.data.data.map((avis) => {
          const userId = avis.attributes.users_permissions_user.data.id;
          const img =
            avatarsByUserId[userId] ||
            "https://cp.doonun.com/uploads/plant_6a63b26a77.png";
          return {
            id: avis.id,
            img,
            name: avis.attributes.users_permissions_user.data.attributes
              .username,
            avis: avis.attributes.Avis,
            note: avis.attributes.Note.toString(),
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
h2 {
  margin-top: 100px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 25px;
}

p {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
}

.cont-img-faq {
  width: 100%;
  margin-top: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.cont-img-faq img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: contain;
  max-width: 200px;
  height: 200px;
  animation: floating 3s ease-in-out infinite;
}

.list-avis {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
}

.list-avis::-webkit-scrollbar {
  height: 8px;
}

.list-avis::-webkit-scrollbar-track {
  background: transparent;
}

.list-avis::-webkit-scrollbar-thumb {
  background: var(--placeholder);
  transition: 0.2s ease-out;
}

.list-avis::-webkit-scrollbar-thumb:hover {
  background: var(--text);
}

.list-avis > a {
  display: inline-block;
  flex: 1 1 25%;
}

@media (max-width: 576px) {
  .list-avis::-webkit-scrollbar {
    display: none;
  }

  .list-avis {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .list-avis {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }

  .list-avis {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
}

@keyframes floating {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 686px) {
  h2 {
    margin-top: 20px;
  }
}
</style>
