<template>
    <div class="copy" @click="copy">
        <span class="tooltip" v-if="showTooltip">Copiés 🥰</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_outline_copy" data-name="vuesax/outline/copy" transform="translate(-684 -380)">
                <g id="copy">
                    <path id="Vector"
                        d="M9.85,15.5H5.65C1.74,15.5,0,13.76,0,9.85V5.65C0,1.74,1.74,0,5.65,0h4.2c3.91,0,5.65,1.74,5.65,5.65v4.2C15.5,13.76,13.76,15.5,9.85,15.5Zm-4.2-14C2.55,1.5,1.5,2.55,1.5,5.65v4.2C1.5,12.95,2.55,14,5.65,14h4.2C12.95,14,14,12.95,14,9.85V5.65c0-3.1-1.05-4.15-4.15-4.15Z"
                        transform="translate(685.25 387.25)" fill="#292d32" />
                    <path id="Vector-2" data-name="Vector"
                        d="M9.85,15.5H8.75A.755.755,0,0,1,8,14.75v-3.1C8,8.55,6.95,7.5,3.85,7.5H.75A.755.755,0,0,1,0,6.75V5.65C0,1.74,1.74,0,5.65,0h4.2c3.91,0,5.65,1.74,5.65,5.65v4.2C15.5,13.76,13.76,15.5,9.85,15.5ZM9.5,14h.35C12.95,14,14,12.95,14,9.85V5.65c0-3.1-1.05-4.15-4.15-4.15H5.65C2.55,1.5,1.5,2.55,1.5,5.65V6H3.85C7.76,6,9.5,7.74,9.5,11.65Z"
                        transform="translate(691.25 381.25)" fill="#292d32" />
                    <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(684 380)" fill="none"
                        opacity="0" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Copy-d',
    data() {
        return {
            showTooltip: false
        };
    },
    methods: {
        copy() {
            this.showTooltip = true;
            setTimeout(() => {
                this.showTooltip = false;
            }, 1000);
            this.$emit('copyClicked');
        }
    }
}
</script>

<style scoped>
.copy {
    position: relative;
}

.tooltip {
    position: absolute;
    bottom: 35px;
    right: -30px;
    font-size: 14px;
    background: var(--card);
    width: 85px;
    color: var(--text);
    padding: 5px 8px;
    border-radius: 5px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    opacity: 1;
    /* Assurez-vous que l'opacité est à 1 */
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: var(--card);
    bottom: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.copy svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.copy svg path {
    fill: var(--text)
}
</style>
