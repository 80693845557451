<template>
  <div class="container-resume">
    <h3 id="scroll-target">Mes cours</h3>

    <div v-if="loadingCourses" class="loading"><loadCircle /></div>

    <div v-else class="box-lists">
      <div v-if="myCourses.length > 0" class="list-course-u">
        <router-link
          v-for="course in reversedCourses"
          :key="course.details[0].id"
          :to="`/cours/${course.slug}`"
        >
          <CardCourseProfil
            :img="getCourseCover(course)"
            :titre="course.details[0].attributes.titre"
            :percent="Number(course.pourcentage)"
            :category="course.details[0].attributes.Category"
            :level="course.details[0].attributes.niveau"
          />
        </router-link>
      </div>

      <div v-else>
        <p style="text-align: center">
          Vous n'avez pas encore commencé de cours
        </p>
      </div>

      <div class="qur">
        <h3>Mes sourates</h3>
        <Favoris />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import loadCircle from "../loadCircle.vue";
import Favoris from "../Favoris.vue";
import CardCourseProfil from "../CardCourseProfil.vue";

export default {
  name: "reprendre-f",
  components: {
    loadCircle,
    CardCourseProfil,
    Favoris,
  },
  data() {
    return {
      user: null,
      token: localStorage.getItem("jwtToken"),
      lastVerse: [],
      lastVerseProgress: 0,
      lastVerseNumber: "",
      lastVerset: "",
      lastVerseSourate: "",
      lastVerseText: "",
      myCourses: [],
      tauxCourses: [],
      loadingCourses: true,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getUserPoints", "getToken"]),
    reversedCourses() {
      return this.myCourses.slice().reverse();
    },
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),

    async fetchCheckedVersets() {
      if (this.getUserInfo && this.getUserInfo.id) {
        const userId = this.getUserInfo.id;

        try {
          const response = await axios.get(
            `/versets-checks?filters[id_user][$eq]=${userId}&populate=*`,
            {
              headers: {
                Authorization: `Bearer ${this.getToken}`,
              },
            }
          );

          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const lastChecked =
              response.data.data[response.data.data.length - 1];
            this.lastVerseProgress = lastChecked.attributes.taux;
            this.lastVerseNumber = lastChecked.attributes.id_sourate;
            const versetList = lastChecked.attributes.list_verset
              .split("|")
              .map(Number); // Convertir en nombres
            versetList.sort((a, b) => a - b); // Trier en ordre croissant
            this.lastVerset = versetList[versetList.length - 1]; // Obtenir le dernier élément

            // Faire une requête supplémentaire pour les informations de la sourate
            const sourateResponse = await axios.get(
              `/sourates/${this.lastVerseNumber}`,
              {
                params: {
                  populate: ["Phonetique", `versets.numero.${this.lastVerse}`],
                },
              }
            );
            if (sourateResponse.data && sourateResponse.data.data) {
              const sourateData = sourateResponse.data.data;
              this.lastVerseSourate = sourateData.attributes.Phonetique;

              const versetData = sourateData.attributes.versets.data.find(
                (v) => v.attributes.numero === parseInt(this.lastVerset)
              );
              this.lastVerseText = versetData
                ? versetData.attributes.fr
                : "Texte non trouvé";
            }
          }
        } catch (error) {
          // console.error("Erreur lors de la récupération des informations:", error);
        }
      } else {
        // console.error("Utilisateur non défini");
      } // Assurez-vous que l'utilisateur est connecté
    },

    async fetchCourseSuivi() {
      try {
        // Récupérer les suivis de l'utilisateur
        const myCourseSuivis = await axios.get("/suivis", {
          params: {
            "filters[user_id][$eq]": this.getUserInfo.id,
          },
          headers: { Authorization: `Bearer ${this.getToken}` },
        });

        this.myCourses = [];

        for (let suivi of myCourseSuivis.data.data) {
          const slug = suivi.attributes.slug;
          const pourcentage = suivi.attributes.pourcentage;

          // Récupérer les informations de chaque cours
          const courseResponse = await axios.get(`/courses`, {
            headers: { Authorization: `Bearer ${this.getToken}` },
            params: {
              "filters[url][$eq]": slug,
              populate: "*",
            },
          });

          if (courseResponse.data && courseResponse.data.data) {
            // Ajouter les informations du cours avec le pourcentage de suivi
            this.myCourses.push({
              details: courseResponse.data.data,
              slug: slug,
              pourcentage: pourcentage,
            });
          }
        }

        // console.log('Détails de mes cours suivis', this.myCourses);
      } catch (error) {
        // console.error("Erreur lors de la récupération des cours:", error);
      }
    },

    getCourseCover(course) {
      return `https://cp.doonun.com${course.details[0].attributes.Couverture.data.attributes.formats.medium.url}`;
    },
  },
  async mounted() {
    await Promise.all([this.fetchCheckedVersets(), this.fetchCourseSuivi()]);
    this.loadingCourses = false;
  },
};
</script>

<style scoped>
.container-resume {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 100px;
}

h3 {
  font-size: 25px;
  margin-bottom: 0 !important;
}

.box-lists {
  width: 100% !important;
}

@media (max-width: 800px) {
  .container-resume {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .container-resume {
    flex-direction: column;
  }
}
.container-resume h3 {
  margin-bottom: 30px;
}
.loading {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.list-course-u {
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 15px;
  padding-bottom: 20px;
}

.list-course-u::-webkit-scrollbar {
  height: 8px;
}

.list-course-u::-webkit-scrollbar-track {
  background: transparent;
}

.list-course-u::-webkit-scrollbar-thumb {
  background: var(--placeholder);
  transition: 0.2s ease-out;
}

.list-course-u::-webkit-scrollbar-thumb:hover {
  background: var(--text);
}

.list-course-u > a {
  display: inline-block;
  flex: 1 1 25%;
}

@media (max-width: 576px) {
  .list-course-u::-webkit-scrollbar {
    display: none;
  }

  .list-course-u {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .list-course-u {
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
  }

  .list-course-u {
    scrollbar-width: none;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
}

.qur {
  margin-top: 40px;
}

.qur h3 {
  margin-bottom: 20px !important;
}

.verse-resume {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: var(--padding);
  border-radius: var(--br10);
  background: var(--card);
  margin-bottom: 30px;
  transition: 0.2s;
}

.verse-resume p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.resume:hover {
  background: var(--button);
}

.pr {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-top: 20px;
  margin-right: 20px;
}

.pr svg {
  width: 50px !important;
  height: 50px !important;
}
</style>
