<template>
    <div class="loader"></div>
</template>

<script>
export default {
    name: 'loader-d',
}
</script>

<style scoped>
.loader {
    border: 4px solid var(--text);
    border-left-color: transparent;
    border-radius: 50%;
}

.loader {
    border: 4px solid var(--text);
    border-left-color: transparent;
    width: 16px;
    height: 16px;
}

.loader {
    border: 4px solid var(--text);
    border-left-color: transparent;
    width: 16px;
    height: 16px;
    animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>