<template>
  <router-link :to="`/sourate/${sourate}#${numero}`">
    <div class="versets">
      <p class="num-v">{{ phonetique }} : {{ numero }} - {{ trad }}</p>
      <p class="french" v-html="highlightedFrench"></p>
      <!-- <div class="top-v">
            </div> -->
      <p class="arabe" v-html="highlightedArabic"></p>
      <!-- <router-link :to="`/ibn-kathir/${sourate}/${numero}`">
        <p>Voir le tafsir</p>
      </router-link> -->
    </div>
  </router-link>
</template>

<script>
export default {
  name: "versetC-d",
  props: {
    sourate: Number,
    numero: Number,
    arabe: String,
    french: String,
    phonetique: String,
    searchTerm: String,
    trad: String,
  },
  computed: {
    highlightedArabic() {
      return this.highlightText(this.arabe, this.searchTerm);
    },
    highlightedFrench() {
      return this.highlightText(this.french, this.searchTerm);
    },
  },
  methods: {
    highlightText(text, searchTerm) {
      if (!searchTerm) return text;

      // Créez une expression régulière pour rechercher le terme recherché sans tenir compte de la casse
      const regex = new RegExp(
        searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
        "gi"
      );

      return text.replace(
        regex,
        (matched) =>
          `<span class="highlight" style="background-color: yellow !important; color: #000 !important;">${matched}</span>`
      );
    },
  },
};
</script>

<style scoped>
.versets {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 35px 20px;
  background: var(--card);
}

.versets .top-v {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
}

.num-v {
  font-size: 18px;
  font-weight: 500;
}

.arabe {
  text-align: right;
  font-size: 25px;
  font-family: "Uthmanic", sans-serif;
  font-weight: 500;
}

.french {
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}

@media (max-width: 480px) {
  .versets {
    margin-top: 10px;
    padding: 0;
  }

  .arabe {
    padding-bottom: 20px;
  }
}
</style>
