<template>
  <div class="box-header">
    <div class="container">
      <div class="bx-h">
        <div class="info-hero">
          <h1>
            Semez les graines de <span>la connaissance</span> et récoltez
            <span>les fruits</span> de la foi
          </h1>
          <p>
            Rejoignez Doonun et remplissez votre devoir islamique d'apprendre;
            chaque pas vers la connaissance est un pas vers le paradis.
          </p>
          <router-link to="/inscription">
            <Go />
          </router-link>
        </div>
        <div class="box-img-hero">
          <img :src="`${url}uploads/Fichier_1d_4x_1f14a0e0ee.png`" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Go from "./Go.vue";
export default {
  name: "Hero-d",
  components: {
    Go,
  },
  data() {
    return {
      url: process.env.VUE_APP__URL,
    };
  },
};
</script>

<style scoped>
.box-header {
  margin-top: -40px;
  width: 100%;
  background: rgb(248, 249, 254);
  background: linear-gradient(
    145deg,
    rgba(248, 249, 254, 1) 0%,
    rgba(240, 247, 255, 1) 19%,
    rgba(250, 248, 235, 1) 100%
  );
  padding: 50px 0 0 0;
  min-height: 500px;
}

.container {
  padding-bottom: 0;
  margin-bottom: 30px;
}

.bx-h {
  max-width: 1400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.box-header .info-hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
}

.box-header .info-hero a {
  padding-bottom: 20px;
}

.box-header .info-hero h1 {
  color: #000 !important;
  font-size: 2.5vw;
  font-weight: 800;
  line-height: 3vw;
}

.box-header .info-hero h1 span {
  background: #c39afc;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.box-header .info-hero p {
  font-size: 18px;
  color: #000;
}

.box-header .inscription {
  display: inline-block;
  padding: var(--padding) 30px;
  border-radius: 5px;
  background: var(--button);
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
}

.box-img-hero {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: auto;
  width: 100%;
  max-height: 500px;
  /* height: auto; */
}

.box-img-hero img {
  max-width: 100%;
  max-height: 500px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

@media screen and (max-width: 1300px) {
  .box-header {
    min-height: 500px;
  }

  .box-img-hero {
    min-height: 500px;
  }

  .box-img-hero img {
    max-width: 500px;
    max-height: 500px;
  }

  .box-header .info-hero h1 {
    font-size: 3vw;
    line-height: 3.5vw;
  }
}

@media screen and (max-width: 768px) {
  .box-header {
    grid-template-columns: 1fr;
  }

  .box-img-hero {
    height: 300px;
    justify-content: center;
  }

  .box-header .info-hero h1 {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 2.3rem;
  }

  .box-header .info-hero p {
    font-size: 16px;
  }

  .box-header .info-hero {
    gap: 20px;
  }

  .box-header .inscription {
    padding: 10px 20px;
    border-radius: 10px;
  }

  .title-c-section {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .box-img-hero {
    min-height: 300px;
  }
}
</style>
