<template>
    <div class="container">
        <div class="box-cgv">
            <h1>Conditions générales de vente</h1>
            <Head>
                <title>CGV - Doonun</title>
                <meta name="robots" content="Noindex, nofollow">
            </Head>
            <h2>Règlement et conditions</h2>

            <h3>1 - Abonnement pour l'accès illimité à toutes les fonctionnalités de Doonun</h3>

            <p><strong>1) Déroulement de l'abonnement</strong></p>
            <p>L'abonnement offre un accès illimité à toutes les fonctionnalités de Doonun, y compris les cours. Pour
                débuter les cours, l'utilisateur doit se connecter à son compte et suivre les
                instructions fournies.</p>

            <p><strong>2) Modalités de l'abonnement</strong></p>
            <p>Doonun propose des abonnements mensuels, annuels et à vie. Pour les abonnements mensuels et annuels,
                l'utilisateur a la possibilité de résilier son abonnement à tout moment.</p>

            <p><strong>3) Engagement de l'utilisateur</strong></p>
            <p>En s'abonnant, l'utilisateur s'engage à respecter les conditions d'utilisation de la plateforme et à utiliser
                les services de manière appropriée. Tout comportement abusif ou contraire aux règles peut entraîner la
                résiliation de l'abonnement sans remboursement.</p>

            <h2>2 - Modalités de paiement</h2>

            <p><strong>1) Tarifs et options de paiement</strong></p>
            <p>Les tarifs des abonnements sont indiqués sur le site de Doonun. Le paiement s'effectue par
                carte bancaire après l'inscription à la platerforme. Pour sécuriser les transactions, Doonun utilise Stripe
            </p>

            <p><strong>2) Remboursement</strong></p>
            <p>Pour les abonnements mensuels et annuels, aucun remboursement n'est possible après le début de la période
                d'abonnement. Les abonnements à vie ne sont pas remboursables.</p>

            <p>Doonun s'engage à respecter ses abonnés et à fournir des services de qualité conformes aux attentes. Pour
                toute question ou préoccupation concernant les abonnements, veuillez contacter notre service client à
                l'adresse suivante : <a href="mailto:contact@doonun.com">contact@doonun.com</a>.</p>

        </div>
    </div>
</template>

<script>
import { Head } from '@vueuse/head';
export default {
    name: 'cgv-d',
    components : {
        Head
    }
}
</script>

<style scoped>
.box-cgv {
    display: block;
    margin: 0 auto;
    max-width: 1100px;
    width: 100%;
    padding: 50px 40px;
    background: var(--card);
    border-radius: 10px;
}

h1 {
    text-align: center;
    margin-bottom: 30px;
}

h2,
h3 {
    margin-bottom: 20px;
}

p {
    text-align: justify;
    margin-bottom: 30px;
}

@media (max-width: 480px) {
    .container {
        margin-top: -40px;
        background: var(--card);
    }

    .box-cgv {
        padding: 40px 0;
    }
}
</style>