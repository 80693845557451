<template>
    <div class="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="9.121" height="9.121" viewBox="0 0 9.121 9.121">
            <g id="Groupe_1" data-name="Groupe 1" transform="translate(-549.439 1644.561)">
                <path id="Vector" d="M0,7,7,0" transform="translate(550.5 -1643.5)" fill="none" stroke="#292d32"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                <path id="Vector-2" data-name="Vector" d="M7,7,0,0" transform="translate(550.5 -1643.5)" fill="none"
                    stroke="#292d32" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'closem-d',
}
</script>

<style scoped>
.close {
    display: flex;
    cursor: pointer;
    padding: 10px;
}

.close svg {
    width: 12px;
    height: 12px;
}

.close svg path {
    stroke: #000 !important;
    transition: .2s ease-in;
}
</style>