<template>
  <div class="container">
    <h1>Invocations</h1>
    <div class="input-search-c">
      <input
        type="search"
        v-model="searchQuery"
        placeholder="Rechercher une catégorie..."
      />
    </div>
    <div class="c-duaa">
      <!-- Utilisation de la boucle v-for pour afficher les catégories -->
      <router-link
        v-for="category in filteredCategories"
        :key="category.id"
        :to="`/duaas/${category.slug}`"
        class="Card-duaa"
      >
        <div class="img-duaa">
          <img :src="category.iconUrl" :alt="category.name" />
        </div>
        <div class="info-duaa">
          <p>{{ category.name }}</p>
        </div>
      </router-link>
    </div>
  </div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <Tabs />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { useHead, Head } from "@vueuse/head";
import Tabs from "../components/Tabs.vue";
export default {
  name: "invo-d",
  components: { Head, Tabs },
  setup() {
    useHead({
      title: "Duaas pour chaque situation",
      meta: [
        {
          name: `description`,
          content:
            "Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins.",
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: "Duaas pour chaque situation",
        },
        {
          property: "og:description",
          content:
            "Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins.",
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/duaas_73117ce2be.png",
        },
        {
          property: "og:url",
          content: "https://www.doonun.com/coran",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "Duaas pour chaque situation",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content:
            "Découvrez l'importance des invocations islamiques dans votre vie quotidienne avec notre sélection de Douas sur Doonun. Trouvez la citadelle du musulman dans la protection et la guidance offertes par les invocations. Apprenez la signification et la prononciation correcte de chaque Doua en arabe et en français. Renforcez votre foi et votre connexion avec Allah en intégrant ces invocations importantes dans vos prières quotidiennes. Explorez notre sélection dès maintenant pour trouver les invocations les plus pertinentes pour vos besoins.",
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/duaas_73117ce2be.png",
        },
      ],
    });
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      categories: "categoriesList",
    }),
    filteredCategories() {
      return this.categories.filter((category) =>
        category.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions(["fetchCategories"]),
  },
  created() {
    this.$store.dispatch("initCategories");
    this.fetchCategories();
  },
};
</script>

<style scoped>
h1 {
  text-align: center;
  margin-bottom: 20px;
}

.input-search-c {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  background: var(--card);
  border-radius: var(--br10);
  margin-bottom: 20px;
}

.input-search-c input {
  width: 100%;
  max-width: 600px;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text);
  font-family: "Poppins", "Inter", sans-serif;
}

.input-search-c input::placeholder {
  color: var(--placeholder);
}

.c-duaa {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: stretch;
  justify-content: start;
  gap: 10px;
}

.Card-duaa {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: var(--card);
  border-radius: 10px;
  max-width: 500px;
}

.img-duaa {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.img-duaa img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  object-position: center;
}

.info-duaa {
  display: flex;
  align-items: center;
  justify-content: start;
}

.info-duaa p {
  font-weight: 600;
}
</style>
