<template>
  <div class="container">
    <div v-if="hasSubmitted">
      <div class="success-submit">
        <p class="emoji">👋😊</p>
        <p>Nous avons bien reçu votre retour d'expérience. Merci !</p>
        <svg
          fill="none"
          height="512"
          viewBox="0 0 24 24"
          width="512"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <linearGradient
            id="paint0_linear_1796_28334"
            gradientUnits="userSpaceOnUse"
            x1="13.06"
            x2="13.06"
            y1="2.714"
            y2="21.897"
          >
            <stop offset="0" stop-color="#fd6700" />
            <stop offset="1" stop-color="#ffce00" />
          </linearGradient>
          <path
            clip-rule="evenodd"
            d="m20.0146 2.73961c.4001.10721.6375.51846.5303.91856l-.366 1.36602c-.1072.4001-.5185.63754-.9186.53033-.4001-.1072-.6375-.51846-.5303-.91856l.366-1.36602c.1072-.4001.5185-.63754.9186-.53033zm-4.1461 1.69618c-.6728-1.16534-2.2813-1.34714-3.1972-.36138l-6.44852 6.94009c.22497.2041.42253.446.58254.7232l2.5 4.3301c.16003.2772.2708.5693.33503.8662l9.23455-2.1146c1.3117-.3003 1.9585-1.7842 1.2857-2.9495zm-8.02038 12.29131-2.5-4.3301c-.41422-.7174-1.3316-.9632-2.04904-.549s-.96325 1.3316-.54904 2.049l2.5 4.3301c.41421.7175 1.3316.9633 2.04904.5491s.96325-1.3316.54904-2.0491zm5.86118 4.4196-1.9034-3.2967 3.0596-.7006 1.4419 2.4974c.4142.7174.1684 1.6348-.5491 2.049-.7174.4142-1.6348.1684-2.049-.5491zm7.9392-13.57689c-.4001-.10721-.8113.13023-.9185.53033s.1302.81135.5303.91856l1.366.36602c.4001.10721.8114-.13023.9186-.53033s-.1302-.81135-.5303-.91856z"
            fill="url(#paint0_linear_1796_28334)"
            fill-rule="evenodd"
          />
        </svg>
        <router-link to="/"><button>Revenir à l'accueil</button></router-link>
      </div>
    </div>
    <div v-else>
      <Head>
        <title>Avis</title>
        <meta name="robots" content="Noindex, nofollow" />
        <link
          rel="icon"
          href="https://cp.doonun.com/uploads/logo_058fb96f65.svg"
        />
      </Head>
      <div class="h-info">
        <h1>Qu'avez-vous pensé de Doonun ?</h1>
        <p class="info">
          Votre avis nous aidera à nous améliorer, <br />n'hésitez pas à nous
          partager votre expérience de la plateforme.
        </p>
      </div>
      <div class="input-avis">
        <form @submit.prevent="submitFeedback">
          <div class="input-note">
            <input
              type="number"
              v-model="feedback.rating"
              placeholder="0"
              min="1"
              max="5"
            /><span>/5</span>
          </div>
          <textarea
            v-model="feedback.text"
            maxlength="350"
            placeholder="Commencez à écrire..."
            @input="updateCharacterCount"
            autofocus
          ></textarea>
          <span class="count-character">{{ characterCount }}/350</span>

          <button type="submit">Soumettre</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { Head } from "@vueuse/head";

export default {
  name: "avis-p",
  components: {
    Head,
  },
  data() {
    return {
      feedback: {
        text: "",
        rating: 0,
      },
      characterCount: 0,
      hasSubmitted: false,
    };
  },
  computed: {
    ...mapGetters("user", ["getUserInfo", "getToken"]),
  },
  mounted() {
    this.checkUserFeedback();
  },
  methods: {
    ...mapActions("user", ["fetchUserInfo"]),
    updateCharacterCount(event) {
      this.characterCount = event.target.value.length;
    },
    async checkUserFeedback() {
      const userInfo = this.getUserInfo;
      try {
        const response = await axios.get(
          `https://cp.doonun.com/api/avis-users?filters[users_permissions_user][id][$eq]=${userInfo.id}&populate=*`,
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );
        if (response.data && response.data.data.length > 0) {
          this.hasSubmitted = true;
        }
      } catch (error) {
        console.error(
          "Erreur lors de la vérification de l'avis de l'utilisateur",
          error
        );
        this.hasSubmitted = false; // Assurez-vous de gérer cet état correctement
      }
    },
    async submitFeedback() {
      const userInfo = this.getUserInfo;
      try {
        await axios.post(
          "/avis-users",
          {
            data: {
              Avis: this.feedback.text,
              Note: this.feedback.rating,
              users_permissions_user: userInfo.id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.getToken}`,
            },
          }
        );
        this.hasSubmitted = true;
      } catch (error) {
        console.error("Erreur lors de la soumission de l'avis", error);
      }
    },
  },
};
</script>

<style scoped>
.h-info {
  margin-top: 40px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px 10px 0 0;
  max-width: 800px;
  width: 100%;
  padding: 60px 20px 0 20px;
  background: var(--card);
}

h1 {
  text-align: center;
}

.info {
  text-align: center;
}

.input-avis {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 20px;
  background: var(--card);
  border-radius: 0 0 10px 10px;
  max-width: 800px;
  width: 100%;
}

form {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

form input,
form textarea {
  border: none;
  background: transparent;
  color: var(--text);
  resize: none;
  background: var(--body);
  border-radius: 10px;
  padding: 10px;
}

form textarea {
  padding: 20px;
  color: var(--text) !important;
  min-height: 300px;
  font-weight: 600;
  font-size: 18px;
}

form input {
  width: 60px;
  height: 60px;
  text-align: center;
  background: var(--body);
  font-size: 30px;
  font-weight: 700;
}

form input::-webkit-outer-spin-button,
form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-note span {
  font-size: 18px;
  font-weight: 700;
}

.input-avis button {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background: var(--text);
  color: var(--color);
  padding: 10px 30px;
  border-radius: 30px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  font-size: 16px;
}

.count-character {
  text-align: end;
}

.success-submit {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

.success-submit p {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.success-submit .emoji {
  font-size: 30px;
}

.success-submit svg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  height: 200px;
}

.success-submit a {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.success-submit button {
  border: none;
  padding: 15px 30px;
  background: var(--text);
  color: var(--color);
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  transition: 0.2s;
}

.success-submit button:hover {
  background: var(--button);
  color: #fff;
}
</style>
