<template>
  <div class="container-subscribe">
    <h3 id="scroll-target">Abonnement</h3>

    <div class="box-mySub" v-if="hasSubscription">
      <h3 v-if="hasSubscription">{{ offerType }}</h3>
      <p v-if="hasSubscription">
        <i class="fa-regular fa-calendar-days"></i> Actif depuis le {{ date }}
      </p>
      <a
        href="https://billing.stripe.com/p/login/fZecOc8jS3ao4HS7ss"
        target="_blank"
        v-if="hasSubscription && oneTime == false"
      >
        <div class="manag-sub">
          <span>Gérer mon abonnement</span>
        </div>
      </a>
    </div>

    <div class="list-billings" v-if="!hasSubscription">
      <div class="tarif month">
        <p><span>4.99€</span> 🍂 Offre étudiant/mois</p>
        <button
          type="button"
          class="SS_ProductCheckout"
          :data-id="8"
          :data-email="userEmail"
          @click="handleStripeCheckout"
        >
          <span v-if="loading[8]">
            <loadCircle />
          </span>
          <span v-else>S'abonner</span>
        </button>
      </div>
      <div class="tarif year">
        <p>
          <span>40.98€</span> <span class="annuelle">-36%</span> 🍂 Offre
          étudiant/année
        </p>
        <button
          type="button"
          class="SS_ProductCheckout"
          :data-id="9"
          :data-email="userEmail"
          @click="handleStripeCheckout"
        >
          <span v-if="loading[9]">
            <loadCircle />
          </span>
          <span v-else>S'abonner</span>
        </button>
      </div>
      <div class="tarif life">
        <p><span>147€</span> 🍂 Obtenir Doonun en une seule fois !</p>
        <button
          type="button"
          class="SS_ProductCheckout"
          :data-id="3"
          :data-email="userEmail"
          @click="handleStripeCheckout"
        >
          <span v-if="loading[3]">
            <loadCircle />
          </span>
          <span v-else>Prendre</span>
        </button>
      </div>
    </div>

    <div class="discord">
      <p>Rejoindre la communauté sur Discord</p>
      <a href="https://discord.gg/YtjESS5SjC" target="_blank">
        <span>Rejoindre</span>
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loadCircle from "../loadCircle.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "sub-f",
  components: {
    loadCircle,
  },
  data() {
    return {
      user: null,
      userEmail: "",
      loading: {},
      token: localStorage.getItem("jwtToken"),
    };
  },
  computed: {
    ...mapGetters("sub", ["subscriptionDetails", "isSubscribed"]),
    hasSubscription() {
      return this.isSubscribed;
    },
    offerType() {
      return this.subscriptionDetails.offerType;
    },
    date() {
      return this.subscriptionDetails.date;
    },
    oneTime() {
      return this.subscriptionDetails.isOneTime;
    },
  },
  async mounted() {
    this.loadStripeScript();
    await this.fetchUser();
    if (this.token && this.userEmail) {
      await this.checkSubscriptionStatus();
    }
  },
  methods: {
    ...mapActions("sub", ["checkSubscriptionStatus"]),

    loadStripeScript() {
      if (
        !document.querySelector(
          'script[src="https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js";
        script.onload = this.initializeStripeButton;
        document.head.appendChild(script);
      }
    },

    handleStripeCheckout(event) {
      const button = event.currentTarget;
      const planId = button.getAttribute("data-id");
      const userEmail = button.getAttribute("data-email");
      this.loading[planId] = true;

      axios
        .get(
          `https://cp.doonun.com/strapi-stripe/getRedirectUrl/${planId}/${userEmail}`,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        )
        .then((response) => {
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la redirection vers Stripe:", error);
          this.loading[planId] = false;
        });
    },

    async fetchUser() {
      if (this.token) {
        try {
          const response = await axios.get("users/me", {
            headers: { Authorization: `Bearer ${this.token}` },
          });
          this.user = response.data;
          this.userEmail = this.user.email;
          // Mettre à jour l'e-mail de l'utilisateur dans le store
          this.$store.commit("sub/SET_USER_EMAIL", this.user.email);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur:",
            error
          );
        }
      }
    },

    convertTimestampToDate(timestamp) {
      let date = new Date(timestamp);
      return `${date.getDate()} ${date.toLocaleString("default", {
        month: "long",
      })} ${date.getFullYear()}`;
    },
  },
};
</script>

<style scoped>
h3 {
  font-size: 25px;
  margin-bottom: 30px;
}

.container-subscribe {
  margin-top: 100px;
}

@media (max-width: 800px) {
  .container-subscribe {
    margin-top: 30px;
  }

  h3 {
    margin-bottom: 20px !important;
  }
}

.container-subscribe .btn-g {
  margin: 20px 0;
}

.sugg {
  margin-bottom: 20px;
}

.list-billings {
  margin-top: 20px;
}

.list-billings .tarif {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--card);
  border-radius: 10px;
  padding: 10px 10px 10px 20px;
  margin-bottom: 10px;
  color: var(--text);
  gap: 20px;
  outline: 1px solid var(--text);
}

.list-billings .tarif span {
  font-weight: 600;
}

.list-billings .tarif p span {
  font-size: 18px;
  font-weight: 700;
}

.list-billings .tarif button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  color: var(--color);
  background: var(--black);
  padding: 10px 10px;
  border-radius: 10px;
  min-width: 90px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.2s;
}

.list-billings .tarif button:hover {
  background: var(--button);
  color: #fff;
}

.annuelle {
  background: #ff3358;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
}

.discord {
  padding: 50px 20px;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background: rgb(171, 119, 242);
  background: linear-gradient(
    45deg,
    rgba(171, 119, 242, 1) 0%,
    rgba(66, 112, 222, 1) 100%
  );
  border-radius: 10px;
}

.discord p {
  text-align: center;
  line-height: 1em;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

.discord a {
  padding: 10px 60px;
  border-radius: 30px;
  background: #fff;
  color: #000;
  transition: 0.2s ease;
}

.discord a:hover {
  background: #5865f2;
  color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.discord a span {
  font-weight: 500;
}

.discord div {
  display: flex;
  align-items: center;
  gap: 10px;
  transition: 0.2s ease-in;
}

.discord:hover .arrow {
  margin-right: -10px;
}

.discord img {
  margin-top: -70px;
  position: absolute;
  margin-left: -20px;
  width: 95%;
}

.box-mySub {
  margin-top: 10px;
  position: relative;
  padding: 20px;
  background: rgb(253, 196, 72);
  background: linear-gradient(
    45deg,
    rgba(253, 196, 72, 1) 0%,
    rgba(255, 212, 117, 1) 100%
  );
  border-radius: 8px;
  color: #000;
}

.box-mySub h3 {
  color: #000 !important;
}

.box-mySub p {
  font-weight: 600;
}

.manag-sub {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 10px;
  background: #000;
  color: var(--white);
  border-radius: 8px;
  transition: 0.2s ease;
}

.manag-sub:hover {
  background: var(--button);
}
</style>
