import axios from "axios";
export default {
  namespaced: true,
  state: () => ({
    coursList: [],
    currentCours: null,
    filter: {
      searchTerm: "",
      level: "",
      category: "",
    },

    recherche: "",
    level: "",
    category: "",
  }),
  getters: {
    // Getters pour accéder ou calculer l'état des cours
    filteredCoursList: (state) => {
      const searchTerm = state.filter.searchTerm.toLowerCase().trim();
      const levelFilter = state.filter.level.toLowerCase().trim();
      const categoryFilter = state.filter.category.toLowerCase().trim();
      return state.coursList.filter((course) => {
        const titreMatch = course.titre.toLowerCase().includes(searchTerm);
        const pointsMatch = course.points
          ? course.points.toString().toLowerCase().includes(searchTerm)
          : false;
        const niveauMatch = levelFilter
          ? course.niveau
            ? course.niveau.toLowerCase() === levelFilter
            : false
          : true;
        const categoryMatch = categoryFilter
          ? course.Category
            ? course.Category.toLowerCase() === categoryFilter
            : false
          : true;
        return (titreMatch || pointsMatch) && niveauMatch && categoryMatch;
      });
    },

    lastFiveCours: (state) => {
      return state.coursList.slice(-4);
    },

    getCurrentCours: (state) => state.currentCours,
  },
  mutations: {
    SET_COURS_LIST(state, cours) {
      state.coursList = cours;
    },
    SET_COURS_DETAILS(state, coursDetails) {
      state.currentCoursDetails = coursDetails;
    },
    SET_CURRENT_COURS(state, coursDetails) {
      state.currentCours = coursDetails;
    },
    SET_FILTER(state, filter) {
      state.filter = { ...filter };
    },

    SET_RECHERCHE(state, recherche) {
      state.recherche = recherche;
    },
    SET_LEVEL(state, level) {
      state.level = level;
    },
    SET_CATEGORY(state, category) {
      state.category = category;
    },
  },
  actions: {
    async fetchCoursList({ commit }) {
      let page = 1;
      let hasMorePages = true;
      let allCoursData = [];

      try {
        while (hasMorePages) {
          // Logique pour récupérer la liste des cours avec pagination et filtres
          const response = await axios.get("/courses", {
            params: {
              "pagination[page]": page,
              "pagination[pageSize]": 100,
              fields: ["titre", "points", "niveau", "url", "Category"],
              populate: "Couverture",
            },
          });

          // console.log("list des cours : ", response);

          // Traiter la réponse et extraire les données de cours
          const fetchedCours = response.data.data.map((item) => ({
            ...item.attributes,
          }));

          allCoursData = allCoursData.concat(fetchedCours);

          // Vérifier si la page actuelle est la dernière
          hasMorePages =
            response.data.meta.pagination.page <
            response.data.meta.pagination.pageCount;
          page++;
        }

        // Une fois toutes les pages récupérées, commitez la mutation pour mettre à jour l'état
        commit("SET_COURS_LIST", allCoursData);
      } catch (error) {
        // console.error("Erreur lors de la récupération de la liste des cours:", error);
        commit("SET_COURS_LIST", []);
      }
    },
    async fetchCoursDetails({ commit, rootState }, slug) {
      try {
        const response = await axios.get("/courses", {
          params: {
            "filters[url][$eq]": slug,
            fields: [
              "titre",
              "url",
              "description",
              "niveau",
              "points",
              "Category",
            ],
            populate: ["Couverture", "chapitres", "chapitres.numero"],
          },
        });

        console.log("détail du cours", response);

        if (response.data.data.length > 0) {
          const coursDetails = response.data.data[0];
          commit("SET_CURRENT_COURS", coursDetails);

          if (rootState.user.userInfo && rootState.user.token) {
            const progressResponse = await axios.get("/suivis", {
              params: {
                "filters[user_id][$eq]": rootState.user.userInfo.id,
                "filters[slug][$eq]": slug,
              },
              headers: { Authorization: `Bearer ${rootState.user.token}` },
            });

            if (progressResponse.data.data.length > 0) {
              const progressData = progressResponse.data.data[0].attributes;
              const idSuivi = progressResponse.data.data[0].id;

              commit(
                "user/UPDATE_USER_PROGRESS",
                { courseId: slug, progress: progressData, idSuivi },
                { root: true }
              );
            }
          }
        } else {
          // console.error("Aucun cours trouvé pour le slug:", slug);
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des détails du cours:", error);
      }
    },

    updateFilter({ commit }, filter) {
      commit("SET_FILTER", filter);
    },

    async fetchChaptersDetails({ commit }, slug) {
      try {
        const responseCourse = await axios.get("/courses", {
          params: {
            "filters[url][$eq]": slug,
            populate: [
              "chapitres",
              "chapitres.contenus",
              "chapitres.contenus.quizzes",
              "chapitres.contenus.audio",
            ],
          },
        });

        if (responseCourse.data.data.length > 0) {
          const courseDetails = responseCourse.data.data[0];
          commit("SET_COURS_DETAILS", courseDetails);
        } else {
          // console.error("Aucun cours trouvé pour l'URL:", slug);
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des détails du cours:", error);
      }
    },
  },
};
