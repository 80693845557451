<template>
  <label>
    <svg
      class="hamburger"
      @click.prevent.stop="toggleSidebar"
      xmlns="http://www.w3.org/2000/svg"
      width="20.64"
      height="24"
      viewBox="0 0 20.64 24"
    >
      <g
        id="vuesax_linear_menu"
        data-name="vuesax/linear/menu"
        transform="translate(-684 -380)"
      >
        <g id="menu" transform="translate(684 380)">
          <path
            id="Vector"
            d="M0,0H7.32"
            transform="translate(10.32 7)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-width="2"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H14.64"
            transform="translate(3 12)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-width="2"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H7.32"
            transform="translate(3 17)"
            fill="none"
            stroke="#292d32"
            stroke-linecap="round"
            stroke-width="2"
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M0,0H20.64V24H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  </label>
  <div
    v-if="showSidebar"
    class="sidebar"
    @click.stop="handleSidebarClick"
    ref="sidebarRef"
  >
    <div class="box-sider slideInLeft" ref="boxSiderRef">
      <div class="liens">
        <ul>
          <li @click.prevent.stop="toggleSidebar">
            <div class="logo">
              <router-link to="/">
                <div class="img-logo">
                  <Logo />
                </div>
              </router-link>
              <p class="name">Doonun</p>
            </div>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/" class="link">
              <p class="link-side">Accueil</p>
            </router-link>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/coran" class="link">
              <p class="link-side">Coran</p>
            </router-link>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/versets" class="link">
              <p class="link-side">Recherche</p>
            </router-link>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/cours" class="link">
              <p class="link-side">Cours</p>
            </router-link>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/duaas" class="link">
              <p class="link-side">Invocations</p>
            </router-link>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/classement" class="link">
              <p class="link-side">Classement</p>
            </router-link>
          </li>
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/priere" class="link">
              <p class="link-side">Horaires</p>
            </router-link>
          </li>
          <!-- <li>
            <a
              href="https://fr.tipeee.com/doonun/"
              target="_blank"
              class="link"
            >
              <p class="link-side">Soutenir</p>
            </a>
          </li> -->
          <li @click.prevent.stop="toggleSidebar">
            <router-link to="/contact" class="link">
              <p class="link-side">Nous contacter</p>
            </router-link>
          </li>
          <li
            @click.prevent.stop="toggleSidebar"
            class="join"
            v-if="!isLoggedIn"
          >
            <router-link to="/Inscription">
              <span class="text-inscription">Rejoindre Doonun</span>
            </router-link>
          </li>
          <!-- <li @click.prevent.stop="toggleSidebar">
                        <router-link to="/hadith">Hadith</router-link>
                    </li>
                    <li @click.prevent.stop="toggleSidebar">
                        <router-link to="/livres">Livres</router-link>
                    </li> -->
        </ul>
      </div>
      <div class="socials">
        <div class="column-title">
          <p>Nos réseaux</p>
        </div>
        <ul class="reseaux">
          <li>
            <a href="https://discord.gg/YtjESS5SjC" target="_blank"
              ><i class="fa-brands fa-discord"></i
            ></a>
          </li>
          <li>
            <a href="https://twitter.com/Doonunfr" target="_blank">
              <i class="fa-brands fa-twitter"></i
            ></a>
          </li>
          <li>
            <a href="https://www.tiktok.com/@doonunfr" target="_blank"
              ><i class="fa-brands fa-tiktok"></i
            ></a>
          </li>
          <li>
            <a href="https://www.youtube.com/@doonunfr" target="_blank"
              ><i class="fa-brands fa-youtube"></i
            ></a>
          </li>
          <li>
            <a href="https://www.instagram.com/doonunfr/" target="_blank"
              ><i class="fa-brands fa-instagram"></i
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "./Logo.vue";
export default {
  name: "Hamburger-d",
  components: {
    Logo,
  },
  data() {
    return {
      showSidebar: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  methods: {
    toggleSidebar(event) {
      console.log("Toggling sidebar");
      if (this.showSidebar) {
        this.showSidebar = false;
      } else {
        this.showSidebar = true;
      }
      console.log("showSidebar value:", this.showSidebar);
      event.stopPropagation();
    },
    handleSidebarClick(event) {
      if (!this.$refs.boxSiderRef.contains(event.target)) {
        this.showSidebar = false; // Masque la barre latérale si vous cliquez en dehors de boxSiderRef
      }
    },
    handleClickOutside(event) {
      const hambEl = this.$refs.hamburgerRef;
      const sidebarEl = this.$refs.sidebarRef;

      if (
        hambEl &&
        !hambEl.contains(event.target) &&
        sidebarEl &&
        !sidebarEl.contains(event.target)
      ) {
        this.showSidebar = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.hamburger {
  height: 30px;
  width: 30px;
}

label svg path {
  stroke: var(--text);
}

.sidebar .logo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sidebar .logo p {
  font-weight: 500;
  margin-bottom: 20px;
}

.sidebar .logo .img-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: var(--br10);
  background: var(--bglogo);
  margin-bottom: 20px;
}

.sidebar {
  display: block !important;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000053;
  z-index: 1012 !important;
}

.sidebar .box-sider {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--padding);
  width: 70%;
  min-height: 100vh;
  overflow-y: auto;
  background: var(--card);
}

.liens .link {
  padding: 10px 0;
  border-top: none;
  border-bottom: 0.5px dashed var(--black);
  font-size: 16px;
  font-weight: 400;
}

.liens li {
  list-style: none;
}

.liens li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.column-title p {
  margin-bottom: 20px;
}

.join {
  margin-top: 20px;
  background: var(--button);
  padding: 10px 20px;
  border-radius: 5px;
}

.join a {
  display: flex;
  justify-content: center !important;
  align-items: center;
}

.join span {
  color: #fff;
  font-weight: 500;
}

.reseaux {
  display: flex;
  align-items: center;
  gap: 25px;
}

.reseaux li {
  list-style: none;
}

.reseaux i {
  font-size: 20px;
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.box-sider {
  padding-bottom: 5vh !important;
}

@-webkit-keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>
