<template>
  <div class="box">
    <h3 id="scroll-target">Avatars et Thèmes</h3>
    <div class="themes">
      <Theme />
    </div>
    <div class="avatars">
      <SliderAvatar />
    </div>
  </div>
</template>

<script>
import SliderAvatar from "../SliderAvatar.vue";
import Theme from "../Theme.vue";
export default {
  name: "avatar-theme",
  components: {
    SliderAvatar,
    Theme,
  },
};
</script>

<style scoped>
.box {
  margin-top: 100px;
  margin-bottom: 100px;
}

h3 {
  font-size: 25px;
  margin-bottom: 40px !important;
}

.avatars {
  margin-top: 40px;
}

@media (max-width: 800px) {
  .box {
    margin-top: 30px;
  }
}
</style>
