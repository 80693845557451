<template>
  <div class="sui">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="24"
      height="24"
      color="#000000"
      fill="none"
    >
      <path
        d="M20 12L4 12"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 17C15 17 20 13.3176 20 12C20 10.6824 15 7 15 7"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "Suivant-d",
  // watch: {
  //     '$route': 'fetchData'
  // },
};
</script>

<style scoped>
.sui {
  display: flex;
  align-items: center;
}

.sui a {
  display: flex;
  align-items: center;
}

.sui svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.sui svg path {
  stroke: var(--text);
}
</style>
