<template >
    <div class="blitz">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_bold_flash" data-name="vuesax/bold/flash" transform="translate(-428 -316)">
                <g id="flash">
                    <path id="Vector"
                        d="M13.186,8.721H10.1v-7.2c0-1.68-.91-2.02-2.02-.76l-.8.91-6.77,7.7c-.93,1.05-.54,1.91.86,1.91h3.09v7.2c0,1.68.91,2.02,2.02.76l.8-.91,6.77-7.7C14.976,9.581,14.586,8.721,13.186,8.721Z"
                        transform="translate(432.724 317.999)" fill="#292d32" />
                    <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(452 340) rotate(180)"
                        fill="none" opacity="0" />
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Blitz-d',
}
</script>

<style>
.blitz {
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
}

.blitz svg {
    width: 15px;
    height: 15px;
}

.blitz svg path {
    fill: var(--text) !important;
}
</style>