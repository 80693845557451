<template>
  <div class="box-c-player">
    <div class="box-p-b">
      <audio
        autoplay
        ref="audio"
        :src="`https://cp.doonun.com/${urlAudio}`"
        @timeupdate="updateProgress"
        @loadedmetadata="initializeProgress"
        @ended="audioEnded"
        @playing="audioPlaying"
      ></audio>
      <button @click="togglePlay">
        <i v-if="!isPlaying" class="fa-solid fa-play"></i>
        <i v-else class="fa-solid fa-pause"></i>
      </button>
      <p>{{ formatTime(duration) }}</p>
    </div>
    <div class="audio-controls range">
      <input
        ref="progressBar"
        class="progress-bar win10-thumb"
        type="range"
        min="0"
        :max="duration"
        step="1"
        v-model="currentTime"
        @input="seekTo"
      />
    </div>
    <p>{{ formatTime(currentTime) }}</p>
  </div>
</template>

<script>
export default {
  name: "audio-player-course",
  props: {
    urlAudio: String,
  },
  data() {
    return {
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      currentProgress: 0,
    };
  },
  methods: {
    togglePlay() {
      const audio = this.$refs.audio;
      if (this.isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    updateProgress(event) {
      this.currentTime = event.target.currentTime;
      this.currentProgress = (this.currentTime / this.duration) * 100;
      if (this.$refs.progressBar) {
        this.$refs.progressBar.style.setProperty(
          "--progress-width",
          `${this.currentProgress}%`
        );
      }
    },
    initializeProgress(event) {
      this.duration = event.target.duration;
    },
    seekTo() {
      const audio = this.$refs.audio;
      audio.currentTime = this.currentTime;
    },
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
    audioEnded() {
      // Mettre l'état de lecture à false et revenir au début de l'audio
      this.isPlaying = false;
      this.currentTime = 0;
    },
    audioPlaying() {
      // Mettre l'état de lecture à true lorsque l'audio est en lecture
      this.isPlaying = true;
    },
  },
};
</script>

<style scoped>
audio {
  width: 100%;
  margin-bottom: 10px;
}

.box-c-player {
  margin-top: 15px;
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 20px;
}

.box-p-b {
  display: flex;
  align-items: center;
  gap: 10px;
}

button {
  display: inline-block;
  background: var(--body);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  color: var(--text);
  border: none;
  border-radius: 5px;
  outline: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: 0.2s;
}

button:hover {
  background-color: var(--button);
  color: #fff;
}

.audio-controls {
  display: flex;
  align-items: center;
}

.progress-bar {
  flex-grow: 1;
}

.progress-bar {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 4px;
  background: var(--body);
  --progress-width: 0%;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 14px;
  width: 14px;
  background-color: var(--card);
  border-radius: 50%;
  border: 3px solid var(--text);
  margin-top: -5px;
}

.progress-bar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: var(--text);
  border-radius: 50%;
  border: none;
}

.progress-bar::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(30, 0, 255, 0.6);
}

.progress-bar::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    var(--text) var(--progress-width),
    #ecf1fd 0
  );
  height: 4px;
  border-radius: 15px;
}

.progress-bar::-moz-range-track {
  background: linear-gradient(
    to right,
    var(--text) var(--progress-width),
    #ecf1fd 0
  );
  height: 4px;
  border-radius: 15px;
}

.progress-bar::-ms-track {
  background: linear-gradient(
    to right,
    var(--text) var(--progress-width),
    #ecf1fd 0
  );
  height: 4px;
  border-radius: 15px;
}
</style>
