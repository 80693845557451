import { registerRoute } from "workbox-routing";
import { StaleWhileRevalidate } from "workbox-strategies";
import { CacheableResponsePlugin } from "workbox-cacheable-response";
import { ExpirationPlugin } from "workbox-expiration";

const CACHE_NAME = "my-cache";
const FOUR_HOURS_IN_MS = 4 * 60 * 60 * 1000;
const CURRENT_CACHE_VERSION = "v1";

// Mise en cache des ressources statiques (scripts, styles, images)
registerRoute(
  ({ request }) =>
    request.destination === "script" ||
    request.destination === "style" ||
    request.destination === "image",
  new StaleWhileRevalidate({
    cacheName: `${CACHE_NAME}-${CURRENT_CACHE_VERSION}`,
    plugins: [
      new CacheableResponsePlugin({ statuses: [200] }),
      new ExpirationPlugin({ maxAgeSeconds: FOUR_HOURS_IN_MS }),
    ],
  })
);

// Gestion de l'événement de mise à jour du service worker
self.addEventListener("message", (event) => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    self.skipWaiting();
  }
});

// Nettoyage des anciennes versions du cache lors de l'activation
self.addEventListener("activate", (event) => {
  event.waitUntil(
    caches.keys().then((cacheNames) => {
      return Promise.all(
        cacheNames
          .filter(
            (cacheName) =>
              cacheName.startsWith(`${CACHE_NAME}-`) &&
              cacheName !== `${CACHE_NAME}-${CURRENT_CACHE_VERSION}`
          )
          .map((cacheName) => caches.delete(cacheName))
      );
    })
  );
});

// Installation du service worker
self.addEventListener("install", (event) => {
  event.waitUntil(
    caches.open(`${CACHE_NAME}-${CURRENT_CACHE_VERSION}`).then((cache) => {
      return cache.addAll([]);
    })
  );
});

// Gestion des requêtes fetch
self.addEventListener("fetch", (event) => {
  if (event.request.method !== "GET") {
    return;
  }

  if (event.request.url.includes("/api/")) {
    return;
  }

  event.respondWith(
    caches.open(`${CACHE_NAME}-${CURRENT_CACHE_VERSION}`).then((cache) => {
      return cache.match(event.request).then((response) => {
        const fetchPromise = fetch(event.request).then((fetchResponse) => {
          // Mettre en cache la nouvelle réponse
          cache.put(event.request, fetchResponse.clone());
          return fetchResponse;
        });

        // Afficher une alerte lorsque la nouvelle version est détectée
        fetchPromise.then(() => {
          self.clients.matchAll().then((clients) => {
            clients.forEach((client) => {
              client.postMessage({
                type: "NEW_VERSION_AVAILABLE",
              });
            });
          });
        });

        return response || fetchPromise;
      });
    })
  );
});

// Gestion de l'événement de clic sur le bouton de mise à jour du cache
self.addEventListener("message", (event) => {
  if (event.data && event.data.type === "CLEAR_CACHE") {
    event.waitUntil(
      caches.delete(`${CACHE_NAME}-${CURRENT_CACHE_VERSION}`).then(() => {
        self.clients.matchAll().then((clients) => {
          clients.forEach((client) => {
            client.postMessage({
              type: "CACHE_CLEARED",
            });
          });
        });
      })
    );
  }
});
