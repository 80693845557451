<template>
  <div class="container">
    <h1>Cours</h1>
    <div class="input-search-c">
      <div class="course-search">
        <form @submit.prevent="fetchFilteredCours">
          <input
            type="search"
            placeholder="Rechercher un cours..."
            v-model="recherche"
          />
        </form>
      </div>
    </div>
    <div class="filtres-course">
      <ul>
        <li class="card-line" @click="isListView = true">
          <i class="fa-solid fa-grip-lines"></i>
        </li>
        <li class="card-grid" @click="isListView = false">
          <i class="fa-solid fa-grip"></i>
        </li>
        <li @click="setOrder('default')">
          <i class="fa-solid fa-earth-africa"></i>Tous
        </li>
        <li @click="setOrder('nouveaux')">
          <i class="fa-solid fa-fire-flame-curved"></i>Nouveaux
        </li>
        <li @click="setOrder('points')">
          <i class="fa-solid fa-arrow-up-1-9"></i>Points
        </li>
      </ul>
      <div class="filter">
        <div class="select">
          <form @submit.prevent="fetchFilteredCours">
            <select v-model="level" class="minimal">
              <option value="" style="display: none">Niveau</option>
              <option value="">Tous</option>
              <option value="debutant">Débutant</option>
              <option value="intermediaire">Intermédiaire</option>
              <option value="avance">Avancé</option>
            </select>
          </form>
          <i class="fa-solid fa-chevron-down"></i>
        </div>
        <div class="select">
          <form @submit.prevent="fetchFilteredCours">
            <select v-model="category" class="minimal">
              <option value="" style="display: none">Catégorie</option>
              <option value="">Tous</option>
              <option value="Al-Qur'an">Coran</option>
              <option value="Hadith">Hadith</option>
              <option value="Fiqh">Fiqh</option>
              <option value="Usul">Usul al-Fiqh</option>
              <option value="Aqidah">Croyance</option>
              <option value="Lugha">Langue</option>
              <option value="Akhlaq">Ethique</option>
              <option value="Tarikh">Histoire</option>
              <option value="Conseils">Conseils</option>
              <option value="Questions">Questions</option>
            </select>
          </form>
          <i class="fa-solid fa-chevron-down"></i>
        </div>
      </div>
    </div>
    <div v-if="filteredCoursList.length === 0">
      <p style="text-align: center; margin-top: 30px">
        Aucun cours n'a été trouvé !
      </p>
    </div>
    <div v-if="filteredCoursList.length !== 0">
      <div class="new" v-if="!isListView">
        <NewCardCourse
          v-for="course in paginatedCoursList"
          :key="course.titre"
          :titre="course.titre"
          :points="Number(course.points)"
          :category="course.Category"
          :img="course.Couverture.data.attributes.formats.small.url"
          :level="course.niveau"
          :slug="course.url"
        />
      </div>
      <div class="container-cours" v-else>
        <CardCourseList
          v-for="course in paginatedCoursList"
          :key="course.titre"
          :titre="course.titre"
          :points="Number(course.points)"
          :category="course.Category"
          :img="course.Couverture.data.attributes.formats.small.url"
          :level="course.niveau"
          :slug="course.url"
        />
      </div>
    </div>
    <Paginations
      :total-items="filteredCoursList.length"
      :items-per-page="itemsPerPage"
      :current-page="currentPage"
      @page-changed="handlePageChange"
    />
  </div>
  <Head>
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <Tabs />
</template>

<script>
import Paginations from "../components/Paginations.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import CardCourse from "../components/CardCours.vue";
import NewCardCourse from "../components/NewCardCourse.vue";
import CardCourseList from "../components/CardCoursList.vue";
import { useHead, Head } from "@vueuse/head";
import Tabs from "../components/Tabs.vue";
export default {
  name: "Cours-d",
  setup() {
    useHead({
      title: "Cours islamique - Doonun",
      meta: [
        {
          name: `description`,
          content:
            "Apprenez les sciences islamiques sur Doonun. Découvrez notre sélection de cours en ligne sur des sujets variés tels que la croyance, la jurisprudence, la spiritualité et bien plus encore. Inscrivez-vous dès maintenant et commencez votre voyage d'apprentissage islamique.",
        },
        {
          name: "robots",
          content: "index, follow, noarchive",
        },
        {
          property: "og:title",
          content: "Accueil - Doonun",
        },
        {
          property: "og:description",
          content:
            "Apprenez les sciences islamiques sur Doonun. Découvrez notre sélection de cours en ligne sur des sujets variés tels que la croyance, la jurisprudence, la spiritualité et bien plus encore. Inscrivez-vous dès maintenant et commencez votre voyage d'apprentissage islamique.",
        },
        {
          property: "og:image",
          content: "https://cp.doonun.com/uploads/CORAN_e6d55279b0.png",
        },
        {
          property: "og:url",
          content: "https://www.doonun.com/coran",
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:title",
          content: "Accueil - Doonun",
        },
        {
          name: "twitter:site",
          content: "@doonunfr",
        },
        {
          name: "twitter:creator",
          content: "@doonunfr",
        },
        {
          name: "twitter:description",
          content:
            "Apprenez les sciences islamiques sur Doonun. Découvrez notre sélection de cours en ligne sur des sujets variés tels que la croyance, la jurisprudence, la spiritualité et bien plus encore. Inscrivez-vous dès maintenant et commencez votre voyage d'apprentissage islamique.",
        },
        {
          name: "twitter:image",
          content: "https://cp.doonun.com/uploads/CORAN_e6d55279b0.png",
        },
      ],
    });
  },
  components: {
    // CardCourse,
    CardCourseList,
    Paginations,
    Head,
    Tabs,
    NewCardCourse,
  },
  data() {
    return {
      recherche: "",
      level: "",
      category: "",
      currentPage: 1,
      itemsPerPage: 20,
      order: "default",
      isListView: false,
    };
  },
  computed: {
    ...mapGetters("cours", ["filteredCoursList"]),
    ...mapState("cours", ["recherche", "level", "category"]),
    paginatedCoursList() {
      let start = (this.currentPage - 1) * this.itemsPerPage;
      let end = start + this.itemsPerPage;
      let courses = this.filteredCoursList.slice();

      if (this.order === "nouveaux") {
        courses.reverse();
      } else if (this.order === "points") {
        courses.sort((a, b) => a.points - b.points);
      }

      return courses.slice(start, end);
    },
  },
  methods: {
    ...mapActions("cours", ["fetchCoursList", "updateFilter"]),
    ...mapMutations("cours", ["SET_RECHERCHE", "SET_LEVEL", "SET_CATEGORY"]),

    fetchFilteredCours() {
      this.$store.commit("cours/SET_FILTER", {
        searchTerm: this.recherche,
        level: this.level,
        category: this.category,
      });
      this.fetchCoursList();
    },

    handlePageChange(newPage) {
      this.currentPage = newPage;
    },

    setOrder(order) {
      this.order = order;
      this.currentPage = 1;
      if (order !== "points") {
        this.fetchCoursList();
      }
    },
  },
  mounted() {
    this.fetchCoursList();
  },
  created() {
    this.recherche = this.$store.state.cours.recherche;
    this.level = this.$store.state.cours.level;
    this.category = this.$store.state.cours.category;
    this.fetchFilteredCours();
  },
  watch: {
    recherche(newVal) {
      this.SET_RECHERCHE(newVal);
      this.fetchFilteredCours();
    },
    level(newVal) {
      this.SET_LEVEL(newVal);
      this.fetchFilteredCours();
    },
    category(newVal) {
      this.SET_CATEGORY(newVal);
      this.fetchFilteredCours();
    },
  },
};
</script>

<style scoped>
h1 {
  margin: 20px 0;
  text-align: center;
}

.container-cours {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
}

.new {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(264px, 1fr));
  gap: 20px;
}

.filtres-course {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.filtres-course ul {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.filtres-course ul li {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 8px 13px;
  border-radius: 8px;
  list-style: none;
  background: var(--card);
  font-size: 13px;
  font-weight: 500;
  transition: 0.2s;
}

.filtres-course ul li:hover {
  color: #fff;
  background: var(--button);
}

.course-search form {
  display: flex;
  justify-content: center;
  width: 100%;
}

.course-search form input {
  width: 100%;
  max-width: 600px;
  padding: 15px 20px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  color: var(--text);
  font-family: "Poppins", "Inter", sans-serif;
}

.course-search form input::placeholder {
  color: var(--placeholder);
}

.filter {
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.filtres-course .select {
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background: var(--card);
  border-radius: 8px;
}

.filtres-course .select i {
  position: absolute;
  right: 0;
  font-size: 14px;
  margin-right: 12px;
  margin-top: -3px;
  pointer-events: none;
}

.filtres-course form {
  width: 100%;
}

.filtres-course select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: var(--text);
  outline: none;
  border: none;
  width: 100%;
  border-radius: 8px;
}

.filtres-course select::-ms-expand {
  display: none;
}

.filtres-course select option {
  background-color: var(--card);
  letter-spacing: 1.2px;
  font-weight: 400;
  font-size: 16px;
  border-radius: 8px;
}

@media (max-width: 726px) {
  .filter {
    max-width: 100%;
  }

  .filtres-course {
    margin-top: 0px;
  }

  .filtres-course ul {
    margin-top: 10px;
    margin-bottom: 0;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }

  .filtres-course ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
  }

  .filtres-course ul li {
    flex-grow: 1;
  }
}
</style>
