<template>
  <div class="share-container">
    <p>Partager sur :</p>
    <div class="twitter" @click="shareTwitter">
      <i class="fa-brands fa-twitter"></i>
    </div>
    <div class="facebook" @click="shareFacebook">
      <i class="fa-brands fa-facebook-f"></i>
    </div>
    <div class="whatsapp" @click="shareWhatsApp">
      <i class="fa-brands fa-whatsapp"></i>
    </div>
    <div class="telegram" @click="shareTelegram">
      <i class="fa-brands fa-telegram"></i>
    </div>
    <div class="lien" @click="copyLink">
      <i class="fa-solid fa-link"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Share-d",
  props: {
    textToShare: {
      type: String,
      required: true,
    },
  },
  methods: {
    shareTwitter() {
      const url =
        "https://twitter.com/intent/tweet?text=" +
        encodeURIComponent(
          `Invocation : ${this.textToShare} ${window.location.href}`
        );
      window.open(url, "_blank");
    },
    shareFacebook() {
      const url =
        "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(
          `Invocation : ${this.textToShare} ${window.location.href}`
        );
      window.open(url, "_blank");
    },
    shareWhatsApp() {
      const url =
        "whatsapp://send?text=" +
        encodeURIComponent(
          `Invocation : ${this.textToShare} ${window.location.href}`
        );
      window.open(url);
    },
    shareTelegram() {
      const url =
        "https://t.me/share/url?url=" +
        encodeURIComponent(window.location.href) +
        "&text=" +
        encodeURIComponent(
          `Invocation : ${this.textToShare} ${window.location.href}`
        );
      window.open(url, "_blank");
    },
    async copyLink() {
      try {
        await navigator.clipboard.writeText(window.location.href);
        alert("Lien copié dans le presse-papiers !");
      } catch (error) {
        console.error("Erreur lors de la copie du lien :", error);
      }
    },
  },
};
</script>

<style scoped>
.share-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: end;
}

.share-container p {
  font-weight: 500;
}

.share-container div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  outline: 1px solid #000;
  border-radius: 10px;
  opacity: 0.5;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.share-container div:hover {
  background: var(--button);
  outline: none;
  opacity: 1;
}

.share-container div:hover i {
  color: #fff;
}

@media (max-width: 480px) {
  .share-container {
    justify-content: center;
  }

  .share-container p {
    display: none;
  }
}
</style>
