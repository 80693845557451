<template>
  <router-link :to="sourateLink">
    <div class="card-quran">
      <div class="chg">
        <div class="top-s">
          <div class="num">
            <p>{{ sourate.Numero }}</p>
          </div>
          <div class="fav">
            <p>{{ localSourate.likes }}</p>
            <div class="con-like" @click.stop>
              <input
                class="like"
                type="checkbox"
                title="like"
                v-model="liked"
                @change="toggleLike"
              />
              <div class="checkmark">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="outline"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z"
                  ></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="filled"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div class="info-s-c">
          <p class="ph">{{ sourate.Phonetique }}</p>
          <p class="info-q-c">
            <span class="titre-q">{{ sourate.Arabe }}</span>
            <span> : {{ sourate.Titre }}</span>
            <span>
              a été révélée à
              <span v-if="sourate.Revelation === 'Mecque'">la</span>
              {{ sourate.Revelation }}</span
            >
            <span>, et contient {{ sourate.Nombre }} versets</span>
          </p>
        </div>
        <div v-if="isUserConnected" class="progress">
          <div class="evolution">
            <div class="bar" :style="{ width: taux + '%' }"></div>
          </div>
          <p class="pourcent">{{ taux }}%</p>
        </div>
        <p class="vues">{{ localSourate.vues }} vues</p>
      </div>
    </div>
  </router-link>

  <PopupSub
    @close-popup="closePopup"
    v-if="displaySub"
    :url="`https://cp.doonun.com/uploads/save_6039249_b978ff8dab.png`"
    :alert="'Oops ! Il semblerait que vous ayez touché le plafond de favoris : 20 pour les membres et 5 pour les utilisateurs.'"
  ></PopupSub>
</template>

<script>
import { mapGetters } from "vuex";
import PopupSub from "./PopupSub.vue";
export default {
  name: "Card-d",
  components: {
    PopupSub,
  },
  props: {
    sourate: Object,
    versetsCheck: Object,
    taux: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      user: {},
      liked: false,
      localSourate: null,
      isLoading: true,
      displaySub: false,
    };
  },
  computed: {
    ...mapGetters("sub", ["isSubscribed"]),
    isUserConnected() {
      return this.user && Object.keys(this.user).length > 0;
    },
    sourateLink() {
      return `/sourate/${this.sourate.Numero}`;
    },
    hasSubscription() {
      return this.isSubscribed;
    },
  },
  created() {
    this.localSourate = { ...this.sourate };
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    async initializeData() {
      try {
        await Promise.all([this.fetchLikes(), this.fetchVues()]);

        // Récupération de l'utilisateur
        const token = localStorage.getItem("jwtToken");
        if (token) {
          const response = await this.$axios.get("users/me", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          this.user = response.data;

          // Vérification du like
          const users = this.localSourate.id_users
            ? this.localSourate.id_users.split("|")
            : [];
          if (
            this.user &&
            this.user.id &&
            users.includes(this.user.id.toString())
          ) {
            this.liked = true;
          }
        }

        this.isLoading = false;
      } catch (error) {
        // console.error("Erreur lors de la récupération des informations:", error);
      }
    },
    closePopup() {
      this.displaySub = false;
    },
    async fetchLikes() {
      try {
        const localData = this.getLocalSourateData(this.sourate.Numero);
        if (localData && localData.likes !== undefined) {
          this.localSourate.likes = localData.likes;
          this.localSourate.id_users = localData.id_users || "";
        } else {
          const response = await this.$axios.get(
            `/like-sourates/${this.sourate.Numero}`
          );
          this.localSourate.likes = response.data.data.attributes.likes;
          this.localSourate.id_users =
            response.data.data.attributes.id_users || "";
          this.updateLocalSourateData(this.sourate.Numero, {
            likes: this.localSourate.likes,
            id_users: this.localSourate.id_users,
          });
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des likes:", error);
      }
    },

    async fetchVues() {
      try {
        const localData = this.getLocalSourateData(this.sourate.Numero);
        if (localData && localData.vues !== undefined) {
          this.localSourate.vues = localData.vues;
        } else {
          const response = await this.$axios.get(
            `/vues-sourates/${this.sourate.Numero}`
          );
          this.localSourate.vues = response.data.data.attributes.vues;
          this.updateLocalSourateData(this.sourate.Numero, {
            vues: this.localSourate.vues,
          });
        }
      } catch (error) {
        // console.error("Erreur lors de la récupération des vues:", error);
      }
    },

    getLocalSourateData(numero) {
      const localData = localStorage.getItem(`sourateData`);
      return localData ? JSON.parse(localData)[numero] : null;
    },

    updateLocalSourateData(numero, data) {
      const existingData =
        JSON.parse(localStorage.getItem(`sourateData`)) || {};
      existingData[numero] = { ...existingData[numero], ...data };
      localStorage.setItem(`sourateData`, JSON.stringify(existingData));
    },
    async toggleLike() {
      if (this.isUserConnected) {
        const favoritesLimit = this.hasSubscription ? 20 : 5;
        try {
          const response = await this.$axios.get(
            `/like-sourates/${this.sourate.Numero}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
                "Content-Type": "application/json",
              },
            }
          );
          if (response.data && response.data.data) {
            const currentIdUsers = response.data.data.attributes.id_users || "";
            const users = currentIdUsers.split("|");
            const userIndex = users.indexOf(this.user.id.toString());
            if (userIndex === -1) {
              // User is trying to add a like
              const currentLikesCount = this.getLocalSourateLikesCount();
              if (currentLikesCount >= favoritesLimit) {
                this.displaySub = true;
                return; // Prevent adding the like if limit is reached
              }
              this.localSourate.likes += 1;
              users.push(this.user.id.toString());
            } else {
              // User is trying to remove a like
              this.localSourate.likes -= 1;
              users.splice(userIndex, 1);
            }
            this.localSourate.id_users = users.join("|");
            await this.$axios.put(
              `/like-sourates/${this.sourate.Numero}`,
              {
                data: {
                  likes: this.localSourate.likes,
                  id_users: this.localSourate.id_users.toString(),
                },
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
                  "Content-Type": "application/json",
                },
              }
            );
            this.updateLocalSourateData(this.sourate.Numero, {
              likes: this.localSourate.likes,
              id_users: this.localSourate.id_users,
            });
          } else {
            throw new Error(
              "Aucune entrée correspondante trouvée pour la sourate."
            );
          }
        } catch (error) {
          console.error("Erreur lors de la mise à jour des likes:", error);
        }
      }
    },

    getLocalSourateLikesCount() {
      const localData = JSON.parse(localStorage.getItem(`sourateData`)) || {};
      return Object.values(localData).filter(
        (data) =>
          data.id_users &&
          data.id_users.split("|").includes(this.user.id.toString())
      ).length;
    },
  },
};
</script>

<style>
.card-quran {
  position: relative;
  max-width: 700px;
  width: 100%;
  min-width: 235px;
  min-height: 180px;
  height: 245px;
  background: var(--card);
  border-radius: var(--br10);
  padding: var(--padding);
}

@media (max-width: 576px) {
  .card-quran {
    min-width: 285px;
  }
}

.card-quran .chg {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  color: var(--text);
  gap: 10px;
}

.info-s-c {
  min-height: 105px;
}

.card-quran .vues {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 13px;
  margin-left: 20px;
  margin-bottom: 10px;
}

.card-quran .top-s {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: top;
}

.card-quran .top-s .fav {
  display: flex;
  align-items: top;
  justify-content: end;
  gap: 10px;
}

.card-quran .top-s .fav p {
  margin-top: -2px;
  font-weight: 400;
}

.card-quran .num {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--body);
  padding: 10px;
  border-radius: var(--br10);
}

.card-quran .num p {
  font-weight: 700;
  font-size: 16px;
}

.card-quran .ph {
  font-size: 16px;
  font-weight: 600;
}

.card-quran .titre-q {
  font-family: "Noto Sans Arabic", sans-serif;
  font-weight: 600;
}

.progress {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0 5px;
  /* margin-bottom: 10px; */
}

.progress p {
  font-size: 13px;
  font-weight: 500;
}

.progress .evolution {
  height: 8px;
  width: 100%;
  background: var(--body);
  border-radius: var(--br30);
}

.progress .evolution .bar {
  height: 8px;
  width: 30%;
  border-radius: var(--br30);
  background: var(--button);
  /* box-shadow: rgba(86, 61, 241, 0.411) 0px 4px 16px,
    rgba(160, 61, 241, 0.08) 0px 8px 32px; */
}

.con-like {
  --red: rgb(255, 91, 137);
  position: relative;
  width: 20px;
  height: 20px;
}

.con-like .like {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}

.con-like .checkmark {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.con-like .outline,
.con-like .filled {
  fill: var(--text);
  position: absolute;
}

.con-like .filled {
  animation: kfr-filled 0.5s;
  display: none;
}

.con-like .poly {
  stroke: var(--red);
  fill: var(--red);
}

.con-like .like:checked ~ .checkmark .filled {
  display: block;
}

.skeleton {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.skeleton .top-s-s {
  display: flex;
  justify-content: space-between;
  height: 40px;
}

.skeleton .top-s-s .num-s {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  animation: pulse-bg 1s infinite;
}

.skeleton .top-s-s .fav-s {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  animation: pulse-bg 1s infinite;
}

.skeleton .ph-s {
  margin-top: 10px;
  width: 60%;
  height: 10px;
  border-radius: 5px;
  animation: pulse-bg 1s infinite;
}

.skeleton .info-q-c-s {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.skeleton .info-q-c-s {
  height: 30px;
  border-radius: 5px;
  animation: pulse-bg 1s infinite;
}

.skeleton .progress-s {
  margin-top: 10px;
  height: 8px;
  border-radius: 5px;
  animation: pulse-bg 1s infinite;
}

.skeleton .vues-s {
  width: 30%;
  margin-top: 10px;
  height: 8px;
  border-radius: 5px;
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #ddd;
  }

  50% {
    background-color: #d0d0d0;
  }

  100% {
    background-color: #ddd;
  }
}

@keyframes kfr-filled {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 0.8;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    display: none;
  }
}
</style>
