<template>
  <Head>
    <title>Tarifs - Doonun</title>
    <meta name="robots" content="Noindex, nofollow" />
    <link rel="icon" href="https://cp.doonun.com/uploads/logo_058fb96f65.svg" />
  </Head>
  <div class="poput-tarifs">
    <div class="container">
      <div class="box-tarif">
        <h2>Choisissez l'offre<br />qui vous convient</h2>
        <p></p>
        <div class="c-y-m">
          <p>Mensuelle</p>
          <div class="toggle-switch">
            <input
              class="toggle-input"
              id="toggle"
              type="checkbox"
              @change="toggleAnnual"
            />
            <label class="toggle-label" for="toggle"></label>
          </div>
          <p class="annuel">
            Annuelle <span :class="{ 'actif-f': isAnnual }">-36%</span>
          </p>
        </div>
        <div class="tarifs" v-for="tarif in tarifs" :key="tarif.id">
          <div class="offer" :class="tarif.class">
            <h3>{{ tarif.title }}</h3>
            <p>{{ tarif.description }}</p>
            <p class="prix">{{ tarif.price }}</p>
            <div v-if="tarif.id === 0">
              <router-link to="/profil" class="btn-t">
                <div class="go-by">{{ tarif.btn }}</div>
              </router-link>
            </div>
            <div
              v-else
              class="btn-t SS_ProductCheckout"
              type="button"
              :data-id="tarif.id"
              :data-email="userEmail"
              @click="handleStripeCheckout"
            >
              <div class="go-by" v-if="loading[tarif.id]">
                <loadCircle />
              </div>
              <div class="go-by" v-else>{{ tarif.btn }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "vue3-carousel/dist/carousel.css";
import loadCircle from "../components/loadCircle.vue";
import { mapActions, mapGetters } from "vuex";
import { Head } from "@vueuse/head";

export default {
  name: "page-tarifs",
  components: {
    loadCircle,
    Head,
  },
  data() {
    return {
      rappel: localStorage.getItem("rappel") === "false" ? false : true,
      isAnnual: false,
      isLoading: true,
      user: null,
      userEmail: "",
      token: localStorage.getItem("jwtToken"),
      loading: {},
      settings: {
        itemsToShow: 1,
        snapAlign: "start",
      },
      breakpoints: {
        700: {
          itemsToShow: 1.5,
          snapAlign: "start",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
      tarifs: [
        {
          id: 8,
          class: "premium",
          title: "Etudiant",
          description:
            "L'offre étudiant vous donne un accès complet à la plateforme.",
          price: "4.99€/mois",
          option1: "Fonctionnalités Avancées.",
          option2: "Accès illimité.",
          option3: "Groupe privé des étudiants.",
          btn: "S'abonner",
        },
        {
          id: 3,
          class: "life",
          title: "Pour la vie",
          description: "Payez en une seule fois, et obtenez Doonun à vie.",
          price: "147€",
          option1: "Fonctionnalités Premium.",
          option2: "Contenus Exclusifs.",
          option3: "Groupe privé des étudiants.",
          btn: "Prendre",
        },
        {
          id: 0,
          class: "basic",
          title: "Basic",
          description: "Profitez des options basiques de Doonun gratuitement",
          price: "Gratuit",
          option1: "Fonctionnalités de Base.",
          option2: "Débloquer des cours.",
          option3: "Suivre sa progression",
          btn: "Continuer",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("sub", ["subscriptionDetails", "isSubscribed"]),
    hasSubscription() {
      return this.isSubscribed;
    },
    offerType() {
      return this.subscriptionDetails.offerType;
    },
    date() {
      return this.subscriptionDetails.date;
    },
    oneTime() {
      return this.subscriptionDetails.isOneTime;
    },
    ...mapGetters({
      isLoggedIn: "user/isLoggedIn",
    }),
  },
  async mounted() {
    await this.loadStripeScript();
    await this.fetchUser();
    if (this.token && this.userEmail) {
      await this.checkSubscriptionStatus();
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions("sub", ["checkSubscriptionStatus"]),

    loadStripeScript() {
      if (
        !document.querySelector(
          'script[src="https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://cp.doonun.com/plugins/strapi-stripe/static/stripe.js";
        script.onload = this.initializeStripeButton;
        document.head.appendChild(script);
      }
    },

    handleStripeCheckout(event) {
      const button = event.currentTarget;
      const planId = parseInt(button.getAttribute("data-id"), 10); // Convertir en nombre pour la comparaison
      const userEmail = button.getAttribute("data-email");
      this.loading[planId] = true;

      // Si planId est 1, mettre à jour rappel et arrêter la logique
      if (planId === 0) {
        this.rappel = false;
        localStorage.setItem("rappel", "false");
        this.loading[planId] = false; // Arrêter le chargement
        event.preventDefault(); // Empêcher toute action par défaut (important si ce code est dans une balise <a> ou similaire)
        return; // Stopper l'exécution de la fonction ici
      }

      // La logique existante pour la redirection vers Stripe
      axios
        .get(
          `https://cp.doonun.com/strapi-stripe/getRedirectUrl/${planId}/${userEmail}`,
          {
            headers: { Authorization: `Bearer ${this.token}` },
          }
        )
        .then((response) => {
          if (response.data && response.data.url) {
            window.location.href = response.data.url;
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la redirection vers Stripe:", error);
          this.loading[planId] = false;
        });
    },

    async fetchUser() {
      if (this.token) {
        try {
          const response = await axios.get("users/me", {
            headers: { Authorization: `Bearer ${this.token}` },
          });
          this.user = response.data;
          this.userEmail = this.user.email;
          // Mettre à jour l'e-mail de l'utilisateur dans le store
          this.$store.commit("sub/SET_USER_EMAIL", this.user.email);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations de l'utilisateur:",
            error
          );
        }
      }
    },

    toggleAnnual() {
      this.isAnnual = !this.isAnnual;
      this.tarifs = this.tarifs.map((tarif) => {
        if (tarif.id === 8 || tarif.id === 9) {
          // Vérifiez si l'ID est 7 ou 9
          return {
            ...tarif,
            id: this.isAnnual ? 9 : 7, // Mettez à jour l'ID en fonction de l'état de isAnnual
            price: this.isAnnual ? "40.98€/année" : "4.99€/mois",
          };
        }
        return tarif;
      });
    },
  },
};
</script>

<style scoped>
.poput-tarifs {
  position: fixed;
  top: 0;
  z-index: 1006;
  width: 100%;
  height: 100vh;
  background-color: var(--body);
  padding-top: 50px;
  overflow: auto;
}

.poput-tarifs::-webkit-scrollbar {
  width: 10px !important;
  display: none !important;
}

.poput-tarifs::-webkit-scrollbar-thumb {
  background: var(--card) !important;
}

.box-tarif {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  max-width: 1000px;
  width: 100%;
  overflow: auto;
}

.box-tarif h2 {
  text-align: center;
}

.tarifs {
  display: flex;
  justify-content: center;
  gap: 20px;
  font-size: 18px;
}

.offer {
  position: relative;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 16px;
  max-width: 350px;
}

.offer.premium {
  background: #7ee0d6;
}

.offer.life {
  background: #ffbc01;
}

.offer.basic {
  background: #f2b2d7;
}

.offer h3 {
  font-size: 25px;
  font-weight: 600;
}

.offer .prix {
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.offer .go-by {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  color: #ffffff;
  padding: 10px 30px;
  font-size: 14px;
  line-height: 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 110px;
  height: 40px;
  margin-left: auto;
}

.annuel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.annuel span {
  padding: 3px 10px;
  color: var(--text);
  background: var(--header);

  border-radius: 5px;
  font-weight: 600;
}

.annuel .actif-f {
  background: #ff3358;
  color: #fff;
}

.c-y-m {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 34px;
  border-radius: 17px;
  background-color: #e5e7eb;
  cursor: pointer;
  transition: background-color 0.4s ease;
}

.toggle-label:before {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: transform 0.4s ease;
}

.toggle-input:checked + .toggle-label {
  background-color: #57d798;
}

.toggle-input:checked + .toggle-label:before {
  transform: translateX(26px);
}
</style>
